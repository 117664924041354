import ReactHtmlParser from 'react-html-parser'

export function parseHtmlText(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g

  return ReactHtmlParser(
    text.replace(urlRegex, function (url) {
      return `<a href="${url}" target="_blank">${url}</a>`
    }),
  )
}

export const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1)

export function compareStrings(stringA, stringB) {
  if (stringA === undefined) {
    return -1
  }

  if (stringB === undefined) {
    return 1
  }

  return stringA.localeCompare(stringB)
}

export function compareWellPosition(stringA, stringB) {
  const charPart = [stringA.substring(0, 1), stringB.substring(0, 1)]
  const numPart = [stringA.substring(1) * 1, stringB.substring(1) * 1]

  if (numPart[0] < numPart[1]) return -1

  if (numPart[0] > numPart[1]) return 1

  if (charPart[0] < charPart[1]) return -1

  if (charPart[0] > charPart[1]) return 1
}
