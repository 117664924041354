import { useEffect, useState, useCallback } from 'react'

import AppointmentsService from '../services/appointments-service'
import { handleError } from '../utils/error-utils'

export default function useAppointmentsByPositives(user) {
  const [isCheckedIn, setIsCheckedIn] = useState(false)
  const [appointmentsByPositives, setAppointmentsByPositives] = useState()
  const [
    isAppointmentsLoadingByPositives,
    setAppointmentsLoadingByPositives,
  ] = useState(true)

  const filterCheckedAppointments = useCallback(
    appointmentsArray => {
      return appointmentsArray.filter(item => {
        const {
          finalizedAutomatedBatch,
          finalizedBatch,
          confirmedByDoctor,
        } = item

        if (finalizedAutomatedBatch || finalizedBatch) {
          return isCheckedIn === false ? !confirmedByDoctor : true
        }
        return false
      })
    },
    [isCheckedIn],
  )

  useEffect(() => {
    setAppointmentsLoadingByPositives(true)
    try {
      AppointmentsService.getPositivesByDoctor(user.uid, appointments => {
        setAppointmentsByPositives(filterCheckedAppointments(appointments))
        setAppointmentsLoadingByPositives(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving appointments by positives')
      setAppointmentsLoadingByPositives(false)
    }

    return () => {
      AppointmentsService.unsubscribeAppointments()
    }
  }, [user, filterCheckedAppointments])

  return {
    appointmentsByPositives,
    isAppointmentsLoadingByPositives,
    isCheckedIn,
    setIsCheckedIn,
  }
}
