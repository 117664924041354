import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  Form,
  Input,
  Divider,
  Select,
  Checkbox,
  Radio,
  Typography,
} from 'antd'
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { handleError } from '../../utils/error-utils'
import DateMasked from '../../components/date-masked'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import { RadioGroup } from '../../components/radio-group'
import { removeMask } from '../../utils/date-utils'
import { InstantSearch } from 'react-instantsearch-dom'
import InsuranceProviderSearchBox from '../../components/insurance-provider-search-box'
import { algolia, payersIndex } from '../../config/config'
import PhoneMasked from '../../components/phone-masked'
import HasNoInsuranceModal from '../no-insurance-modal'
import SocialSecurityMasked from '../../components/social-security-masked'
import { STATES } from '../../constants'
import { ProFormSelect } from '@ant-design/pro-form'
import AddressService from '../../services/address-service'
import {
  AddressFieldContainer,
  CityFieldContainer,
  FlexDisplay,
  noDisplay,
  StateFieldContainer,
} from '../styles/edit-result-test-groups-style'
import { COMPANY_NAME } from '../../constants'

const { Option } = Select

const { Text } = Typography

const { Group } = Checkbox
const { confirm } = HasNoInsuranceModal

export default function EditResultModal({
  data,
  isModalVisible,
  onClickClose,
  setEditedInfo,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [socialSecurityNumber, setSocialSecurityNumber] = useState()
  const [hasInsurance, setHasInsurance] = useState()
  const [formEdit] = Form.useForm()
  const { t } = useTranslation()
  const RACE_OPTIONS = t('RACE_OPTIONS', { returnObjects: true })
  const ETHNIC_OPTIONS = t('ETHNIC_OPTIONS', { returnObjects: true })
  const SYMPTHOMS_LIST = t('SYMPTOMS_LIST', { returnObjects: true })
  const RESULT_OPTIONS = t('RESULT_OPTIONS', { returnObjects: true })
  const INSURED_RELATIONSHIP_LIST = t('INSURED_RELATIONSHIP_LIST', {
    returnObjects: true,
  })

  useEffect(() => {
    if (data.socialSecurityNumber) {
      setSocialSecurityNumber(data.socialSecurityNumber)
      const head = data.socialSecurityNumber.substr(0, 5)
      const tail = data.socialSecurityNumber.substr(5)
      formEdit.setFieldsValue({
        socialSecurityNumber: head.replace(/[0-9]/g, '*') + tail,
      })
    }
  }, [data.socialSecurityNumber, formEdit, isModalVisible])

  const Close = () => {
    setIsLoading(false)
    onClickClose()
  }
  const onFinish = async values => {
    try {
      setIsLoading(true)
      values.socialSecurityNumber = socialSecurityNumber
      await setEditedInfo(values)
      Close()
    } catch (error) {
      Close()
      handleError(error, 'Error when updating data')
    }
  }

  const showConfirm = () => {
    confirm({
      title: 'Confirm: No Insurance Plan',
      icon: <ExclamationCircleOutlined />,
      content:
        "By clicking on Ok button you confirm: I swear I don't have any health insurance or not able to afford for one",
      onOk() {},
      onCancel() {},
      cancelButtonProps: {
        ghost: true,
        disabled: true,
        style: noDisplay,
      },
    })
  }

  const handleSSNChange = event => {
    const value = removeMask(event.target.value)
    const counter = value?.length
    const lastNumber = socialSecurityNumber ? socialSecurityNumber : ''
    const currentNumber = value?.substr(counter - 1, counter)
    if (value?.length > socialSecurityNumber?.length || !socialSecurityNumber) {
      setSocialSecurityNumber(lastNumber + currentNumber)
    }
    if (value?.length < socialSecurityNumber?.length) {
      setSocialSecurityNumber(lastNumber.slice(0, -1))
    }
    if (value?.length < 6) {
      formEdit.setFieldsValue({
        socialSecurityNumber: event.target.value.replace(/[0-9]/g, '*'),
      })
    }
  }

  const handleInsuranceChange = value => {
    formEdit.setFieldsValue({ insuranceProviderName: value })
  }

  const handleHasInsuranceChange = event => {
    setHasInsurance(event.target.value)
  }

  const handleRaceChange = chekedValues => {
    return formEdit.setFieldsValue({ race: chekedValues })
  }

  const handleSymptomsChange = chekedValues => {
    const lastListIndex = chekedValues.length - 1

    if (chekedValues[lastListIndex] === 'noneAbove') {
      return formEdit.setFieldsValue({ symptoms: ['noneAbove'] })
    }

    if (chekedValues[0] === 'noneAbove') {
      return formEdit.setFieldsValue({
        symptoms: chekedValues.filter(item => item !== 'noneAbove'),
      })
    }
    return formEdit.setFieldsValue({ symptoms: chekedValues })
  }

  const getErrorMessage = async () => {
    const noMaskSSN = removeMask(formEdit.getFieldValue('socialSecurityNumber'))
    if (noMaskSSN.length > 0 && noMaskSSN.length < 9) {
      return Promise.reject(
        'Social security number was not filled in correctly',
      )
    }
  }
  const getAddressInfo = async typedZip => {
    let response = await AddressService.getAddresByZipcode(typedZip)
    formEdit.setFieldsValue({ state: response.state, city: response.city })
    if (response.error) {
      return false
    }
    return true
  }

  const resetForm = () => {
    formEdit.resetFields()
    onClickClose()
  }

  return (
    <Modal
      title={`Edit ${data?.fullName} info`}
      visible={isModalVisible}
      centered
      width={600}
      closeIcon={<CloseOutlined onClick={onClickClose} />}
      afterClose={resetForm}
      onOk={() => formEdit.submit()}
      destroyOnClose
      footer={[
        <Button key="back" onClick={onClickClose}>
          Return
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => formEdit.submit()}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        name="editResult"
        form={formEdit}
        onFinish={onFinish}
        initialValues={data}
        layout="vertical"
      >
        <ContentSpace>
          <PageTitle>Barcode</PageTitle>
          <Form.Item name="barcode">
            <Input
              name="barcode"
              placeholder="Enter barcode"
              width="100%"
              autoFocus
              hasFeedback
            />
          </Form.Item>
          <Form.Item
            name="barcodeConfirmation"
            dependencies={['barcode']}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    (!getFieldValue('barcode') && !value) ||
                    (!!getFieldValue('barcode') &&
                      getFieldValue('barcode').toString() === value)
                  ) {
                    return Promise.resolve()
                  }
                  return Promise.reject('The two barcodes do not match!')
                },
              }),
            ]}
          >
            <Input
              name="barcodeConfirmation"
              placeholder="Re-enter barcode"
              width="100%"
            />
          </Form.Item>
        </ContentSpace>
        <Divider />
        <ContentSpace>
          <PageTitle>Personal</PageTitle>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: `${t('messages.firstNameRequired')}` },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: `${t('messages.lastNameRequired')}` },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dateOfBirth"
            label="Date of Birth"
            rules={[
              { required: true, message: `${t('messages.dobRequired')}` },
            ]}
          >
            <DateMasked />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: `${t('messages.validEmail')}`,
                type: 'email',
              },
            ]}
          >
            <Input placeholder="example@example.com" />
          </Form.Item>
          <Form.Item name="driverLicenseNumber" label="Drivers License Number">
            <Input placeholder="#####" />
          </Form.Item>
          <Form.Item
            name="socialSecurityNumber"
            label="Social Security Number"
            rules={[{ validator: getErrorMessage, validateTrigger: 'submit' }]}
          >
            <SocialSecurityMasked onChange={handleSSNChange} />
          </Form.Item>
          <Form.Item name="sexAtBirth" label={t('demographics.sex')}>
            <RadioGroup
              options={t('SEX_AT_BIRTH_OPTIONS', { returnObjects: true })}
            />
          </Form.Item>
          <Form.Item name="race" label="Race">
            <Group onChange={handleRaceChange}>
              <ContentSpace>
                {RACE_OPTIONS.map(item => (
                  <Checkbox key={item.key} value={item.value}>
                    {item.label}
                  </Checkbox>
                ))}
              </ContentSpace>
            </Group>
          </Form.Item>
          <Form.Item name="ethnicity" label="Ethnicity">
            <RadioGroup options={ETHNIC_OPTIONS} />
          </Form.Item>
          <Form.Item name="symptoms" label="Symptoms">
            <Group onChange={handleSymptomsChange}>
              <ContentSpace>
                {SYMPTHOMS_LIST.map(item => (
                  <Checkbox key={item.key} value={item.key}>
                    {item.title}
                  </Checkbox>
                ))}
              </ContentSpace>
            </Group>
          </Form.Item>
          <Form.Item label={t('phoneNumber.placeholder')} name="phoneNumber">
            <PhoneMasked autoFocus={false} />
          </Form.Item>
        </ContentSpace>
        <Divider />
        <ContentSpace>
          <PageTitle>Address</PageTitle>
          <Input.Group>
            <AddressFieldContainer name="street1" label="Street Address">
              <Input placeholder="Street and number" />
            </AddressFieldContainer>
            <Form.Item name="street2">
              <Input placeholder="Apartment, suite, building, floor, etc." />
            </Form.Item>
          </Input.Group>
          <Form.Item
            label={t('address.zipcode')}
            name="zip"
            rules={[
              {
                required: true,
                message: `${t('rules.zipRequired')}`,
              },
              {
                async validator(_, value) {
                  if (
                    value &&
                    value.length === 5 &&
                    (await getAddressInfo(value))
                  ) {
                    return Promise.resolve()
                  }
                  formEdit.setFieldsValue({ state: '', city: '' })
                  return Promise.reject('Invalid Zip code')
                },
              },
            ]}
          >
            <Input placeholder="#####" />
          </Form.Item>
          <FlexDisplay>
            <CityFieldContainer label="City" name="city">
              <Input placeholder="City Name" />
            </CityFieldContainer>
            <StateFieldContainer label="State" name="state">
              <ProFormSelect
                name="state"
                placeholder={t('address.state')}
                valueEnum={STATES}
                width="100%"
              />
            </StateFieldContainer>
          </FlexDisplay>
        </ContentSpace>
        <Divider />
        <ContentSpace>
          <PageTitle>Insurance</PageTitle>
          <Text>{t('hasInsurance.title', { String: COMPANY_NAME })}</Text>
          <Form.Item name="hasInsurance">
            <Radio.Group onChange={handleHasInsuranceChange}>
              <ContentSpace>
                <Radio key={1} value="yes">
                  {t('affirmation.positive')}
                </Radio>
                <Radio key={2} value="no" onClick={() => showConfirm()}>
                  {t('affirmation.negative')}
                </Radio>
              </ContentSpace>
            </Radio.Group>
          </Form.Item>
          {hasInsurance === 'yes' && (
            <ContentSpace>
              <Form.Item
                label={t('planDetail.insuranceProvider')}
                name="insuranceProviderName"
              >
                <InstantSearch
                  indexName={payersIndex.indexName}
                  searchClient={algolia}
                >
                  <InsuranceProviderSearchBox
                    getFieldValue={formEdit.getFieldValue}
                    handleChange={handleInsuranceChange}
                  />
                </InstantSearch>
              </Form.Item>
              <Form.Item
                label={t('planDetail.memberId')}
                name="insuranceMemberId"
              >
                <Input placeholder={t('planDetail.memberIdPlaceholder')} />
              </Form.Item>

              <Form.Item label={t('planDetail.groupMember')} name="groupNumber">
                <Input placeholder={t('planDetail.groupPlaceholder')} />
              </Form.Item>

              <Form.Item
                label={t('planDetail.insuredRelationship')}
                name="insuredRelationship"
              >
                <Select placeholder={t('planDetail.selection')}>
                  {INSURED_RELATIONSHIP_LIST.map(({ key, title }) => (
                    <Option key={key} value={key}>
                      {title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </ContentSpace>
          )}
        </ContentSpace>
      </Form>
    </Modal>
  )
}
