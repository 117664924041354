import { useEffect, useState } from 'react'

import AppointmentsService from '../services/appointments-service'
import { firebase } from '../services/firebase-service'
import { handleError } from '../utils/error-utils'

export default function useAppointmentsByUser() {
  const user = firebase.auth().currentUser
  const [appointmentsByUser, setAppointmentsByUser] = useState()
  const [isAppointmentsByUserLoading, setAppointmentsByUserLoading] = useState(
    true,
  )

  useEffect(() => {
    setAppointmentsByUserLoading(true)
    try {
      AppointmentsService.observeAppointmentsByUser(user.uid, appointments => {
        setAppointmentsByUser(appointments)
        setAppointmentsByUserLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving appointments by user')
      setAppointmentsByUserLoading(false)
    }

    return () => {
      AppointmentsService.unsubscribeAppointments()
    }
  }, [user.uid])

  return {
    appointmentsByUser,
    isAppointmentsByUserLoading,
  }
}
