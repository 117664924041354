import { StyledFirebaseAuth } from 'react-firebaseui'
import styled from 'styled-components'

const LoginForm = styled(StyledFirebaseAuth)`
  .firebaseui-container {
    min-height: 150px;
    box-shadow: none;
    max-width: 100% !important;
  }
  .firebaseui-container.firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  .firebaseui-container.firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: ${props => props.theme.spacing.xg} !important;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  .firebaseui-card-header {
    padding: 0 !important;

    &::before {
      content: 'Sign in with email';
      font-weight: 500 !important;
      font-size: 30px !important;
      line-height: 36px !important;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
  .firebaseui-title {
    margin-bottom: 0 !important;
    font-weight: 500 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    color: rgba(0, 0, 0, 0.85) !important;
    position: initial;
    display: none;
  }
  .firebaseui-card-content {
    padding: 0 !important;
  }
  .firebaseui-card-actions {
    padding: ${props => props.theme.spacing.sm} 0
      ${props => props.theme.spacing.lg} !important;
    float: right;
    text-align: right;
    box-sizing: unset;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .firebaseui-label {
    padding: 0 0 ${props => props.theme.spacing.sm};
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    position: initial;
  }
  .is-focused .firebaseui-label,
  .is-dirty .firebaseui-label {
    color: rgba(0, 0, 0, 0.85) !important;
    font-size: 14px !important;
    top: 0 !important;
  }
  .firebaseui-subtitle,
  .firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .firebaseui-form-actions
    .mdl-button--raised.mdl-button--colored.firebaseui-button {
    background: ${props => props.theme.colors.primary.main};
    float: left;
  }
  .firebaseui-input,
  .firebaseui-textfield-invalid .firebaseui-input-invalid {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: ${props => props.theme.spacing.xs} 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: ${props => props.theme.colors.white};
    background-image: none;
    border: 1px solid ${props => props.theme.colors.gray[400]};
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .is-focused .firebaseui-input {
    border-color: ${props => props.theme.colors.primary.light} !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
  }
  .firebase-input-invalid,
  .mdl-textfield.is-invalid .firebaseui-input {
    border-color: ${props => props.theme.colors.danger.light};
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  .firebaseui-error-wrapper {
    clear: both;
    min-height: 24px;
    padding-top: 0px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .firebaseui-form-actions
    .mdl-button--raised.mdl-button--colored.firebaseui-button,
  .firebaseui-link.firebaseui-id-dismiss-info-bar {
    float: right;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primary.main};
    border-color: ${props => props.theme.colors.primary.main};
    height: ${props => props.theme.spacing.xg};
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;
    line-height: 1.5715;
    position: relative;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    text-transform: capitalize;
    box-shadow: none;
    float: left;
    margin-left: 0;
  }
  .firebaseui-id-secondary-link.firebaseui-button.mdl-button {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    height: ${props => props.theme.spacing.xg};
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    background: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.gray[400]};
    text-transform: capitalize;
  }
  .firebaseui-card-actions
    .firebaseui-textfield.mdl-textfield
    .firebaseui-label::after {
    background-color: ${props => props.theme.colors.primary.main} !important;
  }
  .firebaseui-id-dismiss-info-bar {
    display: block;
    margin-top: ${props => props.theme.spacing.xg};
  }
  .firebaseui-info-bar {
    border: 0;
    border-radius: 10px;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 10%;
    background-color: ${props => props.theme.colors.white};
  }
  .firebaseui-info-bar-message {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
  }
  .mdl-shadow--2dp {
    box-shadow: none !important;
  }
`

export default LoginForm
