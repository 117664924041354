import React from 'react'

import Home from '../pages/home/home'
import Cancel from '../pages/cancel/canceledAppointment'
import CancelConfirmation from '../pages/cancel/cancellationConfirmation'
import Priority from '../pages/priority/priority'
import Symptoms from '../pages/symptoms/symptoms'
import SymptomsDate from '../pages/symptoms/symptoms-date'
import Contact from '../pages/contact/contact'
import TestReason from '../pages/test-reason/test-reason'
import NoEmail from '../pages/email/no-email'
import EmailAdd from '../pages/email/email-add'
import Login from '../pages/login/login'
import requireRoles from '../utils/roles-utils'
import DashboardLayout from '../components/dashboard/dashboard-layout'
import LocationsList from '../pages/dashboard/locations-list'
import UsersList from '../pages/dashboard/users-list'
import { LocationScheduleProvider } from '../contexts/location-schedule-context'
import UserContextProvider from '../contexts/user-context'
import LocationsSchedule from '../pages/dashboard/locations-schedule'
import Results from '../pages/results/results'
import FormTemplate from '../templates/form-template'
import BirthDate from '../pages/birth-date/birth-date'
import BookingConfirmation from '../pages/confirmation/booking-confirmation'
import BookingSuccess from '../pages/confirmation/booking-success'
import DemographicsPt1 from '../pages/demographics/demographics-pt1'
import EmailSuccess from '../pages/email/email-success'
import EmailExistent from '../pages/email/email-existent'
import FullName from '../pages/full-name/full-name'
import HipaaWarn from '../pages/hipaa/hipaa-warn'
import DriverLicense from '../pages/driver-license/driver-license'
import PersonalAddress from '../pages/address/personal-address'
import PhoneNumber from '../pages/phone-number/phone-number'
import DoctorReferred from '../pages/doctor-referral/doctor-referred'
import DoctorReferralCode from '../pages/doctor-referral/doctor-referral-code'
import DoctorResultList from '../pages/dashboard/doc-results-list'
import LocationOptions from '../pages/location/location-options'
import TimeSlots from '../pages/time-slots/time-slots'
import NonUserAuth from '../pages/authentication/non-user-auth'
import PlanDetails1 from '../pages/insurance/plan-details1'
import PlanDetails2 from '../pages/insurance/plan-details2'
import HasInsurance from '../pages/insurance/has-insurance'
import DemographicsPt2 from '../pages/demographics/demographics-pt2'
import Forbidden from '../pages/alerts/forbidden'
import ResultsList from '../pages/dashboard/results-list'
import BatchList from '../pages/dashboard/batches-list'
import BatchCreate from '../pages/dashboard/batch-create'
import PositiveResult from '../pages/dashboard/positive-results'
import AutomatedBatchList from '../pages/dashboard/automated-batches-list'
import AutomatedBatchCreate from '../pages/dashboard/automated-batch-create'
import { DocumentsList } from '../pages/dashboard/documents-list'
import BillingsList from '../pages/dashboard/billings-list'
import AppointmentsList from '../pages/dashboard/appointments-list'
import ReportingList from '../pages/dashboard/reporting-list'
import TestGroupList from '../pages/dashboard/test-group-list'
import TestGroupAdd from '../pages/dashboard/test-group-add'
import SpecimensList from '../pages/dashboard/specimens-list'
import SpecimensFullList from '../pages/dashboard/specimens-full-list'
import SpecimensDocOrg from '../pages/dashboard/specimens-doc-org'
import Receiving from '../pages/dashboard/receiving-list'
import PrintBarcodes from '../pages/dashboard/print-barcodes'
import ReceivingAppointments from '../pages/dashboard/receiving-appointments'
import PatientLookupList from '../pages/dashboard/patient-lookup-list'
import routes from '../config/routes'
import CreateGuestUser from '../pages/create-guest-user/create-guest-user'
import { USER_ROLES } from '../constants'
import { AppointmentProvider } from '../contexts/appointment-context'

const {
  admin,
  billing,
  collection,
  guest,
  lab,
  reporting,
  organization,
  doctor,
} = USER_ROLES

export const adminRoutes = [
  {
    path: routes.dashboard.locationsList,
    component: LocationsList,
    template: DashboardLayout,
    default: true,
  },
  {
    path: routes.dashboard.printBarcodes,
    component: PrintBarcodes,
    template: DashboardLayout,
  },
  {
    path: `${routes.dashboard.locationsList}/:locationId`,
    component: props => (
      <LocationScheduleProvider>
        <LocationsSchedule {...props} />
      </LocationScheduleProvider>
    ),
    template: DashboardLayout,
  },
  {
    path: `${routes.dashboard.specimensList}/:userId`,
    component: props => <SpecimensFullList {...props} />,
    template: DashboardLayout,
  },
  {
    path: `${routes.dashboard.specimensList}/:locationId`,
    component: props => <SpecimensFullList {...props} />,
    template: DashboardLayout,
  },

  {
    path: routes.dashboard.specimensList,
    component: SpecimensList,
    template: DashboardLayout,
  },

  {
    path: routes.dashboard.usersList,
    component: UsersList,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.receiving,
    component: Receiving,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.receivingAppointments,
    component: ReceivingAppointments,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.patientLookup,
    component: PatientLookupList,
    template: DashboardLayout,
  },
].map(route => ({
  ...route,
  component: requireRoles(admin, Forbidden)(route.component),
}))

export const billingRoutes = [
  {
    path: routes.dashboard.billingsList,
    component: BillingsList,
    template: DashboardLayout,
    default: true,
  },
].map(route => ({
  ...route,
  component: requireRoles(
    [admin, billing],
    Forbidden,
    'oneOf',
  )(route.component),
}))

export const collectionRoutes = [
  {
    path: routes.dashboard.appointments,
    component: AppointmentsList,
    template: DashboardLayout,
    default: true,
  },
].map(route => ({
  ...route,
  component: requireRoles(
    [collection, admin, doctor],
    Forbidden,
    'oneOf',
  )(route.component),
}))

export const labRoutes = [
  {
    path: routes.dashboard.batches,
    component: BatchList,
    template: DashboardLayout,
    default: true,
  },
  {
    path: routes.dashboard.automatedBatches,
    component: AutomatedBatchList,
    template: DashboardLayout,
    default: true,
  },
  {
    path: routes.dashboard.printBarcodes,
    component: PrintBarcodes,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.batchCreate,
    component: BatchCreate,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.automatedBatchCreate,
    component: AutomatedBatchCreate,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.receiving,
    component: Receiving,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.receivingAppointments,
    component: ReceivingAppointments,
    template: DashboardLayout,
  },
].map(route => ({
  ...route,
  component: requireRoles([lab, admin], Forbidden, 'oneOf')(route.component),
}))

export const reportingRoutes = [
  {
    path: routes.dashboard.reportingList,
    component: ReportingList,
    template: DashboardLayout,
    default: true,
  },
].map(route => ({
  ...route,
  component: requireRoles(
    [reporting, admin, doctor],
    Forbidden,
    'oneOf',
  )(route.component),
}))

export const guestDashboardRoutes = [
  {
    path: routes.dashboard.resultsList,
    component: ResultsList,
    template: DashboardLayout,
    default: true,
  },
].map(route => ({
  ...route,
  component: requireRoles(guest, Forbidden)(route.component),
}))

export const organizationRoutes = [
  {
    path: routes.dashboard.testGroupList,
    component: TestGroupList,
    template: DashboardLayout,
    default: true,
  },
  {
    path: routes.dashboard.testGroupAdd,
    component: TestGroupAdd,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.specimensOrg,
    component: SpecimensDocOrg,
    template: DashboardLayout,
  },
].map(route => ({
  ...route,
  component: requireRoles(
    [organization, doctor],
    Forbidden,
    'oneOf',
  )(route.component),
}))

export const doctorRoutes = [
  {
    path: routes.dashboard.locationsList,
    component: LocationsList,
    template: DashboardLayout,
    default: true,
  },
  {
    path: routes.dashboard.doctorResultList,
    component: DoctorResultList,
    template: DashboardLayout,
    default: true,
  },
  {
    path: routes.dashboard.positiveResult,
    component: PositiveResult,
    template: DashboardLayout,
    default: true,
  },
  {
    path: routes.dashboard.patientLookup,
    component: PatientLookupList,
    template: DashboardLayout,
  },
  {
    path: routes.dashboard.specimensDoc,
    component: SpecimensDocOrg,
    template: DashboardLayout,
  },
  {
    path: `${routes.dashboard.locationsList}/:locationId`,
    component: props => (
      <LocationScheduleProvider>
        <LocationsSchedule {...props} />
      </LocationScheduleProvider>
    ),
    template: DashboardLayout,
  },
].map(route => ({
  ...route,
  component: requireRoles([doctor, admin], Forbidden, 'oneOf')(route.component),
}))

export const dashboardRoutes = [
  {
    path: routes.dashboard.home,
    redirectToDefault: true,
  },
  {
    path: routes.dashboard.login,
    component: Login,
    template: FormTemplate,
    onlyNoAuth: true,
  },
  ...doctorRoutes,
  ...collectionRoutes,
  ...labRoutes,
  ...billingRoutes,
  ...reportingRoutes,
  ...guestDashboardRoutes,
  ...organizationRoutes,
  ...adminRoutes,
]

export const guestFormRoutes = [
  {
    path: routes.bookingConfirmation,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <BookingConfirmation {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.doctorReferred,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <DoctorReferred {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.doctorReferralCode,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <DoctorReferralCode {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.locationOptions,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <LocationOptions {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.birthDate,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <BirthDate {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.bookingSuccess,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <BookingSuccess {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.demographicsPt1,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <DemographicsPt1 {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.demographicsPt2,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <DemographicsPt2 {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.fullName,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <FullName {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.hasInsurance,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <HasInsurance {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.hipaaWarn,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <HipaaWarn {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.driverLicense,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <DriverLicense {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.personalAddress,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <PersonalAddress {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.planDetails2,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <PlanDetails2 {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.planDetails1,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <PlanDetails1 {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.timeSlots,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <TimeSlots {...props} />
      </AppointmentProvider>
    ),
  },
  {
    path: routes.phoneNumber,
    template: FormTemplate,
    component: props => (
      <AppointmentProvider>
        <PhoneNumber {...props} />
      </AppointmentProvider>
    ),
  },
].map(route => ({
  ...route,
  component: requireRoles(guest, Forbidden)(route.component),
}))

export const publicRoutes = [
  {
    path: routes.home,
    component: Home,
    template: FormTemplate,
    default: true,
  },
  {
    path: routes.results,
    component: Results,
    template: FormTemplate,
  },
  {
    path: routes.createUser,
    component: CreateGuestUser,
    template: FormTemplate,
  },
  {
    path: routes.nonUserAuth,
    component: NonUserAuth,
    template: FormTemplate,
  },
  {
    path: routes.priority,
    component: Priority,
    template: FormTemplate,
  },
  {
    path: routes.symptoms,
    component: Symptoms,
    template: FormTemplate,
  },
  {
    path: routes.symptomsDate,
    component: SymptomsDate,
    template: FormTemplate,
  },
  {
    path: routes.contact,
    component: Contact,
    template: FormTemplate,
  },
  {
    path: routes.testReason,
    component: TestReason,
    template: FormTemplate,
  },
  {
    path: routes.noEmail,
    component: NoEmail,
    template: FormTemplate,
  },
  {
    path: routes.emailAdd,
    component: EmailAdd,
    template: FormTemplate,
  },
  {
    path: routes.emailSuccess,
    component: EmailSuccess,
    template: FormTemplate,
  },
  {
    path: routes.emailExistent,
    component: EmailExistent,
    template: FormTemplate,
  },
  {
    path: routes.cancel,
    component: Cancel,
    template: FormTemplate,
  },
  {
    path: routes.cancelConfirmation,
    component: CancelConfirmation,
    template: FormTemplate,
  },
]
