import React from 'react'
import { Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
const { Text } = Typography

export const WarningTooltip = () => {
  return (
    <Tooltip
      placement="bottom"
      title={
        <>
          <div>Required fields are:</div>
          <div>First Name </div>
          <div>Last Name </div>
          <div>Date of Birth </div>
          <div>Email </div>
          <div>City </div>
          <div>State </div>
          <div>Street1 </div>
          <div>Zip</div>
        </>
      }
    >
      <InfoCircleOutlined style={{ color: '#faad14' }} />
      <Text style={{ marginLeft: '6px' }}>
        The lines below weren't inserted. Please check the required fields.
      </Text>
    </Tooltip>
  )
}
