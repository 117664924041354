import React, { useContext, useState, useEffect } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import LinkButton from '../../components/link-button'
import useTestGroup from '../../hooks/use-test-group'
import { UserContext } from '../../contexts/user-context'
import { createTestGroup, getGroupId } from '../../utils/test-group-utils'
import TableActionsDropdown from '../../components/dashboard/table-actions-dropdown'
import { compareAsc, parseISO, format } from 'date-fns'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import routes from '../../config/routes'
import { Form, Upload, Button, Modal, Divider, Tooltip } from 'antd'
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons'
import Input from '../../components/input'
import StorageService from '../../services/storage-service'
import UserService from '../../services/user-service'
import { exportCSVTemplate } from '../../utils/reporting-utils'
import { handleError } from '../../utils/error-utils'
import {
  successNotification,
  warningNotification,
} from '../../components/dashboard/ui-notification'
import { WarningTooltip } from '../../components/warning-tooltip'

const TestGroupList = () => {
  const { user, role } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [hasWarning, setHasWarning] = useState(false)
  const [hasReferralCode, setHasReferralCode] = useState()
  const [warningList, setWarningList] = useState([])
  const [form] = Form.useForm()
  const history = useHistory()
  const { testGroups, isTestGroupLoading } = useTestGroup({
    uid: user?.uid,
    role,
  })

  useEffect(() => {
    if (warningList.length > 0) setHasWarning(true)
    user?.uid && UserService.getUserDoctorRefCode(user?.uid, setHasReferralCode)
  }, [user.uid, warningList])

  const columns = [
    {
      key: 'groupId',
      title: 'Group ID',
      dataIndex: 'groupId',
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      hideInTable: role === 'admin',
      renderText: (_, row) => {
        return row.title
      },
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      renderText: date => {
        return format(parseISO(date), 'MM/dd/yyyy')
      },
      sorter: {
        compare: (a, b) => compareAsc(parseISO(a.date), parseISO(b.date)),
        multiple: 3,
      },
    },
    {
      key: 'fullName',
      title: 'Full Name',
      dataIndex: 'fullName',
    },
    {
      valueType: 'option',
      align: 'right',
      render: (_, record) => [
        <TableActionsDropdown
          key="actionGroup"
          menus={[
            {
              key: 'view',
              name: (
                <Link
                  to={{
                    pathname: routes.dashboard.testGroupAdd,
                    state: {
                      organizationId: record.id,
                      groupId: record.groupId,
                    },
                  }}
                >
                  View
                </Link>
              ),
            },
          ]}
        />,
      ],
    },
  ]

  const warningColumns = [
    {
      key: 'index',
      title: 'Line',
      dataIndex: 'index',
    },
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      key: 'dateOfBirth',
      title: 'DOB',
      dataIndex: 'dateOfBirth',
    },
    {
      key: 'barcode',
      title: 'Barcode',
      dataIndex: 'barcode',
    },
  ]

  const onFinish = async ({ title }) => {
    createTestGroup(user, history, title)
  }

  async function importFile(file) {
    try {
      const id = await getGroupId()
      const title = form.getFieldValue('title')

      const fail = await StorageService.uploadTestGroupFile(
        file,
        id,
        user,
        hasReferralCode,
        title,
      )
      setWarningList(
        fail.data.filter(e => {
          if (e.index) return e
        }),
      )

      fail.data?.includes('usedBarcode') &&
        warningNotification({
          message: 'Review import file',
          description: 'Barcode already used.',
        })

      successNotification({
        message: 'Uploaded sucessfully',
      })
    } catch (error) {
      handleError(error, 'File upload error!')
    }
  }

  const onImportCSV = async event => {
    setIsLoading(true)
    await importFile(event)
    setIsLoading(false)
  }
  const exportTemplate = async event => {
    setIsLoading(true)
    await exportCSVTemplate()
    setIsLoading(false)
  }

  return (
    <>
      <PageContainer
        extra={
          (role === 'organization' || role === 'doctor') && (
            <Form
              name="testGroupAdd"
              form={form}
              onFinish={onFinish}
              layout="inline"
            >
              <Form.Item name="title">
                <Input
                  placeholder="Title"
                  value={form.getFieldValue('title')}
                  size="200px"
                />
              </Form.Item>
              <LinkButton type="primary" size="medium">
                Create Test Group
              </LinkButton>
              <Upload
                accept=".txt, .csv"
                showUploadList={false}
                beforeUpload={onImportCSV}
              >
                <Tooltip
                  placement="bottom"
                  title={
                    <>
                      <div>Required fields are:</div>
                      <div>First Name </div>
                      <div>Last Name </div>
                      <div>Date of Birth </div>
                      <div>Email </div>
                      <div>City </div>
                      <div>State </div>
                      <div>Street1 </div>
                      <div>Zip</div>
                    </>
                  }
                >
                  <Button style={{ marginLeft: 10 }}>
                    <UploadOutlined /> Upload Test Group
                  </Button>
                </Tooltip>
              </Upload>
              <Button style={{ marginLeft: 10 }} onClick={exportTemplate}>
                <DownloadOutlined /> Download Test Group CSV Template
              </Button>
            </Form>
          )
        }
      >
        <ProTable
          size="small"
          columns={columns}
          rowKey="key"
          search={false}
          options={false}
          dataSource={testGroups}
          loading={isTestGroupLoading || isLoading}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
          }}
        />
      </PageContainer>
      <Modal
        title={'Review the file you just uploaded!'}
        visible={hasWarning}
        centered
        width={600}
        onCancel={() => {
          setHasWarning(false)
        }}
        destroyOnClose
        footer={[
          <Button
            key="back"
            onClick={() => {
              setHasWarning(false)
            }}
          >
            Close
          </Button>,
        ]}
      >
        <WarningTooltip />
        <Divider />
        <ProTable
          size="small"
          columns={warningColumns}
          rowKey="key"
          search={false}
          options={false}
          dataSource={warningList}
          loading={isTestGroupLoading || isLoading}
          pagination={false}
        />
      </Modal>
    </>
  )
}

export default TestGroupList
