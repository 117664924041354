export function getSocialSecurityNumberMasked(socialSecurityNumber) {
  if (socialSecurityNumber) {
    const socialSecurityFormatted = socialSecurityNumber
      .substr(0, 6)
      .replace(/[0-9]/g, '*')
    const socialSecurityLastNumbers = socialSecurityNumber.slice(-4)
    return socialSecurityFormatted + socialSecurityLastNumbers
  }
  return
}
