import React, { useContext, useState, useEffect } from 'react'
import TableActionsDropdown from './table-actions-dropdown'
import { Button } from 'antd'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PDFResult from '../../pages/dashboard/pdf-result'
import resultService from '../../services/result-service'
import { UserContext } from '../../contexts/user-context'
import { parseISO, format } from 'date-fns'

export default function PdfActions({
  row,
  setAppointment,
  setVisible,
  setEditData,
}) {
  const { role } = useContext(UserContext)
  const [editedRow, setEditedRow] = useState()
  useEffect(() => {
    setEditedRow(row)
  }, [row])
  const canRenderSendResult = () => {
    if (role === 'admin') return true
    return role !== 'doctor' || role !== 'guest' ? false : true
  }
  const canRenderEdit = () => {
    return role === 'admin' ? true : false
  }
  return (
    <TableActionsDropdown
      key="actionGroup"
      menus={[
        canRenderEdit() && {
          key: 'edit',
          name: (
            <Button
              key="edit"
              type="link"
              onClick={() => {
                setAppointment(row)
                setEditData({ visible: true, data: row })
              }}
            >
              Edit Info
            </Button>
          ),
        },

        {
          key: 'viewResult',
          name: (
            <Button
              key="viewResult"
              type="link"
              disabled={!row.result || row.result === 'Pending'}
              onClick={() => {
                setAppointment(row)
                setVisible(true)
              }}
            >
              View Result
            </Button>
          ),
        },
        canRenderSendResult() && {
          key: 'sendResult',
          name: (
            <Button
              key="sendResult"
              type="link"
              disabled={!row.result || row.result === 'Pending'}
              onClick={() => {
                resultService.sendTestResultEmail(
                  row.key,
                  row.email,
                  row.result,
                  row.confirmation,
                  row.userId,
                )
              }}
            >
              Send Result
            </Button>
          ),
        },
        {
          key: 'download',
          name: (
            <PDFDownloadLink
              document={<PDFResult data={editedRow} />}
              fileName={`${row.firstName} ${
                row.lastName
              } - Covid19 Test Result ${
                row.resultEnteredDate &&
                format(
                  parseISO(row.resultEnteredDate),
                  `MM-dd-yyyy hh${String.fromCodePoint(0xa789)}mm a`,
                )
              }.pdf`}
            >
              <Button
                type="link"
                disabled={!row.result || row.result === 'Pending'}
              >
                Download Result
              </Button>
            </PDFDownloadLink>
          ),
        },
      ]}
    />
  )
}
