import { Radio as DefaultRadio } from 'antd'
import styled from 'styled-components'

const Radio = styled(DefaultRadio)`
  white-space: normal;
  &.ant-radio-wrapper:hover .ant-radio::after {
    visibility: hidden;
  }
`

export default Radio
