import React from 'react'
import { Typography } from 'antd'

import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import ListContainer from '../../components/list-container'
import { useTranslation } from 'react-i18next'
const { Paragraph } = Typography

function Priority() {
  const { t } = useTranslation()
  return (
    <ContentSpace>
      <PageTitle>{t('priority.title')}</PageTitle>
      <Paragraph>
        {t('priority.paragraph')}

        <ListContainer>
          <li>{t('priority.1st')}</li>
          <li>{t('priority.2nd')}</li>
          <li>{t('priority.3rd')}</li>
        </ListContainer>
      </Paragraph>
      <ActionButtons
        hideBackButton
        nextButtonUrl={routes.symptoms}
        nextButtonTitle={t('buttons.continue')}
      />
    </ContentSpace>
  )
}

export default Priority
