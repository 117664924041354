import { PageContainer } from '@ant-design/pro-layout'
import styled from 'styled-components'

const LocationContainer = styled(PageContainer)`
  .ant-pro-page-container-extraContent {
    margin-left: 0;
  }
  .ant-form-item label:after {
    content: '';
    margin-right: 12px;
  }
`
export default LocationContainer
