import { errorNotification } from '../components/dashboard/ui-notification'

export function handleError(error, alertTitle) {
  errorNotification({
    message: alertTitle,
    description: error?.message ? error.message : error,
  })
}

export function hasFormErrors(form) {
  return form.getFieldsError().filter(({ errors }) => errors.length).length
}
