import { Radio as DefaultRadio } from 'antd'
import styled from 'styled-components'

const RadioItem = styled(DefaultRadio)`
  white-space: normal;
  display: block;
  height: 30px;
`

export default RadioItem
