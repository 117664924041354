import React, { useRef } from 'react'
import { Button, Switch, Typography, Modal, Form } from 'antd'
import ProTable from '@ant-design/pro-table'
import ProForm from '@ant-design/pro-form'
import { compareAsc, format, parseISO } from 'date-fns'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { BILLING_STATUS } from '../../constants'
import {
  getCorrectBillingStatus,
  isBillingStatusPending,
} from '../../utils/appointment-utils'
import SearchBox from '../../components/search-box'
import useBillings from '../../hooks/use-billing'
import appointmentsService from '../../services/appointments-service'
import { successNotification } from '../../components/dashboard/ui-notification'
import { compareStrings } from '../../utils/text-utils'
import BillingModalContent from '../../components/billing-modal-content'
import CustomPageContainer from '../../components/dashboard/custom-page-container'
import { algoliaDOBParse } from '../../utils/date-utils'

const { Text } = Typography
const { confirm } = Modal

async function showConfirm(row, actionRef) {
  confirm({
    width: '80%',
    title: 'Confirm Billing',
    icon: <ExclamationCircleOutlined />,
    content: <BillingModalContent info={row} />,
    async onOk() {
      try {
        await appointmentsService.update(row.key, {
          billingStatus: 'processed',
        })
        actionRef.current.reload()
        successNotification({
          message: 'Billing confirmed',
        })
      } catch (error) {
        this.close()
      }
    },
    onCancel() {
      return null
    },
  })
}

function getTableColumns(actionRef) {
  return [
    {
      key: 'fullName',
      title: (_, type) => (type === 'table' ? 'Full Name' : 'Search Billings'),
      dataIndex: 'fullName',
      sorter: {
        compare: (a, b) => compareStrings(a.fullName, b.fullName),
        multiple: 5,
      },
    },
    {
      key: 'dateOfBirth',
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      search: false,
      renderText: date => {
        return algoliaDOBParse(date)
      },
      sorter: {
        compare: (a, b) => compareAsc(a.dateOfBirth, b.dateOfBirth),
        multiple: 4,
      },
    },
    {
      key: 'slotsDateTime',
      title: 'Appointment Date',
      dataIndex: 'slotsDateTime',
      search: false,
      renderText: date => date && format(parseISO(date), 'MM/dd/yyyy'),
      sorter: {
        compare: (a, b) =>
          compareAsc(parseISO(a.slotsDateTime), parseISO(b.slotsDateTime)),
        multiple: 3,
      },
    },
    {
      key: 'hasInsurance',
      title: 'Insured',
      dataIndex: 'hasInsurance',
      search: false,
      render: (_, { hasInsurance }) =>
        hasInsurance === 'yes' ? (
          <Text>Insured</Text>
        ) : (
          <Text>Not Insured</Text>
        ),
      sorter: {
        compare: (a, b) => compareStrings(a.hasInsurance, b.hasInsurance),
        multiple: 2,
      },
    },
    {
      key: 'billingStatus',
      title: 'Status',
      dataIndex: 'billingStatus',
      search: false,
      sorter: {
        compare: (a, b) => {
          const firstBillingStatus = getCorrectBillingStatus(a.billingStatus)
          const secondBillingStatus = getCorrectBillingStatus(b.billingStatus)

          return firstBillingStatus.localeCompare(secondBillingStatus)
        },
        multiple: 1,
      },
      render: (_, { billingStatus }) => {
        switch (billingStatus) {
          case 'processed':
            return <Text type="success">{BILLING_STATUS.processed}</Text>
          default:
            return <Text type="danger">{BILLING_STATUS.pending}</Text>
        }
      },
    },
    {
      key: 'option',
      width: 80,
      valueType: 'option',
      align: 'right',
      render: (_, row) => [
        isBillingStatusPending(row.billingStatus) &&
          row.hasOwnProperty('dateOfBirth') && (
            <Button
              key="processButton"
              type="link"
              onClick={() => {
                showConfirm(row, actionRef)
              }}
            >
              Process
            </Button>
          ),
      ],
    },
  ]
}

export default function BillingsList() {
  const actionRef = useRef()
  const [formInstance] = ProForm.useForm()
  const {
    isBillingsLoading,
    billings,
    isProcessed,
    setIsProcessed,
    setSearchQuery,
  } = useBillings()
  const handleSearch = searchText => {
    setSearchQuery(searchText)
  }

  return (
    <CustomPageContainer
      extraContent={
        <Form form={formInstance}>
          <Form.Item label="Search Billings">
            <SearchBox
              onSearch={handleSearch}
              placeholder="Search by Last Name"
              enterButton={
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              }
              allowClear
            />
          </Form.Item>
        </Form>
      }
    >
      <ProTable
        columns={getTableColumns(actionRef)}
        actionRef={actionRef}
        dataSource={billings}
        loading={isBillingsLoading}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        headerTitle={
          <>
            <Switch
              checked={isProcessed}
              onClick={() => setIsProcessed(!isProcessed)}
            />{' '}
            <Text>Show Processed</Text>
          </>
        }
        options={false}
        dateFormatter="string"
        search={false}
      />
    </CustomPageContainer>
  )
}
