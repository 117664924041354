import 'firebase/database'
import 'firebase/functions'
import 'firebase/messaging'
import 'firebase/analytics'
import 'firebase/storage'
import 'firebase/auth'

import { FirebaseConfig } from '../config/config'
import firebase from 'firebase/app'

firebase.initializeApp(FirebaseConfig)
const analytics = firebase.analytics()

// Uncomment the code below when using the Firebase emulators
//firebase.functions().useFunctionsEmulator('http://localhost:5001')
let messaging

try {
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
  }
} catch (error) {
  console.log(error)
}

function analyticsEvent(event, params) {
  try {
    analytics.logEvent(event, params)
  } catch (error) {
    console.log(error)
  }
}

class FirebaseService {
  constructor(path) {
    this.auth = firebase.auth()
    this.db = firebase.database
    this.path = path
    this.storageRef = firebase.storage().ref()
    this.messaging = messaging
    this.functions = firebase.functions()
  }
}

export default new FirebaseService()
export { firebase, FirebaseService, analyticsEvent }
