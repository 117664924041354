import { useEffect, useState } from 'react'

import BatchService from '../services/batch-service'
import { FilteredBatchByDate } from '../utils/batch-utils'
import { handleError } from '../utils/error-utils'

export default function useBatch() {
  const [batches, setBatches] = useState()
  const [isBatchesLoading, setIsBatchesLoading] = useState(false)
  const [showAllBatches, setShowAllBatches] = useState(false)

  useEffect(() => {
    try {
      setIsBatchesLoading(true)
      BatchService.observeBatches(response => {
        const batchesList = showAllBatches
          ? response
          : FilteredBatchByDate(response)
        setBatches(batchesList)
        setIsBatchesLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving batch')
      setIsBatchesLoading(false)
    }

    return () => {
      BatchService.unsubscribeBatch()
    }
  }, [showAllBatches])

  return { batches, isBatchesLoading, showAllBatches, setShowAllBatches }
}
