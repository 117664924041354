import React, { useRef, useState, useContext, useEffect } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import { format, parseISO, isBefore } from 'date-fns'
import { Modal } from 'antd'
import { compareStrings } from '../../utils/text-utils'
import useReportings from '../../hooks/use-reporting'
import { PDFViewer } from '@react-pdf/renderer'
import PDFResult from './pdf-result'
import PdfActions from '../../components/dashboard/pdf-actions'
import EditResultModal from '../../components/dashboard/edit-result-modal'
import { LocationSelectorProvider } from '../../contexts/location-selector-context'

import { errorNotification } from '../../components/dashboard/ui-notification'
import resultService from '../../services/result-service'
import { UserContext } from '../../contexts/user-context'
import ProfilesService from '../../services/profiles-service'
import { algoliaDOBParse } from '../../utils/date-utils'

function getTableColumns(setVisible, setEditData, setAppointment, onChange) {
  return [
    {
      key: 'resultEnteredDate',
      title: 'Result Date',
      dataIndex: 'resultEnteredDate',
      defaultSortOrder: 'ascend',
      width: 200,
      renderText: date => date && format(parseISO(date), 'MM/dd/yyyy'),
      sorter: {
        compare: (a, b) =>
          parseISO(a.slotsDateTime) - parseISO(b.slotsDateTime),
        multiple: 3,
      },
    },
    {
      key: 'fullName',
      title: 'Full Name',
      dataIndex: 'fullName',
      width: 250,
      renderText: text => {
        return text
      },
    },
    {
      key: 'dateOfBirth',
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      width: 250,
      renderText: date => {
        return algoliaDOBParse(date)
      },
      sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
    },
    {
      key: 'phoneNumber',
      title: 'Phone',
      dataIndex: 'phoneNumber',
      width: 200,
    },
    {
      key: 'result',
      title: 'Result',
      dataIndex: 'result',
      sorter: {
        compare: (a, b) => compareStrings(a.result, b.result),
        multiple: 6,
      },
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, row) => [
        <PdfActions
          row={row}
          setAppointment={setAppointment}
          setVisible={setVisible}
          setEditData={setEditData}
        />,
      ],
    },
  ]
}

export default function DoctorResultList() {
  const actionRef = useRef()
  const [visible, setVisible] = useState(false)
  const [editData, setEditData] = useState({ visible: false, data: {} })
  const [appointment, setAppointment] = useState()

  const { user, role } = useContext(UserContext)
  const {
    isReportingsLoading,
    setDateSelected,
    dateSelected,
    doctorReportings,
    setDoctorReferralCode,
  } = useReportings()

  useEffect(() => {
    async function fetchData() {
      const profile = await ProfilesService.get(user.uid)
      setDoctorReferralCode(profile.doctorReferralCode)
    }
    fetchData()
  }, [user.uid, setDoctorReferralCode])

  function dateValidator(id, date) {
    if (id === 'endDate' && isBefore(date, dateSelected.startDate)) {
      errorNotification({
        message: 'Invalid End Date',
        description: 'End Date must be greater than Start Date',
      })
      return false
    }
    return true
  }

  function onChange(id, date) {
    dateValidator(id, date) && setDateSelected({ ...dateSelected, [id]: date })
  }

  const handleCloseButtonClick = () => {
    setEditData({ visible: false, data: {} })
  }
  const setEditedInfo = values => {
    return resultService.updateEditedResults(appointment.id, values)
  }
  return (
    <LocationSelectorProvider>
      <PageContainer
        title={
          <>
            <h1>Doctor Results</h1>
          </>
        }
      >
        <ProTable
          columns={getTableColumns(
            setVisible,
            setEditData,
            setAppointment,
            onChange,
          )}
          actionRef={actionRef}
          dataSource={doctorReportings}
          loading={isReportingsLoading}
          rowKey="key"
          search={false}
          options={false}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          dateFormatter="string"
        />
        <Modal
          width={770}
          visible={visible}
          closable
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <PDFViewer width={700} height={700}>
            <PDFResult data={appointment} />
          </PDFViewer>
        </Modal>

        <EditResultModal
          isModalVisible={editData.visible}
          onClickClose={handleCloseButtonClick}
          data={editData.data}
          setEditedInfo={setEditedInfo}
        />
      </PageContainer>
    </LocationSelectorProvider>
  )
}
