import React from 'react'
import { Radio, Spin } from 'antd'

import LocationSelectItem from './location-select-item'
import useLocationsByAvailability from '../hooks/use-locations-by-availability'

export const LocationSelect = ({
  isActive,
  hasScheduleAvailable = true,
  referralCode,
  ...props
}) => {
  const { locations, isLocationsLoading } = useLocationsByAvailability(
    hasScheduleAvailable,
    referralCode,
  )

  if (isLocationsLoading || isLocationsLoading === undefined) {
    return <Spin />
  }

  return (
    <Radio.Group {...props} style={{ width: '100%' }}>
      {locations &&
        locations.map(element => {
          return (
            <LocationSelectItem
              key={element.key}
              isActive={isActive}
              data={element}
            />
          )
        })}
    </Radio.Group>
  )
}
