import React from 'react'

import { useLocationScheduleContext } from '../../contexts/location-schedule-context'
import { TimeSlot } from './time-slot'

export default function SchedulerBasicTimeSlot(props) {
  const { removeTimeSlot } = useLocationScheduleContext()

  return (
    <TimeSlot {...props} onClick={() => removeTimeSlot(props.data.startDate)} />
  )
}
