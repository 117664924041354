import React, { useState, useEffect } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import { Form, AutoComplete, Button, Typography } from 'antd'
import { parse, parseISO, format, sub } from 'date-fns'
import ProTable from '@ant-design/pro-table'
import Input from '../../components/input'
import LinkButton from '../../components/link-button'
import DatePicker from '../../components/date-picker'
import ContainerInline from '../../components/container-inline'
import useTestGroup from '../../hooks/use-test-group'
import usePeople from '../../hooks/use-people'
import { createAppointmentFromTestGroup } from '../../utils/appointment-utils'
import { compareStrings } from '../../utils/text-utils'
import theme from '../../styles/theme'
import { handleError } from '../../utils/error-utils'
import { printBarcodes } from '../../utils/printer-utils'
import { updateStatus } from '../../utils/test-group-utils'
import { useHistory } from 'react-router-dom'
import TestGroupFormModal from '../../components/dashboard/test-group-form-modal'
import { useContext } from 'react'
import { UserContext } from '../../contexts/user-context'
import ProfilesService from '../../services/profiles-service'
import { exportCSVByOneDate } from '../../utils/reporting-utils'
import EditableTestGroupsAction from '../../components/dashboard/editable-test-group'
import BarcodeModal from '../../components/dashboard/add-barcode-modal'
import { algoliaDOBParse } from '../../utils/date-utils'
import AddImagesModal from '../../components/dashboard/add-images-modal'
const TestGroupList = props => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [barcodeModal, setBarcodeModal] = useState({ isVisible: false })
  const [imagesModal, setImagesModal] = useState({ isVisible: false })
  const { user } = useContext(UserContext)
  const [userInfo, setUserInfo] = useState()

  const {
    organizationId,
    groupId,
    appointments,
    duplicate,
  } = props.location.state
  const history = useHistory()
  useEffect(() => {
    async function fetchData() {
      const profile = await ProfilesService.get(user.uid)
      setUserInfo(profile)
    }
    fetchData()
  }, [user.uid, setUserInfo])

  const {
    testGroupAppointment,
    isTestGroupLoading,
    isDuplicateLoading,
    testGroup,
  } = useTestGroup({
    groupId,
    appointments,
    organizationId,
    duplicate,
    history,
  })
  const { peoples } = usePeople(organizationId, testGroupAppointment)
  const { role } = useContext(UserContext)
  const [modalSettings, setModalSettings] = useState({ isVisible: false })

  const renderButton = (barcode, row) => {
    if (!barcode) {
      return (
        <Button
          type="link"
          onClick={() => setBarcodeModal({ isVisible: true, data: row })}
        >
          Add a barcode
        </Button>
      )
    }
    return barcode
  }

  const renderImagesButton = row => {
    return (
      <Button
        type="link"
        onClick={() => setImagesModal({ isVisible: true, data: row })}
      >
        Add documents
      </Button>
    )
  }

  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      width: 50,
      dataIndex: 'firstName',
      sorter: {
        compare: (a, b) => compareStrings(a.firstName, b.firstName),
        multiple: 6,
      },
    },
    {
      key: 'lastName',
      title: 'Last Name',
      width: 50,
      dataIndex: 'lastName',
      sorter: {
        compare: (a, b) => compareStrings(a.lastName, b.lastName),
        multiple: 5,
      },
    },
    {
      key: 'dateOfBirth',
      title: 'DOB',
      width: 50,
      dataIndex: 'dateOfBirth',
      renderText: date => {
        return algoliaDOBParse(date)
      },
    },
    {
      key: 'phoneNumber',
      title: 'Phone',
      width: 90,
      dataIndex: 'phoneNumber',
    },
    {
      key: 'email',
      title: 'Email',
      width: 50,
      dataIndex: 'email',
    },
    {
      key: 'barcode',
      title: 'Barcode',
      width: 50,
      dataIndex: 'barcode',
      align: 'center',
      renderText: (barcode, row) => renderButton(barcode, row),
      sorter: {
        compare: (a, b) => compareStrings(a.barcode, b.barcode),
        multiple: 4,
      },
    },
    {
      key: 'documentImages',
      title: 'Documents',
      width: 50,
      align: 'center',
      render: (_, row) => [renderImagesButton(row)],
    },
    {
      key: 'result',
      title: 'Result',
      width: 50,
      valueType: 'text',
      dataIndex: 'result',
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, row) => [
        <EditableTestGroupsAction key={`${row.id}-options`} row={row} />,
      ],
    },
  ]

  const populateFields = (firstName, lastName, email, dateOfBirth) => {
    form.setFieldsValue({
      firstName,
      lastName,
      email,
      dateOfBirth: parse(dateOfBirth, 'MMddyyyy', new Date()),
    })
  }

  const filterOptionAutoComplete = (inputValue, option) => {
    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
  }

  const disabledDate = current => {
    const minYear = sub(new Date(), { years: 100 })
    const maxYear = new Date()

    return (current && current < minYear) || current > maxYear
  }
  const handleAddButtonClick = async () => {
    const person = peoples?.find(
      people => people.email === form.getFieldValue('email'),
    )
    let isDoctor = role === 'doctor'
    let docRef = userInfo?.doctorReferralCode
    const providerInfo = {
      ...(isDoctor && {
        providerFacilityName: userInfo?.providerFacilityName,
        providerFirstName: userInfo?.providerFirstName,
        providerMiddleName: userInfo?.providerMiddleName,
        providerLastName: userInfo?.providerLastName,
        providerAddress: userInfo?.providerAddress,
        providerCity: userInfo?.providerCity,
        providerState: userInfo?.providerState,
        providerZip: userInfo?.providerZip,
        providerPhone: userInfo?.providerPhone,
        providerNPI: userInfo?.providerNPI,
      }),
    }
    if (person) {
      try {
        setIsLoading(true)
        delete person.symptoms
        delete person.barcode
        delete person.key
        await createAppointmentFromTestGroup({
          doctorReferralCode: docRef,
          duplicate: null,
          organizationId,
          groupId,
          appointment: person,
          providerInfo,
          personId: person.key,
          slotsDateTime: new Date().toISOString(),
        })
        setIsLoading(false)
      } catch (error) {
        handleError(error, 'Error when creating appointment')
      }
      return form.resetFields()
    }

    setModalSettings({ isVisible: true })
  }

  const handleCloseButtonClick = () => {
    setModalSettings({ isVisible: false })
    form.resetFields()
  }
  const handleCloseBarcodeModal = () => {
    setBarcodeModal({ isVisible: false })
    form.resetFields()
  }
  const handleCloseImageModal = () => {
    setImagesModal({ isVisible: false })
    form.resetFields()
  }
  const handlePrintBarcodes = () => {
    printBarcodes(testGroupAppointment)
    updateStatus(groupId, 'processed')
  }
  const handleExportCSV = () => {
    const appointmentsFormatted = testGroupAppointment.map(a => {
      return {
        firstName: a.firstName,
        lastName: a.lastName,
        phoneNumber: a.phoneNumber,
        dateOfBirth: format(a.dateOfBirth, 'MM/dd/yyyy'),
        barcode: a.barcode,
        email: a.email,
        result: a.result,
      }
    })
    const dateFormatted = format(parseISO(new Date().toISOString()), 'MMddyyyy')
    exportCSVByOneDate(
      testGroup.title,
      groupId,
      appointmentsFormatted,
      dateFormatted,
    )
  }

  const canRenderExport = () => {
    if (role === 'organization' || role === 'doctor') {
      return (
        <LinkButton
          type="primary"
          size="medium"
          style={{ marginLeft: theme.spacing.lg }}
          htmlType="button"
          disabled={!testGroupAppointment || testGroupAppointment.length === 0}
          onClick={() => handleExportCSV(appointments)}
        >
          Export CSV
        </LinkButton>
      )
    }
  }

  const handleFinalizeTestGroup = () => {
    updateStatus(groupId, 'pending')
  }

  const renderTestGroupStatus = () => {
    if (testGroup?.status === '' || testGroup?.status === undefined) {
      if (testGroup?.title) return `Test Group #${groupId}: ${testGroup.title}`
      return `Test Group #${groupId}`
    }

    return `Test Group #${groupId}${
      testGroup?.title ? `: ${testGroup?.title}` : ''
    } | Status: ${testGroup?.status}`
  }

  return (
    <PageContainer
      title={renderTestGroupStatus()}
      content={
        <Form name="testGroupAdd" form={form}>
          <ContainerInline>
            {testGroup?.status === '' && (
              <>
                <Form.Item
                  name="firstName"
                  style={{ marginRight: theme.spacing.lg }}
                >
                  <AutoComplete
                    style={{ width: 200 }}
                    options={peoples}
                    placeholder="First Name"
                    filterOption={(inputValue, option) =>
                      filterOptionAutoComplete(inputValue, option)
                    }
                    onSelect={(value, option) =>
                      populateFields(
                        option.firstName,
                        option.lastName,
                        option.email,
                        option.dateOfBirth,
                      )
                    }
                    value={form.getFieldValue('firstName')}
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  style={{ marginRight: theme.spacing.lg }}
                >
                  <Input
                    placeholder="Last Name"
                    value={form.getFieldValue('lastName')}
                    size="200px"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  style={{ marginRight: theme.spacing.lg }}
                  rules={[
                    { type: 'email', message: 'Email is not a valid email!' },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    value={form.getFieldValue('email')}
                    size="200px"
                  />
                </Form.Item>
                <Form.Item name="dateOfBirth">
                  <DatePicker
                    widthInput="200"
                    format="MM/dd/yyyy"
                    placeholder="Date of Birth"
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                <LinkButton
                  type="primary"
                  size="middle"
                  onClick={handleAddButtonClick}
                  loading={isLoading}
                >
                  Add
                </LinkButton>
                {role === 'admin' && (
                  <LinkButton
                    type="primary"
                    size="medium"
                    style={{ marginLeft: theme.spacing.lg }}
                    htmlType="button"
                    onClick={handlePrintBarcodes}
                  >
                    Print Barcodes
                  </LinkButton>
                )}
                <LinkButton
                  type="primary"
                  size="medium"
                  style={{ marginLeft: theme.spacing.lg }}
                  htmlType="button"
                  disabled={
                    !testGroupAppointment || testGroupAppointment.length === 0
                  }
                  onClick={handleFinalizeTestGroup}
                >
                  Finalize Test Group
                </LinkButton>
              </>
            )}
            {canRenderExport()}
          </ContainerInline>
        </Form>
      }
      extra={
        testGroup?.status !== '' && (
          <ContainerInline>
            {role === 'admin' && (
              <LinkButton
                type="primary"
                size="medium"
                style={{ marginLeft: theme.spacing.lg }}
                htmlType="button"
                onClick={handlePrintBarcodes}
              >
                Print Barcodes
              </LinkButton>
            )}
          </ContainerInline>
        )
      }
    >
      <ProTable
        size="small"
        columns={columns}
        rowKey="id"
        search={false}
        options={false}
        dataSource={testGroupAppointment}
        loading={isTestGroupLoading || isDuplicateLoading}
        pagination={false}
      />
      <TestGroupFormModal
        isModalVisible={modalSettings.isVisible}
        onClickClose={handleCloseButtonClick}
        data={form.getFieldsValue()}
        organizationId={organizationId}
        groupId={groupId}
      />
      <BarcodeModal
        isModalVisible={barcodeModal.isVisible}
        onClickClose={handleCloseBarcodeModal}
        data={barcodeModal?.data}
      />
      <AddImagesModal
        isModalVisible={imagesModal.isVisible}
        onClickClose={handleCloseImageModal}
        data={imagesModal?.data}
      />
    </PageContainer>
  )
}

export default TestGroupList
