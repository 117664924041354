import React from 'react'
import { Typography, Form, Button, Switch } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import ProTable from '@ant-design/pro-table'
import DatePicker from '../../components/date-picker'
import IconButton from '../../components/icon-button'
import { PlusOutlined } from '@ant-design/icons'
import routes from '../../config/routes'
import { useHistory } from 'react-router-dom'
import ContainerInline from '../../components/container-inline'
import useAutomatedBatche from '../../hooks/use-automated-batches'
import { createNewAutomatedBatch } from '../../utils/automated-batch-utils'
import { format, parse } from 'date-fns'
import PageContainerBatches from '../../components/dashboard/page-container-batches.js'
import { compareStrings } from '../../utils/text-utils'
const { Text } = Typography

const AutomatedBatchesList = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const {
    AutomatedBatches,
    isAutomatedBatchesLoading,
    showAllBatches,
    setShowAllBatches,
  } = useAutomatedBatche()
  const columns = [
    {
      key: 'date',
      title: 'Date',
      width: 250,
      dataIndex: 'date',
      renderText: date => {
        return (
          date && format(parse(date, 'dd-MM-yyyy', new Date()), 'MM/dd/yyyy')
        )
      },
      sorter: {
        compare: (a, b) =>
          parse(a.date, 'dd-MM-yyyy', new Date()) -
          parse(b.date, 'dd-MM-yyyy', new Date()),
      },
    },
    {
      key: 'batch',
      title: 'Batch #',
      width: 250,
      dataIndex: 'batch',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: status =>
        status === 'Completed' ? (
          <Text type="success">{status}</Text>
        ) : (
          <Text>{status}</Text>
        ),
      sorter: {
        compare: (a, b) => compareStrings(a.status, b.status),
        multiple: 2,
      },
    },
    {
      valueType: 'option',
      align: 'right',
      render: (text, row, index, action) => [
        <Button
          type="link"
          onClick={() => {
            const { date, batch } = row
            history.push({
              pathname: routes.dashboard.automatedBatchCreate,
              state: {
                batchDate: date,
                batchNumber: batch,
              },
            })
          }}
        >
          View
        </Button>,
      ],
    },
  ]

  const onFinish = async ({ batchDate }) => {
    await createNewAutomatedBatch(batchDate, history)
  }

  return (
    <PageContainerBatches
      content={
        <Form name="batchDate" form={form} onFinish={onFinish}>
          <ContainerInline>
            <Form.Item
              name="batchDate"
              rules={[{ required: true, message: 'Batch date is required' }]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item>
              <IconButton icon={<PlusOutlined />} type={'primary'}>
                Create a Batch
              </IconButton>
            </Form.Item>
          </ContainerInline>
        </Form>
      }
    >
      <ProTable
        size="small"
        columns={columns}
        rowKey={() => uuidv4().slice(0, 7).toString()}
        search={false}
        options={false}
        dataSource={AutomatedBatches}
        loading={isAutomatedBatchesLoading}
        pagination={false}
        headerTitle={
          <>
            <Switch
              checked={showAllBatches}
              onClick={() => setShowAllBatches(!showAllBatches)}
            />{' '}
            <Text>Show All</Text>
          </>
        }
      />
    </PageContainerBatches>
  )
}

export default AutomatedBatchesList
