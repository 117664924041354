import React from 'react'

import { Typography } from 'antd'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import useQueryParam from '../../hooks/use-query-param'
import { useTranslation } from 'react-i18next'
import CancelAppointmentModal from '../../components/cancel-appointment-modal'

const { Text } = Typography

function CanceledAppointment() {
  const appointmentId = useQueryParam('appointmentId')
  const { t } = useTranslation()

  return (
    <ContentSpace>
      <PageTitle>{t('cancelConfirmation.title')}</PageTitle>
      <Text>{t('cancelConfirmation.description')}</Text>
      <CancelAppointmentModal appointmentId={appointmentId} />
    </ContentSpace>
  )
}

export default CanceledAppointment
