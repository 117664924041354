import React from 'react'
import { Typography, Form } from 'antd'
import AppointmentService from '../../services/appointments-service'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import routes from '../../config/routes'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import { RadioGroup } from '../../components/radio-group'
import { DemographicTooltip } from '../../components/demographic-tooltip'
import Spin from '../../components/main-spin'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'

const { Title, Text } = Typography

function DemographicsPt1() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const url = new URL(window.location.href)
  const isEdit = url.searchParams.get('isEdit')
  const { t } = useTranslation()

  const onFinish = async values => {
    try {
      await AppointmentService.update(appointmentId, values)
      if (isEdit) {
        history.push(routes.bookingConfirmation)
      } else {
        history.push(routes.demographicsPt2)
      }
    } catch (error) {
      handleError(error, 'Error saving the demographics data')
    }
  }

  const isNextButtonDisabled = () => {
    return hasFormErrors(form) || !form.getFieldValue('sexAtBirth')
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle>{t('demographics.title')}</PageTitle>
      <Text>{t('demographics.text')}</Text>
      <Title level={5}>
        {t('demographics.expand')} <DemographicTooltip large />
      </Title>

      <Form form={form} onFinish={onFinish} initialValues={appointment}>
        <ContentSpace>
          <Title level={3} type="primary">
            {t('demographics.sex')}
          </Title>
          <Form.Item
            name="sexAtBirth"
            rules={[
              { required: true, message: `${t('messages.sexRequired')}` },
            ]}
          >
            <RadioGroup
              options={t('SEX_AT_BIRTH_OPTIONS', { returnObjects: true })}
            />
          </Form.Item>

          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            backButtonUrl={
              isEdit ? routes.bookingConfirmation : routes.phoneNumber
            }
          />
        </ContentSpace>
      </Form>
    </ContentSpace>
  )
}

export default DemographicsPt1
