import styled from 'styled-components'
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui'

const { DayScaleCell: DefaultDayScaleCell } = WeekView

const DayScaleCell = styled(DefaultDayScaleCell)`
  div,
  p {
    color: ${props =>
      props.today
        ? props.theme.colors.primary.main
        : props.theme.colors.gray[600]} !important;
  }
`

export default DayScaleCell
