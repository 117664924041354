import React from 'react'
import { connectInfiniteHits } from 'react-instantsearch-dom'

import { FormSelect } from './select'

function CustomSearchBox({
  hits,
  refine,
  handleChange,
  getFieldValue,
  placeholder,
}) {
  return (
    <FormSelect
      handleChange={handleChange}
      selectOptions={hits}
      placeholder={placeholder}
      value={getFieldValue('insuranceProviderName')}
      onSearch={value => {
        refine(value)
      }}
    />
  )
}

const InsuranceProviderSearchBox = connectInfiniteHits(CustomSearchBox)

export default InsuranceProviderSearchBox
