import React, { useContext, useRef, useState } from 'react'
import {
  Button,
  Input,
  Modal,
  Typography,
  Descriptions,
  Form,
  Tooltip,
} from 'antd'
import ProTable from '@ant-design/pro-table'
import ProForm from '@ant-design/pro-form'
import {
  ButtonSpan,
  SearchSpan,
} from '../../components/dashboard/responsive-span-header'
import AddUserModal from '../../components/dashboard/add-user-modal'
import useUsers from '../../hooks/use-users'
import TableActionsDropdown from '../../components/dashboard/table-actions-dropdown'
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  PauseCircleFilled,
} from '@ant-design/icons'
import { successNotification } from '../../components/dashboard/ui-notification'
import userService from '../../services/user-service'
import { handleError } from '../../utils/error-utils'
import { compareStrings } from '../../utils/text-utils'
import UserPageContainer from '../../components/dashboard/users-page-container'
import { UserContext } from '../../contexts/user-context'
import { useHistory } from 'react-router-dom'
import routes from '../../config/routes'

const { Text, Title } = Typography
const { confirm } = Modal

function returnName(name) {
  let stringResult = ''
  name !== undefined && name !== null
    ? (stringResult = name)
    : (stringResult = 'Not Filled')
  return stringResult
}
function returnRole(role) {
  let receivedRole = Object.keys(role)[0]
  let formattedRole =
    receivedRole.charAt(0).toUpperCase() + receivedRole.slice(1)
  return formattedRole
}

function switchToUser(user, history) {
  confirm({
    width: '50%',
    title: 'Switch To user',
    icon: <ExclamationCircleOutlined />,
    content: (
      <div>
        <Title level={5} type="danger">
          Attention! You'll be logged as '{user.name}'
        </Title>
        <br />
        <Text>
          You will be preforming actions as this user and assume responsibility
          for editing/adjusting data. Log in as:
        </Text>
        <Descriptions bordered>
          <Descriptions.Item label="Name" span={3}>
            {user.name}
          </Descriptions.Item>
          <Descriptions.Item label="Role" span={3}>
            {returnRole(user.role)}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {user.email}
          </Descriptions.Item>
        </Descriptions>
      </div>
    ),
    onOk() {
      try {
        userService.switchToUserId(user.uid)
        history.push(routes.dashboard.home)
      } catch (error) {
        handleError(error, 'Error switching to user')
      }
    },
  })
}

function resetPassword(user, callback, loading) {
  confirm({
    width: '50%',
    title: 'Reset Password',
    icon: <ExclamationCircleOutlined />,
    content: (
      <div>
        <Title level={5} type="danger">
          Attention! This action cannot be undone
        </Title>
        <br />
        <Text>
          The user shall receive an email with instructions to reset their
          password in a few moments. Over on this email:
        </Text>
        <Descriptions bordered>
          <Descriptions.Item label="Email" span={3}>
            {user.email}
          </Descriptions.Item>
        </Descriptions>
      </div>
    ),
    onOk() {
      loading(true)
      try {
        userService.resetPassword(user.email).then(() => {
          callback()
          successNotification({
            message: 'The email will be sent in a few moments',
          })
        })
      } catch (error) {
        handleError(error, 'Error reseting the user password')
      }
      loading(false)
    },
  })
}

function toggleUserDisabled(user, callback, loading) {
  confirm({
    width: '50%',
    title: `${user.disabled ? 'Enable' : 'Disable'} User`,
    icon: <ExclamationCircleOutlined />,
    content: (
      <div>
        {user.disabled || (
          <>
            <Title level={5} type="danger">
              Attention! This user will loose access to dashboard, but his
              actions will not be undone
            </Title>
            <br />
          </>
        )}

        <Text>
          Are you sure you want to {user?.disabled ? 'enable' : 'disable'} this
          user:
        </Text>
        <Descriptions bordered>
          <Descriptions.Item label="Email" span={3}>
            {user.email}
          </Descriptions.Item>
          <Descriptions.Item label="FullName" span={3}>
            {user.name}
          </Descriptions.Item>
        </Descriptions>
        {user.disabled || (
          <Text>
            They will no longer have access to this platform while disabled
          </Text>
        )}
      </div>
    ),
    onOk() {
      try {
        loading(true)
        userService.toggleUserEnabledStatus(user.uid).then(() => {
          successNotification({
            message: `User succesfully ${
              user?.disabled ? 'enabled' : 'disabled'
            }!`,
          })
          callback()
        })
      } catch (error) {
        handleError(
          error,
          `Error ${user?.disabled ? 'enabling' : 'disabling'} this user`,
        )
      }
    },
  })
}

function getTableColumns(
  setModalSettings,
  reloadUsers,
  setIsUsersLoading,
  isImpersonator,
  history,
) {
  return [
    {
      key: 'disabled',
      title: '',
      dataIndex: 'disabled',
      width: 25,
      render: text => {
        if (text) {
          return (
            <Tooltip placement="topLeft" title={'Inactive User'}>
              <Text type="danger">
                <PauseCircleFilled />
              </Text>
            </Tooltip>
          )
        }
        return (
          <Tooltip placement="topLeft" title={'Active User'}>
            <Text type="success">
              <CheckCircleFilled />
            </Text>
          </Tooltip>
        )
      },
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: 250,
      valueType: 'text',
      search: false,
      defaultSortOrder: 'descend',
      renderText: name => returnName(name),
      sorter: {
        compare: (a, b) =>
          compareStrings(returnName(a.name), returnName(b.name)),
        multiple: 6,
      },
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) =>
          compareStrings(returnRole(a.role), returnRole(b.role)),
        multiple: 6,
      },
      renderText: role => returnRole(role),
    },
    {
      key: 'email',
      title: (_, type) => (type === 'table' ? 'Email' : 'Emails'),
      dataIndex: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 2,
      },
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, record) => [
        <TableActionsDropdown
          key="actionGroup"
          onSelect={async key => {
            if (key === 'resetPassword') {
              resetPassword(record, reloadUsers, setIsUsersLoading)
            }

            if (key === 'edit') {
              setModalSettings({ isVisible: true, user: record })
            }

            if (key === 'toggle') {
              toggleUserDisabled(record, reloadUsers, setIsUsersLoading)
            }

            if (key === 'switch') {
              switchToUser(record, history)
            }
          }}
          menus={[
            {
              key: 'resetPassword',
              name: 'Reset Password',
            },
            {
              key: 'edit',
              name: 'Edit',
            },
            {
              key: 'toggle',
              name: record.disabled ? 'Enable' : 'Disable',
            },
            isImpersonator && {
              key: 'switch',
              name: `Log in as '${record.name}'`,
            },
          ]}
        />,
      ],
    },
  ]
}

export default () => {
  const { isImpersonator } = useContext(UserContext)
  const actionRef = useRef()
  const [formInstance] = ProForm.useForm()
  const [modalSettings, setModalSettings] = useState({ isVisible: false })
  const history = useHistory()
  const {
    users,
    isUsersLoading,
    reloadUsers,
    setSearchFilters,
    setIsUsersLoading,
  } = useUsers()

  function handleAddButtonClick() {
    setModalSettings({ isVisible: true })
  }

  function handleCloseButtonClick() {
    setModalSettings({ isVisible: false })
    reloadUsers()
  }
  function resetSearch() {
    formInstance.resetFields()
    setSearchFilters(null)
  }

  async function handleSearchSubmit() {
    const { email } = formInstance.getFieldsValue()
    setSearchFilters([{ email }])
  }

  return (
    <UserPageContainer
      extraContent={
        <Form form={formInstance} layout="inline">
          <SearchSpan>
            <Form.Item label="Search" name="email">
              <Input placeholder="E-mail Address" />
            </Form.Item>
          </SearchSpan>
          <ButtonSpan>
            <Form.Item>
              <Button type="default" onClick={resetSearch}>
                Reset
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSearchSubmit}
              >
                Search
              </Button>
            </Form.Item>
          </ButtonSpan>
        </Form>
      }
    >
      <ProTable
        columns={getTableColumns(
          setModalSettings,
          reloadUsers,
          setIsUsersLoading,
          isImpersonator,
          history,
        )}
        actionRef={actionRef}
        dataSource={users}
        loading={isUsersLoading}
        rowKey="uid"
        onSubmit={handleSearchSubmit}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        options={false}
        dateFormatter="string"
        headerTitle="Current Users"
        toolBarRender={() => [
          <Button type="primary" onClick={handleAddButtonClick}>
            Add an User
          </Button>,
        ]}
        search={false}
      />
      <AddUserModal
        isModalVisible={modalSettings.isVisible}
        onClickClose={handleCloseButtonClick}
        user={modalSettings?.user}
      />
    </UserPageContainer>
  )
}
