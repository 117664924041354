import React from 'react'
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
} from '@react-pdf/renderer'
import { format, parseISO } from 'date-fns'

import Logo from '../../assets/imgs/header-logo.png'
import theme from '../../styles/theme'
import { formatDateOfBirth } from '../../utils/date-utils'

const styles = StyleSheet.create({
  page: {
    padding: theme.spacing.xg,
  },
  logo: {
    width: 150,
    height: 28,
  },
  title: {
    paddingVertical: theme.spacing.lg,
    fontSize: 24,
  },
  section: {
    borderWidth: 0.5,
    borderColor: theme.colors.gray[400],
    marginBottom: theme.spacing.lg,
  },
  subTitle: {
    fontSize: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: theme.colors.gray[400],
    padding: theme.spacing.lg,
  },
  containerLocation: {
    padding: theme.spacing.lg,
  },
  description: {
    fontSize: 10,
    fontWeight: 'ultralight',
    marginBottom: theme.spacing.sm,
  },
  containerDescription: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing.lg,
  },
  containerItem: {
    flexDirection: 'row',
    flex: 1,
  },
  details: {
    fontSize: 16,
    color: theme.colors.primary.main,
    marginBottom: theme.spacing.lg,
  },
  confirmationCode: {
    fontSize: 25,
    padding: theme.spacing.lg,
  },
  titleItem: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 2,
  },
})

const BookingConfirmationPDF = ({ appointment, location, title }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image src={Logo} style={styles.logo} />
      <Text style={styles.title}>{title}</Text>
      <View style={styles.section}>
        <Text style={styles.subTitle}>Confirmation #</Text>
        <Text style={styles.confirmationCode}>
          {appointment?.confirmationCode}
        </Text>
      </View>
      <Text style={styles.details}>Appointment Details</Text>
      <View style={styles.section}>
        <Text style={styles.subTitle}>{location?.title}</Text>
        <View style={styles.containerLocation}>
          <Text style={styles.description}>{location?.address}</Text>
          <Text style={styles.description}>
            {location?.cityState} {location?.zipCode}
          </Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.containerDescription}>
          <View style={styles.containerItem}>
            <View style={styles.left}>
              <Text style={styles.titleItem}>Date:</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.description}>
                {format(parseISO(appointment?.slotsDateTime), 'MM/dd/yyyy')}
              </Text>
            </View>
          </View>
          <View style={styles.containerItem}>
            <View style={styles.left}>
              <Text style={styles.titleItem}>Time:</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.description}>
                {format(parseISO(appointment?.slotsDateTime), 'h:mm a')}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.containerDescription}>
          <View style={styles.containerItem}>
            <View style={styles.left}>
              <Text style={styles.titleItem}>Name:</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.description}>
                {`${appointment?.firstName} ${
                  appointment?.middleName
                    ? appointment?.middleName + ' ' + appointment?.lastName
                    : appointment?.lastName
                }`}
              </Text>
            </View>
          </View>
          <View style={styles.containerItem}>
            <View style={styles.left}>
              <Text style={styles.titleItem}>Date of Birth:</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.description}>
                {formatDateOfBirth(appointment?.dateOfBirth)}
              </Text>
            </View>
          </View>
          <View style={styles.containerItem}>
            <View style={styles.left}>
              <Text style={styles.titleItem}>Phone:</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.description}>{appointment?.phoneNumber}</Text>
            </View>
          </View>
          <View style={styles.containerItem}>
            <View style={styles.left}>
              <Text style={styles.titleItem}>Address:</Text>
            </View>
            <View style={styles.right}>
              <Text style={styles.description}>
                {`${location.address} ${location.addressComplement && '-'} ${
                  location.addressComplement
                }`}
              </Text>
              <Text style={styles.description}>
                {location.cityState} {location.zipCode}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
)

export default BookingConfirmationPDF
