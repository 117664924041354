import React, { Component } from 'react'

export const withRoles = WrappedComponent => {
  class RolesHOC extends Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return RolesHOC
}
