import React from 'react'
import { Typography, Form, Checkbox } from 'antd'
import AppointmentService from '../../services/appointments-service'
import { useHistory } from 'react-router-dom'
import routes from '../../config/routes'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import { RadioGroup } from '../../components/radio-group'
import { DemographicTooltip } from '../../components/demographic-tooltip'
import Spin from '../../components/main-spin'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import { useTranslation } from 'react-i18next'
const { Group } = Checkbox
const { Title, Text } = Typography

const DemographicsPt2 = () => {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const url = new URL(window.location.href)
  const isEdit = url.searchParams.get('isEdit')
  const { t } = useTranslation()
  const ETHNIC_OPTIONS = t('ETHNIC_OPTIONS', { returnObjects: true })
  const RACE_OPTIONS = t('RACE_OPTIONS', { returnObjects: true })

  const onFinish = async values => {
    try {
      await AppointmentService.update(appointmentId, values)
      if (isEdit) {
        history.push(routes.bookingConfirmation)
      } else {
        history.push(routes.hipaaWarn)
      }
    } catch (error) {
      handleError(error, 'Error saving the demographics data')
    }
  }

  const handleChange = chekedValues => {
    return form.setFieldsValue({ race: chekedValues })
  }

  const isNextButtonDisabled = () => {
    return (
      hasFormErrors(form) ||
      !form.getFieldValue('race') ||
      !form.getFieldValue('ethnicity')
    )
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle> {t('demographics.demographics2')} </PageTitle>
      <Text> {t('demographics.text')}</Text>
      <Title level={5}>
        {t('demographics.expand')} <DemographicTooltip large />
      </Title>
      <Form form={form} onFinish={onFinish} initialValues={appointment}>
        <Title level={3} type="primary">
          {t('demographics.race')}
        </Title>
        <Form.Item
          name="race"
          rules={[{ required: true, message: `${t('messages.raceRequired')}` }]}
        >
          <Group onChange={handleChange}>
            <ContentSpace>
              {RACE_OPTIONS.map(item => (
                <Checkbox key={item.key} value={item.value}>
                  {item.label}
                </Checkbox>
              ))}
            </ContentSpace>
          </Group>
        </Form.Item>
        <Title level={3} type="primary">
          {t('demographics.ethnicity')}
        </Title>
        <Form.Item
          name="ethnicity"
          rules={[
            { required: true, message: `{t('messages.ethnicityRequired)}` },
          ]}
        >
          <RadioGroup options={ETHNIC_OPTIONS} />
        </Form.Item>

        <ActionButtons
          isNextButtonDisabled={isNextButtonDisabled}
          backButtonUrl={
            isEdit ? routes.bookingConfirmation : routes.demographicsPt1
          }
        />
      </Form>
    </ContentSpace>
  )
}

export default DemographicsPt2
