import styled from 'styled-components'
import { Tooltip } from 'antd'

export const StyledTooltip = styled(Tooltip)`
  .ant-typography {
    color: white;
  }
  .ant-tooltip-content {
    width: ${props => (props.large ? '23em' : '100%')};
  }
`
