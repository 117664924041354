/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { format, addMinutes } from 'date-fns'
import { appointmentsIndex } from '../config/config'
import AppointmentsService from '../services/appointments-service'
import {
  algoliaSearch,
  parsePatientSearchResultToAppointments,
} from '../utils/algolia-utils'
import { handleError } from '../utils/error-utils'

export default function usePatientLookup(doctorReferralCode = undefined) {
  const [appointments, setAppointments] = useState()
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState()
  const [searchFilters, setSearchFilters] = useState()

  const fiterDOB = async (arr, dob) => {
    return arr.filter(item => {
      let date = new Date(item.dateOfBirth)
      let dobDate = new Date(dob)

      let fDate = format(addMinutes(date, date.getTimezoneOffset()), 'MMddyyyy')
      let fDob = format(dobDate, 'MMddyyyy')
      if (doctorReferralCode) {
        return fDate === fDob && item.doctorReferralCode === doctorReferralCode
      }
      return fDate === fDob
    })
  }

  useEffect(() => {
    //ADMIN or DOCTOR + FILTERS
    if (!searchFilters?.dob) {
      return
    }

    async function fetchData() {
      try {
        setIsAppointmentsLoading(true)
        let searchFirstName = await algoliaSearch(
          '',
          appointmentsIndex,
          `firstName:"${searchFilters.firstName}"`,
        )
        let searchLastName = await algoliaSearch(
          '',
          appointmentsIndex,
          `lastName:"${searchFilters.lastName}"`,
        )
        const searchFor = searchFilters.firstName
          ? searchFirstName
          : searchLastName
        const filterByDOB = await fiterDOB(searchFor, searchFilters.dob)
        setAppointments(
          await parsePatientSearchResultToAppointments(filterByDOB),
        )
        setIsAppointmentsLoading(false)
      } catch (error) {
        handleError(error, 'Error searching by appointments')
        setIsAppointmentsLoading(false)
      }
    }

    fetchData()
  }, [doctorReferralCode, searchFilters])

  useEffect(() => {
    //NO FILTERS
    if (
      searchFilters?.firstName ||
      searchFilters?.lastName ||
      searchFilters?.dob
    ) {
      return
    }
    try {
      setIsAppointmentsLoading(true)
      setAppointments()
      setIsAppointmentsLoading(false)
    } catch (error) {
      handleError(error, 'Error retrieving appointments')
      setIsAppointmentsLoading(false)
    }

    return () => {
      AppointmentsService.unsubscribeAppointments()
    }
  }, [searchFilters, doctorReferralCode])

  return {
    appointments,
    isAppointmentsLoading,
    setSearchFilters,
  }
}
