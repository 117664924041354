import React from 'react'
import { Radio, Typography } from 'antd'
import Card from './spaced-card'

const { Text } = Typography

const LocationSelectItem = ({ data, isActive }) => {
  return (
    <Card
      title={<Text disabled={!isActive}>{data.title}</Text>}
      extra={
        isActive ? (
          <Radio value={data}>
            <Text type="primary"></Text>
          </Radio>
        ) : (
          false
        )
      }
    >
      <Text disabled={!isActive}>{data.address}</Text>
      <br />
      <Text disabled={!isActive}>
        {data.cityState} {data.zipCode}
      </Text>
    </Card>
  )
}

export default LocationSelectItem
