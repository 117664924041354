import React, { useEffect, useState } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProForm from '@ant-design/pro-form'
import { Card, Form } from 'antd'
import { useParams } from 'react-router-dom'

import AppointmentsTimeslotDropdown from '../../components/dashboard/location-scheduler-timeslot'
import {
  createLocationSchedule,
  checkScheduleOperation,
} from '../../utils/location-schedule-utils'
import { useLocationScheduleContext } from '../../contexts/location-schedule-context'
import { handleError } from '../../utils/error-utils'
import LocationScheduler from '../../components/dashboard/location-scheduler'
import useLocations from '../../hooks/use-locations'

export default function LocationsSchedule() {
  const [formInstance] = ProForm.useForm()
  const {
    schedule,
    setScheduleData,
    initialSlots,
  } = useLocationScheduleContext()
  const { locationId } = useParams()
  const { location } = useLocations(locationId)
  const initialValues = { schedule: [] }
  const requiredFieldRule = {
    required: true,
    message: 'Please, select at least one time slot',
  }

  async function handleFormSubmit() {
    await checkScheduleOperation(locationId, schedule, initialSlots)
  }

  function resetForm() {
    formInstance.setFieldsValue(initialValues)
    setScheduleData([])
  }

  function handleFinishFailure() {
    const errorMessage = formInstance.getFieldError('schedule')[0]
    handleError(errorMessage, 'Error to save schedule')
  }

  useEffect(() => {
    formInstance.setFieldsValue({ schedule })
  }, [formInstance, schedule])

  return (
    <PageContainer
      title={`Schedule for ${location?.title}`}
      content={<AppointmentsTimeslotDropdown />}
    >
      <Card>
        <ProForm
          form={formInstance}
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          onFinishFailed={handleFinishFailure}
          submitter={{
            onReset: resetForm,
          }}
        >
          <Form.Item help={false} name="schedule" rules={[requiredFieldRule]}>
            <LocationScheduler />
          </Form.Item>
        </ProForm>
      </Card>
    </PageContainer>
  )
}
