import { TableDropdown } from '@ant-design/pro-table'
import styled from 'styled-components'

const TableActionsDropdown = styled(TableDropdown)`
  font-size: 30px;
  padding: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.gray[800]};
`

export default TableActionsDropdown
