import React, { useState, useEffect, useContext } from 'react'
import { Typography, Switch } from 'antd'
import ProTable from '@ant-design/pro-table'
import { compareStrings } from '../../utils/text-utils'
import { PageContainer } from '@ant-design/pro-layout'
import { format, parseISO } from 'date-fns'
import useSpecimens from '../../hooks/use-specimens-by-user'
import { UserContext } from '../../contexts/user-context'
import UserService from '../../services/user-service'

const { Text } = Typography

function getTableColumns(isReceived) {
  return [
    {
      key: 'locationName',
      title: 'Location',
      dataIndex: 'locationName',
      sorter: {
        compare: (a, b) => compareStrings(a.locationName, b.locationName),
        multiple: 4,
      },
    },
    {
      key: 'barcode',
      title: 'Barcode',
      dataIndex: 'barcode',
      sorter: {
        compare: (a, b) => compareStrings(a.barcode, b.barcode),
        multiple: 3,
      },
    },
    {
      key: 'confirmation',
      title: 'Confirmation',
      dataIndex: 'confirmation',
      renderText: date => {
        return date && format(parseISO(date), 'MM/dd/yyyy')
      },
    },
    isReceived && {
      key: 'receivedOn',
      title: 'Received',
      dataIndex: ['status', 'receivedOn'],
      renderText: date => {
        return date && <Text type="secondary">Received</Text>
      },
    },
  ]
}

export default () => {
  const { user, role } = useContext(UserContext)
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    UserService.getUserInfo(user.uid, response => {
      setUserInfo(response)
    })
  }, [role, user.uid])

  const isDoctor = role === 'doctor'
  const {
    appointmentsSpecimen,
    isLoading,
    isReceived,
    setIsReceived,
  } = useSpecimens(
    !isDoctor && userInfo?.uid,
    isDoctor && userInfo?.doctorReferralCode,
    '',
    false,
  )
  return (
    <PageContainer
      title={`${userInfo?.fullName ?? 'Name Not Found'}'s - Total Specimens ${
        appointmentsSpecimen?.length ?? ''
      }`}
    >
      <ProTable
        columns={getTableColumns(isReceived)}
        dataSource={appointmentsSpecimen}
        loading={isLoading}
        options={false}
        search={false}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        dateFormatter="string"
        rowKey="key"
        headerTitle={
          <>
            <Switch
              checked={isReceived}
              onClick={() => setIsReceived(!isReceived)}
            />
            <Text>Show Received</Text>
          </>
        }
      />
    </PageContainer>
  )
}
