import React, { createContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Spin } from 'antd'

import { handleError } from '../utils/error-utils'
import routes from '../config/routes'
import { USER_ROLES } from '../constants'
import useQueryParam from '../hooks/use-query-param'
import UserService from '../services/user-service'

export const UserContext = createContext()

const UserContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState()
  const [isPending, setIsPending] = useState(true)
  const [user, setUser] = useState(null)
  const [role, setRole] = useState()
  const [isImpersonator, setIsImpersonator] = useState(false)
  const token = useQueryParam('token')
  const { pathname } = useLocation()

  useEffect(() => {
    async function signInWithCustomToken() {
      if (
        pathname === routes.doctorReferred &&
        !!token &&
        isLoggedIn === false
      ) {
        try {
          setIsPending(true)
          await UserService.signInWithCustomToken(token)
        } catch (error) {
          setIsPending(false)
          handleError(null, 'Error authenticating the user by token')
        }
      }
    }

    signInWithCustomToken()
  }, [token, isLoggedIn, pathname])

  useEffect(() => {
    let unsubscribeAuthObserver = () => null

    try {
      unsubscribeAuthObserver = UserService.observeUser(async user => {
        if (user) {
          const idTokenResult = await UserService.getIdTokenResult()
          if (idTokenResult.claims.admin) {
            if (idTokenResult.claims.impersonator) {
              setIsImpersonator(true)
            }
            setRole(USER_ROLES.admin)
          } else if (idTokenResult.claims.lab) {
            setRole(USER_ROLES.lab)
          } else if (idTokenResult.claims.collection) {
            setRole(USER_ROLES.collection)
          } else if (idTokenResult.claims.billing) {
            setRole(USER_ROLES.billing)
          } else if (idTokenResult.claims.guest) {
            setRole(USER_ROLES.guest)
          } else if (idTokenResult.claims.reporting) {
            setRole(USER_ROLES.reporting)
          } else if (idTokenResult.claims.organization) {
            setRole(USER_ROLES.organization)
          } else if (idTokenResult.claims.doctor) {
            setRole(USER_ROLES.doctor)
          }

          const signInMethods = await UserService.fetchSignInMethodsForEmail(
            user?.email,
          )

          setUser({ ...user, signInMethods })
        }

        setIsLoggedIn(!!user)
        setIsPending(false)
      })
    } catch (error) {
      setIsPending(false)
      handleError(null, 'Error fetching the user data')
    }

    return () => unsubscribeAuthObserver()
  }, [])

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isPending,
        user,
        setUser,
        role,
        setRole,
        isImpersonator,
      }}
    >
      {isPending ? <Spin /> : children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
