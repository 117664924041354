import styled from 'styled-components'
import { device } from './../../constants/index'

const SearchSpan = styled.span`
  @media ${device.mobileS}, ${device.mobileM} {
    padding-top: 2px;
    width: 70%;
    display: block;
    flex-direction: row;
    & .ant-input {
      margin-bottom: 11px;
    }
  }

  @media ${device.mobileL} {
    padding-top: 2px;
    width: 70%;
    display: flex;
    flex-direction: row;
  }
`
const ButtonSpan = styled.span`
  @media ${device.mobileS} {
    display: flex;
  }
  @media ${device.mobileM} {
    display: flex;
    justify-content: flex-end !important;
  }
  @media ${device.mobileL} {
    padding-top: 2px;
    width: 30%;
    display: flex;
    justify-content: flex-end !important;
  }
`
export { ButtonSpan, SearchSpan }
