import React from 'react'
import { Typography } from 'antd'
const { Text } = Typography

export const COMPANY_NAME = 'Sterling Pathology'
export const FORMATED_COMPANY_NAME = COMPANY_NAME.toLowerCase().replace(' ', '')
export const APP_NAME = COMPANY_NAME
export const COMPANY_PHONE_NUMBER = '(562) 799-8900'
export const ERROR_APPOINTMENT_CANCELED = 'Error to cancel the appointment'
export const APPOINTMENT_CANCELED = 'Your appointment has been canceled'
export const INDEX_LINE = 19

export const USPS_API_KEY =
  process.env.REACT_APP_USPS_ADDRESS_USERID || '810TEST01153'

export const AVAILABLETIMESLOTS = [
  {
    id: 1202912,
    dateString: 'September 28, 2020',
    timeSlots: [
      {
        dateTime: '280720201530',
        interval: '3:30 PM - 4:00 PM',
        vacancy: 55,
      },
      {
        dateTime: '280720201600',
        interval: '4:00 PM - 4:30 PM',
        vacancy: 54,
      },
      {
        dateTime: '280720201630',
        interval: '4:30 PM - 5:00 PM',
        vacancy: 38,
      },
    ],
  },
  {
    id: 1202913,
    dateString: 'September 29, 2020',
    timeSlots: [
      {
        dateTime: '290720201530',
        interval: '3:30 PM - 4:00 PM',
        vacancy: 72,
      },
      {
        dateTime: '290720201600',
        interval: '4:00 PM - 4:30 PM',
        vacancy: 68,
      },
      {
        dateTime: '290720201630',
        interval: '4:30 PM - 5:00 PM',
        vacancy: 49,
      },
    ],
  },
]

export const APPOINTMENT_CONFIRMATION_CHECKS = [
  {
    text: 'I commit to attend this appointment as shown below',
    value: 1,
  },
  {
    text: (
      <Text>
        <b>I AGREE</b> to cancel IF I am unable to attend so the appointment can
        be released for another county resident{' '}
      </Text>
    ),
    value: 2,
  },
]

export const FICTIONAL_APPOINTMENT = {
  appointmentId: 'FSS-APT00092841',
  drivethrough: {
    siteTitle: 'Long beach city college - Veterians stadium',
    locationLine1: '5000 E. Lew Davis Dr',
    locationLine2: 'Long Beach, CA 90808',
  },
  timeSlot: {
    dateString: 'September 28, 2020',
    timeString: '4:30 - 5:00PM',
  },
  personalInfo: {
    fullName: 'Chris S Whitman',
    birthDate: '07/12/1988',
    phone: '(424) 274-8320',
    personalAddress: (
      <>
        <p>2900 N Bellflower Blvd</p>
        <p>Long Beach, CA 90815</p>
      </>
    ),
  },
  demographic: {
    sex: 'Male',
    gender: 'Male',
    orientation: 'Male',
    race: 'Preffer not to state',
    Ethnicity: 'Preffer not to state',
  },
  identification: {
    driver: 'DL29939992',
    ssn: '***-**-8383',
  },
}

export const GENDER_OPTIONS = [
  {
    key: 1,
    value: 'female',
    label: 'Female',
  },
  {
    key: 2,
    value: 'male',
    label: 'Male',
  },
  {
    key: 3,
    value: 'other',
    label: 'Other',
  },
]

export const SEX_AT_BIRTH_OPTIONS = [
  {
    key: 1,
    value: 'female',
    label: 'Female',
  },
  {
    key: 2,
    value: 'male',
    label: 'Male',
  },
  {
    key: 3,
    value: 'other',
    label: 'Other',
  },
]

export const SEXUAL_ORIENTATION_OPTIONS = [
  {
    key: 1,
    value: 'hetero',
    label: 'Straight/Heterosexual',
  },
  {
    key: 2,
    value: 'homo',
    label: 'Gay or Lesbian',
  },
  {
    key: 3,
    value: 'bi',
    label: 'Bisexual',
  },
  {
    key: 4,
    value: 'selfdescribed',
    label: 'Prefer to self-describe',
  },
  {
    key: 5,
    value: 'notstated',
    label: 'Prefer not to state',
  },
]

export const RACE_OPTIONS = [
  {
    key: '1',
    value: 'native',
    label: 'American Indian or Alaska Native',
  },
  {
    key: '2',
    value: 'asian',
    label: 'Asian',
  },
  {
    key: '3',
    value: 'black',
    label: 'Black or African American',
  },
  {
    key: '4',
    value: 'isles',
    label: 'Native Hawaiian or Other Pacific islander',
  },
  {
    key: '5',
    value: 'white',
    label: 'White',
  },
  {
    key: '6',
    value: 'other',
    label: 'Other',
  },
  {
    key: '7',
    value: 'notstated',
    label: 'Prefer not to state',
  },
]

export const ETHNIC_OPTIONS = [
  {
    key: '1',
    value: 'latino',
    label: 'Hispanic or Latino',
  },
  {
    key: '2',
    value: 'notlatino',
    label: 'Not Hispanic or Latino',
  },
  {
    key: '1',
    value: 'other',
    label: 'Other',
  },
]

export const WELL_POSITION = [
  ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
  ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
]

export const BILLING_STATUS = { pending: 'Pending', processed: 'Processed' }

export const INSURANCE_STATUS = { pending: 'pending', processed: 'processed' }

export const USER_ROLES = {
  admin: 'admin',
  lab: 'lab',
  collection: 'collection',
  billing: 'billing',
  reporting: 'reporting',
  guest: 'guest',
  organization: 'organization',
  doctor: 'doctor',
  impersonator: 'impersonator',
}

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const INSURED_RELATIONSHIP_LIST = [
  {
    key: 'self',
    title: 'Self',
  },
  {
    key: 'spouse',
    title: 'Spouse',
  },
  {
    key: 'child',
    title: 'Child',
  },
  {
    key: 'other',
    title: 'Other',
  },
]

export const RESULTS = [
  {
    value: 'Positive',
  },
  {
    value: 'Negative',
  },
]

export const STATUS = {
  inProgress: 'In-progress',
  completed: 'Completed',
}

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '995px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}

export const SYMPTOMS_LIST = [
  {
    key: 'fever',
    title: 'Fever or chills',
  },
  {
    key: 'cough',
    title: 'Cough',
  },
  {
    key: 'difficultyBreathing',
    title: 'Shortness of breath or difficulty breathing',
  },
  {
    key: 'fatigue',
    title: 'Fatigue',
  },
  {
    key: 'muscleAches',
    title: 'Muscle or body aches',
  },
  {
    key: 'tasteLoss',
    title: 'New loss of taste or smell',
  },
  {
    key: 'headache',
    title: 'Headache',
  },
  {
    key: 'soreThroat',
    title: 'Sore throat',
  },
  {
    key: 'congestion',
    title: 'Congestion or runny nose',
  },
  {
    key: 'nausea',
    title: 'Nausea or vomiting',
  },
  {
    key: 'diarrhea',
    title: 'Diarrhea',
  },
  {
    key: 'noneAbove',
    title: 'None of the above',
  },
]

export const TEST_REASONS_LIST = [
  {
    key: 1,
    value: 1,
    title: 'I am over 65',
  },
  {
    key: 2,
    value: 2,
    title: 'I have a chronic condition',
  },
  {
    key: 3,
    value: 3,
    title:
      'I am an essential worker beyound the high-risk settings noted above (first responders, critical government personnel, health care professionals, grocery workers, delivery, rideshare, taxi and public transit drivers, credentialed members of the media, construction workers)',
  },
  {
    key: 4,
    value: 4,
    title: 'None of the above',
  },
]
