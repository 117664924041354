import React from 'react'
import { useTranslation } from 'react-i18next'
import { Result } from 'antd'
import routes from '../../config/routes'

import ActionButtons from '../../components/action-buttons'
const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('alerts.notFoundSubtitle')}
      extra={
        <ActionButtons
          hideBackButton={true}
          nextButtonUrl={routes.home}
          nextButtonTitle={t('buttons.home')}
        />
      }
    />
  )
}

export default NotFound
