import React from 'react'

import { useLocationScheduleContext } from '../../contexts/location-schedule-context'
import TimeTableCell from './time-table-cell'

export default function SchedulerTableCell(props) {
  const { addTimeSlot } = useLocationScheduleContext()

  return (
    <TimeTableCell
      {...props}
      onDoubleClick={() => null}
      onClick={() => addTimeSlot(props.startDate.toJSON())}
    />
  )
}
