import React from 'react'
import { Typography } from 'antd'
import { addMinutes, format, parse, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import ContentSpace from './content-space'
import TextSpacing from './text-spacing'
import { Menu, MenuItem } from './menu'

const { SubMenu } = Menu
const { Title, Text } = Typography

export const AppointmentDateSelect = ({
  locationSchedule,
  value = [],
  onChange,
  ...props
}) => {
  function triggerChange(event) {
    if (onChange) {
      onChange(event.key)
    }
  }
  const { t } = useTranslation()
  return (
    <ContentSpace {...props}>
      {locationSchedule &&
        locationSchedule.map(schedule => {
          return (
            <div style={{ width: '100%' }} key={schedule.key}>
              <Menu
                defaultOpenKeys={['sub1']}
                mode="inline"
                onClick={triggerChange}
                selectedKeys={value}
              >
                <SubMenu
                  key="sub1"
                  title={
                    <Title level={5}>
                      {format(
                        parse(schedule.date, 'yyyy-MM-dd', new Date()),
                        'LLLL d, yyyy',
                      )}
                    </Title>
                  }
                >
                  {schedule.timeSlots.length > 0 &&
                    schedule.timeSlots.map(timeSlot => (
                      <MenuItem
                        value={timeSlot.startDate}
                        key={timeSlot.startDate}
                        disabled={!timeSlot.slots}
                      >
                        <TextSpacing style={{ fontWeight: 'bold' }}>
                          {format(parseISO(timeSlot.startDate), 'h:mm a')} -{' '}
                          {format(
                            addMinutes(parseISO(timeSlot.startDate), 30),
                            'h:mm a',
                          )}
                        </TextSpacing>
                        <Text type="primary" style={{ fontWeight: 'bold' }}>
                          {timeSlot.slots} {t('timeSlots.slotsAvailable')}
                        </Text>
                      </MenuItem>
                    ))}
                </SubMenu>
              </Menu>
            </div>
          )
        })}
    </ContentSpace>
  )
}
