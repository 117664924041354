import React, { useContext } from 'react'
import { ConfigProvider } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import enUSIntl from 'antd/lib/locale/en_US'

import appLogo from '../../assets/imgs/app-logo.svg'
import { dashboardMenuRoutes } from '../../config/routes'
import ProLayout from './pro-layout'
import RightHeaderContent from './right-header-content'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../contexts/user-context'

export default function DashboardLayout({ children }) {
  const { t } = useTranslation()
  const location = useLocation()
  const { role } = useContext(UserContext)

  const filterMenu = menu => {
    if (!!role) {
      return {
        routes: [
          ...menu.filter(item => {
            if (item.role.includes(role)) {
              return item
            }

            return null
          }),
        ],
      }
    }
    return {
      routes: [],
    }
  }

  return (
    <ConfigProvider locale={enUSIntl}>
      <ProLayout
        logo={appLogo}
        location={{ pathname: location.pathname }}
        title={t('dashboard.pageTitle')}
        menuHeaderRender={logo => logo}
        menuItemRender={(item, itemText) => (
          <Link to={item.path}>{itemText}</Link>
        )}
        rightContentRender={RightHeaderContent}
        route={filterMenu(dashboardMenuRoutes.routes)}
        locale="en-US"
      >
        {children}
      </ProLayout>
    </ConfigProvider>
  )
}
