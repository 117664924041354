import { useEffect, useState } from 'react'

import LocationService from '../services/location-service'
import { handleError } from '../utils/error-utils'

export default function useLocationsByAvailability(
  hasScheduleAvailable = true,
  referralCode = null,
) {
  const [locations, setLocations] = useState()
  const [isLocationsLoading, setIsLocationsLoading] = useState()

  useEffect(() => {
    setIsLocationsLoading(true)
    try {
      if (referralCode) {
        LocationService.observeLocationsFilteredByReferralCode(
          referralCode,
          locations => {
            setLocations(locations)
            setIsLocationsLoading(false)
          },
        )
      } else {
        LocationService.observeLocationsFilteredByAvailability(
          hasScheduleAvailable,
          locations => {
            setLocations(locations)
            setIsLocationsLoading(false)
          },
        )
      }
    } catch (error) {
      handleError(error, 'Error retrieving locations filtered by availability')
      setIsLocationsLoading(false)
    }

    return () => {
      LocationService.unsubscribeLocations()
    }
  }, [hasScheduleAvailable, referralCode])

  return { locations, isLocationsLoading }
}
