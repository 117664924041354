import React, { useEffect, useState } from 'react'
import { Modal, Button, Typography, Input, Form } from 'antd'
import ProForm, { ProFormText } from '@ant-design/pro-form'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import useProfiles from '../../hooks/use-profiles'
import { errorNotification } from '../../components/dashboard/ui-notification'
import { useHistory } from 'react-router-dom'
import routes from '../../config/routes'

export default function CheckInModal({
  form,
  doctorReferralCode,
  isVisible,
  onClickClose,
  finalize,
}) {
  const [formInstance] = ProForm.useForm()
  const { profiles } = useProfiles()
  const [doctorInfo, setDoctorInfo] = useState()
  const history = useHistory()

  const { Text } = Typography
  const { t } = useTranslation()

  const handleQuit = () => {
    onClickClose()
    form.resetFields()
  }
  useEffect(() => {
    if (isVisible) {
      const response = profiles?.find(
        e => e.doctorReferralCode === doctorReferralCode,
      )
      if (!response) {
        errorNotification({
          message: 'Invalid Referral Code',
          description: `${t('confirm_doctor.ref_notFound')}`,
        })
      }
      setDoctorInfo(response)
    }
  }, [isVisible, profiles, doctorReferralCode, setDoctorInfo, t])

  const renderInfo = () => {
    if (!doctorInfo) {
      return (
        <>
          <Text style={{ color: 'red' }}>{t('confirm_doctor.not_found')}</Text>
        </>
      )
    }
    return (
      <>
        <Text>{`${t('confirm_doctor.confirm')}`}</Text>
        <br></br>
        <Text strong>{doctorInfo?.fullName}</Text>
      </>
    )
  }

  const handleSubmit = () => {
    if (doctorInfo) {
      finalize(doctorInfo)
    }
    handleQuit()
  }
  return (
    <Modal
      title={t('confirm_doctor.title')}
      visible={isVisible}
      centered
      closeIcon={<CloseOutlined onClick={handleQuit} />}
      destroyOnClose
      footer={[
        <Button key="back" onClick={handleQuit}>
          {t('confirm_doctor.close')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {doctorInfo
            ? t('affirmation.positive')
            : t('confirm_doctor.try_again')}
        </Button>,
      ]}
    >
      {renderInfo()}
    </Modal>
  )
}
