import { FirebaseService } from './firebase-service'
import { paths } from './paths'
import getUUid from 'uuid-by-string'
import { removeEmptyValues } from '../utils/appointment-utils'

class PeopleService extends FirebaseService {
  constructor() {
    super(paths.people)
  }

  observePeoplesByOrganizationId(organizationId, callback) {
    try {
      this.path
        .orderByChild('organizationId')
        .equalTo(organizationId)
        .on('value', snapshot => {
          const peoples = this.formatSnapshot(snapshot)
          callback(peoples)
        })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  formatSnapshot(snapshot) {
    const peoples = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()

      peoples.push({
        ...values,
        key: childSnapshot.key,
        value: `${values.firstName} ${values.lastName}`,
      })
    })

    return peoples
  }

  unsubscribePeoples() {
    this.path.off()
  }

  async add({ organizationId, appointment }) {
    const { firstName, lastName, email, dateOfBirth } = appointment
    try {
      const peopleId = getUUid(`${firstName}${lastName}${email}${dateOfBirth}`)
      const people = {
        ...appointment,
        organizationId,
      }
      await this.path.child(peopleId).set(removeEmptyValues(people))
    } catch (error) {
      throw error
    }
  }
}

export default new PeopleService()
