import React from 'react'
import ContentSpace from './content-space'
import { Card } from 'antd'
import LinkButton from './link-button'
import routes from '../config/routes'
import { Description } from './description'
import { useTranslation } from 'react-i18next'

export const CardTimeSlot = ({ data, isEditable = true }) => {
  const { t } = useTranslation()
  return (
    <ContentSpace>
      <Card>
        <Description
          label={t('general.date')}
          content={data.dateString}
          extra={
            isEditable && (
              <LinkButton type="link" to={routes.timeSlots + '?isEdit=true'}>
                {t('buttons.edit')}
              </LinkButton>
            )
          }
        />
        <Description label={t('timeSlots.time')} content={data.timeString} />
      </Card>
    </ContentSpace>
  )
}
