import React, { useContext, useState, useEffect } from 'react'
import { Col, Row } from 'antd'
import Header from '../components/header'
import LanguageMenu from '../components/language-dropdown'
import { Container } from './styles/form-template-styled'
import routes from '../config/routes'
import { Link } from 'react-router-dom'
import { UserContext } from '../contexts/user-context'
import { LoginButton } from '../components/styles/login-button'

const FormTemplate = ({ children }) => {
  const { isLoggedIn, user, role, setRole } = useContext(UserContext)
  const [hasLogin, setLogin] = useState('Login')

  useEffect(() => {
    if (isLoggedIn && role !== 'guest') setLogin('Dashboard')
    else setLogin('Login')

    if (role == undefined && user?.displayName) setRole('guest')
  }, [isLoggedIn])

  return (
    <Container>
      <Row justify="space-between" align="middle">
        <Col xs={{ span: 22 }} sm={{ span: 12 }} md={{ span: 6 }}>
          <Header />
        </Col>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
          style={{ paddingTop: '4%' }}
        >
          <LoginButton>
            <Link to={routes.dashboard.login}>
              <div>{hasLogin}</div>
            </Link>
          </LoginButton>
          <LanguageMenu />
        </Col>
      </Row>
      <Row>{children}</Row>
    </Container>
  )
}

export default FormTemplate
