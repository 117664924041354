export async function dateExists(arr, date) {
  return arr.some(item => {
    return item.date === date
  })
}

export async function removeDup(arr) {
  return arr.filter((dateRcv, index, self) => {
    return (
      index ===
      self.findIndex(t => {
        return t.date === dateRcv.date
      })
    )
  })
}
