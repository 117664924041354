import { FirebaseService } from './firebase-service'
import { paths } from './paths'
import { capitalize } from '../utils/text-utils'

class TestGroupService extends FirebaseService {
  constructor() {
    super(paths.testGroups)
  }

  async create(groupId, user, title) {
    const currentDate = new Date()

    try {
      await this.path.child(groupId).set({
        id: user.uid,
        fullName: user.displayName,
        groupBatchId: groupId,
        groupBatchCounter: 1,
        date: currentDate.toISOString(),
        status: '',
        title,
      })
    } catch (error) {
      throw error
    }
  }

  async update(groupId, testGroup) {
    try {
      await this.path.child(groupId).update(testGroup)
    } catch (error) {
      throw error
    }
  }

  observeTestGroupsById(uid, role, callback) {
    try {
      if (role === 'admin') {
        this.path.on('value', snapshot => {
          const testGroups = this.formatSnapshotToAdmin(snapshot)
          callback(testGroups)
        })
      } else {
        this.path
          .orderByChild('id')
          .equalTo(uid)
          .on('value', snapshot => {
            const testGroups = this.formatSnapshot(snapshot)
            callback(testGroups)
          })
      }
    } catch (error) {
      throw error
    }
  }

  observeTestGroupById(groupId, callback) {
    try {
      this.path.child(groupId).on('value', snapshot => {
        const testGroup = snapshot.val()
        callback(testGroup)
      })
    } catch (error) {
      throw error
    }
  }

  formatSnapshotToAdmin(snapshot) {
    const testGroups = []

    snapshot.forEach(childSnapshot => {
      const { status } = childSnapshot.val()
      if (status === 'pending' || status === 'processed') {
        testGroups.push({
          ...childSnapshot.val(),
          groupId: childSnapshot.key,
          status: status ? capitalize(status) : status,
        })
      }
    })

    return testGroups
  }

  formatSnapshot(snapshot) {
    const testGroups = []

    snapshot.forEach(childSnapshot => {
      const { status } = childSnapshot.val()
      testGroups.push({
        ...childSnapshot.val(),
        groupId: childSnapshot.key,
        status: status ? capitalize(status) : status,
      })
    })
    return testGroups
  }

  unsubscribeTestGroups() {
    this.path.off()
  }

  getLastGroupBatchId(testGroupArray) {
    return testGroupArray.reduce(
      (max, character) =>
        character.groupBatchId > max ? character.groupBatchId : max,
      testGroupArray[0].groupBatchId,
    )
  }

  getTestGroup = async groupId => {
    const testGroup = await this.path.child(groupId).once('value')
    return testGroup.val()
  }

  incrementGroupBatchCounter = async groupId => {
    const currentCounter = await this.getTestGroup(groupId)
    this.path
      .child(groupId)
      .update({ groupBatchCounter: currentCounter.groupBatchCounter + 1 })
  }
}

export default new TestGroupService()
