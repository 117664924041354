import React, { useRef, useContext, useState, useEffect } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import ProForm from '@ant-design/pro-form'
import { Input } from 'antd'
import { format, parseISO, compareAsc, addMinutes } from 'date-fns'
import InfoModal from '../../components/dashboard/patient-info-modal'
import DateMasked from '../../components/date-masked'
import usePatientLookup from '../../hooks/use-patient-lookup'
import { compareStrings } from '../../utils/text-utils'
import { UserContext } from '../../contexts/user-context'
import { errorNotification } from '../../components/dashboard/ui-notification'
import UserService from '../../services/user-service'
import PatientLookupActions from '../../components/dashboard/patient-lookup-actions'

function getTableColumns(setVisible, setAppointmentInfo) {
  return [
    {
      key: 'date',
      title: 'Date',
      hideInSearch: true,
      width: 250,
      dataIndex: 'slotsDateTime',
      renderText: date => date && format(parseISO(date), 'MM/dd/yyyy'),
      sorter: {
        compare: (a, b) =>
          compareAsc(parseISO(a.slotsDateTime), parseISO(b.slotsDateTime)),
        multiple: 3,
      },
    },
    {
      key: 'firstName',
      title: (_, type) =>
        type === 'table' ? 'First Name' : 'Search By First Name',
      dataIndex: 'firstName',
      width: 250,
      valueType: 'text',
      renderFormItem: (item, { type, defaultRender }, form) => {
        const isLastNameFilled = form.getFieldValue('lastName')
        if (isLastNameFilled) {
          form.setFieldsValue({ firstName: null })
        }
        if (type === 'form') {
          return defaultRender(item)
        }
        return <Input placeholder="John" disabled={!!isLastNameFilled} />
      },
      sorter: {
        compare: (a, b) => compareStrings(a.firstName, b.firstName),
        multiple: 2,
      },
    },
    {
      key: 'lastName',
      title: (_, type) =>
        type === 'table' ? 'Last Name' : 'Search By Last Name',
      dataIndex: 'lastName',
      width: 250,
      valueType: 'text',
      renderFormItem: (item, { type, defaultRender }, form) => {
        const isFirstNameFilled = form.getFieldValue('firstName')
        if (isFirstNameFilled) {
          form.setFieldsValue({ lastName: null })
        }
        if (type === 'form') {
          return defaultRender(item)
        }
        return <Input placeholder="Smith" disabled={!!isFirstNameFilled} />
      },
      sorter: {
        compare: (a, b) => compareStrings(a.lastName, b.lastName),
        multiple: 2,
      },
    },
    {
      key: 'dateOfBirth',
      title: (_, type) => (type === 'table' ? 'DOB' : 'Search By DOB'),
      dataIndex: 'dateOfBirth',
      width: 250,
      defaultSortOrder: 'descend',
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form,
      ) => {
        if (type === 'form') {
          return defaultRender(_)
        }
        return <DateMasked antdStyle={true} />
      },
      renderText: date => {
        return (
          date &&
          format(addMinutes(date, date.getTimezoneOffset()), 'MM/dd/yyyy')
        )
      },
      sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
    },
    {
      key: 'barcode',
      title: 'Barcode',
      hideInSearch: true,
      width: 250,
      dataIndex: 'barcode',
    },
    {
      key: 'result',
      title: 'Result',
      hideInSearch: true,
      width: 250,
      dataIndex: 'result',
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, row) => [
        <PatientLookupActions
          row={row}
          setAppointmentInfo={setAppointmentInfo}
          setVisible={setVisible}
        />,
      ],
    },
  ]
}

export default function PatientLookupList() {
  const actionRef = useRef()
  const [formInstance] = ProForm.useForm()
  const { user, role } = useContext(UserContext)
  const [userInfo, setUserInfo] = useState({})
  const [visible, setVisible] = useState(false)
  const [appointmentInfo, setAppointmentInfo] = useState()

  const {
    appointments,
    isAppointmentsLoading,
    setSearchFilters,
  } = usePatientLookup(userInfo?.doctorReferralCode)

  useEffect(() => {
    UserService.getUserInfo(user.uid, response => {
      setUserInfo(response)
    })
  }, [role, user.uid])

  const handleCloseButtonClick = () => {
    setVisible(false)
  }
  function handleResetSearch() {
    formInstance.resetFields()
    setSearchFilters(null)
  }
  async function handleSearchSubmit() {
    const { lastName, firstName, dateOfBirth } = formInstance.getFieldsValue()

    if (
      (!dateOfBirth && !lastName) ||
      (!dateOfBirth && !firstName) ||
      (!firstName && !lastName)
    ) {
      return errorNotification({
        message: `Please enter the ${
          !dateOfBirth ? 'DOB' : 'First or Last Name'
        } to filter`,
      })
    }
    setSearchFilters({ lastName, firstName, dob: dateOfBirth })
  }
  return (
    <PageContainer>
      <ProTable
        columns={getTableColumns(setVisible, setAppointmentInfo)}
        actionRef={actionRef}
        dataSource={appointments}
        loading={isAppointmentsLoading}
        rowKey="key"
        toolBarRender={false}
        onSubmit={handleSearchSubmit}
        onReset={handleResetSearch}
        search={{
          form: formInstance,
          labelWidth: [100, 200, 100, 100, 100],
          searchText: 'Search',
        }}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        dateFormatter="string"
      />
      <InfoModal
        isModalVisible={visible}
        onClickClose={handleCloseButtonClick}
        data={appointmentInfo}
        setVisible={setVisible}
      />
    </PageContainer>
  )
}
