import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { handleError } from '../utils/error-utils'
import LocationScheduleService from '../services/locations-schedule-service'

const LocationScheduleContext = createContext()
const { Provider } = LocationScheduleContext

function LocationScheduleProvider({ children }) {
  const [schedule, setScheduleData] = useState([])
  const [initialSlots, setInitialSlots] = useState(30)
  const [isScheduleLoading, setIsScheduleLoading] = useState(false)
  const { locationId } = useParams()

  function addTimeSlot(startDate) {
    const filteredScheduleData = schedule.filter(
      item => item.startDate !== startDate,
    )

    setScheduleData([
      ...filteredScheduleData,
      {
        startDate: startDate,
      },
    ])
  }

  function removeTimeSlot(startDate) {
    const filteredScheduleData = schedule.filter(
      item => item.startDate !== startDate,
    )

    setScheduleData(filteredScheduleData)
  }

  useEffect(() => {
    setIsScheduleLoading(true)
    try {
      LocationScheduleService.observeLocationSchedule(locationId, schedule => {
        setInitialSlots(schedule[0]?.initialSlots ?? initialSlots)
        setScheduleData(schedule)
        setIsScheduleLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving the available schedule')
      setIsScheduleLoading(false)
    }

    return () => {
      LocationScheduleService.unsubscribeLocationSchedule()
    }
  }, [initialSlots, locationId])

  return (
    <Provider
      value={{
        schedule,
        setScheduleData,
        isScheduleLoading,
        addTimeSlot,
        removeTimeSlot,
        initialSlots,
        setInitialSlots,
      }}
    >
      {children}
    </Provider>
  )
}

function useLocationScheduleContext() {
  const context = useContext(LocationScheduleContext)

  if (context === undefined) {
    throw new Error(
      'useLocationScheduleContext must be used within a LocationScheduleProvider',
    )
  }

  return context
}

export { useLocationScheduleContext, LocationScheduleProvider }
