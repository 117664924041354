import React from 'react'
import ContentSpace from './content-space'
import { Card } from 'antd'
import { useTranslation } from 'react-i18next'
import LinkButton from './link-button'
import routes from '../config/routes'
import { Description } from './description'

export const CardDemographic = ({ data, isEditable = true }) => {
  const { t } = useTranslation()
  const ETHNIC_OPTIONS = t('ETHNIC_OPTIONS', { returnObjects: true })
  const GENDER_OPTIONS = t('GENDER_OPTIONS', { returnObjects: true })
  const RACE_OPTIONS = t('RACE_OPTIONS', { returnObjects: true })
  const SEXUAL_ORIENTATION_OPTIONS = t('SEXUAL_ORIENTATION_OPTIONS', {
    returnObjects: true,
  })
  const SEX_AT_BIRTH_OPTIONS = t('SEX_AT_BIRTH_OPTIONS', {
    returnObjects: true,
  })
  const findLabel = (variable, value) => {
    const foundItem = variable.find(item => item.value === value)
    return foundItem?.label
  }
  const findMapLabel = (variable, value) => {
    const races = variable
      .filter(item => value.includes(item.value))
      .map(item => {
        return item?.label + ' '
      })
    return races
  }

  return (
    <ContentSpace>
      <Card>
        <Description
          label={t('demographics.sex')}
          content={findLabel(SEX_AT_BIRTH_OPTIONS, data.sex)}
          extra={
            isEditable && (
              <LinkButton
                type="link"
                to={routes.demographicsPt1 + '?isEdit=true'}
              >
                {t('buttons.edit')}
              </LinkButton>
            )
          }
        />

        <Description
          label={t('demographics.race')}
          content={findMapLabel(RACE_OPTIONS, data.race)}
          extra={
            isEditable && (
              <LinkButton
                type="link"
                to={routes.demographicsPt2 + '?isEdit=true'}
              >
                {t('buttons.edit')}
              </LinkButton>
            )
          }
        />

        <Description
          label={t('demographics.ethnicity')}
          content={findLabel(ETHNIC_OPTIONS, data.ethnicity)}
        />
      </Card>
    </ContentSpace>
  )
}
