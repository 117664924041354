import React from 'react'
import { Typography, Form } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import { AppointmentDateSelect } from '../../components/appointment-date-select'
import AppointmentService from '../../services/appointments-service'
import useLocationSchedule from '../../hooks/use-location-schedule'
import Spin from '../../components/main-spin'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import useLocations from '../../hooks/use-locations'
import useQueryParam from '../../hooks/use-query-param'
import { useAppointmentContext } from '../../contexts/appointment-context'

const { Text } = Typography

function TimeSlots() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const { location, isLocationLoading } = useLocations(appointment?.locationId)
  const { locationSchedule, isLocationScheduleLoading } = useLocationSchedule(
    appointment?.locationId,
  )
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const isEditable = useQueryParam('isEdit') === 'true'

  const onFinish = async values => {
    try {
      await AppointmentService.update(appointmentId, values)
      if (isEditable) {
        history.push(routes.bookingConfirmation)
      } else {
        history.push(routes.personalAddress)
      }
    } catch (error) {
      handleError(error, 'Error saving a time slot')
    }
  }

  const isNextButtonDisabled = () => {
    return hasFormErrors(form) || !form.getFieldValue('slotsDateTime')
  }

  if (isAppointmentLoading || isLocationLoading || isLocationScheduleLoading) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle>{t('timeSlots.title')} </PageTitle>
      <Text>
        {t('timeSlots.text')}
        <Text type="primary">{location?.title}</Text>
      </Text>

      <Form form={form} onFinish={onFinish} initialValues={appointment}>
        <ContentSpace>
          <Form.Item
            name="slotsDateTime"
            rules={[
              {
                required: true,
                message: `${t('rules.timeSlotRequired')}`,
              },
            ]}
          >
            <AppointmentDateSelect locationSchedule={locationSchedule} />
          </Form.Item>

          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            backButtonUrl={
              isEditable ? routes.bookingConfirmation : routes.locationOptions
            }
          />
        </ContentSpace>
      </Form>
    </ContentSpace>
  )
}

export default TimeSlots
