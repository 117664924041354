import styled from 'styled-components'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'

const DefaultDatePicker = generatePicker(dateFnsGenerateConfig)

const DatePicker = styled(DefaultDatePicker)`
  @media (min-width: 260px) {
    width: ${({ widthInput, fullWidth = false }) =>
      fullWidth ? '100%' : `${widthInput}px`};
    margin-right: ${({ theme }) => theme.spacing.lg};
  }
`

export default DatePicker
