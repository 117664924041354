import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { UserContext } from '../../contexts/user-context'

function Forbidden() {
  const { role, isLoggedIn } = useContext(UserContext)
  const { t } = useTranslation()

  return (
    <div>
      <h2>{t('alerts.forbiddenTitle')}</h2>
      <p>
        {t('alerts.forbiddenSubitle')}{' '}
        {isLoggedIn && role
          ? `${t('alerts.forbiddenRole1', { String: role })}`
          : `${t('alerts.forbiddenRole2')}`}
      </p>
    </div>
  )
}

export default Forbidden
