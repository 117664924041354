import { USER_ROLES } from '../constants'
import {
  adminRoutes,
  billingRoutes,
  collectionRoutes,
  guestDashboardRoutes,
  labRoutes,
  organizationRoutes,
  publicRoutes,
  reportingRoutes,
  doctorRoutes,
} from '../routes/route-paths'

export function findDefaultRoute(role) {
  let routesArray

  switch (role) {
    case USER_ROLES.admin:
      routesArray = adminRoutes
      break

    case USER_ROLES.billing:
      routesArray = billingRoutes
      break

    case USER_ROLES.collection:
      routesArray = collectionRoutes
      break

    case USER_ROLES.lab:
      routesArray = labRoutes
      break

    case USER_ROLES.guest:
      routesArray = guestDashboardRoutes
      break

    case USER_ROLES.organization:
      routesArray = organizationRoutes
      break

    case USER_ROLES.reporting:
      routesArray = reportingRoutes
      break

    case USER_ROLES.doctor:
      routesArray = doctorRoutes
      break

    default:
      routesArray = publicRoutes
  }

  return routesArray.find(route => route.default)
}
