import { Space as DefaultSpace } from 'antd'
import styled from 'styled-components'

const ContentSpace = styled(DefaultSpace).attrs(props => ({
  size: props.size || 22,
  direction: props.direction || 'vertical',
}))`
  width: ${({ direction }) => (direction === 'vertical' ? '100%' : 'auto')};

  .ant-space-item > * {
    margin: 0 !important;
  }
`

export default ContentSpace
