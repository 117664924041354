import React, { useEffect, useContext } from 'react'
import { Modal } from 'antd'
import ProForm, { ProFormText, ProFormSelect } from '@ant-design/pro-form'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { createLocation, updateLocation } from '../../utils/location-utils'
import useLocations from '../../hooks/use-locations'
import { STATES } from '../../constants'
import { UserContext } from '../../contexts/user-context'

export default function AddLocationModal({
  isModalVisible,
  locationId,
  onClickClose,
}) {
  const [formInstance] = ProForm.useForm()
  const { t } = useTranslation()
  const requiredFieldRule = {
    required: true,
    message: `${t('messages.fieldRequired')}`,
  }
  const { location, isLocationLoading } = useLocations(locationId)
  const { user } = useContext(UserContext)

  const initialValues = {
    title: '',
    address: '',
    addressComplement: '',
    zipCode: '',
    cityState: null,
  }

  async function handleFormSubmit() {
    if (locationId) {
      return handleUpdateLocation()
    }

    return handleCreateLocation()
  }

  function parseCityState() {
    const formValues = formInstance.getFieldsValue()

    return {
      ...formValues,
      cityState: `${formValues.city}, ${formValues.state}`,
    }
  }

  async function handleCreateLocation() {
    await createLocation(parseCityState(), user)
    formInstance.resetFields()
    onClickClose()
  }

  async function handleUpdateLocation() {
    await updateLocation(locationId, parseCityState())
    onClickClose()
  }

  function resetForm() {
    formInstance.setFieldsValue(initialValues)
  }

  useEffect(() => {
    const locationToUpdate = {
      ...location,
      city: location?.cityState.split(', ')[0],
      state: location?.cityState.split(', ')[1],
    }
    formInstance.setFieldsValue(locationToUpdate)
  }, [location, formInstance])

  return (
    <Modal
      title={t('location.addLocation')}
      visible={isModalVisible}
      centered
      closeIcon={<CloseOutlined onClick={onClickClose} />}
      afterClose={resetForm}
      destroyOnClose
      footer={false}
    >
      {isLocationLoading ? (
        <div>{t('messages.loading')}...</div>
      ) : (
        <ProForm
          form={formInstance}
          initialValues={initialValues}
          onFinish={handleFormSubmit}
        >
          <ProFormText
            name="title"
            label={t('location.title')}
            placeholder=""
            rules={[requiredFieldRule]}
            width="100%"
          />
          <ProFormText
            name="address"
            label={t('address.street')}
            placeholder=""
            rules={[requiredFieldRule]}
            width="100%"
          />
          <ProFormText
            name="addressComplement"
            label={t('address.addressType')}
            placeholder=""
            width="100%"
          />
          <ProFormText
            name="zipCode"
            label={t('address.zipcode')}
            placeholder=""
            width="100%"
          />
          <ProFormSelect
            name="state"
            label={`${t('address.state')}`}
            placeholder=""
            valueEnum={STATES}
            width="100%"
          />
          <ProFormText
            name="city"
            label={`${t('address.city')}`}
            placeholder=""
            width="100%"
          />
        </ProForm>
      )}
    </Modal>
  )
}
