import React, { useEffect, useState } from 'react'
import { Col, Row, Select, Spin } from 'antd'

import TextSpacing from '../text-spacing'
import { useLocationScheduleContext } from '../../contexts/location-schedule-context'

const { Option } = Select

const AppointmentsTimeslotDropdown = () => {
  const {
    schedule,
    setInitialSlots,
    initialSlots,
  } = useLocationScheduleContext()
  const [isDropVisble, setIsDropVisble] = useState(false)
  const [existingInitial, setExistingInitial] = useState()

  const possibilities = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50]
  const onChange = value => {
    setInitialSlots(value)
  }

  useEffect(() => {
    var check = schedule[0]?.initialSlots
    setExistingInitial(check)
    setIsDropVisble(!check)
  }, [schedule])

  return (
    <Row>
      <Col span={8}>
        <TextSpacing big>
          Appointments per time slot:{' '}
          {!isDropVisble && <b>{existingInitial}</b>}
        </TextSpacing>
        {isDropVisble && (
          <Select onChange={onChange} value={initialSlots}>
            {possibilities &&
              possibilities.map(element => (
                <Option value={element} key={element}>
                  {element}
                </Option>
              ))}
          </Select>
        )}
      </Col>
    </Row>
  )
}

export default AppointmentsTimeslotDropdown
