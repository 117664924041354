import React, { useState } from 'react'
import { Form } from 'antd'
import { PageContainer } from '@ant-design/pro-layout'
import Input from '../../components/input'
import SearchBox from '../../components/search-box'
import ContainerInline from '../../components/container-inline'
import { successNotification } from '../../components/dashboard/ui-notification'

const PrintBarcodes = props => {
  const [barcodeForm] = Form.useForm()
  const [delayValue, setDelayValue] = useState(2500)

  const getErrorMessage = async () => {
    const barcode = barcodeForm.getFieldValue('amount')
    if (!barcode) {
      return Promise.reject('Must enter an amount')
    }
  }

  const getDelayErrorMessage = async () => {
    const delayInput = barcodeForm.getFieldValue('delayInput')
    if (!delayInput) {
      return Promise.reject('Must enter an delay amount')
    }
  }

  const getErrorMessages = async () => {
    const alphaFromInput = barcodeForm.getFieldValue('alphaFromInput')
    if (!alphaFromInput) {
      return Promise.reject('Must enter an alpha')
    }
  }

  const clearBarcodeAmountInput = () => {
    barcodeForm.setFieldsValue({ firstLine: '' })
    barcodeForm.setFieldsValue({ secondLine: '' })
    barcodeForm.setFieldsValue({ thirdLine: '' })
    barcodeForm.setFieldsValue({ fourthLine: '' })
    barcodeForm.setFieldsValue({ amount: '' })
    barcodeForm.setFieldsValue({ alphaFromInput: '' })
  }

  const delay = (t, val) => {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve(val)
      }, t)
    })
  }

  const toInputUppercase = e => {
    e.target.value = ('' + e.target.value).toUpperCase()
  }

  const onBarcodeFinish = async ({
    amount,
    alphaFromInput,
    firstLine,
    secondLine,
    thirdLine,
    fourthLine,
  }) => {
    const browserPrint = window.BrowserPrint
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    for (let i = 0; i < parseInt(amount); i++) {
      const counter = zeroPad(i, 9)
      const pre = counter.slice(0, -1)
      const suf = counter.charAt(counter.length - 1)

      const zpl =
        `
        ^XA
        ^MMT
        ^PW376
        ^LL0300
        ^LS0
        ^FT17,50^A0N,38,38^FH^FD` +
        firstLine +
        `^FS
        ^FT17,88^A0N,38,38^FH^FD` +
        secondLine +
        `^FS
        ^FT17,127^A0N,38,38^FH^FD` +
        thirdLine +
        `^FS
        ^FT17,166^A0N,38,38^FH^FD` +
        fourthLine +
        `^FS
        ^BY2,3,60^FT48,268^BCN,,Y,N
        ^FD>:` +
        alphaFromInput +
        `>5` +
        pre +
        `>6` +
        suf +
        `^FS
        ^PQ1,0,1,Y^XZ
      `
      browserPrint.getDefaultDevice('printer', function (device) {
        device.send(zpl)
        device.send(zpl)
      })
      await delay(delayValue)
    }

    clearBarcodeAmountInput()
    successNotification({
      message: 'Success!',
      description: (
        <span>
          <b>{amount}</b> barcodes printed
        </span>
      ),
    })
  }

  return (
    <PageContainer
      content={
        <>
          <ContainerInline>
            <Form
              name="printBarcodes"
              form={barcodeForm}
              onFinish={onBarcodeFinish}
              initialValues={{ delayInput: delayValue }}
            >
              <Form.Item
                name="firstLine"
                label="First line of label"
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="First Line"
                  value={barcodeForm.getFieldValue('firstLine')}
                  size="400px"
                />
              </Form.Item>
              <Form.Item
                name="secondLine"
                label="Second line of label"
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Second Line"
                  value={barcodeForm.getFieldValue('secondLine')}
                  size="400px"
                />
              </Form.Item>
              <Form.Item
                name="thirdLine"
                label="Third line of label"
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Third Line"
                  value={barcodeForm.getFieldValue('thirdLine')}
                  size="400px"
                />
              </Form.Item>
              <Form.Item
                name="fourthLine"
                label="Fourth line of label"
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Fourth Line"
                  value={barcodeForm.getFieldValue('fourthLine')}
                  size="400px"
                />
              </Form.Item>
              <Form.Item
                name="alphaFromInput"
                label="Alpha prefix of barcode - 3 characters required"
                labelCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Alpha is required' },
                  { validator: getErrorMessages, validateTrigger: 'submit' },
                ]}
              >
                <Input
                  placeholder="Prefix: ABC"
                  maxLength="3"
                  minLength="3"
                  onInput={toInputUppercase}
                  value={barcodeForm.getFieldValue('alphaFromInput')}
                  size="400px"
                />
              </Form.Item>
              <Form.Item
                name="delayInput"
                label="Delay in milliseconds"
                labelCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'Delay is required' },
                  {
                    validator: getDelayErrorMessage,
                    validateTrigger: 'submit',
                  },
                ]}
              >
                <Input
                  placeholder="Delay in milliseconds"
                  size="400px"
                  value={delayValue}
                  onChange={event => setDelayValue(event.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="amount"
                rules={[
                  { required: true, message: 'Amount is required' },
                  { validator: getErrorMessage, validateTrigger: 'submit' },
                ]}
                label="Amount of labels to print"
                labelCol={{ span: 24 }}
              >
                <SearchBox
                  placeholder="Enter amount of labels to print"
                  enterButton="Print"
                  onSearch={barcodeForm.submit}
                  value={barcodeForm.getFieldValue('amount')}
                  size="400px"
                />
              </Form.Item>
            </Form>
          </ContainerInline>
        </>
      }
    ></PageContainer>
  )
}

export default PrintBarcodes
