import React from 'react'
import { Form, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { parse, format } from 'date-fns'
import DateMasked from '../../components/date-masked'

import AppointmentService from '../../services/appointments-service'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import Spin from '../../components/main-spin'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import { isDateAfterToday, removeMask } from '../../utils/date-utils'

const { Text } = Typography

function BirthDate() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()
  const url = new URL(window.location.href)
  const isEdit = url.searchParams.get('isEdit')

  const onFinish = async ({ dateOfBirth }) => {
    const dateString = removeMask(dateOfBirth)
    try {
      await AppointmentService.update(appointmentId, {
        dateOfBirth: dateString,
      })
      if (isEdit) {
        history.push(routes.bookingConfirmation)
      } else {
        history.push(routes.phoneNumber)
      }
    } catch (error) {
      handleError(error, t('errorMessage.dobError'))
    }
  }

  const checkDateOfBirth = (_, value) => {
    const dateString = removeMask(value)
    if (!value) {
      return Promise.reject(t('messages.dobRequired'))
    }

    if (dateString?.length === 8) {
      try {
        const parsedValue = parse(dateString, 'MMddyyyy', new Date())

        if (isDateAfterToday(parsedValue)) {
          return Promise.reject(`${t('messages.futureDate')}`)
        }

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(`${t('messages.invalidDate')}`)
      }
    }
  }

  const isNextButtonDisabled = () => {
    const date = form.getFieldValue('dateOfBirth')
    const dateString = removeMask(date)

    return (
      hasFormErrors(form) ||
      !form.getFieldValue('dateOfBirth') ||
      dateString?.length < 8
    )
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  const getInitialValue = dateOfBirth => {
    if (dateOfBirth) {
      return format(appointment?.dateOfBirth, 'MMddyyyy')
    }
    return dateOfBirth
  }

  return (
    <ContentSpace>
      <PageTitle>{t('personalData.birth')}</PageTitle>
      <Text>
        {t('general.date')} {t('general.dateFormat')}
      </Text>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          dateOfBirth: getInitialValue(appointment?.dateOfBirth),
        }}
      >
        <Form.Item
          name="dateOfBirth"
          rules={[
            {
              validator: checkDateOfBirth,
            },
          ]}
        >
          <DateMasked autoFocus style={{ width: '100%' }} />
        </Form.Item>

        <ActionButtons
          isNextButtonDisabled={isNextButtonDisabled}
          backButtonUrl={isEdit ? routes.bookingConfirmation : routes.fullName}
        />
      </Form>
    </ContentSpace>
  )
}

export default BirthDate
