import React, { useEffect, useState } from 'react'
import { Form, Input, Typography } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import routes from '../../config/routes'
import UserService from '../../services/user-service'
import { successNotification } from '../../components/dashboard/ui-notification'

const { Text } = Typography

function CreateGuestUser() {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const result = location.state?.result

  useEffect(() => {
    if (!result) {
      history.replace(routes.home)
    }
  })

  const onFinish = async values => {
    try {
      setIsSubmitting(true)
      const userToken = await UserService.createUserToken(result.userId)
      await UserService.signInWithCustomToken(userToken.data)
      await UserService.updateAuthProvider({
        email: result.email,
        password: values.password,
        uid: result.userId,
      })
      await UserService.signOut()
      successNotification({
        message: `${t('messages.userCreated')}`,
      })
      history.replace(routes.dashboard.home)
    } catch (error) {
      setIsSubmitting(false)
      handleError(error, 'Error updating the user auth provider')
    }
  }

  const isNextButtonDisabled = () => {
    return (
      hasFormErrors(form) || !form.getFieldValue('password') || isSubmitting
    )
  }

  if (!result) {
    return null
  }

  return (
    <ContentSpace>
      <PageTitle>Create a recurring user</PageTitle>
      <Text>
        This is the easier way to keep yours and your family's test results
        nested
      </Text>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please, enter a secure password' },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            loading={isSubmitting}
            hideBackButton={true}
            nextButtonTitle="Create User"
          />
        </Form.Item>
      </Form>
    </ContentSpace>
  )
}

export default CreateGuestUser
