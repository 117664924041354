import { getFormattedAppointmentValues } from '../utils/appointment-utils'

import { FirebaseService } from './firebase-service'
import { paths } from './paths'

class SpecimensService extends FirebaseService {
  constructor() {
    super(paths.appointments)
  }

  observeAppointmentsByOrganizationID(organizationId, isAdmin, callback) {
    try {
      this.path
        .orderByChild('organizationId')
        .equalTo(organizationId)
        .on('value', snapshot => {
          const appointments = !isAdmin
            ? this.formatSnapshot(snapshot)
            : this.formatSnapshotForAdmins(snapshot)
          callback(appointments)
        })
    } catch (error) {
      throw error
    }
  }
  observeAppointmentsByDoctor(doctorReferralCode, isAdmin, callback) {
    try {
      this.path
        .orderByChild('doctorReferralCode')
        .equalTo(doctorReferralCode)
        .on('value', snapshot => {
          const appointments = !isAdmin
            ? this.formatSnapshot(snapshot)
            : this.formatSnapshotForAdmins(snapshot)
          callback(appointments)
        })
    } catch (error) {
      throw error
    }
  }

  observeAppointmentsLocationID(locationId, isAdmin, callback) {
    try {
      this.path
        .orderByChild('locationId')
        .equalTo(locationId)
        .on('value', snapshot => {
          const appointments = !isAdmin
            ? this.formatSnapshot(snapshot)
            : this.formatSnapshotForAdmins(snapshot)
          callback(appointments)
        })
    } catch (error) {
      throw error
    }
  }

  formatSnapshotForAdmins(snapshot) {
    const appointments = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()

      if (
        values.confirmation &&
        !values?.status?.receivedOn &&
        values?.barcode
      ) {
        const formattedValues = getFormattedAppointmentValues(values)

        appointments.push({
          ...values,
          ...formattedValues,
          key: childSnapshot.key,
        })
      }
    })

    return appointments
  }

  formatSnapshot(snapshot) {
    const appointments = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()

      const formattedValues = getFormattedAppointmentValues(values)

      appointments.push({
        ...values,
        ...formattedValues,
        key: childSnapshot.key,
      })
    })

    return appointments
  }

  unsubscribe() {
    this.path.off()
  }
}

export default new SpecimensService()
