import styled from 'styled-components'

const ContentModal = styled.div`
  & .ant-row {
    margin-top: 10px;
    margin-bottom: 0;
  }

  & .ant-modal-confirm-btns {
    margin-top: 0;
  }
`

export default ContentModal
