import styled from 'styled-components'
import { Modal } from 'antd'

const HasNoInsuranceModal = styled(Modal)`
  & .ant-btn-background-ghost {
    display: none !important;
  }
`

export default HasNoInsuranceModal
