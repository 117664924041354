import { Space as DefaultSpace } from 'antd'
import styled from 'styled-components'

export const Container = styled(DefaultSpace).attrs(props => ({
  size: props.size || 22,
  direction: props.direction || 'vertical',
}))`
  width: ${({ direction }) => (direction === 'vertical' ? '100%' : 'auto')};
  display: flex;
  max-width: 620px;
  min-height: 100vh;
  margin: ${props => props.theme.spacing.lg} auto;
  padding: ${props => props.theme.spacing.xg};
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  border: 1px ${props => props.theme.colors.gray[200]};
  box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.01);
  .ant-space-item > * {
    margin: 0 !important;
  }
`
