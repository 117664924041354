import React from 'react'
import { Radio, Typography, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import {
  getAppointmentFromStorage,
  setAppointmentOnStorage,
} from '../../utils/appointment-utils'
import { handleError, hasFormErrors } from '../../utils/error-utils'

const { Text } = Typography

function Contact() {
  const appointment = getAppointmentFromStorage()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()

  const onFinish = async values => {
    try {
      if (
        appointment.symptoms[0] !== 'noneAbove' &&
        !appointment.symptomsDate
      ) {
        throw new Error('You need to inform the symptoms date first')
      }

      setAppointmentOnStorage({ ...appointment, ...values })
      history.push(routes.testReason)
    } catch (error) {
      handleError(error)
    }
  }

  const isNextButtonDisabled = () => {
    return hasFormErrors(form) || !form.getFieldValue('contactWithPerson')
  }

  return (
    <ContentSpace>
      <PageTitle>{t('contact.title')}</PageTitle>
      <Text> {t('contact.text')}</Text>

      <Form form={form} onFinish={onFinish} initialValues={appointment}>
        <Form.Item
          name="contactWithPerson"
          rules={[
            { required: true, message: 'Please choose an option above!' },
          ]}
        >
          <Radio.Group>
            <ContentSpace>
              <Radio key={1} value="yes">
                {t('affirmation.positive')}
              </Radio>
              <Radio key={2} value="no">
                {t('affirmation.negative')}
              </Radio>
            </ContentSpace>
          </Radio.Group>
        </Form.Item>

        <ActionButtons
          isNextButtonDisabled={isNextButtonDisabled}
          backButtonUrl={
            appointment.symptoms[0] === 'noneAbove'
              ? routes.symptoms
              : routes.symptomsDate
          }
        />
      </Form>
    </ContentSpace>
  )
}

export default Contact
