import React, { useState } from 'react'
import { Form, Typography, Input, Select, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { InstantSearch } from 'react-instantsearch-dom'
import { useTranslation } from 'react-i18next'

import AppointmentService from '../../services/appointments-service'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import LinkButton from '../../components/link-button'
import Spin from '../../components/main-spin'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import InsuranceProviderSearchBox from '../../components/insurance-provider-search-box'
import { algolia, payersIndex } from '../../config/config'

const { Text } = Typography

function PlanDetails1() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()
  const insuredRelationshipList = t('INSURED_RELATIONSHIP_LIST', {
    returnObjects: true,
  })

  const [isNotThere, setisNotThere] = useState(false)
  const onFinish = async values => {
    try {
      AppointmentService.update(appointmentId, values)
      history.push(routes.driverLicense, {
        previousPath: routes.planDetails1,
      })
    } catch (error) {
      handleError(error, `${t('messages.planDetailsError')}`)
    }
  }

  const isNextButtonDisabled = () => {
    return (
      hasFormErrors(form) ||
      !form.getFieldValue('insuranceProviderName') ||
      !form.getFieldValue('insuranceMemberId') ||
      !form.getFieldValue('insuredRelationship')
    )
  }

  const handleChange = value => {
    form.setFieldsValue({ insuranceProviderName: value })
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle>{t('planDetail.title')}</PageTitle>
      <Text>{t('planDetail.text')}</Text>

      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        initialValues={appointment}
      >
        {isNotThere ? (
          <Form.Item
            label={t('planDetail.insuranceProvider')}
            name="insuranceProviderName"
            rules={[
              { required: true, message: `${t('rules.insuranceRequired')}` },
            ]}
          >
            <Input placeholder={t('planDetail.insuranceNamePlaceholder')} />
          </Form.Item>
        ) : (
          <Form.Item
            label={t('planDetail.insuranceProvider')}
            name="insuranceProviderName"
            rules={[
              { required: true, message: `${t('rules.insuranceRequired')}` },
            ]}
          >
            <InstantSearch
              indexName={payersIndex.indexName}
              searchClient={algolia}
            >
              <InsuranceProviderSearchBox
                placeholder={t('planDetail.insuranceNameSelPlaceholder')}
                getFieldValue={form.getFieldValue}
                handleChange={handleChange}
              />
            </InstantSearch>
          </Form.Item>
        )}
        <Form.Item>
          <a onClick={() => setisNotThere(!isNotThere)}>
            {isNotThere ? t('planDetail.able') : t('planDetail.unable')}
          </a>
        </Form.Item>

        <Form.Item
          label={t('planDetail.memberId')}
          name="insuranceMemberId"
          rules={[
            { required: true, message: `${t('rules.memberIdRequired')}` },
          ]}
        >
          <Input placeholder={t('planDetail.memberIdPlaceholder')} />
        </Form.Item>

        <Form.Item label={t('planDetail.groupMember')} name="groupNumber">
          <Input placeholder={t('planDetail.groupPlaceholder')} />
        </Form.Item>

        <Form.Item
          label={t('planDetail.insuredRelationship')}
          name="insuredRelationship"
          rules={[
            {
              required: true,
              message: `${t('rules.insuredRelationshipRequired')}`,
            },
          ]}
        >
          <Select placeholder={t('planDetail.selection')}>
            {insuredRelationshipList.map(({ key, title }) => (
              <Select.Option key={key} value={key}>
                {title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <ActionButtons
          isNextButtonDisabled={isNextButtonDisabled}
          backButtonUrl={routes.hasInsurance}
        />
      </Form>
    </ContentSpace>
  )
}

export default PlanDetails1
