import { Form, Space } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import LinkButton from './link-button'

function ActionButtons({
  backButtonTitle,
  backButtonUrl,
  backButtonAction,
  hideBackButton = false,
  nextButtonTitle,
  nextButtonUrl,
  nextButtonAction,
  isNextButtonDisabled,
  loading,
  type,
}) {
  const { t } = useTranslation()
  return (
    <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
      {() => (
        <Space size={22}>
          {!hideBackButton && (
            <LinkButton
              to={backButtonUrl}
              onClick={backButtonAction}
              type={type || 'default'}
            >
              {backButtonTitle || `${t('buttons.return')}`}
            </LinkButton>
          )}
          <LinkButton
            type="primary"
            disabled={
              typeof isNextButtonDisabled === 'function'
                ? isNextButtonDisabled()
                : isNextButtonDisabled
            }
            to={nextButtonUrl}
            onClick={nextButtonAction}
            loading={loading}
          >
            {nextButtonTitle || `${t('buttons.saveAndContinue')}`}
          </LinkButton>
        </Space>
      )}
    </Form.Item>
  )
}

export default ActionButtons
