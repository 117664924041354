import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

function LinkButton({
  to,
  children,
  title,
  htmlType = 'submit',
  size = 'large',
  ...props
}) {
  if (to) {
    return (
      <Link to={to}>
        <Button size={size} {...props}>
          {title || children}
        </Button>
      </Link>
    )
  }

  return (
    <Button size={size} {...props} htmlType={htmlType}>
      {title || children}
    </Button>
  )
}

export default LinkButton
