import React from 'react'
import { Modal, Typography } from 'antd'
import ContentModal from './content-modal'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import routes from '../config/routes'
import LinkButton from './link-button'

const { Text } = Typography

export default function ConfirmResultModal({ appointmentId }) {
  const [modal, contextHolder] = Modal.useModal()
  const history = useHistory()
  const { t } = useTranslation()

  const confirmCancel = () => {
    history.push(`${routes.cancel}?appointmentId=${appointmentId}`)
  }

  const config = {
    title: t('cancelConfirmation.titleModal'),
    content: (
      <ContentModal>
        <Text>{t('cancelConfirmation.title')}</Text>
      </ContentModal>
    ),
    width: 500,
    cancelText: t('affirmation.negative'),
    okText: t('affirmation.positive'),
    centered: true,
    onOk: () => confirmCancel(),
  }

  return (
    <>
      <LinkButton
        type="primary"
        onClick={() => {
          modal.confirm(config)
        }}
      >
        {t('cancelConfirmation.titleButton')}
      </LinkButton>
      {contextHolder}
    </>
  )
}
