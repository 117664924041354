import React from 'react'
import { Label, LabelContent, Line, Content } from './description-item-styles'

export const Description = ({ label, content, extra = '' }) => {
  return (
    <Line>
      <Content>
        <Label>{label}:</Label>
        <LabelContent>{content}</LabelContent>
      </Content>
      {extra}
    </Line>
  )
}
