import { Collapse } from 'antd'
import styled from 'styled-components'

const { Panel } = Collapse

const CollapsableOptionsContentSpace = styled(Panel)`
  padding-left: '0px !important';

  .ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    & > .anticon .anticon-down .ant-collapse-arrow {
      font-size: 24px;
      display: flex;
      height: 100%;
      align-items: center;
    }
    & > .ant-typography .ant-typography-primary {
      display: flex;
      width: 90%;
    }
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
    div.ant-radio-group.ant-radio-group-outline {
      width: 100%;
    }
  }
`

export default CollapsableOptionsContentSpace
