import React, { useRef, useState, useEffect } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import ProForm from '@ant-design/pro-form'
import { format, getTime, parseISO, isBefore } from 'date-fns'
import { Button, Modal, Slider } from 'antd'
import { compareStrings } from '../../utils/text-utils'
import DatePicker from '../../components/date-picker-fns'
import useReportings from '../../hooks/use-reporting'
import { exportCSV, formatDataToCSV } from '../../utils/reporting-utils'
import { buildAlgoliaFilters } from '../../utils/algolia-utils'
import { RESULTS } from '../../constants'
import { PDFViewer } from '@react-pdf/renderer'
import PDFResult from './pdf-result'
import PdfActions from '../../components/dashboard/pdf-actions'
import EditResultModal from '../../components/dashboard/edit-result-modal'

import { errorNotification } from '../../components/dashboard/ui-notification'
import { SmallSpan } from '../../components/styles/styled-span'
import resultService from '../../services/result-service'
import ProfilesService from '../../services/profiles-service'
import { algoliaDOBParse } from '../../utils/date-utils'

function getTableColumns(
  setVisible,
  setEditData,
  setAppointment,
  onChange,
  profiles,
) {
  return [
    {
      key: 'firstName',
      title: (_, type) =>
        type === 'table' ? 'First Name' : 'Filter by First Name',
      dataIndex: 'firstName',
      width: 200,
      valueType: 'text',
    },
    {
      key: 'lastName',
      title: (_, type) =>
        type === 'table' ? 'Last Name' : 'Filter by Last Name',
      dataIndex: 'lastName',
      width: 200,
      valueType: 'text',
    },
    {
      key: 'date',
      title: (_, type) => (type === 'table' ? 'Appt. Date' : 'Search'),
      dataIndex: 'slotsDateTime',
      hideOn: ['lg', 'sm', 'xs'],
      search: false,
      defaultSortOrder: 'descend',
      width: 200,
      render: text => {
        return format(parseISO(text), 'MM/dd/yyyy')
      },
      sorter: (a, b) => a.confirmation - b.confirmation,
    },
    {
      key: 'dateOfBirth',
      title: (_, type) => (type === 'table' ? 'DOB' : 'Filter by DOB'),
      dataIndex: 'dateOfBirth',
      width: 250,
      defaultSortOrder: 'descend',
      filters: true,
      fieldProps: {
        placeholder: 'Search',
        allowClear: true,
      },
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form,
      ) => {
        if (type === 'form') {
          return null
        }
        const status = form.getFieldValue('state')
        if (status !== 'open') {
          return (
            <Slider
              range
              defaultValue={[2000, 2020]}
              min={1900}
              max={2020}
              marks={{ 1900: '1900', 2020: '2020' }}
            />
          )
        }
        return defaultRender(_)
      },
      renderText: date => {
        return algoliaDOBParse(date)
      },
      sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
    },
    {
      key: 'city',
      title: (_, type) => (type === 'table' ? 'City' : 'Filter by City'),
      dataIndex: 'city',
      width: 200,
      valueType: 'text',
      filters: true,
      fieldProps: {
        placeholder: 'Search',
        allowClear: true,
      },
      sorter: {
        compare: (a, b) => a.city.localeCompare(b.city),
        multiple: 2,
      },
    },
    {
      key: 'state',
      title: (_, type) => (type === 'table' ? 'State' : 'Filter by State'),
      dataIndex: 'state',
      width: 200,
      valueType: 'text',
      filters: true,
      sorter: {
        compare: (a, b) => a.state.localeCompare(b.state),
        multiple: 2,
      },
      fieldProps: {
        placeholder: 'Search',
        allowClear: true,
      },
    },
    {
      key: 'result',
      title: (_, type) => (type === 'table' ? 'Result' : 'Filter by Result'),
      dataIndex: 'result',
      valueType: 'select',
      sorter: {
        compare: (a, b) => compareStrings(a.result, b.result),
        multiple: 6,
      },
      fieldProps: {
        placeholder: 'Search',
        options: RESULTS.map(element => ({
          label: element.value,
          value: element.value,
        })),
      },
    },
    {
      key: 'doctorReferralCode',
      title: (_, type) =>
        type === 'table'
          ? 'Doctor?organization'
          : 'Filter by Doctor/Organization',
      dataIndex: 'doctorReferralCode',
      valueType: 'select',
      hideInTable: true,
      fieldProps: {
        placeholder: 'Search',
        options:
          profiles &&
          profiles.map((e, key) => ({
            key,
            label:
              e.doctorReferralCode !== 'undefined'
                ? `Dr. ${e.fullName}`
                : `Org. ${e.fullName}`,
            value:
              e.doctorReferralCode !== 'undefined'
                ? e.doctorReferralCode
                : e.uid,
          })),
      },
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, row) => [
        <PdfActions
          key={row.id}
          row={row}
          setAppointment={setAppointment}
          setVisible={setVisible}
          setEditData={setEditData}
        />,
      ],
    },
  ]
}

export default function ReportingList() {
  const actionRef = useRef()
  const [formInstance] = ProForm.useForm()
  const [visible, setVisible] = useState(false)
  const [editData, setEditData] = useState({ visible: false, data: {} })
  const [appointment, setAppointment] = useState()
  const [profiles, setProfiles] = useState()

  useEffect(() => {
    async function get() {
      try {
        var doc = []
        var org = []
        await ProfilesService.observeDoctorByName(profiles => {
          profiles.map(p => {
            if (p.doctorReferralCode === 'undefined') {
              org.push(p)
            } else {
              doc.push(p)
            }
          })
          setProfiles(doc.concat(org))
        })
      } catch (error) {
        console.log(error)
      }
    }
    get()
  }, [])

  const {
    reportings,
    isReportingsLoading,
    setSearchFilters,
    setDateSelected,
    dateSelected,
  } = useReportings()

  function dateValidator(id, date) {
    if (id === 'endDate' && isBefore(date, dateSelected.startDate)) {
      errorNotification({
        message: 'Invalid End Date',
        description: 'End Date must be greater than Start Date',
      })
      return false
    }
    return true
  }

  function onChange(id, date) {
    dateValidator(id, date) && setDateSelected({ ...dateSelected, [id]: date })
  }
  async function handleSearchSubmit(values) {
    if (values.dateOfBirth) {
      const [start, end] = values.dateOfBirth
      values.dateOfBirth = [
        getTime(new Date(start, 0, 1)),
        getTime(new Date(end, 11, 31, 23, 59)),
      ]
    }
    if (values.doctorReferralCode?.length > 7) {
      values.organizationId = values.doctorReferralCode
      delete values.doctorReferralCode
    }
    setSearchFilters(buildAlgoliaFilters(values))
  }

  function handleResetSearch() {
    setSearchFilters(null)
  }

  const handleExportCSV = async reportings => {
    const endDateSelectedFormatted = format(dateSelected.endDate, 'MMddyyyy')
    const startDateSelectedFormatted = format(
      dateSelected.startDate,
      'MMddyyyy',
    )
    const reportingsFormatted = await formatDataToCSV(reportings)
    exportCSV(
      reportingsFormatted,
      startDateSelectedFormatted,
      endDateSelectedFormatted,
    )
  }

  const handleCloseButtonClick = () => {
    setEditData({ visible: false, data: {} })
  }
  const setEditedInfo = values => {
    return resultService.updateEditedResults(appointment.id, values)
  }
  return (
    <PageContainer
      title={
        <>
          <SmallSpan>Start Date:</SmallSpan>
          <DatePicker
            bordered={false}
            format={'EEEE, d LLLL yyyy'}
            value={dateSelected.startDate}
            onChange={date => onChange('startDate', date)}
          />

          <SmallSpan>End Date:</SmallSpan>
          <DatePicker
            bordered={false}
            format={'EEEE, d LLLL yyyy'}
            value={dateSelected.endDate}
            onChange={date => onChange('endDate', date)}
          />
        </>
      }
      content={
        <Button onClick={() => handleExportCSV(reportings)} type="primary">
          Export results to csv
        </Button>
      }
    >
      <ProTable
        columns={getTableColumns(
          setVisible,
          setEditData,
          setAppointment,
          onChange,
          profiles,
        )}
        actionRef={actionRef}
        dataSource={reportings}
        loading={isReportingsLoading}
        rowKey="id"
        toolBarRender={false}
        onSubmit={handleSearchSubmit}
        onReset={handleResetSearch}
        search={{
          form: formInstance,
          labelWidth: [100, 200, 100, 100, 100],
          searchText: 'Search',
        }}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        dateFormatter="string"
      />
      <Modal
        width={770}
        visible={visible}
        closable
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <PDFViewer width={700} height={700}>
          <PDFResult data={appointment} />
        </PDFViewer>
      </Modal>

      <EditResultModal
        isModalVisible={editData.visible}
        onClickClose={handleCloseButtonClick}
        data={editData.data}
        setEditedInfo={setEditedInfo}
      />
    </PageContainer>
  )
}
