import { useEffect, useState } from 'react'
import getUUid from 'uuid-by-string'

import PeopleService from '../services/people-service'
import { parseDateOfBirth } from '../utils/date-utils'
import { handleError } from '../utils/error-utils'

export default function usePeople(organizationId, tg) {
  const [peoples, setPeoples] = useState()
  const [isPeoplesLoading] = useState(false)

  useEffect(() => {
    if (!tg) {
      return
    }

    try {
      PeopleService.observePeoplesByOrganizationId(organizationId, response => {
        const addedPerson = tg.map(appointment => {
          const { firstName, lastName, email, dateOfBirth } = appointment

          return getUUid(
            `${firstName}${lastName}${email}${parseDateOfBirth(dateOfBirth)}`,
          )
        })
        const filtered = response.filter(person => {
          const { firstName, lastName, email, dateOfBirth } = person
          const singleID = getUUid(
            `${firstName}${lastName}${email}${parseDateOfBirth(dateOfBirth)}`,
          )

          if (!addedPerson.includes(singleID)) {
            return person
          }
        })

        setPeoples(filtered)
      })
    } catch (error) {
      handleError(error, 'Error retrieving peoples')
    }

    return () => {
      PeopleService.unsubscribePeoples()
    }
  }, [organizationId, tg])

  return {
    peoples,
    isPeoplesLoading,
  }
}
