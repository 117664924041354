import styled from 'styled-components'
import { Card as defaultCard } from 'antd'
const { device } = require('../constants')
const Card = styled(defaultCard)`
  margin-bottom: 16px;
  width: 100%;
  display: inline-table;
  & .ant-card-head {
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    & .ant-card-head-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      & .ant-card-head-title {
        position: absolute;
        padding: 5px 0;
        white-space: nowrap;
      }
      & .ant-card-extra {
        width: 100%;
        padding: 5px 0;
        & .ant-radio-wrapper {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 37px;
        }
      }
    }
  }
  & .ant-card-body {
    padding: 5px 10px;
  }
  @media ${device.mobileS} {
    display: inline-table;
    & .ant-card-head {
      font-size: 14px !important;
      & .ant-card-head-wrapper {
        display: flex;
        & .ant-card-head-title {
          white-space: nowrap;
        }
      }
    }
  }
  @media ${device.mobileM} {
    display: inline-table;
    & .ant-card-head {
      font-size: 16px !important;
      & .ant-card-head-wrapper {
        display: flex;
        & .ant-card-head-title {
          white-space: pre-wrap;
        }
      }
    }
  }
`
export default Card
