import React, { useCallback, useState } from 'react'
import Webcam from 'react-webcam'
import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Modal from './capture-photo-modal'
import { base64toFile } from '../../utils/image-utils'
import StorageService from '../../services/storage-service'

const videoConstraints = {
  facingMode: { exact: 'environment' },
}

const WebcamCapture = ({
  onCaptureDriver,
  onCaptureDriverBack,
  onCaptureInsurance,
  onCaptureInsuranceBack,
  isModalVisible,
  onClose,
  type,
  id,
}) => {
  const webcamRef = React.useRef(null)
  const [loading, setLoading] = useState()

  const capture = useCallback(async () => {
    setLoading(true)
    const imageSrc = webcamRef.current.getScreenshot()
    const file = await base64toFile(imageSrc, id)
    const image = await StorageService.uploadDriverLicenseAndInsuranceCard(
      file,
      id,
      type,
    )
    if (type === 'driver-license') {
      onCaptureDriver(image)
    } else if (type === 'driver-license-back') {
      onCaptureDriverBack(image)
    } else if (type === 'insurance-card') {
      onCaptureInsurance(image)
    } else if (type === 'insurance-card-back') {
      onCaptureInsuranceBack(image)
    }
    setLoading(false)
    onClose()
  }, [
    id,
    type,
    onClose,
    onCaptureDriver,
    onCaptureDriverBack,
    onCaptureInsurance,
    onCaptureInsuranceBack,
  ])

  return (
    <Modal
      title="Take a Picture"
      visible={isModalVisible}
      centered
      closeIcon={<CloseOutlined onClick={onClose} />}
      destroyOnClose
      footer={false}
      width={650}
    >
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        width={480}
        height={480}
        videoConstraints={videoConstraints}
      />
      <Button type="primary" onClick={capture} loading={loading}>
        Capture photo
      </Button>
    </Modal>
  )
}

export default WebcamCapture
