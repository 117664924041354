import { Descriptions, Typography } from 'antd'
import React from 'react'

const { Title } = Typography

const ResultModalContent = ({ info }) => {
  const { firstName, lastName, dateOfBirth, email, phoneNumber } = info
  return (
    <p>
      <Descriptions bordered>
        <Descriptions.Item label="First Name" span={3}>
          {firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name" span={1}>
          {lastName}
        </Descriptions.Item>
        <Descriptions.Item label="DOB" span={1}>
          {dateOfBirth}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={1}>
          {email}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number" span={1}>
          {phoneNumber}
        </Descriptions.Item>
      </Descriptions>
      <Title level={5} style={{ marginTop: 10 }}>
        I confirm that the patient was contacted and understood their positive
        test result
      </Title>
    </p>
  )
}

export default ResultModalContent
