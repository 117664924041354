import React from 'react'
import { Form, Modal, Radio, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import AppointmentService from '../../services/appointments-service'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import Spin from '../../components/main-spin'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import { COMPANY_NAME } from '../../constants'
import { DemographicTooltip } from '../../components/demographic-tooltip'
import styled from 'styled-components'

export const HasNoInsuranceModal = styled(Modal)`
  & .ant-btn-background-ghost {
    display: none !important;
  }
`

const { Text, Title } = Typography
const { confirm } = HasNoInsuranceModal

function showConfirm() {
  confirm({
    title: 'Confirm: No Insurance Plan',
    icon: <ExclamationCircleOutlined />,
    content:
      "By clicking on Ok button you confirm: I swear I don't have any health insurance or not able to afford for one",
    onOk() {},
    onCancel() {},
    cancelButtonProps: {
      ghost: true,
      disabled: true,
      style: { display: 'none' },
    },
  })
}

function HasInsurance() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()

  const onFinish = async values => {
    try {
      await AppointmentService.update(appointmentId, values)
      history.push(
        values?.hasInsurance === 'yes'
          ? routes.planDetails1
          : routes.driverLicense,
        {
          previousPath: routes.hasInsurance,
        },
      )
    } catch (error) {
      handleError(error, 'Error saving insurance data')
    }
  }

  const isNextButtonDisabled = () => {
    return hasFormErrors(form) || !form.getFieldValue('hasInsurance')
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle>{t('hasInsurance.title', { String: COMPANY_NAME })}</PageTitle>
      <Text>{t('hasInsurance.text')}</Text>
      <Title level={5}>
        {t('demographics.expand')}{' '}
        <DemographicTooltip large text={t('hasInsurance.tooltip')} />
      </Title>

      <Form form={form} onFinish={onFinish} initialValues={appointment}>
        <Form.Item
          name="hasInsurance"
          rules={[
            { required: true, message: 'Please choose an option above!' },
          ]}
        >
          <Radio.Group>
            <ContentSpace>
              <Radio key={1} value="yes">
                {t('affirmation.positive')}
              </Radio>
              <Radio key={2} value="no" onClick={() => showConfirm()}>
                {t('affirmation.negative')}
              </Radio>
            </ContentSpace>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            backButtonUrl={routes.hipaaWarn}
          />
        </Form.Item>
      </Form>
    </ContentSpace>
  )
}

export default HasInsurance
