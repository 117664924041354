import DefaultProLayout from '@ant-design/pro-layout'
import styled from 'styled-components'
import { device } from './../../constants/index'

const ProLayout = styled(DefaultProLayout)`
  min-height: 100vh;
  @media ${device.tablet} {
    .ant-pro-sider-logo img {
      height: 42px !important;
    }
  }
  @media ${device.laptop} {
    .ant-pro-sider-logo img {
      height: 32px !important;
    }
  }
`

export default ProLayout
