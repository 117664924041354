import { successNotification } from '../components/dashboard/ui-notification'
import UserService from '../services/user-service'
import { handleError } from './error-utils'

export async function createUser(user) {
  try {
    await UserService.create(user)
    successNotification({
      message: 'User created successfully!',
    })
  } catch (error) {
    handleError(error, 'Error creating user')
  }
}

export async function updateUser(uid, user) {
  try {
    await UserService.updateUser(uid, user)
    successNotification({
      message: 'User updated successfully!',
    })
  } catch (error) {
    handleError(error, 'Error updating user')
  }
}

export async function signOutUser() {
  try {
    await UserService.signOut()

    successNotification({
      message: 'Logged Out successfully',
    })
  } catch (error) {
    handleError(error, 'Error logging out')
  }
}

export function hasUserPassword(user) {
  return user?.signInMethods.includes('password')
}
