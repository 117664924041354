import React, { useEffect } from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import PageTitle from '../../components/page-title'
import ContentSpace from '../../components/content-space'
import { getAppointmentFromStorage } from '../../utils/appointment-utils'
import routes from '../../config/routes'
import { COMPANY_PHONE_NUMBER } from '../../constants'

const { Text, Link } = Typography

function EmailSuccess() {
  const { email } = getAppointmentFromStorage()
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    if (!email) {
      history.push(routes.emailAdd)
    }
  })

  return (
    <ContentSpace>
      <PageTitle fontSize={22}>
        {t('emailSuccess.title', { String: email })}
      </PageTitle>
      <Text>{t('emailSuccess.text')}</Text>
      <Text>
        {t('messages.callSupportP1')}
        <Link href={'tel:562-799-8900'}> {COMPANY_PHONE_NUMBER} </Link>
        {t('messages.callSupportP2')}
      </Text>
    </ContentSpace>
  )
}

export default EmailSuccess
