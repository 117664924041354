import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const RouteFactory = props => {
  const { template: Template, component, componentProps, ...rest } = props

  const renderChildren = routeProps => {
    const Component = props.component
    if (Template) {
      return (
        <Template
          location={routeProps.location}
          history={routeProps.history}
          match={routeProps.match}
        >
          <Component
            {...props}
            {...componentProps}
            location={routeProps.location}
            history={routeProps.history}
            match={routeProps.match}
          />
        </Template>
      )
    } else {
      return (
        <Component
          {...props}
          {...componentProps}
          location={routeProps.location}
          history={routeProps.history}
          match={routeProps.match}
        />
      )
    }
  }

  return <Route {...rest} render={renderChildren} />
}

RouteFactory.propTypes = {
  component: PropTypes.func.isRequired,
  template: PropTypes.func,
  componentProps: PropTypes.object,
}

export default RouteFactory
