import React, { useState, useEffect } from 'react'
import { Modal, Button, Descriptions, Typography } from 'antd'
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { format } from 'date-fns'

import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import { Description } from '../../components/description'

import { useTranslation } from 'react-i18next'

const { Text } = Typography

export default function InfoModal({
  data,
  isModalVisible,
  onClickClose,
  setVisible,
}) {
  const { t } = useTranslation()

  return (
    <Modal
      title={`${data?.fullName} info`}
      visible={isModalVisible}
      centered
      width={600}
      closeIcon={<CloseOutlined onClick={onClickClose} />}
      destroyOnClose
      footer={[
        <Button key="back" onClick={onClickClose}>
          Return
        </Button>,
      ]}
    >
      <ContentSpace>
        <Descriptions bordered>
          <Descriptions.Item label="Name" span={3}>
            {data?.fullName}
          </Descriptions.Item>
          <Descriptions.Item label="DOB" span={1}>
            {data?.dateOfBirth && format(data?.dateOfBirth, 'MM/dd/yyyy')}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number" span={3}>
            {data?.phoneNumber ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={3}>
            {data?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Barcode" span={3}>
            {data?.barcode ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={3}>
            {data?.zip ?? '-'}
            <br />
            {data?.street1}
            <br />
            {data?.city} - {data?.state}
          </Descriptions.Item>
          <Descriptions.Item label="Result" span={3}>
            {data?.result ?? '-'}
          </Descriptions.Item>
        </Descriptions>
      </ContentSpace>
    </Modal>
  )
}
