import React from 'react'
import { Select } from 'antd'

const { Option } = Select

export const FormSelect = ({
  selectOptions,
  placeholder,
  handleChange,
  onSearch,
  value,
}) => {
  return (
    <Select
      loading={!selectOptions}
      onSearch={onSearch}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {selectOptions &&
        selectOptions.map(element => (
          <Option key={element.objectID} value={element.payerName}>
            {element.payerName}
          </Option>
        ))}
    </Select>
  )
}
