import { getFormattedAppointmentValues } from './appointment-utils'
import { appointmentsIndex } from '../config/config'

export async function algoliaSearch(searchQuery, algoliaIndex, filters) {
  const result = await algoliaIndex.search(searchQuery, { filters })
  const { hits } = result

  return hits
}

export async function algoliaSearchBarcode(value) {
  const result = await appointmentsIndex.search(value)
  const { hits } = result

  return hits
}

export function parseSearchResultToAppointments(resultArray) {
  const appointments = []

  resultArray.forEach(item => {
    if (item.confirmationBookAppointment) {
      const formattedValues = getFormattedAppointmentValues(item)

      appointments.push({
        ...item,
        ...formattedValues,
        key: item.objectID,
      })
    }
  })

  return appointments
}

export function parsePatientSearchResultToAppointments(resultArray) {
  const appointments = []

  resultArray.forEach(item => {
    const formattedValues = getFormattedAppointmentValues(item)

    appointments.push({
      ...item,
      ...formattedValues,
      key: item.objectID,
    })
  })

  return appointments
}

export function parseSearchResultToLocations(resultArray) {
  const locations = []

  resultArray.forEach(item => {
    locations.push({
      ...item,
      key: item.objectID,
    })
  })

  return locations
}

export function buildAlgoliaFilters(filters = {}) {
  const filtersArray = convertFiltersInArray(filters)
  const filtersSring = reduceFiltersArrayToString(filtersArray)
  return filtersSring
}

function convertFiltersInArray(filtersObject) {
  return Object.entries(filtersObject).filter(
    ([_, filterValue]) => !!filterValue,
  )
}

function reduceFiltersArrayToString(filtersArray) {
  return filtersArray.reduce(
    (finalString, [filterKey, filterValue], index, array) =>
      `${finalString}${filterKey}:${getFormatedFilterValue(filterValue)}${
        isNotLastArrayPosition(index, array) ? ' AND ' : ''
      }`,
    '',
  )
}

function getFormatedFilterValue(filterValue) {
  if (Array.isArray(filterValue)) {
    return `${filterValue[0]} TO ${filterValue[1]}`
  }
  return textHasSpace(filterValue) ? `"${filterValue}"` : filterValue
}

function textHasSpace(text) {
  return text.match(/\s/g)
}

function isNotLastArrayPosition(index, array) {
  return index < array.length - 1
}
