import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'

import getLanguage from './utils/locale-selector'
import { ConfigProvider } from 'antd'
import RouterComponent from './routes/router-component'
import UserContextProvider from './contexts/user-context'

function App() {
  const { i18n } = useTranslation()
  const language = getLanguage(i18n)
  return (
    <Suspense
      fallback={
        <div>
          <Spin />
        </div>
      }
    >
      <BrowserRouter>
        <ConfigProvider locale={language}>
          <UserContextProvider>
            <RouterComponent />
          </UserContextProvider>
        </ConfigProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
