import React from 'react'
import { Form } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AppointmentService from '../../services/appointments-service'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import Spin from '../../components/main-spin'
import PhoneMasked from '../../components/phone-masked'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import { CustomGroupCheckbox } from '../../components/custom-group-check-box'
import { removeMask } from '../../utils/date-utils'

function PhoneNumber() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const url = new URL(window.location.href)
  const isEdit = url.searchParams.get('isEdit')
  const { t } = useTranslation()

  const onFinish = async ({ phoneNumber, isInscribedSMS = [false] }) => {
    const noMaskPhone = removeMask(phoneNumber)
    const phone = !noMaskPhone.length ? null : phoneNumber

    try {
      await AppointmentService.update(appointmentId, {
        phoneNumber: phone,
        isInscribedSMS: isInscribedSMS[0],
      })
      if (isEdit) {
        history.push(routes.bookingConfirmation)
      } else {
        history.push(routes.demographicsPt1)
      }
    } catch (error) {
      handleError(error, t('errorMessage.phoneNumberError'))
    }
  }

  const isNextButtonDisabled = () => {
    return hasFormErrors(form)
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle>{t('phoneNumber.title')}</PageTitle>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          phoneNumber: appointment?.phoneNumber,
          isInscribedSMS: [appointment?.isInscribedSMS],
        }}
        onChange={() => {
          const sms = form.getFieldValue('isInscribedSMS')[0]
          const phone = removeMask(form.getFieldValue('phoneNumber'))
          if (sms === true && phone === '') {
            form.setFields([
              {
                name: 'phoneNumber',
                errors: [t('rules.phoneNumberRequired')],
              },
            ])
          } else if (sms === undefined && phone === '') {
            form.setFields([
              {
                name: 'phoneNumber',
                errors: [],
              },
            ])
          }
        }}
      >
        <Form.Item label={t('phoneNumber.placeholder')} name="phoneNumber">
          <PhoneMasked autoFocus style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="isInscribedSMS">
          <CustomGroupCheckbox
            items={[
              {
                value: true,
                text: t('phoneNumber.agree'),
              },
            ]}
          />
        </Form.Item>

        <p>{t('phoneNumber.infoMessage')}</p>

        <ActionButtons
          isNextButtonDisabled={isNextButtonDisabled}
          backButtonUrl={routes.birthDate}
          hideBackButton={isEdit}
        />
      </Form>
    </ContentSpace>
  )
}

export default PhoneNumber
