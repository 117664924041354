import React, { useCallback, useContext } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import reverse from 'lodash/reverse'
import { Spin } from 'antd'

import RouteFactory from './route-factory'
import { dashboardRoutes, publicRoutes, guestFormRoutes } from './route-paths'
import Login from '../pages/login/login'
import NotFound from '../pages/alerts/not-found'
import { UserContext } from '../contexts/user-context'
import FormTemplate from '../templates/form-template'
import { findDefaultRoute } from '../utils/routing-utils'
import { hasUserPassword } from '../utils/user-utils'

function RouterHOC() {
  const { isLoggedIn, role, user } = useContext(UserContext)

  const setRedirectToDefaultPage = useCallback(
    route => {
      const defaultPrivateRoute = findDefaultRoute(role)

      return (
        <Redirect
          key={route.path}
          from={route.path}
          to={defaultPrivateRoute.path}
          exact
        />
      )
    },
    [role],
  )

  const setRoute = route => {
    return <RouteFactory key={route.path} {...route} exact />
  }

  const setDashboardRoute = useCallback(
    () => route => {
      if (!isLoggedIn || (isLoggedIn && !hasUserPassword(user))) {
        return setRoute({
          ...route,
          template: FormTemplate,
          component: Login,
        })
      }

      if (isLoggedIn && (route.redirectToDefault || route.onlyNoAuth)) {
        return setRedirectToDefaultPage(route)
      }

      return setRoute({
        ...route,
        template: route.template,
        component: route.component,
      })
    },
    [user, isLoggedIn, setRedirectToDefaultPage],
  )

  const createRoutes = useCallback(() => {
    const allRoutes = [
      dashboardRoutes.map(setDashboardRoute()),
      guestFormRoutes.map(setRoute),
      publicRoutes.map(setRoute),
    ]

    return isLoggedIn ? allRoutes : reverse(allRoutes)
  }, [isLoggedIn, setDashboardRoute])

  if (isLoggedIn === undefined) {
    return <Spin />
  }

  return (
    <Switch>
      {createRoutes()}
      <RouteFactory component={NotFound} />
    </Switch>
  )
}

export default RouterHOC
