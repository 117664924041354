import styled from 'styled-components'

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: ${props => props.theme.spacing.md};
`

export const Label = styled.span`
  margin-right: ${props => props.theme.spacing.md};
  font-weight: bold;
  width: 40%;
`
export const LabelContent = styled.span`
  margin-right: ${props => props.theme.spacing.md};
  width: 40%;
`

export const Content = styled.div`
  display: flex;
  width: 80%;
`
