import { useEffect, useState } from 'react'

import LocationScheduleService from '../services/locations-schedule-service'
import { handleError } from '../utils/error-utils'

export default function useLocationSchedule(locationId) {
  const [locationSchedule, setLocationSchedule] = useState()
  const [isLocationScheduleLoading, setLocationScheduleLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      if (!locationId) {
        return
      }

      setLocationScheduleLoading(true)
      try {
        LocationScheduleService.observeLocationScheduleByDate(
          locationId,
          locationSchedule => {
            const groups = locationSchedule.reduce((groups, item) => {
              const date = item.startDate.split('T')[0]

              if (!groups[date]) {
                groups[date] = []
              }
              groups[date].push(item)
              return groups
            }, {})

            const groupArrays = Object.keys(groups).map(date => {
              return {
                date,
                timeSlots: groups[date],
              }
            })
            setLocationSchedule(groupArrays)
            setLocationScheduleLoading(false)
          },
        )
      } catch (error) {
        handleError(error, 'Error retrieving locations')
        setLocationScheduleLoading(false)
      }

      return () => {
        LocationScheduleService.unsubscribeLocationSchedule()
      }
    }

    fetchData()
  }, [locationId])

  return { isLocationScheduleLoading, locationSchedule }
}
