import React, { useState, useEffect, useCallback } from 'react'
import {
  Modal,
  Button,
  Form,
  Input,
  Divider,
  Select,
  Checkbox,
  Radio,
  Typography,
} from 'antd'
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { InstantSearch } from 'react-instantsearch-dom'
import { algolia, payersIndex } from '../../config/config'
import { handleError } from '../../utils/error-utils'
import InsuranceProviderSearchBox from '../../components/insurance-provider-search-box'
import ContentSpace from '../../components/content-space'
import { RadioGroup } from '../../components/radio-group'
import PageTitle from '../../components/page-title'
import DateMasked from '../../components/date-masked'
import StyledGroup from '../../components/styled-group'
import HasNoInsuranceModal from '../no-insurance-modal'
import { COMPANY_NAME } from '../../constants'
import { ProFormText } from '@ant-design/pro-form'
import { algoliaDOBParse } from '../../utils/date-utils'

const { Text } = Typography

const { Group } = Checkbox
const { confirm } = HasNoInsuranceModal

export default function EditResultModal({
  data,
  isModalVisible,
  onClickClose,
  setEditedInfo,
}) {
  const [hasInsurance, setHasInsurance] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [formEdit] = Form.useForm()
  const { t } = useTranslation()

  const ETHNIC_OPTIONS = t('ETHNIC_OPTIONS', { returnObjects: true })
  const symptonsList = t('SYMPTOMS_LIST', { returnObjects: true })
  const testReasonsList = t('TEST_REASONS_LIST', { returnObjects: true })
  const resultOptions = t('RESULT_OPTIONS', { returnObjects: true })

  const insuredRelationshipList = t('INSURED_RELATIONSHIP_LIST', {
    returnObjects: true,
  })

  const onFinish = async values => {
    try {
      setIsLoading(true)
      await setEditedInfo(values)
      setIsLoading(false)
      onClickClose()
    } catch (error) {
      setIsLoading(false)
      onClickClose()
      handleError(error, 'Error when updating data')
    }
  }

  const updateValues = useCallback(async () => {
    const values = {
      ...data,
      dateOfBirth: data?.dateOfBirth
        ? typeof data.dateOfBirth !== 'string'
          ? algoliaDOBParse(data.dateOfBirth)
          : data.dateOfBirth
        : '',
    }
    formEdit.setFieldsValue(values)
  }, [data, formEdit])

  useEffect(() => {
    if (isModalVisible) {
      if (
        Object.keys(data).length > 0 &&
        Object.keys(formEdit.getFieldsValue()).length === 0
      ) {
        updateValues(data)
      }
    }
    return () => {
      formEdit.resetFields()
    }
  }, [data, formEdit, updateValues, isModalVisible])

  const showConfirm = () => {
    confirm({
      title: 'Confirm: No Insurance Plan',
      icon: <ExclamationCircleOutlined />,
      content:
        "By clicking on Ok button you confirm: I swear I don't have any health insurance or not able to afford for one",
      onOk() {},
      onCancel() {},
      cancelButtonProps: {
        ghost: true,
        disabled: true,
        style: { display: 'none' },
      },
    })
  }

  const handleInsuranceChange = value => {
    formEdit.setFieldsValue({ insuranceProviderName: value })
  }

  const handleHasInsuranceChange = event => {
    setHasInsurance(event.target.value)
  }

  return (
    <Modal
      title={`Edit ${data?.fullName} info`}
      visible={isModalVisible}
      centered
      width={600}
      closeIcon={<CloseOutlined onClick={onClickClose} />}
      onOk={() => formEdit.submit()}
      destroyOnClose
      footer={[
        <Button key="back" onClick={onClickClose}>
          Return
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => formEdit.submit()}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        name="editResult"
        form={formEdit}
        onFinish={onFinish}
        initialValues={data}
        layout="vertical"
      >
        <ContentSpace>
          <PageTitle>Personal</PageTitle>
          <ProFormText name="firstName" label={t('fullName.firstName')} />
          <ProFormText name="lastName" label={t('fullName.lastName')} />
          <Form.Item
            name="dateOfBirth"
            label={t('nonUserAuth.birthPlaceholder')}
          >
            <DateMasked />
          </Form.Item>
          <Form.Item
            name="contactWithPerson"
            label={
              <b>
                In the last 14 days, have you been in contact with a person
                known to be infected with COVID-19?
              </b>
            }
            rules={[
              { required: true, message: 'Please choose an option above!' },
            ]}
          >
            <Radio.Group>
              <ContentSpace>
                <Radio key={1} value="yes">
                  {t('affirmation.positive')}
                </Radio>
                <Radio key={2} value="no">
                  {t('affirmation.negative')}
                </Radio>
              </ContentSpace>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="ethnicity" label={<b>Ethnicity</b>}>
            <RadioGroup options={ETHNIC_OPTIONS} />
          </Form.Item>

          <Form.Item name="sexAtBirth" label={<b>{t('demographics.sex')}</b>}>
            <RadioGroup
              options={t('SEX_AT_BIRTH_OPTIONS', { returnObjects: true })}
            />
          </Form.Item>

          <Form.Item
            name="reasonForTesting"
            label={<b>Reason for testing</b>}
            rules={[{ required: true, message: 'Please select a reason!' }]}
          >
            <StyledGroup noWrap>
              <ContentSpace>
                {testReasonsList.map(item => {
                  return (
                    <Radio key={item.key} value={item.value}>
                      {item.title}
                    </Radio>
                  )
                })}
              </ContentSpace>
            </StyledGroup>
          </Form.Item>

          <ProFormText name="state" label={<b>State</b>} />

          <Form.Item
            name="symptoms"
            label={<b>Symptoms</b>}
            rules={[
              { required: true, message: `${t('messages.validSymptom')}` },
            ]}
          >
            <Group>
              <ContentSpace>
                {symptonsList.map(item => (
                  <Checkbox key={item.key} value={item.key}>
                    {item.title}
                  </Checkbox>
                ))}
              </ContentSpace>
            </Group>
          </Form.Item>
          <Form.Item
            name="symptomsDate"
            label={<b>When did the symptoms begin?</b>}
          >
            <DateMasked />
          </Form.Item>

          <Divider />

          <ContentSpace>
            <PageTitle>Insurance</PageTitle>
            <Text>{t('hasInsurance.title', { String: COMPANY_NAME })}</Text>
            <Form.Item name="hasInsurance">
              <Radio.Group onChange={handleHasInsuranceChange}>
                <ContentSpace>
                  <Radio key={1} value="yes">
                    {t('affirmation.positive')}
                  </Radio>
                  <Radio key={2} value="no" onClick={() => showConfirm()}>
                    {t('affirmation.negative')}
                  </Radio>
                </ContentSpace>
              </Radio.Group>
            </Form.Item>
            {hasInsurance === 'yes' && (
              <ContentSpace>
                <Form.Item
                  label={<b>{t('planDetail.insuranceProvider')}</b>}
                  name="insuranceProviderName"
                >
                  <InstantSearch
                    indexName={payersIndex.indexName}
                    searchClient={algolia}
                  >
                    <InsuranceProviderSearchBox
                      getFieldValue={formEdit.getFieldValue}
                      handleChange={handleInsuranceChange}
                    />
                  </InstantSearch>
                </Form.Item>
                <Form.Item
                  label={<b>{t('planDetail.memberId')}</b>}
                  name="insuranceMemberId"
                >
                  <Input
                    name="insuranceMemberId"
                    placeholder={t('planDetail.memberIdPlaceholder')}
                  />
                </Form.Item>

                <Form.Item
                  label={<b>{t('planDetail.groupMember')}</b>}
                  name="groupNumber"
                >
                  <Input
                    name="insuranceMemberId"
                    insuranceMemberId
                    placeholder={t('planDetail.groupPlaceholder')}
                  />
                </Form.Item>

                <Form.Item
                  label={<b>{t('planDetail.insuredRelationship')}</b>}
                  name="insuredRelationship"
                >
                  <Select placeholder={t('planDetail.selection')}>
                    {insuredRelationshipList.map(({ key, title }) => (
                      <Select.Option key={key} value={key}>
                        {title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </ContentSpace>
            )}
          </ContentSpace>
          <Divider />
          <ContentSpace>
            <PageTitle>Result</PageTitle>
            <Form.Item name="result">
              <Select>
                {resultOptions.map(({ key, value, label }) => (
                  <Select.Option key={key} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </ContentSpace>
        </ContentSpace>
      </Form>
    </Modal>
  )
}
