import { addMonths } from 'date-fns/esm'
import React from 'react'
import {
  minimumDay,
  maximumDay,
  minimumMonth,
  maximumMonth,
  februaryDays,
  months30Days,
  minimumYear,
  maximumDate,
} from '../utils/date-utils'
import { InputMasked } from './styles/masked-input'

export default function DateMasked({ value, onChange, antdStyle, ...props }) {
  const handleDateChange = event => {
    let month = event.target.value.slice(0, 2)
    let day = event.target.value.slice(3, 5)
    let year = event.target.value.slice(6)

    let validatedDate = event.target.value

    if (minimumDay(day)) {
      day = '01'
      validatedDate = validatedDate.replace(validatedDate.slice(3, 5), '01')
    } else if (maximumDay(day)) {
      day = '31'
      validatedDate = validatedDate.replace(validatedDate.slice(3, 5), '31')
    }

    if (minimumMonth(month)) {
      month = '01'
      validatedDate = validatedDate.replace(validatedDate.slice(0, 2), '01')
    } else if (maximumMonth(month)) {
      month = '12'
      validatedDate = validatedDate.replace(validatedDate.slice(0, 2), '12')
    }

    if (februaryDays(day, month, year) === 1) {
      day = '29'
      validatedDate = validatedDate.replace(validatedDate.slice(3, 5), '29')
    } else if (februaryDays(day, month, year) === 2) {
      day = '28'
      validatedDate = validatedDate.replace(validatedDate.slice(3, 5), '28')
    }

    if (months30Days(day, month)) {
      day = '30'
      validatedDate = validatedDate.replace(validatedDate.slice(3, 5), '30')
    }

    if (minimumYear(year)) {
      year = '1900'
      validatedDate = validatedDate.replace(validatedDate.slice(6), '1900')
    }

    if (maximumDate(day, month, year)) {
      const today = new Date()
      validatedDate = validatedDate.replace(
        validatedDate,
        `${('0' + (today.getMonth() + 1)).slice(-2)}${(
          '0' + today.getDate()
        ).slice(-2)}${today.getFullYear()}`,
      )
    }

    if (onChange) {
      onChange(validatedDate)
    }
  }

  return (
    <InputMasked
      {...props}
      mask={[/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      guide
      showMask
      onChange={handleDateChange}
      value={typeof value === 'string' ? value : ''}
      autoFocus
      antdStyle={antdStyle}
    />
  )
}
