import { PageContainer } from '@ant-design/pro-layout'
import styled from 'styled-components'

const UserPageContainer = styled(PageContainer)`
  .ant-pro-page-container-extraContent {
    margin-left: 0;
    width: 100%;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: fixed;
  }
`
export default UserPageContainer
