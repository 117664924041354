import styled from 'styled-components'

const BasicOrgInfo = styled.span`
  font-size: 12px;
  line-height: 1;
  padding-right: ${props => (props.padRight ? '15px' : '10px')};
  padding-left: 10px;
`

export default BasicOrgInfo
