import styled from 'styled-components'
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui'

const { Appointment, Container } = Appointments

export const TimeSlotContainer = styled(Container)`
  width: 14.28% !important;

  & [class*='VerticalAppointment-shortContainer'] {
    justify-content: center;
  }

  & [class*='VerticalAppointment-shortContent'] {
    padding: 0px 8px !important;
  }
`

export const TimeSlot = styled(Appointment)`
  height: 24px !important;
  font-size: 14px !important;
  border: none !important;
  border-radius: 0 !important;
  background: ${({ theme }) => theme.colors.primary.main} !important;

  &:hover {
    background: ${({ theme }) => theme.colors.primary.light} !important;
  }
`
