import React, { useRef, useContext, useState } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import ProForm from '@ant-design/pro-form'
import { compareAsc, format, parseISO, isBefore, isAfter } from 'date-fns'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Modal,
  Typography,
  Descriptions,
  Switch,
  Button,
  Form,
  Input,
} from 'antd'
import {
  ButtonSpan,
  SearchSpan,
} from '../../components/dashboard/responsive-span-header'
import { successNotification } from '../../components/dashboard/ui-notification'
import DatePicker from '../../components/date-picker-fns'
import useAppointments from '../../hooks/use-appointments'
import appointmentsService from '../../services/appointments-service'
import AppointmentsListParameters from '../../components/dashboard/appointments-list-parameters'
import { LocationSelectorProvider } from '../../contexts/location-selector-context'
import { printBarcode } from '../../utils/printer-utils'
import { compareStrings } from '../../utils/text-utils'
import { UserContext } from '../../contexts/user-context'
import CheckInModal from '../../components/dashboard/check-in-modal'
import { errorNotification } from '../../components/dashboard/ui-notification'
import EditableTestGroupsAction from '../../components/dashboard/editable-test-group'
import { algoliaDOBParse } from '../../utils/date-utils'
const { Text } = Typography
const { confirm } = Modal

async function showConfirm(fullName, driver, dob, confirmationCode, key) {
  confirm({
    width: '50%',
    title: 'Confirm Checkin',
    icon: <ExclamationCircleOutlined />,
    content: (
      <p>
        <Text>Would you like to confirm checkin?</Text>

        <Descriptions title={fullName} bordered>
          <Descriptions.Item label="DOB" span={3}>
            {format(dob, 'MM/dd/yyyy')}
          </Descriptions.Item>
          <Descriptions.Item label="Drivers license" span={3}>
            {driver}
          </Descriptions.Item>
          <Descriptions.Item label="Confirmation Code" span={3}>
            {confirmationCode}
          </Descriptions.Item>
        </Descriptions>
      </p>
    ),
    async onOk() {
      try {
        await appointmentsService.update(key, { confirmation: new Date() })
        successNotification({
          message: 'Checked sucessfully',
        })
        printBarcode(key)
      } catch (error) {
        this.close()
      }
    },
    onCancel() {},
  })
}

function getTableColumns(
  handleSearch,
  role,
  isModalVisible,
  setIsModalVisible,
  selectedRow,
  setSelectedRow,
  handleCloseButtonClick,
) {
  return [
    {
      key: 'date',
      title: (_, type) => (type === 'table' ? 'Appt. Date' : 'Search'),
      dataIndex: 'slotsDateTime',
      hideOn: ['lg', 'sm', 'xs'],
      search: false,
      defaultSortOrder: 'descend',
      width: 200,
      render: text => {
        return format(parseISO(text), 'MM/dd/yyyy')
      },
      sorter: (a, b) =>
        compareAsc(parseISO(a.slotsDateTime), parseISO(b.slotsDateTime)),
    },
    {
      key: 'fullName',
      title: 'Full Name',
      dataIndex: 'fullName',
      hideInSearch: true,
      width: 250,
      valueType: 'text',
      sorter: {
        compare: (a, b) => compareStrings(a.fullName, b.fullName),
        multiple: 2,
      },
    },
    {
      key: 'confirmationCode',
      title: 'Confirmation Code',
      dataIndex: 'confirmationCode',
      hideInSearch: true,
      width: 250,
      valueType: 'text',
      defaultSortOrder: 'descend',
    },
    {
      key: 'dateOfBirth',
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      hideInSearch: true,
      width: 250,
      defaultSortOrder: 'descend',
      renderText: date => {
        return algoliaDOBParse(date)
      },
      sorter: (a, b) => a.dateOfBirth - b.dateOfBirth,
    },

    {
      key: 'timeSlotReadable',
      title: 'Time Slot',
      dataIndex: 'timeSlotReadable',
      hideInSearch: true,
      width: 250,
      defaultSortOrder: 'descend',
      sorter: (a, b) => compareAsc(parseISO(a.timeSlot), parseISO(b.timeSlot)),
    },
    {
      key: 'confirmation',
      title: 'Confirmation',
      hideInSearch: true,
      width: 250,
      dataIndex: 'confirmation',
      renderText: date => {
        return date && format(parseISO(date), 'MM/dd/yyyy')
      },
    },
    {
      key: 'barcode',
      title: 'Barcode',
      hideInSearch: true,
      width: 250,
      dataIndex: 'barcode',
    },
    {
      valueType: 'option',
      hideInSearch: true,
      align: 'right',
      render: (_, row) => {
        if (row.confirmation) {
          return <Text disabled>Checked already</Text>
        }
        if (role === 'doctor' || role === 'admin' || role === 'collection') {
          return (
            <>
              <Button
                type="link"
                onClick={() => {
                  setIsModalVisible(true)
                  setSelectedRow(row)
                }}
              >
                Check in
              </Button>
              {isModalVisible && selectedRow?.key === row.key && (
                <CheckInModal
                  fullName={selectedRow?.fullName}
                  driver={selectedRow?.driverLicenseNumber}
                  dob={selectedRow?.dateOfBirth}
                  confirmationCode={selectedRow?.confirmationCode}
                  id={selectedRow?.key}
                  role={role}
                  isModalVisible={isModalVisible}
                  onClickClose={handleCloseButtonClick}
                />
              )}
            </>
          )
        } else {
          return (
            <Button
              type="link"
              onClick={() => {
                showConfirm(
                  row.fullName,
                  row.driverLicenseNumber,
                  row.dateOfBirth,
                  row.confirmationCode,
                  row.key,
                )
              }}
            >
              Check in
            </Button>
          )
        }
      },
    },
    {
      key: 'edit',
      width: 35,
      valueType: 'edit',
      render: (_, row) => [
        <EditableTestGroupsAction key={`${row.id}-options`} row={row} />,
      ],
    },
  ]
}

export default function AppointmentsList() {
  const actionRef = useRef()
  const [formInstance] = ProForm.useForm()
  const {
    appointments,
    isAppointmentsLoading,
    isCheckedIn,
    dateSelected,
    setIsCheckedIn,
    setDateSelected,
    setSearchFilters,
  } = useAppointments()
  const { role } = useContext(UserContext)
  const [isModalVisible, setIsModalVisible] = useState()
  const [selectedRow, setSelectedRow] = useState()

  function dateValidator(id, date) {
    if (id === 'endDate' && isBefore(date, dateSelected.startDate)) {
      errorNotification({
        message: 'Invalid End Date',
        description: 'End Date must be greater than Start Date',
      })
      return false
    }
    if (id === 'startDate' && isAfter(date, dateSelected.endDate)) {
      errorNotification({
        message: 'Invalid Start Date',
        description: 'Start Date must be smaller than End Date',
      })
      return false
    }
    return true
  }

  const onChange = (id, date) => {
    dateValidator(id, date) && setDateSelected({ ...dateSelected, [id]: date })
  }

  const handleCloseButtonClick = () => {
    setIsModalVisible(false)
  }

  function resetSearch() {
    formInstance.resetFields()
    setSearchFilters(null)
  }
  async function handleSearchSubmit() {
    const { lastName } = formInstance.getFieldsValue()
    setSearchFilters(lastName)
  }

  return (
    <LocationSelectorProvider>
      <PageContainer
        title={
          <>
            <Text>Start Date: </Text>
            <DatePicker
              bordered={false}
              format={'EEEE, d LLLL yyyy'}
              defaultValue={dateSelected.startDate}
              value={dateSelected.startDate}
              onChange={date => onChange('startDate', date)}
            />

            <Text>End Date: </Text>
            <DatePicker
              bordered={false}
              format={'EEEE, d LLLL yyyy'}
              defaultValue={dateSelected.endDate}
              value={dateSelected.endDate}
              onChange={date => onChange('endDate', date)}
            />
          </>
        }
        content={
          <>
            <AppointmentsListParameters />
            <Form
              form={formInstance}
              layout="inline"
              style={{ paddingTop: 20 }}
            >
              <SearchSpan>
                <Form.Item label="Search" name="lastName">
                  <Input placeholder="Search by last name" />
                </Form.Item>
              </SearchSpan>
              <ButtonSpan>
                <Form.Item>
                  <Button type="default" onClick={resetSearch}>
                    Reset
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleSearchSubmit}
                  >
                    Search
                  </Button>
                </Form.Item>
              </ButtonSpan>
            </Form>
          </>
        }
      >
        <ProTable
          columns={getTableColumns(
            setSearchFilters,
            role,
            isModalVisible,
            setIsModalVisible,
            selectedRow,
            setSelectedRow,
            handleCloseButtonClick,
          )}
          actionRef={actionRef}
          dataSource={appointments}
          loading={isAppointmentsLoading}
          rowKey="key"
          toolBarRender={() => {}}
          options={false}
          search={false}
          headerTitle={
            <>
              <Switch
                checked={isCheckedIn}
                onClick={() => setIsCheckedIn(!isCheckedIn)}
              />{' '}
              <Text>Show Checked In</Text>
            </>
          }
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          dateFormatter="string"
        />
      </PageContainer>
    </LocationSelectorProvider>
  )
}
