import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'

import routes from '../../config/routes'
import ContentSpace from '../../components/content-space'
import LoginForm from '../../components/dashboard/login-form'
import { ConfigProvider, Typography } from 'antd'
import { Link } from 'react-router-dom'
import FinePrint from '../../components/fine-print'
import { useTranslation } from 'react-i18next'
import { enUSIntl } from '@ant-design/pro-table'

const { Text } = Typography

function Login() {
  const { t } = useTranslation()

  const uiConfig = {
    signInFlow: 'redirect',
    signInSuccessUrl: routes.home,
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    callbacks: {
      signInSuccessWithAuthResult: () => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      },
    },
  }

  return (
    <ConfigProvider locale={enUSIntl}>
      <ContentSpace>
        <LoginForm uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        <Text>
          {t('login.needHelp')}{' '}
          <Link to={routes.home}>{t('login.contactUs')}</Link>
        </Text>
        <FinePrint>{t('login.finePrintText')}</FinePrint>
      </ContentSpace>
    </ConfigProvider>
  )
}

export default Login
