import { Modal as DefaultModal } from 'antd'
import styled from 'styled-components'

const Modal = styled(DefaultModal)`
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export default Modal
