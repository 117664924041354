import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import ProForm, { ProFormText, ProFormSelect } from '@ant-design/pro-form'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { createUser, updateUser } from '../../utils/user-utils'
import ProfilesService from '../../services/profiles-service'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import AddressService from '../../services/address-service'
export default function AddUserModal({ isModalVisible, onClickClose, user }) {
  const [formInstance] = ProForm.useForm()
  const { t } = useTranslation()
  const requiredFieldRule = {
    required: true,
    message: `${t('messages.fieldRequired')}`,
  }
  const roleOptions = [
    {
      value: 'admin',
      label: 'Covid App Admin',
    },
    {
      value: 'collection',
      label: 'Specimen Collection',
    },
    {
      value: 'reporting',
      label: 'Reporting',
    },
    {
      value: 'lab',
      label: 'Lab Testing',
    },
    {
      value: 'billing',
      label: 'Billing',
    },
    {
      value: 'organization',
      label: 'Organization',
    },
    {
      value: 'doctor',
      label: 'Doctor',
    },
  ]
  const initialValues = {
    fullName: '',
    email: '',
    type: '',
  }
  const [fieldVal] = useState(initialValues)
  const [role, setRoleValue] = useState(user && Object.keys(user.role)[0])

  async function handleFormSubmit() {
    if (user) {
      return handleUpdateUser()
    }
    return handleCreateUser()
  }

  async function handleCreateUser() {
    await createUser(formInstance.getFieldsValue())
    formInstance.resetFields()
    onClickClose()
  }

  async function handleUpdateUser() {
    await updateUser(user.uid, formInstance.getFieldsValue())
    formInstance.resetFields()
    onClickClose()
  }

  function resetForm() {
    formInstance.setFieldsValue(fieldVal)
  }

  //Use effect that fills according to user object
  useEffect(() => {
    if (!user) {
      formInstance.setFieldsValue(fieldVal)
    } else if (user) {
      setRoleValue(user && Object.keys(user.role)[0])
      formInstance.setFieldsValue({
        fullName: user.name,
        email: user.email,
        type: user && Object.keys(user.role)[0],
      })
    }
  }, [user, fieldVal, formInstance])

  //Use effect that fills according to user profile object
  useEffect(() => {
    async function fetchData() {
      let userInfo = user && (await ProfilesService.get(user?.uid))
      formInstance.setFieldsValue({
        fullAddress: userInfo?.fullAddress,
        phoneNumber: userInfo?.phoneNumber,
        providerFirstName: userInfo?.providerFirstName,
        providerFacilityName: userInfo?.providerFacilityName,
        providerMiddleName: userInfo?.providerMiddleName,
        providerLastName: userInfo?.providerLastName,
        providerAddress: userInfo?.providerAddress,
        providerCity: userInfo?.providerCity,
        providerState: userInfo?.providerState,
        providerZip: userInfo?.providerZip,
        providerPhone: userInfo?.providerPhone,
        providerNPI: userInfo?.providerNPI,
        pin: userInfo?.pin,
      })
    }
    fetchData()
  }, [formInstance, user])

  //Function that handle select role change
  const handleFormValuesChange = changedValues => {
    const fieldName = Object.keys(changedValues)[0]

    if (fieldName === 'type') {
      const value = changedValues[fieldName]
      setRoleValue(value)
    }
  }

  //Function that retrieve provider state and provider city by zipcode
  const getAddressInfo = async typedZip => {
    let response = await AddressService.getAddresByZipcode(typedZip)
    formInstance.setFieldsValue({
      providerState: response.state,
      providerCity: response.city,
    })
    if (response.error) {
      return false
    }
    return true
  }

  //Function that renders extra fields
  const renderExtraFields = () => {
    if (role === 'organization') {
      return (
        <>
          <ProFormText
            name="fullAddress"
            label="Full Address"
            placeholder="123 Main Street, New York, NY 10030"
            rules={[requiredFieldRule]}
            width="100%"
          />
          <ProFormText
            name="phoneNumber"
            label="Phone"
            placeholder="(555) 555-1234"
            rules={[requiredFieldRule]}
            width="100%"
          />
        </>
      )
    }
    if (role === 'doctor') {
      return (
        <>
          <ProFormText
            name="fullAddress"
            label="Full Address"
            placeholder="123 Main Street, New York, NY 10030"
            rules={[requiredFieldRule]}
            width="100%"
          />
          <ProFormText
            name="phoneNumber"
            label="Phone"
            placeholder="(555) 555-1234"
            rules={[requiredFieldRule]}
            width="100%"
          />
          <ContentSpace>
            <PageTitle fontSize={20}>Provider Information</PageTitle>
            <ProFormText
              name="providerFacilityName"
              label="Facility Name"
              placeholder="Sterling Pathology"
              rules={[requiredFieldRule]}
              width="100%"
            />
            <ProFormText
              name="providerFirstName"
              label="Provider First name"
              placeholder="John"
              rules={[requiredFieldRule]}
              width="100%"
            />
            <ProFormText
              name="providerMiddleName"
              label="Provider Middle Name"
              placeholder="Steven"
              width="100%"
            />
            <ProFormText
              name="providerLastName"
              label="Provider Last Name"
              placeholder="Smith"
              rules={[requiredFieldRule]}
              width="100%"
            />

            <ProFormText
              label="Provider Zip"
              name="providerZip"
              placeholder="90049"
              width="100%"
              rules={[
                {
                  required: true,
                  message: `${t('rules.zipRequired')}`,
                },
                {
                  async validator(_, value) {
                    if (
                      value &&
                      value.length === 5 &&
                      (await getAddressInfo(value))
                    ) {
                      return Promise.resolve()
                    }
                    formInstance.setFieldsValue({
                      providerState: '',
                      providerCity: '',
                    })
                    return Promise.reject('Invalid Zip code')
                  },
                },
              ]}
            />
            <ProFormText
              name="providerAddress"
              label="Provider Address"
              placeholder="12345 Sommerplace St"
              rules={[requiredFieldRule]}
              width="100%"
            />
            <ProFormText
              name="providerCity"
              label="Provider City"
              placeholder="Seal Beach"
              rules={[requiredFieldRule]}
              width="100%"
            />
            <ProFormText
              name="providerState"
              label="Provider State"
              placeholder="CA"
              rules={[requiredFieldRule]}
              width="100%"
            />
            <ProFormText
              name="providerPhone"
              label="Provider Phone"
              placeholder="(424) 555-1212"
              rules={[requiredFieldRule]}
              width="100%"
            />
            <ProFormText
              name="providerNPI"
              label="Provider NPI"
              placeholder="1234567890"
              rules={[requiredFieldRule]}
              width="100%"
            />
            <br />
          </ContentSpace>
        </>
      )
    }
    if (role === 'admin' || role === 'lab') {
      return (
        <ProFormText
          name="pin"
          label="Pin"
          placeholder="1234567890"
          rules={[requiredFieldRule]}
          width="100%"
        />
      )
    }
  }

  return (
    <Modal
      title="Add an User"
      visible={isModalVisible}
      centered
      closeIcon={<CloseOutlined onClick={onClickClose} />}
      afterClose={resetForm}
      destroyOnClose
      footer={false}
    >
      <ProForm
        form={formInstance}
        initialValues={fieldVal}
        onFinish={handleFormSubmit}
        onValuesChange={handleFormValuesChange}
      >
        <ProFormText
          name="fullName"
          label="Full Name"
          placeholder="John Smith"
          rules={[requiredFieldRule]}
          width="100%"
        />

        <ProFormSelect
          width="100%"
          options={roleOptions}
          rules={[requiredFieldRule]}
          name="type"
          label="Role"
        />
        <ProFormText
          name="email"
          label="Email"
          placeholder="john.smith@gmail.com"
          rules={[requiredFieldRule]}
          width="100%"
          readonly={user}
        />
        {renderExtraFields()}
      </ProForm>
    </Modal>
  )
}
