import React, { useEffect, useContext } from 'react'
import { Modal, Descriptions, Typography, Input, Form } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import ProForm, { ProFormText, ProFormSelect } from '@ant-design/pro-form'
import {
  successNotification,
  errorNotification,
} from '../../components/dashboard/ui-notification'
import AppointmentsService from '../../services/appointments-service'

export default function AddBarcodeModal({
  isModalVisible,
  data,
  onClickClose,
}) {
  const [formInstance] = ProForm.useForm()

  const handleFormSubmit = async ({ barcode }) => {
    const { id, fullName } = data
    try {
      if (!barcode) {
        handleFormReset()
        return errorNotification({
          message: 'You must enter a barcode',
        })
      }
      const appointment =
        barcode && (await AppointmentsService.getAppointmentByBarcode(barcode))
      if (appointment) {
        handleFormReset()
        return errorNotification({
          message: 'The barcode you entered is already in use',
        })
      }
      if (barcode && !appointment) {
        await AppointmentsService.update(id, {
          barcode,
        })
      }
      successNotification({
        message: `Added barcode ${barcode} to ${fullName}'s appointment`,
      })
      handleFormReset()
      onClickClose()
    } catch (error) {
      onClickClose()
    }
  }
  const handleFormReset = () => {
    formInstance.resetFields()
  }
  return (
    <Modal
      title={`Add a barcode for ${data?.fullName}`}
      visible={isModalVisible}
      centered
      closeIcon={<CloseOutlined onClick={onClickClose} />}
      destroyOnClose
      footer={false}
    >
      <ProForm form={formInstance} onFinish={handleFormSubmit}>
        <Form.Item
          name="barcode"
          rules={[
            ({ getFieldValue }) => ({
              required: getFieldValue('barcode') === '',
              message: 'Please enter a barcode!',
            }),
          ]}
        >
          <Input
            name="barcode"
            placeholder="Enter barcode"
            width="100%"
            autoFocus
            hasFeedback
          />
        </Form.Item>
        <Form.Item
          name="barcodeConfirmation"
          dependencies={['barcode']}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              required: getFieldValue('barcode') === '',
              message: 'Please confirm your barcode!',
            }),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('barcode') === value) {
                  return Promise.resolve()
                }
                return Promise.reject('The two barcodes do not match!')
              },
            }),
          ]}
        >
          <Input
            name="barcodeConfirmation"
            placeholder="Re-enter barcode"
            width="100%"
          />
        </Form.Item>
      </ProForm>
    </Modal>
  )
}
