import { useEffect, useState } from 'react'

import ProfilesService from '../services/profiles-service'
import { handleError } from '../utils/error-utils'

export default function useProfiles() {
  const [profiles, setProfiles] = useState()
  const [isProfilesLoading, setIsProfilesLoading] = useState(false)

  useEffect(() => {
    try {
      setIsProfilesLoading(true)
      ProfilesService.observeProfilesDoctor(profiles => {
        setProfiles(profiles)
        setIsProfilesLoading(false)
      })
    } catch (error) {
      setIsProfilesLoading(false)
      console.log(error)
      handleError(error, 'Error retrieving profiles')
    }

    return () => {
      ProfilesService.unsubscribeProfiles()
    }
  }, [])

  return {
    profiles,
    isProfilesLoading,
  }
}
