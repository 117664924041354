import React from 'react'
import { Collapse, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import { parseHtmlText } from '../../utils/text-utils'
import { COMPANY_PHONE_NUMBER } from '../../constants'
const { Panel } = Collapse
const { Title, Paragraph } = Typography

function Home() {
  const { t } = useTranslation()
  const faqList = t('FAQ', {
    returnObjects: true,
    String: COMPANY_PHONE_NUMBER,
  })

  return (
    <ContentSpace size={50}>
      <ContentSpace>
        <PageTitle>{t('home.title')}</PageTitle>
        <Paragraph>{t('home.paragraph')}</Paragraph>
        <ActionButtons
          hideBackButton
          nextButtonUrl={routes.priority}
          nextButtonTitle={t('buttons.makeAppointment')}
        />
      </ContentSpace>

      <ContentSpace>
        <Title level={4} type="primary">
          {t('home.faq')}
        </Title>
        <Collapse>
          {faqList.map(item => (
            <Panel key={item.key} header={item.header}>
              <Paragraph>{parseHtmlText(item.paragraph)}</Paragraph>
            </Panel>
          ))}
        </Collapse>
      </ContentSpace>
    </ContentSpace>
  )
}

export default Home
