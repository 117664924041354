import React from 'react'
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer'
import { parseISO, format } from 'date-fns'
import Logo from '../../assets/imgs/header-logo.png'
import theme from '../../styles/theme'
import { formatDateOfBirth } from '../../utils/date-utils'
import RobotoNormal from '../../assets/fonts/Roboto-Regular.ttf'
import RobotoBold from '../../assets/fonts/Roboto-Bold.ttf'
import BritishShorthair from '../../assets/fonts/BritishShorthair.otf'
import BeyondInfinity from '../../assets/fonts/BeyondInfinity.ttf'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoNormal },
    { src: RobotoBold, fontStyle: 'bold', fontWeight: '700' },
  ],
})

Font.register({
  family: 'BritishShorthair',
  fonts: [{ src: BritishShorthair }],
})

Font.register({
  family: 'BeyondInfinity',
  fonts: [{ src: BeyondInfinity }],
})

const styles = StyleSheet.create({
  page: {
    padding: theme.spacing.xg,
  },
  logo: {
    height: 40,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: theme.colors.black,
    padding: theme.spacing.sm,
  },
  title: {
    paddingRight: theme.spacing.lg,
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    color: theme.colors.primary.pdfBlue,
  },
  sectionInformation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 20,
  },
  sectionTitleView: {
    backgroundColor: theme.colors.primary.pdfBlue,
    paddingLeft: theme.spacing.sm,
    marginTop: 3,
  },
  sectionTitle: {
    fontSize: 10,
    color: theme.colors.white,
  },
  finePrintSectionTitleView: {
    backgroundColor: theme.colors.primary.pdfBlue,
    paddingLeft: theme.spacing.sm,
  },
  finePrintSectionTitle: {
    fontSize: 7,
    color: theme.colors.white,
  },
  leftItems: {
    flex: 1,
  },
  middleItems: {
    flex: 1,
  },
  rightItems: {
    flex: 1.2,
  },
  items: {
    marginTop: theme.spacing.xs,
    fontSize: 10,
    fontWeight: 600,
  },
  sectionDiagnosis: {
    borderColor: 'black',
    borderWidth: 1,
    display: 'flex',
    marginTop: 3,
  },
  finePrint: {
    borderColor: 'black',
    borderWidth: 1,
    display: 'flex',
    marginTop: 3,
    fontSize: 6,
  },
  dRowTop: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 1,
    borderColor: 'black',
  },
  dRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dRowText: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: 1,
    borderColor: 'black',
  },
  dColTitle1: {
    paddingVertical: theme.spacing.sm,
    display: 'flex',
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'grey',
    borderRight: 1,
    borderColor: 'black',
  },
  dColTitle: {
    display: 'flex',
    flex: 2,
    flexDirection: 'col',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: 1,
    borderColor: 'black',
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    paddingVertical: theme.spacing.sm,
  },
  dResultText: {
    fontSize: 8,
    paddingVertical: 7,
    paddingHorizontal: 10,
    fontFamily: 'Roboto',
  },
  finePrintDResultText: {
    fontSize: 7,
    paddingVertical: 7,
    paddingHorizontal: 10,
    fontFamily: 'Roboto',
  },
  dTitle2: {
    fontSize: 8,
  },
  dColResult: {
    display: 'flex',
    flex: 1.25,
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: 1,
    borderColor: 'black',
  },
  dColFlag: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: 1,
    borderColor: 'black',
  },
  dColRef: {
    display: 'flex',
    flex: 1.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dColHeadingFirst: {
    fontSize: 11,
    color: 'white',
  },
  dColHeading: {
    fontSize: 11,
  },
  clinicalHistory: {
    marginBottom: theme.spacing.lg,
  },
  titleDiagnosis: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    paddingVertical: theme.spacing.sm,
  },
  titleClinical: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
  },
  titleClinicalDetected: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    color: 'red',
  },
  titleClinicalNotDetected: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    color: '#52c41a',
  },
  titleClinicalCritical: {
    fontSize: 11,
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    color: 'orange',
  },
  results: {
    marginBottom: theme.spacing.xs,
  },
  subTitle: {
    fontSize: 10,
    padding: theme.spacing.md,
    paddingTop: theme.spacing.md,
  },
  description: {
    fontSize: 9,
    paddingLeft: theme.spacing.md,
  },
  pageNumber: {
    fontSize: 8,
  },
  footerTitleView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.primary.pdfBlue,
    marginTop: theme.spacing.sm,
  },
  footerTitle: {
    fontSize: 10,
    color: theme.colors.white,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemsFooter: {
    fontSize: 10,
    color: theme.colors.gray[600],
  },
  footerRight: {
    flex: 0.7,
  },
  footerRightItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signatureResultEntered: {
    fontSize: 10,
    marginTop: 30,
    marginLeft: 10,
  },
  signatureYang: {
    fontSize: 10,
    marginTop: 30,
    marginLeft: 10,
  },
  signatureResultEnteredSigned: {
    fontSize: 17,
    marginTop: 7,
    marginLeft: 10,
    textDecoration: 'underline',
    fontFamily: 'BeyondInfinity',
  },
  signatureYangSigned: {
    fontSize: 17,
    marginTop: 7,
    marginLeft: 10,
    textDecoration: 'underline',
    fontFamily: 'BritishShorthair',
  },
  pcrTitle: {
    fontSize: 14,
    paddingLeft: theme.spacing.md,
    paddingVertical: theme.spacing.xs,
  },
  bottom: {
    position: 'absolute',
    bottom: 30,
    width: '100%',
    marginLeft: theme.spacing.xg,
  },
})

const renderResult = result => {
  if (result === 'Negative') {
    return <Text style={styles.titleClinicalNotDetected}>NOT DETECTED</Text>
  } else if (result === 'Positive') {
    return <Text style={styles.titleClinicalDetected}>DETECTED</Text>
  } else {
    return <Text style={styles.titleClinical}>INVALID</Text>
  }
}

const renderFlag = result => {
  if (result === 'Positive') {
    return <Text style={styles.titleClinicalCritical}>Critical</Text>
  }
}

const techInitials = enteredName => {
  var name = enteredName?.split(' ')

  if (name) {
    if (name.length >= 2) {
      return `${name[0].charAt(0).toUpperCase()}${name[1]
        .charAt(0)
        .toUpperCase()}`
    } else if (name[0] !== null && name[0] !== '') {
      return `${name[0].charAt(0).toUpperCase()}`
    }
  }
  return ''
}

const PDFResult = ({ data }) => (
  <Document>
    <Page size="LETTER" style={styles.page} wrap>
      <View style={styles.header} fixed>
        <Image src={Logo} style={styles.logo} />
        <Text style={styles.title}>COVID-19 Report</Text>
      </View>
      <View style={styles.sectionTitleView} fixed>
        <Text style={styles.sectionTitle} fixed>
          PATIENT & PHYSICIAN INFORMATION
        </Text>
      </View>
      <View style={styles.sectionInformation} fixed>
        <View style={styles.leftItems}>
          <Text style={styles.items}>Record ID#: {data.confirmationCode}</Text>
          <Text style={styles.items}>Patient: {data.fullName}</Text>
          <Text style={styles.items}>Sex: {data.sexAtBirth}</Text>
          <Text style={styles.items}>
            DOB: {formatDateOfBirth(data.dateOfBirth)}
          </Text>
          <Text style={styles.items}>Phone: {data.phoneNumber}</Text>
        </View>
        <View style={styles.middleItems}>
          <Text style={styles.items}>
            Facility:{' '}
            {data.providerFacilityName ||
              'Sterling Pathology National Laboratories'}
          </Text>
          <Text style={styles.items}>
            Physician:{' '}
            {`${data.providerFirstName || 'Changgao'} ${
              data.providerMiddleName || 'Yang,'
            } ${data.providerLastName || 'MD'}`}
          </Text>
          <Text style={styles.items}>
            Address:{' '}
            {`${data.providerAddress || '3030 Old Ranch Pkwy'}${
              data.providerCity ? ` ${data.providerCity}` : ' #430'
            }${
              data.providerAddress || data.providerCity ? ', ' : ', Seal Beach '
            }${data.providerState || 'CA '} ${data.providerZip || '90740'}`}
          </Text>
          <Text style={styles.items}>
            Phone: {data.providerPhone || '(562) 799-8917'}
          </Text>
          <Text style={styles.items}>
            NPI: {data.providerNPI || '1366435638'}
          </Text>
        </View>
        <View style={styles.rightItems}>
          <Text style={styles.items}>Barcode: {data.barcode}</Text>
          <Text style={styles.items}>
            Collected:{' '}
            {!!data?.slotsDateTime &&
              format(parseISO(data?.slotsDateTime), 'MM/dd/yyyy hh:mm a') +
                ' PST'}
          </Text>
          <Text style={styles.items}>
            Received:{' '}
            {!!data?.status?.receivedOn &&
              format(parseISO(data?.status?.receivedOn), 'MM/dd/yyyy hh:mm a') +
                ' PST'}
          </Text>
          <Text style={styles.items}>
            Reported:{' '}
            {!!data?.resultEnteredDate &&
              format(parseISO(data?.resultEnteredDate), 'MM/dd/yyyy hh:mm a') +
                ' PST'}
          </Text>
        </View>
      </View>
      <View style={styles.sectionTitleView}>
        <Text style={styles.sectionTitle}>DIAGNOSIS</Text>
      </View>
      <View style={styles.sectionDiagnosis}>
        <View style={styles.dRowTop}>
          <View style={styles.dColTitle1}>
            <Text style={styles.dColHeadingFirst}>COVID-19 PCR</Text>
          </View>
          <View style={styles.dColResult}>
            <Text style={styles.dColHeading}>Result</Text>
          </View>
          <View style={styles.dColFlag}>
            <Text style={styles.dColHeading}>Flag</Text>
          </View>
          <View style={styles.dColRef}>
            <Text style={styles.dColHeading}>Reference Range</Text>
          </View>
        </View>
        <View style={styles.dRow}>
          <View style={styles.dColTitle}>
            <Text style={styles.dTitle}>SARS-CoV-2</Text>
            <Text style={styles.dTitle2}>(Real-Time RT PCR)</Text>
          </View>
          <View style={styles.dColResult}>
            <Text style={styles.dResult}>{renderResult(data.result)}</Text>
          </View>
          <View style={styles.dColFlag}>
            <Text style={styles.dFlag}>{renderFlag(data.result)}</Text>
          </View>
          <View style={styles.dColRef}>
            <Text style={styles.titleClinical}></Text>
          </View>
        </View>
        {!!data.notes && (
          <View style={styles.dRowText}>
            <Text style={styles.dResultText}>
              Notes from Tech: {data.notes}
            </Text>
          </View>
        )}
      </View>
      <View style={styles.finePrint}>
        <View style={styles.finePrintSectionTitleView}>
          <Text style={styles.finePrintSectionTitle}>Principle</Text>
        </View>
        <View style={styles.dRow}>
          <Text style={styles.finePrintDResultText}>
            Test results are for the identification of SARS-CoV-2 RNA. The
            SARS-CoV-2 RNA is generally detectable in respiratory specimens
            during the acute phase of infection. Positive results are indicative
            of the presence of SARS-CoV-2 RNA; clinical correlation with patient
            history and other diagnostic information is necessary to determine
            patient infection status. Positive results do not rule out bacterial
            infection or co-infection with other viruses. The agent detected may
            not be the definite cause of disease. Laboratories within the United
            States and its territories are required to report all positive
            results to the appropriate public health authorities. Negative
            results do not preclude SARS-CoV-2 infection and should not be used
            as the sole basis for patient management decisions. Negative results
            must be combined with clinical observations, patient history, and
            epidemiological information.
          </Text>
        </View>
      </View>

      <View style={styles.finePrint}>
        <View style={styles.finePrintSectionTitleView}>
          <Text style={styles.finePrintSectionTitle}>Methodology</Text>
        </View>
        <View style={styles.dRow}>
          <Text style={styles.finePrintDResultText}>
            The Real-Time Fluorescent RT-PCR Kit for Detecting SARS-CoV-2 is an
            in vitro diagnostic real- time reverse transcription-PCR assay for
            the qualitative detection of SARS-CoV-2 nucleic acids in throat
            (oropharyngeal) swabs, nasopharyngeal swabs, anterior nasal swabs,
            mid-turbinate nasal swabs, nasal washes, nasal aspirates and
            bronchoalveolar lavage fluid (BALF) from individuals who are
            suspected of COVID-19 by their healthcare provider. The Real-Time
            Fluorescent RT-PCR Kit for Detecting SARS-CoV-2 is only for use
            under the Food and Drug Administration’s Emergency Use
            Authorization. Improper collection, transport, or storage of
            specimens may impact the ability of the assay to perform as
            indicated. False positive or false negative results may arise from:
            Inadequate sample collection, storage and transport and resulting in
            degradation of the SARS-CoV-2 RNA, the presence of RT-PCR
            inhibitors, Mutation in the SARS-CoV-2 virus, or improper laboratory
            processing. Testing is limited to laboratories certified under the
            Clinical Laboratory Improvement Amendments of 1988 (CLIA), 42 USC
            §263a, to perform high complexity tests.
          </Text>
        </View>
      </View>
      <Text style={styles.signatureResultEntered}>
        Tech Initials: {techInitials(data.resultEnteredByName)}
      </Text>
      <Text style={styles.signatureYang}>
        Signing Doctor: Changgao Yang, M.D., Ph.D
      </Text>
      <Text style={styles.signatureYangSigned}>Changgao Yang</Text>
      <View style={styles.bottom}>
        <View style={styles.footerTitleView}>
          <Text style={styles.footerTitle}>EXCELLENCE IN PATHOLOGY</Text>
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerLeft}>
            <Text style={styles.itemsFooter}>CLIA 05D1012129</Text>
            <Text style={styles.itemsFooter}>
              Sterling Pathology National Laboratories
            </Text>
            <Text style={styles.itemsFooter}>
              Medical Director: Changgao Yang, M.D., PhD
            </Text>
            <Text style={styles.itemsFooter}>
              3030 Old Ranch Parkway, Suite 430, Seal Beach, CA 90740
            </Text>
          </View>
          <View style={styles.footerRight}>
            <Text style={styles.itemsFooter}>CAP 7183962</Text>
            <View style={styles.footerRightItems}>
              <Text style={styles.itemsFooter}>Phone:</Text>
              <Text style={styles.itemsFooter}>1-562-799-8900</Text>
            </View>
            <View style={styles.footerRightItems}>
              <Text style={styles.itemsFooter}>Toll Free:</Text>
              <Text style={styles.itemsFooter}>1-800-899-8480</Text>
            </View>
            <View style={styles.footerRightItems}>
              <Text style={styles.itemsFooter}>Fax:</Text>
              <Text style={styles.itemsFooter}>1-562-799-8901</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
)

export default PDFResult
