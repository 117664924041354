export async function parseResultsForBatches(
  data,
  fluorIndex,
  sampleIndex,
  cqIndex,
) {
  const positive = data
    .filter(
      record =>
        record[fluorIndex] === 'FAM' &&
        record[cqIndex] &&
        record[sampleIndex] !== 'pos' &&
        record[sampleIndex] !== 'neg',
    )
    .map(well => {
      return well[0]
    })

  const empty = data
    .filter(
      record =>
        record[fluorIndex] === 'FAM' &&
        !record[cqIndex] &&
        record[sampleIndex] !== 'pos' &&
        record[sampleIndex] !== 'neg',
    )
    .map(well => {
      return well[0]
    })

  const invalid = data
    .filter(
      record =>
        record[fluorIndex] === 'HEX' &&
        !record[cqIndex] &&
        record[sampleIndex] !== 'pos' &&
        record[sampleIndex] !== 'neg',
    )
    .map(well => {
      if (empty.includes(well[0])) {
        return well[0]
      }
    })

  const negative = empty.filter(well => {
    if (!invalid.includes(well)) {
      return well
    }
  })

  return { positive, negative, invalid }
}
