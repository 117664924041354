import { Space as DefaultSpace } from 'antd'
import styled from 'styled-components'

export const DemographicsContentSpace = styled(DefaultSpace).attrs(props => ({
  direction: props.direction || 'vertical',
}))`
  display: flex;
  justify-content: center;
  .ant-space-item > * {
    margin-bottom: ${props => props.theme.spacing.sm} !important;
  }
`
