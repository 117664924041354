import styled from 'styled-components'
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui'

const { TimeTableCell: DefaultTimeTableCell } = WeekView

const TimeTableCell = styled(DefaultTimeTableCell)`
  height: 24px !important;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[200]} !important;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.gray[300]} !important;
  }
`

export default TimeTableCell
