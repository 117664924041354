import React from 'react'
import styled from 'styled-components'
import routes from '../config/routes'
import appLogo from '../assets/imgs/header-logo.svg'
import { APP_NAME } from '../constants'

const MainHeader = styled.header`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const defaultSpaceStyle = {
  paddingLeft: '65%',
}
export default function Header() {
  return (
    <MainHeader>
      <a href={routes.home}>
        <img src={appLogo} alt={APP_NAME} width={252} height={48} />
      </a>
    </MainHeader>
  )
}
