import { successNotification } from '../components/dashboard/ui-notification'
import { handleError } from './error-utils'
import EmailAuthService from '../services/email-authentication-service'

export async function sendEmailLogin() {
  try {
    await EmailAuthService.sendLink()
    successNotification({
      message: 'The Email with a link was sent successfully!',
    })
  } catch (error) {
    handleError(error, 'Error sending email')
    throw error
  }
}
