import XLSX from 'xlsx'
import AppointmentService from '../services/appointments-service'
import StorageService from '../services/storage-service'
import { successNotification } from '../components/dashboard/ui-notification'
import { handleError } from '../utils/error-utils'

export async function importExcel(file, batchDate, batchNumber) {
  try {
    StorageService.uploadFile(file, batchDate, batchNumber)
    const fileReader = new FileReader()
    fileReader.onload = event => {
      const { result } = event.target
      const wb = XLSX.read(result, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
      AppointmentService.updateResults({ data, batchDate, batchNumber })
      successNotification({
        message: 'Uploaded successfully!',
      })
    }
    fileReader.readAsBinaryString(file)
  } catch (error) {
    handleError(error, 'File upload error!')
  }
}

export async function importAutomatedExcel(file, batchDate, batchNumber) {
  try {
    await StorageService.uploadAutomatedResultFile(file, batchDate, batchNumber)
    const fileReader = new FileReader()
    fileReader.onload = async event => {
      const { result } = event.target
      const wb = XLSX.read(result, { type: 'binary' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
      await AppointmentService.updateAutomatedResults({
        data,
        batchDate,
        batchNumber,
      })
    }
    successNotification({
      message: 'Uploaded successfully!',
    })
    fileReader.readAsBinaryString(file)
  } catch (error) {
    handleError(error, 'File upload error!')
  }
}

export function checkConfirmationResult({ confirmationResult }) {
  return confirmationResult === 'Positive' || confirmationResult === 'Invalid'
}

export async function updateConfirmationResult(values, appointmentId) {
  const confirmationResult = checkConfirmationResult(values)
  const result = values.confirmationResult
  try {
    await AppointmentService.update(appointmentId, {
      confirmationResult,
      result,
    })
  } catch (error) {
    console.log(error)
    handleError(error, 'Error updating result confirmation')
  }
}
