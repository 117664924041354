import React, { useContext, useState, useEffect } from 'react'
import TableActionsDropdown from './table-actions-dropdown'
import { Button } from 'antd'
import resultService from '../../services/result-service'
import { UserContext } from '../../contexts/user-context'
import EditResultModal from '../../components/dashboard/edit-result-test-groups'
import { format } from 'date-fns'
import AppointmentsService from '../../services/appointments-service'
import { errorNotification } from './ui-notification'

export default function EditableTestGroupsAction({ row }) {
  const { role } = useContext(UserContext)
  const [editData, setEditData] = useState({ visible: false, data: {} })

  const handleCloseEdit = () => {
    setEditData({ visible: false })
  }
  const setEditedInfo = async values => {
    try {
      await delete values.barcodeConfirmation
      let apptID = row.id
      await AppointmentsService.get(apptID, response => {
        if (!response) {
          return errorNotification({
            message: 'Appointment not found',
          })
        }
      })
      let dateOfBirth = values?.dateOfBirth.replace(new RegExp('/', 'g'), '')
      const formattedValues = { ...values, dateOfBirth: dateOfBirth }
      return resultService.updateEditedResults(apptID, formattedValues)
    } catch (error) {
      return errorNotification({
        message: 'Appointment could not be updated',
      })
    }
  }

  return (
    <>
      <TableActionsDropdown
        style={{ fontSize: '30px' }}
        key="actionGroup"
        menus={[
          (role === 'doctor' || role === 'organization' || 'admin') && {
            key: `${row.barcode}-edit`,
            name: (
              <Button
                key="edit"
                type="link"
                onClick={() => {
                  setEditData({ visible: true, data: row })
                }}
              >
                Edit Info
              </Button>
            ),
          },
        ]}
      />
      <EditResultModal
        isModalVisible={editData.visible}
        onClickClose={handleCloseEdit}
        data={{
          ...row,
          dateOfBirth: format(new Date(row?.dateOfBirth), 'MMddyyyy'),
          barcodeConfirmation: row?.barcode,
        }}
        setEditedInfo={setEditedInfo}
      />
    </>
  )
}
