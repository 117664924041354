import React, { useRef, useState } from 'react'
import { Typography, Input, Form, Button, Modal } from 'antd'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import AppointmentService from '../../services/appointments-service'
import routes from '../../config/routes'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import LinkButton from '../../components/link-button'
import Spin from '../../components/main-spin'
import SocialSecurityMasked from '../../components/social-security-masked'
import { removeMask } from '../../utils/date-utils'
import { getSocialSecurityNumberMasked } from '../../utils/driver-license-utils'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import useQueryParam from '../../hooks/use-query-param'
import { DemographicTooltip } from '../../components/demographic-tooltip'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import HasNoInsuranceModal from '../../components/no-insurance-modal'

const { Text, Title } = Typography
const { confirm } = HasNoInsuranceModal

function DriverLicense({ location }) {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [socialSecurityNumberNoMask, setSocialSecurityNumberNoMask] = useState()
  const [form] = Form.useForm()
  const history = useHistory()
  const isEditable = useQueryParam('isEdit') === 'true'
  const inputMaskRef = useRef(null)
  const { t } = useTranslation()
  const [isUnable, setIsUnable] = useState(false)

  const onFinish = async ({ driverLicenseNumber, socialSecurityNumber }) => {
    const noMaskSSN = removeMask(socialSecurityNumber)
    const SSN = !noMaskSSN.length ? null : socialSecurityNumberNoMask
    try {
      await AppointmentService.update(appointmentId, {
        driverLicenseNumber,
        socialSecurityNumber: SSN,
      })
      history.push(routes.bookingConfirmation)
    } catch (error) {
      handleError(error, 'Error saving data')
    }
  }

  const onChange = event => {
    const value = removeMask(event.target.value)
    const counter = value?.length
    const lastNumber = socialSecurityNumberNoMask
      ? socialSecurityNumberNoMask
      : ''
    const currentNumber = value?.substr(counter - 1, counter)
    if (
      value?.length > socialSecurityNumberNoMask?.length ||
      !socialSecurityNumberNoMask
    ) {
      setSocialSecurityNumberNoMask(lastNumber + currentNumber)
    }
    if (value?.length < socialSecurityNumberNoMask?.length) {
      setSocialSecurityNumberNoMask(lastNumber.slice(0, -1))
    }
    if (value?.length < 6) {
      form.setFieldsValue({
        socialSecurityNumber: event.target.value.replace(/[0-9]/g, '*'),
      })
    }
  }

  function changeDriverLicense() {
    const noMaskSSN = removeMask(form.getFieldValue('socialSecurityNumber'))
    if (form.getFieldValue('driverLicenseNumber') === '')
      form.setFieldValue({ driverLicenseNumber: undefined })

    setIsUnable(
      !(
        hasFormErrors(form) ||
        !form.getFieldValue('driverLicenseNumber') ||
        (noMaskSSN.length > 0 && noMaskSSN.length < 9)
      ),
    )
  }

  const getBackButtonUrl = () => {
    const urlState = location.state

    if (isEditable) {
      return routes.bookingConfirmation
    }
    if (urlState?.previousPath) {
      return urlState.previousPath
    }
    return routes.hasInsurance
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  } else {
    if (!socialSecurityNumberNoMask && appointment?.socialSecurityNumber) {
      setSocialSecurityNumberNoMask(
        appointment?.socialSecurityNumber.replace('-', ''),
      )
    }
  }

  function showConfirm() {
    confirm({
      title: 'Confirm: No Driver License and Social Security Number',
      icon: <ExclamationCircleOutlined />,
      content:
        'By clicking on OK button you confirm: I swear that I do not currently possess a Government ID or Social Security card',
      onOk() {
        setIsUnable(true)
      },
      onCancel() {},
      cancelButtonProps: {
        ghost: true,
        disabled: true,
        style: { display: 'none' },
      },
    })
  }

  return (
    <ContentSpace>
      <PageTitle>{t('driversLicence.title')}</PageTitle>
      <Title level={5}>
        {t('demographics.expand')} <DemographicTooltip large />
      </Title>

      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        initialValues={{
          ...appointment,
          socialSecurityNumber: getSocialSecurityNumberMasked(
            appointment?.socialSecurityNumber,
          ),
        }}
      >
        <Form.Item
          name="driverLicenseNumber"
          label={t('driversLicence.licenceNumber')}
        >
          <Input placeholder="#####" onChange={changeDriverLicense} />
        </Form.Item>
        <Form.Item
          name="socialSecurityNumber"
          label={t('driversLicence.ssNumber')}
        >
          <SocialSecurityMasked
            onChange={onChange}
            value={socialSecurityNumberNoMask}
            reference={inputMaskRef}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="link" onClick={showConfirm} style={{ borderWidth: 0 }}>
            {t('driversLicence.unable')}
          </Button>
        </Form.Item>
        <Form.Item>
          <ActionButtons
            isNextButtonDisabled={!isUnable}
            backButtonUrl={getBackButtonUrl()}
          />
        </Form.Item>
      </Form>
    </ContentSpace>
  )
}

export default DriverLicense
