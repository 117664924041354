import React, { useContext } from 'react'

import { UserContext } from '../contexts/user-context'
import { withRoles } from './roles-hoc'

const checkModes = {
  required: (compare, compareWith) =>
    compare.every(item => compareWith === item),
  oneOf: (compare, compareWith) => compare.some(item => compareWith === item),
}

export const checkRoles = (compare, compareWith, mode) => {
  return Array.isArray(compare)
    ? checkModes[mode]
      ? checkModes[mode](compare, compareWith)
      : checkModes['required'](compare, compareWith)
    : compareWith === compare
}

const requireRoles = (
  requiredRoles,
  Substitute,
  mode = 'required',
) => WrappedComponent =>
  withRoles(({ ...props }) => {
    const { role } = useContext(UserContext)
    return (
      <>
        {checkRoles(requiredRoles, role, mode) ? (
          <WrappedComponent {...props} />
        ) : Substitute ? (
          <Substitute />
        ) : null}
      </>
    )
  })

export default requireRoles
