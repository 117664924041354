import React, { useContext } from 'react'
import { Card, Typography, Button } from 'antd'
import { CopyTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import LinkButton from '../../components/link-button'
import ActionButtons from '../../components/action-buttons'
import { CardLocation } from '../../components/card-location'
import { CardTimeSlot } from '../../components/card-time-slot'
import { CardPersonalInfo } from '../../components/card-personal-info'
import { successNotification } from '../../components/dashboard/ui-notification'
import { handleError } from '../../utils/error-utils'
import { parseAppointment } from '../../utils/appointment-utils'
import Spin from '../../components/main-spin'
import { useAppointmentContext } from '../../contexts/appointment-context'
import useLocations from '../../hooks/use-locations'
import { PDFDownloadLink } from '@react-pdf/renderer'
import ContainerInline from '../../components/container-inline'
import BookingConfirmationPDF from './booking-confirmation-pdf'
import { ThemeContext } from 'styled-components'

const { Text, Title } = Typography

function BookingSuccess() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const { location, isLocationLoading } = useLocations(appointment?.locationId)
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)

  const printAppointment = () => {
    window.print()
  }

  const copyAppointmentId = () => {
    const { confirmationCode } = appointment
    navigator.clipboard.writeText(confirmationCode).then(
      function () {
        successNotification({
          message: `${t('messages.succesCopy')}`,
        })
      },
      function (err) {
        handleError(err, `${t('messages.errorCopy')}`)
      },
    )
  }

  const renderDownloadButton = () => (
    <PDFDownloadLink
      document={
        <BookingConfirmationPDF
          appointment={appointment}
          location={location}
          title={t('bookingSuccess.title')}
        />
      }
      fileName="confirmation.pdf"
      style={{ marginRight: theme.spacing.lg, height: 40 }}
    >
      {() => <Button size="large">Download</Button>}
    </PDFDownloadLink>
  )

  if (
    isAppointmentLoading ||
    isAppointmentLoading === undefined ||
    isLocationLoading ||
    isLocationLoading === undefined
  ) {
    return <Spin />
  }

  const { timeSlot, personalInfo, drivethrough } =
    appointment &&
    location &&
    parseAppointment(appointmentId, appointment, location)

  return (
    <ContentSpace>
      <PageTitle>{t('bookingSuccess.title')}</PageTitle>
      <ContainerInline>
        {renderDownloadButton()}
        <ActionButtons
          nextButtonAction={printAppointment}
          nextButtonTitle={t('general.print')}
          hideBackButton
        />
      </ContainerInline>

      <Card
        title={t('general.confirmation')}
        extra={
          <LinkButton type="link" onClick={() => copyAppointmentId()}>
            <CopyTwoTone />
            {t('bookingSuccess.copy')}
          </LinkButton>
        }
      >
        <Title level={1}>{appointment.confirmationCode}</Title>
      </Card>
      <LinkButton type="link" style={{ paddingLeft: 0 }}>
        <a target="_blank" href="https://youtu.be/Kdp2M2jDEoM">
          <Title level={3} type="primary">
            {t('bookingSuccess.instructionsVideo')}
          </Title>
        </a>
      </LinkButton>
      <Title level={3} type="primary">
        {t('bookingSuccess.appointmentDetails')}
      </Title>

      {appointment && (
        <>
          <CardLocation data={drivethrough} isEditable={false} />
          <CardTimeSlot data={timeSlot} isEditable={false} />
          <CardPersonalInfo data={personalInfo} isEditable={false} />
        </>
      )}
      <Text>
        {t('bookingSuccess.moreInfo')}
        <br />
        <br />
        <LinkButton type="link" style={{ paddingLeft: 0 }}>
          <a
            target="_blank"
            href="https://www.cdc.gov/coronavirus/2019-ncov/index.html"
          >
            {t('bookingSuccess.cdcInfo')}
          </a>
        </LinkButton>
      </Text>
    </ContentSpace>
  )
}

export default BookingSuccess
