import React, { useState, useEffect } from 'react'
import TableActionsDropdown from './table-actions-dropdown'
import { Button } from 'antd'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PDFResult from '../../pages/dashboard/pdf-result'

export default function PatientLookupActions({
  row,
  setAppointmentInfo,
  setVisible,
}) {
  const [editedRow, setEditedRow] = useState()
  useEffect(() => {
    setEditedRow(row)
  }, [row])
  return (
    <TableActionsDropdown
      key="actionGroup"
      menus={[
        {
          key: 'view',
          name: (
            <Button
              key="viewButton"
              type="link"
              onClick={() => {
                setAppointmentInfo(row)
                setVisible(true)
              }}
            >
              View Info
            </Button>
          ),
        },
        {
          key: 'download',
          name: (
            <PDFDownloadLink
              document={<PDFResult data={editedRow} />}
              fileName={`Patient's ${row.firstName} ${row.lastName} - Covid19 Test Result ${row.resultEnteredDate}.pdf`}
            >
              <Button
                type="link"
                disabled={!row.result || row.result === 'Pending'}
              >
                Download Result
              </Button>
            </PDFDownloadLink>
          ),
        },
      ]}
    />
  )
}
