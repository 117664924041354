import { notification } from 'antd'

const displayNotification = ({ type, message, description }) => {
  return notification[type]({
    message,
    description,
  })
}

export const successNotification = ({ message, description }) => {
  displayNotification({ type: 'success', message, description })
}

export const infoNotification = ({ message, description }) => {
  displayNotification({ type: 'info', message, description })
}

export const warningNotification = ({ message, description }) => {
  displayNotification({ type: 'warning', message, description })
}

export const errorNotification = ({ message, description }) => {
  displayNotification({
    type: 'error',
    message: message && message.length > 0 ? message : 'An error has occurred!',
    description,
  })
}
