import React, { useContext } from 'react'
import { Modal, Input, Form } from 'antd'
import ProForm from '@ant-design/pro-form'
import { CloseOutlined } from '@ant-design/icons'
import ProfilesService from '../../services/profiles-service'
import { UserContext } from '../../contexts/user-context'
import { errorNotification } from '../../components/dashboard/ui-notification'

export default function ConfirmPin({ isModalVisible, onClickClose, onReady }) {
  const [formInstance] = ProForm.useForm()
  const { user } = useContext(UserContext)

  const handleFormSubmit = async ({ pin }) => {
    try {
      //If someway it pass the validator and get null here
      if (!pin) {
        return errorNotification({
          message: 'Please enter a pin number',
        })
      }

      //Retrieving profile data
      const profile = await ProfilesService.get(user.uid)
      if (!profile?.pin) {
        return errorNotification({
          message: "Your profile doesn't contain a pin associated",
        })
      }

      let pinMatches = profile?.pin === pin

      //Double check if matches then pass it to main screen
      onReady(!!pinMatches)
      handleFormReset()
      onClickClose()
    } catch (error) {
      //If any error occur , just return that gone wrong
      onReady(false)
      onClickClose()
    }
  }

  const handleFormReset = () => {
    formInstance.resetFields()
  }

  return (
    <Modal
      title="Confirm your pin to finalize specimens"
      visible={isModalVisible}
      centered
      closeIcon={<CloseOutlined onClick={onClickClose} />}
      destroyOnClose
      footer={false}
    >
      <ProForm form={formInstance} onFinish={handleFormSubmit}>
        <Form.Item
          name="pin"
          rules={[
            ({ getFieldValue }) => ({
              required: getFieldValue('pin') === '',
              message: 'Please confirm your pin!',
            }),
            {
              validator(_, value) {
                if (!!value) {
                  return Promise.resolve()
                }
                return Promise.reject('You must input a pin to check in')
              },
            },
          ]}
        >
          <Input
            name="pin"
            placeholder="Enter pin"
            width="100%"
            autoFocus
            hasFeedback
          />
        </Form.Item>
        <Form.Item
          name="pinConfirmation"
          dependencies={['pin']}
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              required: getFieldValue('pin') === '',
              message: 'Please confirm your pin!',
            }),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('pin') === value) {
                  return Promise.resolve()
                }
                return Promise.reject('The two pins do not match!')
              },
            }),
          ]}
        >
          <Input
            name="pinConfirmation"
            placeholder="Re-enter pin"
            width="100%"
          />
        </Form.Item>
      </ProForm>
    </Modal>
  )
}
