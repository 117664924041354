import { FirebaseService, firebase } from './firebase-service'
import { paths } from './paths'
import { v4 as uuidv4 } from 'uuid'

class StorageService extends FirebaseService {
  constructor() {
    super(paths.storage)

    this.updateAutomatedBatchesFunction = firebase
      .functions()
      .httpsCallable('default-updateAutomatedBatches')
    this.uploadTestGroupFileFunction = firebase
      .functions()
      .httpsCallable('default-uploadTestGroupFile')
  }

  uploadFile(file, batchDate, batchNumber) {
    this.path.child(`${batchDate}/batch-${batchNumber}`).put(file)
  }
  uploadAutomatedResultFile(file, batchDate, batchNumber) {
    const uid = uuidv4()
    this.path
      .child(`automated-batch-${batchDate}/${uid}/result-batch-${batchNumber}`)
      .put(file)
  }

  async uploadAutomatedBatchesFile(file, batchDate, batchNumber) {
    const uid = uuidv4()
    const path = `${batchDate}-${batchNumber}/${uid}`
    const pathFile = `${batchDate}-${batchNumber}/${uid}/${file.name}`
    try {
      const uploadTask = await this.path.child(pathFile).put(file)

      const url = await uploadTask.ref.getDownloadURL()

      await this.updateAutomatedBatchesFunction({
        batchDate,
        batchNumber,
        path,
      })
    } catch (error) {
      throw error
    }
  }

  async uploadTestGroupFile(file, groupId, user, doctorReferralCode, title) {
    const path = `testGroup/${groupId}`
    const pathFile = `testGroup/${groupId}/${file.name}`
    try {
      const uploadTask = await this.path.child(pathFile).put(file)

      const url = await uploadTask.ref.getDownloadURL()
      return await this.uploadTestGroupFileFunction({
        path,
        groupId,
        userUID: user.uid,
        userName: user.displayName,
        doctorReferralCode,
        title,
      })
    } catch (error) {
      throw error
    }
  }

  async uploadDriverLicenseAndInsuranceCard(file, id, type) {
    const pathFile = `${type}/${id}/${file.name}`
    const metadata = {
      contentType: 'image/png',
    }
    try {
      const uploadTask = await this.path.child(pathFile).put(file, metadata)
      const url = await uploadTask.ref.getDownloadURL()
      return url
    } catch (error) {
      throw error
    }
  }
}

export default new StorageService()
