import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import ProTable from '@ant-design/pro-table'
import { compareStrings } from '../../utils/text-utils'
import { PageContainer } from '@ant-design/pro-layout'
import { useParams } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import useSpecimens from '../../hooks/use-specimens-by-user'
import ProfilesService from '../../services/profiles-service'
import { useHistory } from 'react-router-dom'
import LocationService from '../../services/location-service'

function getTableColumns(isLocation) {
  return [
    !isLocation && {
      key: 'locationName',
      title: 'Location',
      dataIndex: 'locationName',
      sorter: {
        compare: (a, b) => compareStrings(a.locationName, b.locationName),
        multiple: 4,
      },
    },
    {
      key: 'barcode',
      title: 'Barcode',
      dataIndex: 'barcode',
      sorter: {
        compare: (a, b) => compareStrings(a.barcode, b.barcode),
        multiple: 3,
      },
    },
    {
      key: 'confirmation',
      title: 'Confirmation',
      dataIndex: 'confirmation',
      renderText: date => {
        return date && format(parseISO(date), 'MM/dd/yyyy')
      },
    },
  ]
}

export default props => {
  const { userId } = useParams()
  const [extraInfo, setExtraInfo] = useState({})
  const { isLocation } = props.location.state
  const history = useHistory()

  const handleToBack = () => {
    history.goBack()
  }

  useEffect(() => {
    async function fetchData() {
      const info = isLocation
        ? await LocationService.getLocationNoCallback(userId)
        : await ProfilesService.get(userId)
      setExtraInfo(info)
    }
    fetchData()
  }, [userId, isLocation])

  const { appointmentsSpecimen, isLoading } = useSpecimens(
    !isLocation && userId,
    !isLocation && extraInfo?.doctorReferralCode,
    userId,
    true,
  )
  return (
    <PageContainer
      title={`${
        isLocation ? extraInfo?.title ?? '--' : extraInfo?.fullName ?? '--'
      }'s - Total Specimens ${appointmentsSpecimen?.length ?? '--'}`}
      extra={
        <Button type="primary" onClick={handleToBack}>
          Back
        </Button>
      }
    >
      <ProTable
        columns={getTableColumns(isLocation)}
        dataSource={appointmentsSpecimen}
        loading={isLoading}
        options={false}
        search={false}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        dateFormatter="string"
        rowKey="key"
        headerTitle={``}
      />
    </PageContainer>
  )
}
