import { FirebaseService } from './firebase-service'
import { paths } from './paths'

class ProfilesService extends FirebaseService {
  constructor() {
    super(paths.profiles)
  }

  async get(uid) {
    try {
      const profile = await this.path.child(uid).once('value')
      return profile.val()
    } catch (error) {
      throw error
    }
  }

  async getDoctor(referralCode, callback) {
    try {
      const profile = await this.path
        .orderByChild('doctorReferralCode')
        .equalTo(+referralCode)
        .once('value')

      profile.forEach(doctor => {
        callback(doctor.val())
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  observeProfilesDoctor(callback) {
    try {
      this.path
        .orderByChild('doctorReferralCode')
        .startAt(1)
        .on('value', snapshot => {
          const profiles = this.formatSnapshot(snapshot)
          callback(profiles)
        })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  formatSnapshot = snapshot => {
    const profiles = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()
      profiles.push({
        ...values,
        doctorReferralCode: `${values.doctorReferralCode}`,
        key: childSnapshot.key,
      })
    })

    return profiles
  }

  unsubscribeProfiles() {
    this.path.off()
  }

  observeDoctorByName(callback) {
    try {
      this.path
        .orderByChild('fullName')
        .startAt(1)
        .on('value', snapshot => {
          const profiles = this.formatSnapshot(snapshot)
          callback(profiles)
        })
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

export default new ProfilesService()
