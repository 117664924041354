import { successNotification } from '../components/dashboard/ui-notification'
import LocationService from '../services/location-service'
import ProfilesService from '../services/profiles-service'
import { handleError } from './error-utils'

export async function createLocation(location, user) {
  try {
    const profile = await ProfilesService.get(user.uid)
    await LocationService.create(location, profile)
    successNotification({
      message: 'Location created successfully!',
    })
  } catch (error) {
    handleError(error, 'Error creating location')
  }
}

export async function updateLocation(locationId, location) {
  try {
    await LocationService.update(locationId, location)
    successNotification({
      message: 'Location updated successfully!',
    })
  } catch (error) {
    handleError(error, 'Error updating location')
  }
}

export async function deleteLocation(locationId) {
  try {
    await LocationService.delete(locationId)
    successNotification({
      message: 'Location deleted successfully!',
    })
  } catch (error) {
    handleError(error, 'Error deleting location')
  }
}
