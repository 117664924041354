import React from 'react'
import { Checkbox, Typography, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/page-title'
import routes from '../../config/routes'
import ContentSpace from '../../components/content-space'
import ActionButtons from '../../components/action-buttons'
import { useHistory } from 'react-router-dom'
import {
  getAppointmentFromStorage,
  setAppointmentOnStorage,
} from '../../utils/appointment-utils'
import { hasFormErrors } from '../../utils/error-utils'

const { Text } = Typography
const { Group } = Checkbox

export default function Symptoms() {
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()
  const appointment = getAppointmentFromStorage()
  const symptonsList = t('SYMPTOMS_LIST', { returnObjects: true })

  const handleChange = chekedValues => {
    const lastListIndex = chekedValues.length - 1

    if (chekedValues[lastListIndex] === 'noneAbove') {
      return form.setFieldsValue({ symptoms: ['noneAbove'] })
    }

    if (chekedValues[0] === 'noneAbove') {
      return form.setFieldsValue({
        symptoms: chekedValues.filter(item => item !== 'noneAbove'),
      })
    }
    return form.setFieldsValue({ symptoms: chekedValues })
  }

  const onFinish = ({ symptoms }) => {
    setAppointmentOnStorage({ ...appointment, symptoms })
    if (symptoms[0] === 'noneAbove') {
      history.push(routes.contact)
    } else {
      history.push(routes.symptomsDate)
    }
  }

  const isNextButtonDisabled = () => {
    return (
      hasFormErrors(form) ||
      !form.getFieldValue('symptoms') ||
      form.getFieldValue('symptoms')?.length < 1
    )
  }

  return (
    <ContentSpace>
      <PageTitle>{t('symptoms.title')}</PageTitle>
      <Text>{t('symptoms.subtitle')}</Text>

      <Form form={form} onFinish={onFinish} initialValues={appointment}>
        <Form.Item
          name="symptoms"
          rules={[{ required: true, message: `${t('messages.validSymptom')}` }]}
        >
          <Group onChange={handleChange}>
            <ContentSpace>
              {symptonsList.map(item => (
                <Checkbox key={item.key} value={item.key}>
                  {item.title}
                </Checkbox>
              ))}
            </ContentSpace>
          </Group>
        </Form.Item>
        <Form.Item>
          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            backButtonUrl={routes.priority}
          />
        </Form.Item>
      </Form>
    </ContentSpace>
  )
}
