import React, { useState } from 'react'
import { Form, Input, Space } from 'antd'
import { useHistory } from 'react-router-dom'

import routes from '../../config/routes'
import Spin from '../../components/main-spin'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import { useTranslation } from 'react-i18next'
import useProfiles from '../../hooks/use-profiles'
import { hasFormErrors, handleError } from '../../utils/error-utils'
import { setDoctorReferralCodeOnStorage } from '../../utils/appointment-utils'
import { useAppointmentContext } from '../../contexts/appointment-context'
import AppointmentService from '../../services/appointments-service'
import PageTitle from '../../components/page-title'
import ConfirmDoctor from '../../components/dashboard/confirm-doctor'
import LinkButton from '../../components/link-button'
import ProForm, { ProFormText } from '@ant-design/pro-form'
import { errorNotification } from '../../components/dashboard/ui-notification'

function DoctorReferralCode() {
  const { profiles } = useProfiles()
  const { appointmentId, isAppointmentLoading } = useAppointmentContext()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const [btnDisable, setBtnDisable] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [inputCode, setInputCode] = useState('')

  const onFinish = ({ doctorReferralCode }) => {
    try {
      if (!doctorReferralCode) {
        errorNotification({
          message: 'Invalid Code',
          description: 'Please enter a valid code',
        })
      }
      setIsVisible(!!doctorReferralCode)
    } catch (error) {
      handleError(error, 'Error saving data')
    }
  }

  const finalize = async ({ doctorReferralCode }) => {
    try {
      setDoctorReferralCodeOnStorage(doctorReferralCode)
      await AppointmentService.update(appointmentId, {
        doctorReferralCode: +doctorReferralCode,
      })
      history.push({
        pathname: routes.locationOptions,
        state: {
          doctorReferralCode,
        },
      })
    } catch (error) {
      handleError(error, 'Error saving data')
    }
  }
  const isNextButtonDisabled = () => {
    return hasFormErrors(form) || !btnDisable
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  const onContinue = () => {
    setIsVisible(true)
  }
  const onClickClose = () => {
    setIsVisible(false)
  }

  const onChange = event => {
    setInputCode(event.target.value)
  }

  return (
    <ContentSpace>
      <PageTitle>{t('doctorReferred.doctorReferralCode')}</PageTitle>
      <ProForm form={form} onFinish={onFinish}>
        <Form.Item name="doctorReferralCode">
          <Input
            name="doctorReferralCode"
            placeholder="Enter a valid doctor referral code"
            width="100%"
          />
        </Form.Item>
      </ProForm>

      <ConfirmDoctor
        finalize={finalize}
        isVisible={isVisible}
        form={form}
        profiles={profiles}
        onClickClose={onClickClose}
        doctorReferralCode={form.getFieldValue('doctorReferralCode')}
        setInputCode={setInputCode}
      />
    </ContentSpace>
  )
}

export default DoctorReferralCode
