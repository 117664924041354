import { useEffect, useState } from 'react'

import AutomatedBatchService from '../services/automated-batch-service'
import { FilteredBatchByDate } from '../utils/automated-batch-utils'
import { handleError } from '../utils/error-utils'

export default function useAutomatedBatche() {
  const [AutomatedBatches, setAutomatedBatchesBatches] = useState()
  const [isAutomatedBatchesLoading, setIsAutomatedBatchesLoading] = useState(
    false,
  )
  const [showAllBatches, setShowAllBatches] = useState(false)

  useEffect(() => {
    try {
      setIsAutomatedBatchesLoading(true)
      AutomatedBatchService.observeBatches(response => {
        const batchesList = showAllBatches
          ? response
          : FilteredBatchByDate(response)
        setAutomatedBatchesBatches(batchesList)
        setIsAutomatedBatchesLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving batch')
      setIsAutomatedBatchesLoading(false)
    }

    return () => {
      AutomatedBatchService.unsubscribeBatch()
    }
  }, [showAllBatches])

  return {
    AutomatedBatches,
    isAutomatedBatchesLoading,
    showAllBatches,
    setShowAllBatches,
  }
}
