import { firebase } from './firebase-service'

const db = firebase.database()

const paths = {
  locations: db.ref('locations'),
  users: db.ref('users'),
  automatedBatches: db.ref('automated-batches'),
  appointments: db.ref('appointments'),
  locationsSchedule: db.ref('locations-schedule'),
  batches: db.ref('batches'),
  testGroups: db.ref('test-groups'),
  people: db.ref('people'),
  profiles: db.ref('profiles'),
  storage: firebase.storage().ref(),
}

export { paths }
