import TestGroupService from '../services/test-group-service'
import AppointmentService from '../services/appointments-service'
import routes from '../config/routes'
import { paths } from '../services/paths'
import { createAppointmentFromTestGroup } from './appointment-utils'

export async function createTestGroup(user, history, title = null) {
  const groupID = await getGroupId()
  await TestGroupService.create(groupID, user, title)
  history.push({
    pathname: routes.dashboard.testGroupAdd,
    state: {
      organizationId: user.uid,
      groupId: groupID,
    },
  })
}

export async function createTestGroupCSV(user, appointments, title = null) {
  const groupID = await getGroupId()
  await TestGroupService.create(groupID, user, title)

  appointments.map(appointment => {
    createAppointmentFromTestGroup({
      organizationId: user.uid,
      groupId: groupID,
      appointment: appointment,
      personId: appointment.key,
    })
  })
}

export async function getGroupId() {
  const testGroups = await paths.testGroups.once('value')
  if (testGroups.val()) {
    const testGroupArray = Object.values(testGroups.val())
    return getLastGroupBatchId(testGroupArray) + 1
  }
  return 1
}

function getLastGroupBatchId(testGroupArray) {
  return testGroupArray.reduce(
    (max, key) => (key.groupBatchId > +max ? key.groupBatchId : +max),
    testGroupArray[0].groupBatchId,
  )
}

export async function updateStatus(groupId, status) {
  await TestGroupService.update(groupId, { status })
}
