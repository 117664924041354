import styled from 'styled-components'
import { Input } from 'antd'

const { Search: SearchDefault } = Input

const SearchBox = styled(SearchDefault)`
  min-width: ${({ size }) => size};
  padding-right: 15px;
  & .ant-input-search-button {
    padding: 0 25px;
  }
`

export default SearchBox
