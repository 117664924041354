import { Checkbox as DefaultCheckbox } from 'antd'
import styled from 'styled-components'

const Checkbox = styled(DefaultCheckbox)`
  white-space: normal;
  &.ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: hidden;
  }
`
export default Checkbox
