import React, { useRef, useState } from 'react'
import { Button, Form } from 'antd'
import ProTable from '@ant-design/pro-table'
import ProForm from '@ant-design/pro-form'
import { Link, useHistory } from 'react-router-dom'

import AddLocationModal from '../../components/dashboard/add-location-modal'
import useLocations from '../../hooks/use-locations'
import { updateLocation } from '../../utils/location-utils'
import routes from '../../config/routes'
import TableActionsDropdown from '../../components/dashboard/table-actions-dropdown'
import SearchBox from '../../components/search-box'
import LocationContainer from '../../components/dashboard/location-container'

function getTableColumns(setModalSettings, history) {
  return [
    {
      key: 'title',
      title: (_, type) => (type === 'table' ? 'Location' : 'Search Locations'),
      dataIndex: 'title',
      sorter: {
        compare: (a, b) => a.title.localeCompare(b.title),
        multiple: 1,
      },
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, record) => [
        <TableActionsDropdown
          key="actionGroup"
          onSelect={async key => {
            if (key === 'schedule') {
              history.push(`${routes.dashboard.locationsList}/${record.key}`)
            }

            if (key === 'edit') {
              setModalSettings({ isVisible: true, locationId: record.key })
            }

            if (key === 'disable') {
              updateLocation(record.key, { ...record, isDisabled: true })
            }
          }}
          menus={[
            {
              key: 'schedule',
              name: <Link to={routes.dashboard.locationsList}>Schedule</Link>,
            },
            {
              key: 'edit',
              name: 'Edit',
            },
            {
              key: 'disable',
              name: 'Disable',
            },
          ]}
        />,
      ],
    },
  ]
}

export default function LocationsList() {
  const actionRef = useRef()
  const [formInstance] = ProForm.useForm()
  const [modalSettings, setModalSettings] = useState({ isVisible: false })
  const { locations, isLocationsLoading, setSearchQuery } = useLocations()
  const history = useHistory()

  function handleAddButtonClick() {
    setModalSettings({ isVisible: true })
  }

  function handleCloseButtonClick() {
    setModalSettings({ isVisible: false })
  }

  const handleSearch = searchText => {
    setSearchQuery(searchText)
  }

  return (
    <LocationContainer
      extraContent={
        <Form form={formInstance}>
          <Form.Item label="Search Locations">
            <SearchBox
              onSearch={handleSearch}
              placeholder="Search Locations by name or address"
              size={250}
              enterButton={
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              }
              allowClear
            />
          </Form.Item>
        </Form>
      }
    >
      <ProTable
        columns={getTableColumns(setModalSettings, history)}
        actionRef={actionRef}
        dataSource={locations}
        loading={isLocationsLoading}
        rowKey="key"
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        dateFormatter="string"
        headerTitle="Current Locations"
        toolBarRender={() => [
          <Button type="primary" onClick={handleAddButtonClick}>
            Add a Location
          </Button>,
        ]}
        search={false}
        options={false}
      />
      <AddLocationModal
        isModalVisible={modalSettings.isVisible}
        onClickClose={handleCloseButtonClick}
        locationId={modalSettings?.locationId}
      />
    </LocationContainer>
  )
}
