import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Typography, message, Button, Row, Col, Divider } from 'antd'
import ProForm from '@ant-design/pro-form'
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import AppointmentsService from '../../services/appointments-service'
import { CenteredUploadImage } from './upload-image'
import WebcamCapture from './webcam-capture'
import { Container } from '../styles/check-in-modal'

import { successNotification } from '../../components/dashboard/ui-notification'
import StorageService from '../../services/storage-service'

export default function AddImagesModal({ data, isModalVisible, onClickClose }) {
  const updateImage = useCallback(async (field, stateHook) => {
    if (isModalVisible && data.hasOwnProperty(field)) {
      await stateHook(data[field])
    }
    return null
  })

  const [formInstance] = ProForm.useForm()
  const [driverImageUrl, setDriverImageUrl] = useState()
  const [driverImageBackUrl, setDriverImageBackUrl] = useState()
  const [insuranceImageUrl, setInsuranceImageUrl] = useState()
  const [insuranceImageBackUrl, setInsuranceImageBackUrl] = useState()
  const [driverLoading, setDriverLoading] = useState(false)
  const [insuranceLoading, setInsuranceLoading] = useState(false)
  const [driverLoadingBack, setDriverLoadingBack] = useState(false)
  const [insuranceLoadingBack, setInsuranceLoadingBack] = useState(false)
  const [camera, setCamera] = useState()
  const [type, setType] = useState()
  const { Text } = Typography

  useEffect(() => {
    if (isModalVisible && data) {
      updateImage('driverLicenceUrl', setDriverImageUrl)
      updateImage('driverLicenceBackUrl', setDriverImageBackUrl)
      updateImage('insuranceCardUrl', setInsuranceImageUrl)
      updateImage('insuranceCardBackUrl', setInsuranceImageBackUrl)
    }
    return () => {
      if (!isModalVisible) {
        setDriverImageUrl()
        setDriverImageBackUrl()
        setInsuranceImageUrl()
        setInsuranceImageBackUrl()
      }
    }
  }, [data, isModalVisible, updateImage])

  const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Image must be smaller than 5MB!')
    }
    return isJpgOrPng && isLt5M
  }

  const handleChange = (info, type, side) => {
    if (info.file.status === 'uploading') {
      if (type === 'driver') {
        if (side === 'front') {
          setDriverImageUrl('')
          setDriverLoading(true)
        } else if (side === 'back') {
          setDriverImageBackUrl('')
          setDriverLoadingBack(true)
        }
      } else if (type === 'insurance') {
        if (side === 'front') {
          setInsuranceImageUrl('')
          setInsuranceLoading(true)
        } else if (side === 'back') {
          setInsuranceImageBackUrl('')
          setInsuranceLoadingBack(true)
        }
      }
      return
    }
  }

  const handleFormSubmit = async () => {
    try {
      await AppointmentsService.update(data.key, {
        driverLicenceUrl: driverImageUrl,
        driverLicenceBackUrl: driverImageBackUrl,
        insuranceCardUrl: insuranceImageUrl,
        insuranceCardBackUrl: insuranceImageBackUrl,
      })
      successNotification({
        message: 'Images sent sucessfully',
      })
      handleFormReset()
      onClickClose()
    } catch (error) {
      onClickClose()
    }
  }

  const handleFormReset = () => {
    formInstance.resetFields()
  }

  const uploadButton = (loader, text) => {
    return (
      <div>
        {loader ? (
          <>
            <LoadingOutlined />
            <div style={{ marginTop: 8 }}>Uploading...</div>
          </>
        ) : (
          <>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{text}</div>
          </>
        )}
      </div>
    )
  }

  const customUpload = async ({ onError, onSuccess, file }, type) => {
    try {
      const image = await StorageService.uploadDriverLicenseAndInsuranceCard(
        file,
        data.key,
        type,
      )
      if (type === 'driver-license') {
        setDriverImageUrl(image)
        setDriverLoading(false)
      } else if (type === 'driver-license-back') {
        setDriverImageBackUrl(image)
        setDriverLoadingBack(false)
      } else if (type === 'insurance-card') {
        setInsuranceImageUrl(image)
        setInsuranceLoading(false)
      } else if (type === 'insurance-card-back') {
        setInsuranceImageBackUrl(image)
        setInsuranceLoadingBack(false)
      }
      onSuccess(null, image)
    } catch (e) {
      onError(e)
    }
  }

  const takePicture = type => {
    setCamera(true)
    setType(type)
  }

  const onClose = () => {
    setCamera(false)
  }

  return (
    <>
      <Modal
        title={`Add Documents to ${data?.fullName}`}
        visible={isModalVisible}
        centered
        closeIcon={<CloseOutlined onClick={onClickClose} />}
        destroyOnClose
        onOk={handleFormSubmit}
        onCancel={onClickClose}
        width={'75%'}
      >
        <>
          <Text>
            To add the documents, choose a clear photo, or take a picture of the
            patient document.
          </Text>
          <Text>obs: Max size is 5MB, and the format is jpg or png</Text>
          <Row align="center">
            <Col span={6}>
              <Row justify="center" align="center">
                <CenteredUploadImage
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={info => handleChange(info, 'driver', 'front')}
                  customRequest={info => customUpload(info, 'driver-license')}
                >
                  {driverImageUrl ? (
                    <img
                      src={driverImageUrl}
                      alt="avatar"
                      style={{ width: '100%', height: '100%' }}
                    />
                  ) : (
                    uploadButton(driverLoading, 'Upload Drivers License Front')
                  )}
                </CenteredUploadImage>
                <Button
                  type="primary"
                  onClick={() => takePicture('driver-license')}
                >
                  Take a picture
                </Button>
              </Row>
            </Col>
            <Col span={6}>
              <Row justify="center" align="center">
                <CenteredUploadImage
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={info => handleChange(info, 'driver', 'back')}
                  customRequest={info =>
                    customUpload(info, 'driver-license-back')
                  }
                >
                  {driverImageBackUrl ? (
                    <img
                      src={driverImageBackUrl}
                      alt="avatar"
                      style={{ width: '100%', height: '100%' }}
                    />
                  ) : (
                    uploadButton(
                      driverLoadingBack,
                      'Upload Drivers License Back',
                    )
                  )}
                </CenteredUploadImage>
                <Button
                  type="primary"
                  onClick={() => takePicture('driver-license-back')}
                >
                  Take a picture
                </Button>
              </Row>
            </Col>
            <Container></Container>
          </Row>
          <Divider />
          <Row align="center" justify="center">
            <Col span={6}>
              <Row justify="center" align="center">
                <CenteredUploadImage
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={info => handleChange(info, 'insurance', 'front')}
                  customRequest={info => customUpload(info, 'insurance-card')}
                >
                  {insuranceImageUrl ? (
                    <img
                      src={insuranceImageUrl}
                      alt="avatar"
                      style={{ width: '100%', height: '100%' }}
                    />
                  ) : (
                    uploadButton(
                      insuranceLoading,
                      'Upload Insurance Card Front',
                    )
                  )}
                </CenteredUploadImage>
                <Button
                  type="primary"
                  onClick={() => takePicture('insurance-card')}
                >
                  Take a picture
                </Button>
              </Row>
            </Col>
            <Col span={6}>
              <Row justify="center" align="center">
                <CenteredUploadImage
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={info => handleChange(info, 'insurance', 'back')}
                  customRequest={info =>
                    customUpload(info, 'insurance-card-back')
                  }
                >
                  {insuranceImageBackUrl ? (
                    <img
                      src={insuranceImageBackUrl}
                      alt="avatar"
                      style={{ width: '100%', height: '100%' }}
                    />
                  ) : (
                    uploadButton(
                      insuranceLoadingBack,
                      'Upload Insurance Card Back',
                    )
                  )}
                </CenteredUploadImage>
                <Button
                  type="primary"
                  onClick={() => takePicture('insurance-card-back')}
                >
                  Take a picture
                </Button>
              </Row>
            </Col>
          </Row>
        </>
      </Modal>
      <WebcamCapture
        id={data?.key}
        type={type}
        onCaptureDriver={setDriverImageUrl}
        onCaptureDriverBack={setDriverImageBackUrl}
        onCaptureInsurance={setInsuranceImageUrl}
        onCaptureInsuranceBack={setInsuranceImageBackUrl}
        isModalVisible={camera}
        onClose={onClose}
      />
    </>
  )
}
