import { FirebaseService } from './firebase-service'
import { paths } from './paths'
import { compareWellPosition } from '../utils/text-utils'

class BatchService extends FirebaseService {
  constructor() {
    super(paths.batches)
  }

  observeBatches(callback) {
    this.path.on('value', snapshot => {
      const baches = this.formatSnapshot(snapshot)
      callback(baches)
    })
  }

  formatSnapshot(snapshot) {
    const batches = []

    snapshot.forEach(childSnapshot => {
      childSnapshot.forEach(batch => {
        const { batchNumber, date, status } = batch.val()
        batches.push({
          key: batchNumber,
          batch: batchNumber,
          date,
          status,
        })
      })
    })

    return batches
  }

  observeAppointmentsBatch(batchDate, batchNumber, callback) {
    try {
      paths.appointments
        .orderByChild('batchDate')
        .equalTo(batchDate)
        .on('value', snapshot => {
          const appointmentsBatch = this.formatSnapshotAppointmentsBatch(
            snapshot,
            batchNumber,
          )
          callback(appointmentsBatch)
        })
    } catch (error) {
      throw error
    }
  }

  unsubscribeAppointments() {
    this.path.off()
  }

  formatSnapshotAppointmentsBatch(snapshot, batchNumber) {
    const appointmentsBatch = []
    snapshot.forEach(childSnapshot => {
      if (
        childSnapshot.val().batchNumber === batchNumber &&
        !childSnapshot.val().isAutomatedBatch
      ) {
        appointmentsBatch.push({
          key: childSnapshot.key,
          ...childSnapshot.val(),
        })
      }
    })

    return appointmentsBatch.sort((a, b) =>
      compareWellPosition(a.wellPosition, b.wellPosition),
    )
  }

  observeBatch(batchDate, batchNumber, callback) {
    const batchIndex = batchNumber - 1
    this.path
      .child(batchDate)
      .child(batchIndex)
      .on('value', snapshot => {
        callback(snapshot.val())
      })
  }

  async get(batchDate, callback) {
    this.path.child(batchDate).once('value', snapshot => {
      callback(snapshot.val())
    })
  }

  async create({ date, batchNumber, currentWell }) {
    const batch = {
      batchNumber,
      currentWell,
      date,
      status: 'In Progress',
    }
    const batchIndex = batchNumber - 1
    try {
      await this.path.child(date).child(batchIndex).set(batch)
      return batch
    } catch (error) {
      throw error
    }
  }

  async update(batchDate, batch) {
    try {
      await this.path.child(batchDate).update(batch)
    } catch (error) {
      throw error
    }
  }

  async updateCurrentWell(batchDate, batchNumber, batch) {
    const batchIndex = batchNumber - 1
    try {
      await this.path.child(batchDate).child(batchIndex).update(batch)
    } catch (error) {
      throw error
    }
  }

  async delete(batchId) {
    await this.path.child(batchId).remove()
  }

  unsubscribeBatch() {
    this.path.off()
  }
}

export default new BatchService()
