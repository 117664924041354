import React from 'react'
import { Router, useHistory } from 'react-router-dom'
import RouterHOC from './router-hoc'

const RouterComponent = () => {
  const history = useHistory()
  const routes = <RouterHOC />

  return <Router history={history}>{routes}</Router>
}

export default RouterComponent
