import React, { useState, useEffect, useContext } from 'react'
import { Typography, Modal, Button, Switch } from 'antd'
import { PageContainer } from '@ant-design/pro-layout'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ProForm from '@ant-design/pro-form'
import ProTable from '@ant-design/pro-table'
import SearchBox from '../../components/search-box'
import ResultModalContent from '../../components/dashboard/result-modal-content'
import AppointmentService from '../../services/appointments-service'
import { compareWellPosition } from '../../utils/text-utils'
import appointmentsService from '../../services/appointments-service'
import { successNotification } from '../../components/dashboard/ui-notification'
import { UserContext } from '../../contexts/user-context'
import useAppointmentsByPositives from '../../hooks/use-appointments-by-positives'

const { Text } = Typography
const { confirm } = Modal

async function showConfirm(row) {
  confirm({
    width: '80%',
    title: 'Confirm Result',
    icon: <ExclamationCircleOutlined />,
    content: <ResultModalContent info={row} />,
    async onOk() {
      try {
        await appointmentsService.update(row.key, {
          confirmedByDoctor: new Date(),
          result: 'Positive',
        })
        successNotification({
          message: 'Result confirmed',
        })
      } catch (error) {
        this.close()
      }
    },
    onCancel() {
      return null
    },
  })
}

function getTableColumns(handleChangeInput) {
  return [
    {
      key: 'barcode',
      title: (_, type) => (type === 'table' ? 'Barcode #' : 'Search Specimens'),
      dataIndex: 'barcode',
      width: 200,
      hideOn: ['lg', 'sm', 'xs'],
      renderFormItem: (_, { type, defaultRender, ...props }, form) => {
        return (
          <SearchBox
            onChange={handleChangeInput}
            placeholder="Search by barcode"
          />
        )
      },
    },
    {
      key: 'wellPosition',
      title: 'WELL',
      width: 200,
      valueType: 'text',
      dataIndex: 'wellPosition',
      sorter: {
        compare: (a, b) => compareWellPosition(a.wellPosition, b.wellPosition),
        multiple: 3,
      },
    },
    {
      key: 'firstName',
      title: 'First Name',
      valueType: 'text',
      dataIndex: 'firstName',
    },
    {
      key: 'lastName',
      title: 'Last Name',
      valueType: 'text',
      dataIndex: 'lastName',
    },
    {
      key: 'result',
      title: 'Result',
      valueType: 'text',
      dataIndex: 'result',
      render: text => (
        <Text type={text === 'Positive' && 'danger'}>{text}</Text>
      ),
    },
    {
      valueType: 'option',
      align: 'right',
      render: (_, row) => [
        !row.confirmedByDoctor && (
          <Button
            key="confirmButton"
            type="link"
            onClick={() => {
              showConfirm(row)
            }}
          >
            Confirm
          </Button>
        ),
        row.confirmedByDoctor && <Text type="secondary">Confirmed</Text>,
      ],
    },
  ]
}

const PositiveResults = props => {
  const { user } = useContext(UserContext)
  const {
    appointmentsByPositives,
    isAppointmentsLoadingByPositives,
    isCheckedIn,
    setIsCheckedIn,
  } = useAppointmentsByPositives(user)

  return (
    <PageContainer>
      <ProTable
        size="small"
        columns={getTableColumns(user)}
        rowKey="key"
        options={false}
        loading={isAppointmentsLoadingByPositives}
        dataSource={appointmentsByPositives}
        search={false}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        headerTitle={
          <>
            <Switch
              checked={isCheckedIn}
              onClick={() => setIsCheckedIn(!isCheckedIn)}
            />{' '}
            <Text>Show Confirmed</Text>
          </>
        }
      />
    </PageContainer>
  )
}

export default PositiveResults
