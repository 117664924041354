import AppointmentsService from '../services/appointments-service'
import { format, parseISO } from 'date-fns'

const delay = (t, val) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(val)
    }, t)
  })
}

export async function printBarcode(key) {
  AppointmentsService.get(key, async values => {
    const browserPrint = window.BrowserPrint
    const zeroPad = (num, places) => String(num).padStart(places, '0')
    const counter = zeroPad(values.locationAppointmentCounter, 5)
    const pre = counter.slice(0, -1)
    const suf = counter.charAt(counter.length - 1)
    const date = format(parseISO(values.confirmation), 'MMddyy')
    const loc = zeroPad(values.locationIdentifier, 2)
    const first = values.firstName.toUpperCase()
    const last = values.lastName.toUpperCase()
    const dob = format(values.dateOfBirth, 'MM/dd/yyyy')
    const col = format(parseISO(values.confirmation), 'MM/dd/yyyy')
    const barcode = date + loc + counter

    await AppointmentsService.update(key, { barcode })

    const zpl =
      `
    ^XA
    ^MMT
    ^PW376
    ^LL0300
    ^LS0
    ^BY2,3,91^FT64,277^BCN,,Y,N
    ^FD>;` +
      date +
      loc +
      pre +
      `>6` +
      suf +
      `^FS
    ^FT21,47^A0N,42,40^FH^FD` +
      first +
      `^FS
    ^FT21,90^A0N,42,40^FH^FD` +
      last +
      `^FS
    ^FT21,134^A0N,42,40^FH^FDDOB: ` +
      dob +
      `^FS
    ^FT21,178^A0N,42,40^FH^FDCOL: ` +
      col +
      `^FS
    ^PQ1,0,1,Y^XZ
    `

    browserPrint.getDefaultDevice('printer', function (device) {
      device.send(zpl)
      device.send(zpl)
    })
    await delay(1150)
  })
}

export async function printBarcodes(appointments) {
  for await (let appointment of appointments) {
    const browserPrint = window.BrowserPrint
    const pre = appointment.barcode.slice(0, -1)
    const suf = appointment.barcode.charAt(appointment.barcode.length - 1)
    const first = appointment.firstName.toUpperCase()
    const last = appointment.lastName.toUpperCase()
    const dob = format(appointment.dateOfBirth, 'MM/dd/yyyy')
    const groupName = appointment.groupFullName.slice(0, 20)

    const zpl =
      `
    ^XA
    ^MMT
    ^PW376
    ^LL0300
    ^LS0
    ^BY2,3,91^FT64,277^BCN,,Y,N
    ^FD>;` +
      pre +
      `>6` +
      suf +
      `^FS
    ^FT21,47^A0N,42,40^FH^FD` +
      first +
      `^FS
    ^FT21,90^A0N,42,40^FH^FD` +
      last +
      `^FS
    ^FT21,134^A0N,42,40^FH^FDDOB: ` +
      dob +
      `^FS
    ^FT21,178^A0N,42,40^FH^FD` +
      groupName +
      `^FS
    ^PQ1,0,1,Y^XZ
    `

    browserPrint.getDefaultDevice('printer', function (device) {
      device.send(zpl)
      device.send(zpl)
    })
    await delay(1150)
  }
}
