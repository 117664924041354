import React, { useContext, useEffect, useState } from 'react'
import { Dropdown, Menu, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import BasicUsername from './basic-username'
import BasicOrgInfo from './basic-orginfo'
import { UserContext } from '../../contexts/user-context'
import UserService from '../../services/user-service'
import {
  DownOutlined,
  LogoutOutlined,
  BankFilled,
  PhoneOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons'
import { signOutUser } from '../../utils/user-utils'
import { useHistory } from 'react-router-dom'
import routes from '../../config/routes'

export default function RightHeaderContent() {
  const { t } = useTranslation()
  const { user, role } = useContext(UserContext)
  const history = useHistory()
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    UserService.getUserInfo(user.uid, response => {
      setUserInfo(response)
    })
  }, [role, user.uid])

  const signOut = async () => {
    await signOutUser()
    history.push(routes.dashboard.login)
  }

  const menuUser = (
    <Menu>
      <Menu.Item onClick={signOut}>
        <LogoutOutlined /> {t('logout')}
      </Menu.Item>
    </Menu>
  )
  const orgUserInfo = role === 'organization' && (
    <div>
      <BasicOrgInfo padRight>
        <BankFilled size={15} /> {userInfo?.fullName}
      </BasicOrgInfo>
      <BasicOrgInfo padRight>
        <EnvironmentOutlined size={15} /> {userInfo?.fullAddress}
      </BasicOrgInfo>
      <BasicOrgInfo padRight>
        <PhoneOutlined size={15} /> {userInfo?.phoneNumber}
      </BasicOrgInfo>
    </div>
  )
  const doctorUserInfo = role === 'doctor' && (
    <div>
      <BasicOrgInfo padRight>
        <BankFilled size={15} /> {userInfo?.fullName}
      </BasicOrgInfo>
      <BasicOrgInfo padRight>
        <EnvironmentOutlined size={15} /> {userInfo?.fullAddress}
      </BasicOrgInfo>
      <BasicOrgInfo padRight>
        <PhoneOutlined size={15} /> {userInfo?.phoneNumber}
      </BasicOrgInfo>
      <BasicOrgInfo padRight>
        <EnvironmentOutlined size={15} /> Ref Code:{' '}
        <b>{userInfo?.doctorReferralCode}</b>
      </BasicOrgInfo>
    </div>
  )
  return (
    <>
      {orgUserInfo}
      {doctorUserInfo}
      <Dropdown overlay={menuUser}>
        <Space align="center">
          <BasicUsername>{user?.email}</BasicUsername>
          <DownOutlined size={12} />
        </Space>
      </Dropdown>
    </>
  )
}
