import algoliasearch from 'algoliasearch'

const FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const algoliaIndexPrefix =
  process.env.REACT_APP_ALGOLIA_INDEX_PREFIX || 'staging'
const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID
const algoliaSearchApiKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
const algolia = algoliasearch(algoliaAppId, algoliaSearchApiKey)
const payersIndex = algolia.initIndex(`${algoliaIndexPrefix}_payers`)
const locationsIndex = algolia.initIndex(`${algoliaIndexPrefix}_locations`)
const appointmentsIndex = algolia.initIndex(
  `${algoliaIndexPrefix}_appointments`,
)

export {
  FirebaseConfig,
  payersIndex,
  locationsIndex,
  appointmentsIndex,
  algolia,
}
