import React, { useEffect } from 'react'
import { Form, Input } from 'antd'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import AppointmentsService from '../../services/appointments-service'
import { handleError } from '../../utils/error-utils'
import { useHistory } from 'react-router-dom'
import DateMasked from '../../components/date-masked'
import useQueryParam from '../../hooks/use-query-param'
import UserService from '../../services/user-service'

function NonUserAuth() {
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()
  const userToken = useQueryParam('token')
  const appointmentId = useQueryParam('appointmentId')

  useEffect(() => {
    if (!userToken || !appointmentId) {
      history.replace(routes.home)
    }
  })

  const onFinish = async values => {
    try {
      if (appointmentId) {
        await UserService.signInWithCustomToken(userToken)

        AppointmentsService.get(appointmentId, async appointment => {
          const dateOfBirth = format(new Date(values.dateOfBirth), 'MMddyyyy')
          const appointmentDateOfBirth = format(
            appointment?.dateOfBirth,
            'MMddyyyy',
          )

          if (appointmentDateOfBirth === dateOfBirth) {
            const signInMethods = await UserService.fetchSignInMethodsForEmail(
              appointment?.email,
            )

            const result = {
              ...appointment,
              appointmentId,
              result: appointment?.result,
              firstName: appointment?.firstName,
              middleName: appointment?.middleName,
              lastName: appointment?.lastName,
              testReceived: appointment?.testReceived,
              userId: appointment?.userId,
              email: appointment?.email,
              signInMethods,
            }

            await UserService.signOut()
            return history.replace(routes.results, { result })
          }

          handleError(
            t('messages.birthDateError'),
            t('messages.resultsNotAcessible'),
          )
        })
      }
    } catch (error) {
      handleError(error, 'Error trying to log in the user')
    }
  }

  return (
    <ContentSpace>
      <PageTitle>{t('nonUserAuth.title')}</PageTitle>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          appointmentId,
        }}
      >
        <Form.Item label="Appointment ID" name="appointmentId">
          <Input placeholder={t('nonUserAuth.title')} />
        </Form.Item>
        <Form.Item label={t('nonUserAuth.birthPlaceholder')} name="dateOfBirth">
          <DateMasked />
        </Form.Item>
        <Form.Item>
          <ActionButtons hideBackButton={true} nextButtonTitle="Continue" />
        </Form.Item>
      </Form>
    </ContentSpace>
  )
}

export default NonUserAuth
