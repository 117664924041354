import React, { useRef, useContext, useState } from 'react'
import { Modal, Typography, Input, Form, message, Button, Row, Col } from 'antd'
import ProForm, { ProFormText } from '@ant-design/pro-form'
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { format } from 'date-fns'
import AppointmentsService from '../../services/appointments-service'
import ProfilesService from '../../services/profiles-service'
import { UserContext } from '../../contexts/user-context'
import UploadImage from './upload-image'
import Descriptions from './descriptions'
import WebcamCapture from './webcam-capture'
import { Container } from '../styles/check-in-modal'

import {
  successNotification,
  errorNotification,
} from '../../components/dashboard/ui-notification'
import { printBarcode } from '../../utils/printer-utils'
import StorageService from '../../services/storage-service'

export default function CheckInModal({
  fullName,
  driver,
  dob,
  confirmationCode,
  id,
  isModalVisible,
  onClickClose,
}) {
  const [formInstance] = ProForm.useForm()
  const [driverImageUrl, setDriverImageUrl] = useState()
  const [driverImageBackUrl, setDriverImageBackUrl] = useState()
  const [insuranceImageUrl, setInsuranceImageUrl] = useState()
  const [insuranceImageBackUrl, setInsuranceImageBackUrl] = useState()
  const [driverLoading, setDriverLoading] = useState(false)
  const [insuranceLoading, setInsuranceLoading] = useState(false)
  const [driverLoadingBack, setDriverLoadingBack] = useState(false)
  const [insuranceLoadingBack, setInsuranceLoadingBack] = useState(false)
  const [camera, setCamera] = useState()
  const [type, setType] = useState()
  const { Text } = Typography
  const { user, role } = useContext(UserContext)

  const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Image must be smaller than 5MB!')
    }
    return isJpgOrPng && isLt5M
  }

  const handleChange = (info, type, side) => {
    if (info.file.status === 'uploading') {
      if (type === 'driver') {
        if (side === 'front') {
          setDriverImageUrl('')
          setDriverLoading(true)
        } else if (side === 'back') {
          setDriverImageBackUrl('')
          setDriverLoadingBack(true)
        }
      } else if (type === 'insurance') {
        if (side === 'front') {
          setInsuranceImageUrl('')
          setInsuranceLoading(true)
        } else if (side === 'back') {
          setInsuranceImageBackUrl('')
          setInsuranceLoadingBack(true)
        }
      }
      return
    }
  }

  const handleFormSubmit = async ({ barcode }) => {
    try {
      if (!barcode) {
        await AppointmentsService.update(id, {
          confirmation: new Date(),
          driverLicenceUrl: driverImageUrl,
          driverLicenceBackUrl: driverImageBackUrl,
          insuranceCardUrl: insuranceImageUrl,
          insuranceCardBackUrl: insuranceImageBackUrl,
        })
        printBarcode(id)
      }
      const appointment =
        barcode && (await AppointmentsService.getAppointmentByBarcode(barcode))
      if (appointment) {
        handleFormReset()
        return errorNotification({
          message: 'The barcode you entered is already in use',
        })
      }
      const profile = await ProfilesService.get(user.uid)
      const isDoctor = role === 'doctor'
      await AppointmentsService.update(id, {
        confirmation: new Date(),
        barcode,
        ...(isDoctor && {
          providerFacilityName: profile?.providerFacilityName,
          providerFirstName: profile?.providerFirstName,
          providerMiddleName: profile?.providerMiddleName,
          providerLastName: profile?.providerLastName,
          providerAddress: profile?.providerAddress,
          providerCity: profile?.providerCity,
          providerState: profile?.providerState,
          providerZip: profile?.providerZip,
          providerPhone: profile?.providerPhone,
          providerNPI: profile?.providerNPI,
          driverLicenceUrl: driverImageUrl,
          driverLicenceBackUrl: driverImageBackUrl,
          insuranceCardUrl: insuranceImageUrl,
          insuranceCardBackUrl: insuranceImageBackUrl,
        }),
      })
      successNotification({
        message: 'Checked sucessfully',
      })
      handleFormReset()
      onClickClose()
    } catch (error) {
      onClickClose()
    }
  }

  const handleFormReset = () => {
    formInstance.resetFields()
  }
  const canRenderInput = role => {
    if (role === 'admin' || role === 'collection' || role === 'doctor') {
      return true
    }
    return false
  }

  const uploadButton = (loader, text) => {
    return (
      <div>
        {loader ? (
          <>
            <LoadingOutlined />
            <div style={{ marginTop: 8 }}>Uploading...</div>
          </>
        ) : (
          <>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{text}</div>
          </>
        )}
      </div>
    )
  }

  const customUpload = async ({ onError, onSuccess, file }, type) => {
    try {
      const image = await StorageService.uploadDriverLicenseAndInsuranceCard(
        file,
        id,
        type,
      )
      if (type === 'driver-license') {
        setDriverImageUrl(image)
        setDriverLoading(false)
      } else if (type === 'driver-license-back') {
        setDriverImageBackUrl(image)
        setDriverLoadingBack(false)
      } else if (type === 'insurance-card') {
        setInsuranceImageUrl(image)
        setInsuranceLoading(false)
      } else if (type === 'insurance-card-back') {
        setInsuranceImageBackUrl(image)
        setInsuranceLoadingBack(false)
      }
      onSuccess(null, image)
    } catch (e) {
      onError(e)
    }
  }

  const takePicture = type => {
    setCamera(true)
    setType(type)
  }

  const onClose = () => {
    setCamera(false)
  }

  return (
    <>
      <Modal
        title="Confirm Checkin"
        visible={isModalVisible}
        centered
        closeIcon={<CloseOutlined onClick={onClickClose} />}
        destroyOnClose
        footer={false}
        width={'75%'}
      >
        <>
          <Text>Would you like to confirm checkin?</Text>
          <Descriptions title={fullName} bordered>
            <Descriptions.Item label="DOB" span={3}>
              {format(dob, 'MM/dd/yyyy')}
            </Descriptions.Item>
            <Descriptions.Item label="Drivers license" span={3}>
              <Row gutter={20} align="middle">
                <Col>
                  <Text>{driver}</Text>
                </Col>
                <Col>
                  <Container>
                    <UploadImage
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={info => handleChange(info, 'driver', 'front')}
                      customRequest={info =>
                        customUpload(info, 'driver-license')
                      }
                    >
                      {driverImageUrl ? (
                        <img
                          src={driverImageUrl}
                          alt="avatar"
                          style={{ width: '100%', height: '100%' }}
                        />
                      ) : (
                        uploadButton(
                          driverLoading,
                          'Upload Drivers License Front',
                        )
                      )}
                    </UploadImage>
                    <Button
                      type="primary"
                      onClick={() => takePicture('driver-license')}
                    >
                      Take a picture
                    </Button>
                  </Container>
                </Col>
                <Col>
                  <Container>
                    <UploadImage
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={info => handleChange(info, 'driver', 'back')}
                      customRequest={info =>
                        customUpload(info, 'driver-license-back')
                      }
                    >
                      {driverImageBackUrl ? (
                        <img
                          src={driverImageBackUrl}
                          alt="avatar"
                          style={{ width: '100%', height: '100%' }}
                        />
                      ) : (
                        uploadButton(
                          driverLoadingBack,
                          'Upload Drivers License Back',
                        )
                      )}
                    </UploadImage>
                    <Button
                      type="primary"
                      onClick={() => takePicture('driver-license-back')}
                    >
                      Take a picture
                    </Button>
                  </Container>
                </Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label="Insurance" span={3}>
              <Row gutter={20} align="middle">
                <Col>
                  <Container>
                    <UploadImage
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={info =>
                        handleChange(info, 'insurance', 'front')
                      }
                      customRequest={info =>
                        customUpload(info, 'insurance-card')
                      }
                    >
                      {insuranceImageUrl ? (
                        <img
                          src={insuranceImageUrl}
                          alt="avatar"
                          style={{ width: '100%', height: '100%' }}
                        />
                      ) : (
                        uploadButton(
                          insuranceLoading,
                          'Upload Insurance Card Front',
                        )
                      )}
                    </UploadImage>
                    <Button
                      type="primary"
                      onClick={() => takePicture('insurance-card')}
                    >
                      Take a picture
                    </Button>
                  </Container>
                </Col>
                <Col>
                  <Container>
                    <UploadImage
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={info => handleChange(info, 'insurance', 'back')}
                      customRequest={info =>
                        customUpload(info, 'insurance-card-back')
                      }
                    >
                      {insuranceImageBackUrl ? (
                        <img
                          src={insuranceImageBackUrl}
                          alt="avatar"
                          style={{ width: '100%', height: '100%' }}
                        />
                      ) : (
                        uploadButton(
                          insuranceLoadingBack,
                          'Upload Insurance Card Back',
                        )
                      )}
                    </UploadImage>
                    <Button
                      type="primary"
                      onClick={() => takePicture('insurance-card-back')}
                    >
                      Take a picture
                    </Button>
                  </Container>
                </Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label="Confirmation Code" span={3}>
              {confirmationCode}
            </Descriptions.Item>
            {canRenderInput(role) && (
              <Descriptions.Item label="Barcode (if pre-printed)" span={3}>
                <ProForm form={formInstance} onFinish={handleFormSubmit}>
                  <Form.Item
                    name="barcode"
                    rules={[
                      ({ getFieldValue }) => ({
                        required: getFieldValue('barcode') === '',
                        message: 'Please confirm your barcode!',
                      }),
                      {
                        validator(_, value) {
                          if (
                            (role === 'doctor' && !!value) ||
                            role !== 'doctor'
                          ) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            'Doctors must input a barcode to check in',
                          )
                        },
                      },
                    ]}
                  >
                    <Input
                      name="barcode"
                      placeholder="Enter barcode"
                      width="100%"
                      autoFocus
                      hasFeedback
                    />
                  </Form.Item>
                  <Form.Item
                    name="barcodeConfirmation"
                    dependencies={['barcode']}
                    hasFeedback
                    rules={[
                      ({ getFieldValue }) => ({
                        required: getFieldValue('barcode') === '',
                        message: 'Please confirm your barcode!',
                      }),
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('barcode') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            'The two barcodes do not match!',
                          )
                        },
                      }),
                    ]}
                  >
                    <Input
                      name="barcodeConfirmation"
                      placeholder="Re-enter barcode"
                      width="100%"
                    />
                  </Form.Item>
                </ProForm>
              </Descriptions.Item>
            )}
          </Descriptions>
        </>
      </Modal>
      <WebcamCapture
        id={id}
        type={type}
        onCaptureDriver={setDriverImageUrl}
        onCaptureDriverBack={setDriverImageBackUrl}
        onCaptureInsurance={setInsuranceImageUrl}
        onCaptureInsuranceBack={setInsuranceImageBackUrl}
        isModalVisible={camera}
        onClose={onClose}
      />
    </>
  )
}
