import React from 'react'
import {
  CarryOutOutlined,
  ExperimentOutlined,
  SnippetsOutlined,
  EnvironmentOutlined,
  PicLeftOutlined,
  UserOutlined,
  FileDoneOutlined,
  PrinterOutlined,
} from '@ant-design/icons'

import { USER_ROLES } from '../constants'

const basicDashboardRoute = '/dashboard'

const routes = {
  home: '/',
  priority: '/priority',
  symptoms: '/symptoms',
  symptomsDate: '/symptoms-date',
  testReason: '/test-reason',
  emailAdd: '/email-add',
  birthDate: '/birth-date',
  bookingConfirmation: '/booking-confirmation',
  bookingSuccess: '/booking-success',
  contact: '/contact',
  noEmail: '/no-email',
  demographicsPt1: '/demographics-pt1',
  demographicsPt2: '/demographics-pt2',
  emailExistent: '/email-existent',
  emailSuccess: '/email-success',
  fullName: '/full-name',
  hasInsurance: '/has-insurance',
  hipaaWarn: '/hipaa-warn',
  driverLicense: '/driver-license',
  personalAddress: '/personal-address',
  planDetails2: '/plan-details2',
  planDetails1: '/plan-details1',
  nonUserAuth: '/non-user-auth',
  timeSlots: '/time-slots',
  results: '/results',
  doctorReferred: '/doctor-referred',
  doctorReferralCode: '/doctor-referral-code',
  locationOptions: '/location-options',
  phoneNumber: '/phone-number',
  cancel: '/cancel',
  createUser: '/create-user',
  cancelConfirmation: '/cancel-confirmation',
  notFound: '/404',
  dashboard: {
    home: basicDashboardRoute,
    login: `${basicDashboardRoute}/login`,
    locationsList: `${basicDashboardRoute}/locations`,
    appointments: `${basicDashboardRoute}/appointments`,
    batches: `${basicDashboardRoute}/batches`,
    batchCreate: `${basicDashboardRoute}/batches/batch-create`,
    positiveResult: `${basicDashboardRoute}/batches/positive-results`,
    automatedBatches: `${basicDashboardRoute}/automated-batches`,
    automatedBatchCreate: `${basicDashboardRoute}/automated-batches/automated-batch-create`,
    documentsList: `${basicDashboardRoute}/documents`,
    resultsList: `${basicDashboardRoute}/results`,
    locationsSchedule: `${basicDashboardRoute}/locations/:locationId`,
    usersList: `${basicDashboardRoute}/users`,
    billingsList: `${basicDashboardRoute}/billing`,
    reportingList: `${basicDashboardRoute}/reporting`,
    testGroupList: `${basicDashboardRoute}/test-groups`,
    testGroupAdd: `${basicDashboardRoute}/test-groups/test-group-add`,
    receiving: `${basicDashboardRoute}/receiving`,
    receivingAppointments: `${basicDashboardRoute}/receivingAppointments`,
    doctorResultList: `${basicDashboardRoute}/doctor-results`,
    printBarcodes: `${basicDashboardRoute}/print-barcodes`,
    patientLookup: `${basicDashboardRoute}/patient-lookup`,
    specimensList: `${basicDashboardRoute}/specimens`,
    specimensDoc: `${basicDashboardRoute}/specimens-doctor`,
    specimensOrg: `${basicDashboardRoute}/specimens-org`,
    specimensByLocation: `${basicDashboardRoute}/specimens-location/:locationId`,
    specimensByUser: `${basicDashboardRoute}/specimens-user/:userId`,
  },
}

const {
  admin,
  billing,
  collection,
  guest,
  lab,
  reporting,
  organization,
  doctor,
} = USER_ROLES

export const dashboardMenuRoutes = {
  routes: [
    {
      path: routes.dashboard.locationsList,
      name: 'Locations',
      icon: <EnvironmentOutlined />,
      role: [admin, doctor],
    },
    {
      path: routes.dashboard.appointments,
      name: 'Appointments',
      icon: <CarryOutOutlined />,
      breadcrumbName: 'Appointments',
      role: [admin, collection, doctor],
    },
    {
      path: routes.dashboard.receiving,
      name: 'Receiving',
      icon: <SnippetsOutlined />,
      breadcrumbName: 'Receiving',
      role: [admin, lab],
    },
    {
      path: routes.dashboard.batches,
      name: 'Batch Resulting',
      icon: <SnippetsOutlined />,
      breadcrumbName: 'Batch Resulting',
      role: [admin, lab],
    },
    {
      path: routes.dashboard.positiveResult,
      name: 'Positive Result',
      icon: <SnippetsOutlined />,
      breadcrumbName: 'Positive Result',
      role: [doctor],
    },
    {
      path: routes.dashboard.automatedBatches,
      name: 'Automated Batch',
      icon: <SnippetsOutlined />,
      breadcrumbName: 'Automated Batch',
      role: [admin, lab],
    },
    {
      path: routes.dashboard.usersList,
      name: 'Users',
      icon: <UserOutlined />,
      role: [admin],
    },
    {
      path: routes.dashboard.testGroupList,
      name: 'Test Groups',
      icon: <FileDoneOutlined />,
      role: [organization, doctor],
    },
    {
      path: routes.dashboard.billingsList,
      name: 'Billing',
      icon: <FileDoneOutlined />,
      role: [admin, billing],
    },
    {
      path: routes.dashboard.reportingList,
      name: 'Government Reporting',
      icon: <PicLeftOutlined />,
      role: [admin, reporting],
    },
    {
      path: routes.dashboard.doctorResultList,
      name: 'Results',
      icon: <PicLeftOutlined />,
      role: [doctor],
    },
    {
      path: routes.dashboard.patientLookup,
      name: 'Patient Lookup',
      icon: <PicLeftOutlined />,
      role: [admin, doctor],
    },
    {
      path: routes.dashboard.specimensList,
      name: 'Specimens',
      icon: <ExperimentOutlined />,
      role: [admin],
    },
    {
      path: routes.dashboard.specimensDoc,
      name: 'Specimens',
      icon: <ExperimentOutlined />,
      role: [doctor],
    },
    {
      path: routes.dashboard.specimensOrg,
      name: 'Specimens',
      icon: <ExperimentOutlined />,
      role: [organization],
    },

    {
      path: routes.dashboard.resultsList,
      name: 'Results',
      icon: <ExperimentOutlined />,
      role: [guest],
    },
    {
      path: routes.dashboard.printBarcodes,
      name: 'Print Barcodes',
      icon: <PrinterOutlined />,
      role: [admin, lab],
    },
  ],
}

export default routes
