import { Typography } from 'antd'
import styled from 'styled-components'

const { Text } = Typography

const FinePrint = styled(Text)`
  font-size: 10px;
  line-height: 11px;
  color: ${props => props.theme.colors.gray[600]};
  text-align: justify;
  display: block;
`

export default FinePrint
