import React from 'react'
import { Typography } from 'antd'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ContentSpace from '../../components/content-space'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../../components/action-buttons'
import { removeDoctorReferralCodeOnStorage } from '../../utils/appointment-utils'
import AppointmentsService from '../../services/appointments-service'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { useHistory } from 'react-router-dom'

const { Text } = Typography

function DoctorReferred() {
  const { t } = useTranslation()
  const { appointmentId } = useAppointmentContext()
  const history = useHistory()

  const locationButton = () => {
    removeDoctorReferralCodeOnStorage()
    AppointmentsService.update(appointmentId, { doctorReferralCode: null })
    history.push(routes.locationOptions)
  }

  return (
    <ContentSpace>
      <PageTitle>{t('doctorReferred.title')}</PageTitle>
      <Text>{t('doctorReferred.text')}</Text>
      <ActionButtons
        backButtonUrl={routes.doctorReferralCode}
        backButtonTitle={t('doctorReferred.doctorButton')}
        nextButtonAction={locationButton}
        nextButtonTitle={t('doctorReferred.locationsButton')}
        type="primary"
      />
    </ContentSpace>
  )
}

export default DoctorReferred
