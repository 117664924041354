import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useQueryParam from '../hooks/use-query-param'
import locationService from '../services/location-service'
import { handleError } from '../utils/error-utils'
import { UserContext } from '../contexts/user-context'
import ProfilesService from '../services/profiles-service'

const LocationSelectorContext = createContext()
const { Provider } = LocationSelectorContext

function LocationSelectorProvider({ children }) {
  const [locationId, setLocationId] = useState(useQueryParam('locationId'))
  const [locations, setLocations] = useState()
  const [isLocationsLoading, setIsLocationsLoading] = useState()
  const history = useHistory()
  const { role, user } = useContext(UserContext)

  useEffect(() => {
    if (!locationId) {
      return
    }

    history.replace({
      search: 'locationId=' + locationId,
    })
  }, [locationId, history])

  useEffect(() => {
    try {
      if (role === 'doctor') {
        async function fetchData() {
          setIsLocationsLoading(true)
          const profile = await ProfilesService.get(user.uid)
          locationService.observeLocationsByDoctor(
            profile.doctorReferralCode,
            locationsFirebase => {
              const options = locationsFirebase.map(element => {
                return {
                  key: element.key,
                  value: element.key,
                  label: element.title,
                }
              })
              setLocations(options)
              setLocationId(locationId => locationId || options[0]?.key)
              setIsLocationsLoading(false)
            },
          )
        }
        fetchData()
      }

      if (role !== 'doctor') {
        setIsLocationsLoading(true)
        locationService.observeLocations(locationsFirebase => {
          const options = locationsFirebase.map(element => {
            return {
              key: element.key,
              value: element.key,
              label: element.title,
            }
          })
          setLocations(options)
          setLocationId(locationId => locationId || options[0]?.key)
          setIsLocationsLoading(false)
        })
      }
    } catch (error) {
      setIsLocationsLoading(false)
      handleError(error, 'Error retrieving the available Locations')
    }

    return () => {
      locationService.unsubscribeLocations()
    }
  }, [role, user.uid])

  return (
    <Provider
      value={{
        locationId,
        setLocationId,
        locations,
        isLocationsLoading,
      }}
    >
      {children}
    </Provider>
  )
}

function useLocationSelectorContext() {
  const context = useContext(LocationSelectorContext)

  if (context === undefined) {
    throw new Error(
      'useLocationSelectorContext must be used within a LocationSelectorProvider',
    )
  }

  return context
}

export { useLocationSelectorContext, LocationSelectorProvider }
