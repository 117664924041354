const { PageContainer } = require('@ant-design/pro-layout')
const { default: styled } = require('styled-components')
const { device } = require('../../constants')

const PageContainerBatches = styled(PageContainer)`
  @media ${device.tablet} {
    #batchDate_batchDate {
      width: 300px !important;
    }
  }
  @media ${device.laptop} {
    #batchDate_batchDate {
      width: 550px !important;
    }
  }
`

export default PageContainerBatches
