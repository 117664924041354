import { useCallback, useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { appointmentsIndex } from '../config/config'
import routes from '../config/routes'
import AppointmentsService from '../services/appointments-service'
import {
  algoliaSearch,
  parseSearchResultToAppointments,
} from '../utils/algolia-utils'
import { filterAppointmentsByRangeDate } from '../utils/appointment-utils'
import { handleError } from '../utils/error-utils'
import useQueryParam from './use-query-param'
import { addDays, subDays } from 'date-fns'

export default function useAppointments(appointmentId = undefined) {
  const [appointments, setAppointments] = useState()
  const [isCheckedIn, setIsCheckedIn] = useState(false)
  const [isCanceled, setIsCanceled] = useState(undefined)
  const [isAppointmentsLoading, setIsAppointmentsLoading] = useState()
  const [appointment, setAppointment] = useState()
  const [isAppointmentLoading, setIsAppointmentLoading] = useState()
  const [searchQuery, setSearchQuery] = useState()
  const [dateSelected, setDateSelected] = useState({
    startDate: subDays(new Date(), 3),
    endDate: addDays(new Date(), 3),
  })
  const [searchFilters, setSearchFilters] = useState()
  const locationId = useQueryParam('locationId')
  const url = useLocation().pathname
  const filters = useMemo(() => searchFilters, [searchFilters])

  const filterCheckedAppointments = useCallback(
    appointmentsArray => {
      if (isCheckedIn) return appointmentsArray

      return appointmentsArray.filter(item =>
        isCheckedIn ? item.confirmation : !item.confirmation,
      )
    },
    [isCheckedIn],
  )

  const filterAppointments = useCallback(
    appointmentsArray => {
      return filterCheckedAppointments(
        filterAppointmentsByRangeDate(appointmentsArray, dateSelected),
      )
    },
    [dateSelected, filterCheckedAppointments],
  )

  useEffect(() => {
    if (appointmentId || searchFilters?.length > 0 || !locationId) {
      return
    }

    try {
      setIsAppointmentsLoading(true)

      AppointmentsService.observeAppointments({ locationId }, appointments => {
        setAppointments(filterAppointments(appointments))
        setIsAppointmentsLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving appointments')
      setIsAppointmentsLoading(false)
    }

    return () => {
      AppointmentsService.unsubscribeAppointments()
    }
  }, [
    appointmentId,
    locationId,
    isCheckedIn,
    dateSelected,
    searchFilters,
    filterAppointments,
  ])

  useEffect(() => {
    if (
      appointmentId ||
      !searchFilters ||
      searchFilters?.length === 0 ||
      !locationId
    ) {
      return
    }

    async function fetchData() {
      try {
        setIsAppointmentsLoading(true)
        let searchName = await algoliaSearch(
          '',
          appointmentsIndex,
          searchFilters && `lastName:${searchFilters}`,
          `locationId:${locationId}`,
        )

        const filteredAppointments = await filterAppointments(
          parseSearchResultToAppointments(searchName),
        )
        setAppointments(filteredAppointments)
        setIsAppointmentsLoading(false)
      } catch (error) {
        handleError(error, 'Error searching by appointments')
        setIsAppointmentsLoading(false)
      }
    }

    fetchData()
  }, [
    appointmentId,
    locationId,
    isCheckedIn,
    dateSelected,
    searchFilters,
    filterAppointments,
  ])

  useEffect(() => {
    if (!appointmentId) {
      return
    }

    try {
      setIsAppointmentLoading(true)
      AppointmentsService.get(appointmentId, appointment => {
        setAppointment(appointment)
        setIsAppointmentLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving Appointment')
      setIsAppointmentLoading(false)
    }
  }, [appointmentId])

  useEffect(() => {
    async function cancelAppointment() {
      if (appointmentId && url === routes.cancel) {
        try {
          const appointment = await AppointmentsService.cancelAppointment(
            appointmentId,
          )
          if (appointment === 'canceled') {
            setIsCanceled(null)
          } else if (appointment) {
            setAppointment(appointment)
            setIsCanceled(true)
          } else {
            handleError('Error to cancel the appointment')
            setIsCanceled(false)
          }
        } catch (error) {
          handleError(error, 'Error to cancel the appointment')
          setIsCanceled(false)
        }
      }
    }
    cancelAppointment()
  }, [appointmentId, url])

  return {
    appointments,
    isAppointmentsLoading,
    appointment,
    isAppointmentLoading,
    isCheckedIn,
    dateSelected,
    setIsCheckedIn,
    isCanceled,
    setDateSelected,
    setSearchFilters,
  }
}
