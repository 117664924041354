import React from 'react'
import { Radio } from 'antd'
import { DemographicsContentSpace } from './styles/demographic-content-space'

const { Group } = Radio

export const RadioGroup = ({ options, ...props }) => {
  return (
    <Group {...props}>
      <DemographicsContentSpace>
        {options.map(option => (
          <Radio key={option.key || option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </DemographicsContentSpace>
    </Group>
  )
}
