import React from 'react'
import { format, parseISO, addMinutes, isWithinInterval } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import {
  BILLING_STATUS,
  INSURED_RELATIONSHIP_LIST,
  INSURANCE_STATUS,
  FORMATED_COMPANY_NAME,
} from '../constants'
import TestGroupService from '../services/test-group-service'
import AppointmentService from '../services/appointments-service'
import { handleError } from './error-utils'
import { formatDateOfBirth, parseDateOfBirth } from './date-utils'
import UserService from '../services/user-service'

export const parseAppointment = (appointmentId, appointment, location) => ({
  appointmentId,
  locationId: appointment.locationId,
  confirmationCode: appointment.confirmationCode,
  drivethrough: {
    siteTitle: location.title,
    locationLine1: `${location.address} ${location.addressComplement && '-'} ${
      location.addressComplement
    }`,
    locationLine2: `${location.cityState} ${location.zipCode}`,
  },
  timeSlot: {
    isoString: appointment.slotsDateTime,
    dateString: appointment.slotsDateTime
      ? format(parseISO(appointment.slotsDateTime), 'MM/dd/yyyy')
      : '-',
    timeString: appointment.slotsDateTime
      ? format(parseISO(appointment.slotsDateTime), 'h:mm a')
      : '-',
  },
  personalInfo: {
    fullName: getFullName(appointment),
    email: appointment.email,
    birthDate: formatDateOfBirth(appointment.dateOfBirth),
    phone: appointment.phoneNumber,
    personalAddress: appointment.homeless ? null : (
      <>
        <p>{appointment.street1}</p>
        <p>
          {appointment.city}, {appointment.state} {appointment.zip}
        </p>
      </>
    ),
    homeless: appointment?.homeless,
  },
  demographic: {
    sex: appointment.sexAtBirth,
    race: appointment.race,
    ethnicity: appointment.ethnicity,
  },
  identification: {
    driver: appointment.driverLicenseNumber,
    ssn: appointment.socialSecurityNumber,
  },
})

export const isRequiredDataFilled = appointment => {
  if (
    appointment?.dateOfBirth &&
    appointment?.ethnicity &&
    appointment?.firstName &&
    appointment?.lastName &&
    appointment?.race &&
    appointment?.sexAtBirth &&
    appointment?.slotsDateTime &&
    ((appointment?.city &&
      appointment?.state &&
      appointment?.street1 &&
      appointment?.zip) ||
      appointment?.homeless === true)
  ) {
    return true
  }

  return false
}

export const generateConfirmationCode = () => {
  return Math.random().toString(36).slice(4).substr(0, 8).toUpperCase()
}

export const isBillingStatusPending = billingStatus => {
  return (
    (billingStatus === BILLING_STATUS.pending || billingStatus === undefined) &&
    billingStatus !== 'processed'
  )
}

export const isInsuranceStatusPending = insuranceStatus => {
  return insuranceStatus === INSURANCE_STATUS.pending || !insuranceStatus
}

export const getCorrectBillingStatus = billingStatus => {
  return isBillingStatusPending(billingStatus)
    ? BILLING_STATUS.pending
    : BILLING_STATUS.processed
}

export const getFullName = appointment => {
  const { firstName, middleName, lastName } = appointment

  if (!!firstName && !!middleName && !!lastName) {
    return `${firstName} ${middleName} ${lastName}`
  }

  if (!!firstName && !!lastName) {
    return `${firstName} ${lastName}`
  }

  return '-'
}

export const getAppointmentFromStorage = () => {
  return JSON.parse(
    window.sessionStorage.getItem(`${FORMATED_COMPANY_NAME}-appointment`),
  )
}

export const setAppointmentOnStorage = appointment => {
  window.sessionStorage.setItem(
    `${FORMATED_COMPANY_NAME}-appointment`,
    JSON.stringify(appointment),
  )
}

export const setDoctorReferralCodeOnStorage = referralCode => {
  window.sessionStorage.setItem(
    `${FORMATED_COMPANY_NAME}-doctorReferralCode`,
    JSON.stringify(referralCode),
  )
}

export const removeDoctorReferralCodeOnStorage = () => {
  window.sessionStorage.removeItem(
    `${FORMATED_COMPANY_NAME}-doctorReferralCode`,
  )
}

export const getDoctorReferralCodeFromStorage = () => {
  return JSON.parse(
    window.sessionStorage.getItem(
      `${FORMATED_COMPANY_NAME}-doctorReferralCode`,
    ),
  )
}

export const getResultFromStorage = () => {
  return JSON.parse(
    window.sessionStorage.getItem(`${FORMATED_COMPANY_NAME}-result`),
  )
}

export const setResultOnStorage = result => {
  window.sessionStorage.setItem(
    `${FORMATED_COMPANY_NAME}-result`,
    JSON.stringify(result),
  )
}

export const removeEmptyValues = appointment => {
  return Object.keys(appointment).reduce((accumulator, key) => {
    const newAccumulator = accumulator
    if (appointment[key] !== undefined) newAccumulator[key] = appointment[key]
    return newAccumulator
  }, {})
}

export const getDateFromTimeISO = date => {
  return date?.split('T')[0]
}

export const getInsuredRelationship = (relationshipKey = undefined) => {
  if (!relationshipKey) {
    return undefined
  }

  return INSURED_RELATIONSHIP_LIST.filter(
    item => item.key === relationshipKey,
  )[0]?.title
}

export const getFormattedAppointmentValues = appointment => {
  return {
    dateOfBirth: parseDateOfBirth(appointment?.dateOfBirth),
    fullName: getFullName(appointment),
    timeSlotReadable: getTimeSlotReadeble(appointment?.slotsDateTime),
    insuredRelationship: getInsuredRelationship(
      appointment?.insuredRelationship,
    ),
  }
}

export const filterAppointmentsByDate = (
  appointmentsArray,
  dateSelected = new Date().toISOString(),
) => {
  const dateSelectedFormatted = getDateFromTimeISO(dateSelected)

  return appointmentsArray.filter(appointment => {
    const date = getDateFromTimeISO(appointment.slotsDateTime)
    return date === dateSelectedFormatted
  })
}

export const filterAppointmentsByRangeDate = (
  appointmentsArray,
  dateSelected,
) => {
  let startDate = new Date(dateSelected.startDate).setHours(0, 0, 0, 0)
  let endDate = new Date(dateSelected.endDate).setHours(23, 59, 59, 999)

  return appointmentsArray.filter(appointment => {
    return isWithinInterval(new Date(appointment.slotsDateTime), {
      start: startDate,
      end: endDate,
    })
  })
}
export const filterAppointmentsByDoctor = (
  appointmentsArray,
  doctorReferralCode,
) => {
  return appointmentsArray.filter(
    appointment =>
      doctorReferralCode &&
      !!appointment.result &&
      appointment.doctorReferralCode === doctorReferralCode,
  )
}

export const createAppointmentFromTestGroup = async ({
  duplicate,
  doctorReferralCode,
  organizationId,
  groupId,
  appointment,
  providerInfo,
}) => {
  const { email } = appointment
  const currentTestGroup = await TestGroupService.getTestGroup(groupId)
  try {
    const userId = uuidv4()
    const user = await UserService.getUserByEmail(email)

    if (!user) {
      await UserService.createGuestUser({ userId, email })
    }
    if (duplicate) {
      appointment.barcode = null
      appointment.result = null
    }

    await AppointmentService.create({
      ...appointment,
      ...providerInfo,
      userId,
      organizationId,
      doctorReferralCode,
      groupId: +groupId,
      locationAppointmentCounter: currentTestGroup.groupBatchCounter,
      groupBatchCounter: currentTestGroup.groupBatchId,
      groupFullName: currentTestGroup.fullName,
      confirmationBookAppointment: true,
    })
    TestGroupService.incrementGroupBatchCounter(groupId)
  } catch (error) {
    handleError(error, 'Error when creating appointment')
    throw error
  }
}

export const getTimeSlotReadeble = slotsDateTime => {
  if (!slotsDateTime) {
    return undefined
  }

  return `${format(parseISO(slotsDateTime), 'hh:mm')} - ${format(
    addMinutes(parseISO(slotsDateTime), 30),
    'p',
  )}`
}

export const filterByResult = appointmentsArray => {
  return appointmentsArray.filter(e => {
    if (e.result && (e.result == 'Positive' || e.result == 'Negative')) return e
  })
}
