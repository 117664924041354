import { Descriptions, Typography } from 'antd'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { getFullName } from '../utils/appointment-utils'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

const BillingModalContent = ({ info }) => {
  const {
    city,
    dateOfBirth,
    driverLicenseNumber,
    email,
    phoneNumber,
    socialSecurityNumber,
    state,
    street1,
    street2,
    zip,
    hasInsurance,
    insuranceMemberId,
    insuranceProviderName,
    insuredRelationship,
    slotsDateTime,
    symptoms,
    locationName,
  } = info
  const { t } = useTranslation()

  const symptonsList = t('SYMPTOMS_LIST', { returnObjects: true })
  function searchSymptom(value) {
    let found = symptonsList.find(o => o.key === value)
    return found.title
  }
  return (
    <>
      <Text>Would you like to confirm billing process?</Text>
      <Descriptions
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Name" span={4}>
          {getFullName(info)}
        </Descriptions.Item>
        <Descriptions.Item label="DOB" span={1}>
          {format(dateOfBirth, 'MM/dd/yyyy')}
        </Descriptions.Item>
        <Descriptions.Item label="Social Security Number" span={1}>
          {socialSecurityNumber || 'Not entered'}
        </Descriptions.Item>
        <Descriptions.Item label="Driver License" span={1}>
          {driverLicenseNumber || 'Not entered'}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number" span={1}>
          {phoneNumber || 'Not entered'}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={2}>
          {email || 'Not entered'}
        </Descriptions.Item>
        <Descriptions.Item label="Address" span={4}>
          {street1}
          <br />
          {street2 && (
            <>
              {street2}
              <br />
            </>
          )}
          {zip}
          <br />
          {city} - {state}
        </Descriptions.Item>
        <Descriptions.Item label="Location" span={2}>
          {locationName || 'Not specified'}
        </Descriptions.Item>
        <Descriptions.Item label="Symptoms" span={2}>
          <ul>
            {symptoms
              ? symptoms.map(i => (
                  <li key={i + socialSecurityNumber + driverLicenseNumber}>
                    {searchSymptom(i)}
                  </li>
                ))
              : 'Not specified'}
          </ul>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Appointment Date" span={3}>
          {slotsDateTime
            ? format(parseISO(slotsDateTime), 'dd, MMMM yyyy')
            : 'Not defined'}
        </Descriptions.Item>
        {hasInsurance !== 'no' && (
          <>
            <Descriptions.Item label="Insurance Number" span={1}>
              {insuranceMemberId || 'Not entered'}
            </Descriptions.Item>
            <Descriptions.Item label="Insurance Provider" span={1}>
              {insuranceProviderName || 'Not entered'}
            </Descriptions.Item>
            <Descriptions.Item label="Insurance Relationship" span={1}>
              {insuredRelationship || 'Not entered'}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </>
  )
}

export default BillingModalContent
