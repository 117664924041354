import axios from 'axios'
import convert from 'xml-js'
import { USPS_API_KEY } from '../constants'
class AddressService {
  async getAddresByZipcode(zipcode) {
    const options = {
      method: 'GET',
      url: 'https://secure.shippingapis.com/ShippingAPI.dll',
      params: {
        API: 'CityStateLookup',
        XML: `<CityStateLookupRequest USERID="${USPS_API_KEY}"><ZipCode ID="${zipcode}"><Zip5>${zipcode}</Zip5></ZipCode></CityStateLookupRequest>`,
      },
      headers: { 'Content-Type': 'application/xml' },
    }

    return axios
      .request(options)
      .then(function (response) {
        let jsonResponse = convert.xml2json(response.data, { compact: true })
        let res = JSON.parse(jsonResponse)?.CityStateLookupResponse
        const state = res?.ZipCode.State._text
        const zipcode = res?.ZipCode.Zip5._text
        const city = res?.ZipCode.City._text

        if (state) {
          return { city, state, zipcode }
        }

        return {
          city: '',
          state: '',
          zipcode: '',
          error: `Invalid Zip Code for ${zipcode}`,
        }
      })
      .catch(function (error) {
        return {
          city: '',
          state: '',
          zipcode: '',
          error: `Invalid Zip Code for ${zipcode}`,
        }
      })
  }
}

export default new AddressService()
