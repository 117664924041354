import React from 'react'
import ContentSpace from './content-space'
import styled from 'styled-components'
import Checkbox from './check-box'

const { Group } = Checkbox

const GroupCheckbox = styled(Group)`
  .ant-checkbox > * {
    margin-top: 5px !important;
  }
`
export const defaultCheckboxStyle = {
  display: 'flex',
  justifyContent: 'left',
}

export const CustomGroupCheckbox = ({ items, ...props }) => {
  return (
    <GroupCheckbox {...props}>
      <ContentSpace>
        {items.map(item => (
          <Checkbox
            key={item.value}
            value={item.value}
            style={defaultCheckboxStyle}
          >
            {item.text}
          </Checkbox>
        ))}
      </ContentSpace>
    </GroupCheckbox>
  )
}
