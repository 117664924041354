import { useEffect, useState } from 'react'

import { appointmentsIndex } from '../config/config'
import AppointmentsService from '../services/appointments-service'
import {
  algoliaSearch,
  parseSearchResultToAppointments,
} from '../utils/algolia-utils'
import {
  filterAppointmentsByRangeDate,
  filterAppointmentsByDoctor,
  filterByResult,
} from '../utils/appointment-utils'
import { handleError } from '../utils/error-utils'

export default function useReportings() {
  const [reportings, setReportings] = useState()
  const [doctorReportings, setDoctorReportings] = useState()
  const [doctorReferralCode, setDoctorReferralCode] = useState()
  const [isReportingsLoading, setIsReportingsLoading] = useState()
  const [dateSelected, setDateSelected] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })

  const [searchFilters, setSearchFilters] = useState()

  const filterIfFinalized = appointmentsArray => {
    return appointmentsArray.filter(
      item => item.finalizedAutomatedBatch || item.finalizedBatch,
    )
  }

  useEffect(() => {
    if (searchFilters) {
      return
    }

    try {
      setIsReportingsLoading(true)
      AppointmentsService.observeReportings(reportings => {
        const appointmentsRanged = filterAppointmentsByRangeDate(
          reportings,
          dateSelected,
        )
        setReportings(filterByResult(appointmentsRanged))
        setIsReportingsLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving reportings')
      setIsReportingsLoading(false)
    }

    return () => {
      AppointmentsService.unsubscribeAppointments()
    }
  }, [dateSelected, searchFilters])

  useEffect(() => {
    if (searchFilters) {
      return
    }

    try {
      setIsReportingsLoading(true)
      AppointmentsService.observeDoctorReportings(reportings => {
        const appointmentsByDoctor = filterAppointmentsByDoctor(
          reportings,
          doctorReferralCode,
        )

        setDoctorReportings(filterIfFinalized(appointmentsByDoctor))
        setIsReportingsLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving reportings')
      setIsReportingsLoading(false)
    }

    return () => {
      AppointmentsService.unsubscribeAppointments()
    }
  }, [doctorReferralCode, dateSelected, searchFilters])

  useEffect(() => {
    if (!searchFilters) {
      return
    }

    async function fetchData() {
      try {
        setIsReportingsLoading(true)
        const searchResult = await algoliaSearch(
          '',
          appointmentsIndex,
          searchFilters,
        )

        const appointmentsRanged = filterAppointmentsByRangeDate(
          parseSearchResultToAppointments(searchResult),
          dateSelected,
        )
        const appointmentsByDoctor = filterAppointmentsByDoctor(
          parseSearchResultToAppointments(searchResult),
          doctorReferralCode,
        )
        setDoctorReportings(appointmentsByDoctor)
        setReportings(filterByResult(appointmentsRanged))
        setIsReportingsLoading(false)
      } catch (error) {
        handleError(error, 'Error searching by reportings')
        setIsReportingsLoading(false)
      }
    }

    fetchData()
  }, [doctorReferralCode, dateSelected, searchFilters])

  return {
    reportings,
    isReportingsLoading,
    setSearchFilters,
    setDateSelected,
    dateSelected,
    doctorReportings,
    setDoctorReferralCode,
  }
}
