import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import getLanguage from './../utils/locale-selector'
const { Option } = Select

const LanguageMenu = () => {
  const { i18n } = useTranslation()
  const { locale } = getLanguage(i18n)
  const changeLanguage = language => {
    i18n.changeLanguage(language)
  }

  return (
    <Select
      defaultValue={locale}
      style={{ width: 110, textAlign: 'center' }}
      onChange={changeLanguage}
    >
      <Option value="pt-br" onClick={() => changeLanguage('pt-br')}>
        Português
      </Option>
      <Option value="en" onClick={() => changeLanguage('en')}>
        English
      </Option>
    </Select>
  )
}

export default LanguageMenu
