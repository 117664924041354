import React, { useEffect } from 'react'
import { Card, Typography, Descriptions } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import LinkButton from '../../components/link-button'
import { APP_NAME } from '../../constants'
import routes from '../../config/routes'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PDFResult from '../dashboard/pdf-result'

const { Text, Title } = Typography

function Results() {
  const history = useHistory()
  const location = useLocation()
  const result = location.state?.result
  useEffect(() => {
    if (!result) {
      history.replace(routes.home)
    }
  })

  function handleButtonClick() {
    history.replace(routes.createUser, { result })
  }

  if (!result) {
    return null
  }

  function renderResult(result) {
    if (result === 'Negative') {
      return (
        <Title level={1} type="success">
          Negative
        </Title>
      )
    } else if (result === 'Positive') {
      return (
        <Title level={1} type="warning">
          Positive
        </Title>
      )
    } else {
      return <Title level={1}>Invalid</Title>
    }
  }

  return (
    <ContentSpace>
      <PageTitle>COVID-19 Test Result</PageTitle>

      {result.result && (
        <Card
          title={`${result.firstName} ${
            result.middleName
              ? result.middleName + ' ' + result.lastName
              : result.lastName
          }`}
          extra={<Text>{result.appointmentId}</Text>}
        >
          {renderResult(result.result)}
        </Card>
      )}

      <Descriptions>
        <Descriptions.Item label="Test Kit Received">
          {result.testReceived}
        </Descriptions.Item>
      </Descriptions>

      {!result.signInMethods.includes('password') && (
        <>
          <Text>
            This page can only be viewed once. To maintain access to your
            results, please create a {APP_NAME} account.
          </Text>
          <LinkButton type="primary" onClick={handleButtonClick}>
            Create an account
          </LinkButton>
        </>
      )}

      {result && (
        <PDFDownloadLink
          document={<PDFResult data={result} />}
          fileName={`Patient's ${result.firstName} ${result.lastName} - Covid19 Test Result ${result.resultEnteredDate}.pdf`}
        >
          <LinkButton type="primary">Download Test Result PDF</LinkButton>
        </PDFDownloadLink>
      )}

      <Text>
        Is any of your information incorrect?{' '}
        <LinkButton type="link">Contact Us</LinkButton>
      </Text>
    </ContentSpace>
  )
}

export default Results
