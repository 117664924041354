import { getAppointmentFromStorage } from '../utils/appointment-utils'
import { FirebaseService } from './firebase-service'
import { firebase } from './firebase-service'

class EmailAuthService extends FirebaseService {
  constructor() {
    super()
    this.sendSignInLinkToEmail = firebase
      .functions()
      .httpsCallable('default-sendSignInLinkToEmail')
  }

  async sendLink() {
    try {
      const { email, id, userId } = getAppointmentFromStorage()
      await this.sendSignInLinkToEmail({ email, appointmentId: id, userId })
    } catch (error) {
      throw error
    }
  }

  isValidEmail() {
    if (this.auth.isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem('email')
      if (!email) {
        //redirect to enter email to continue
        return false
      }
      return true
    }
  }

  finishEmailUsage() {
    this.auth
      .signOut()
      .then(() => {
        //redirect to appointment confirmed
      })
      .catch(error => {
        throw error
      })
  }
}

export default new EmailAuthService()
