import styled from 'styled-components'
import { Menu as DefaultMenu } from 'antd'

const { Item } = DefaultMenu

export const MenuItem = styled(Item)`
  &.ant-menu-item {
    padding-left: ${({ theme }) => theme.spacing.sm} !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0 !important;
  }
`

export const Menu = styled(DefaultMenu)`
  &.ant-menu-inline {
    border: 1px solid ${({ theme }) => theme.colors.gray[400]} !important;
    border-radius: 5px;
    padding: 1px;
  }
  &.ant-menu {
    border: 1px solid ${({ theme }) => theme.colors.gray[400]} !important;
    border-radius: 5px;
    padding: 1px;
    & > .ant-menu-submenu.ant-menu-submenu-inline > .ant-menu-submenu-title {
      padding-left: ${({ theme }) => theme.spacing.sm} !important;
      display: flex;
      align-items: center;
      border-bottom: none;

      h5 {
        margin-bottom: 0;
      }
      .ant-menu-submenu-arrow {
        transform: scale(1.2);
      }
    }
    &
      > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
      > .ant-menu-submenu-title {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
    }
    ::before {
      display: table;
      content: none;
      padding: 1px;
    }
    ::after {
      display: inline-block;
      margin-left: ${({ theme }) => theme.spacing.xs} !important;
    }
  }
`
