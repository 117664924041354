import React from 'react'
import { Button, Typography, Tooltip } from 'antd'
import ProTable from '@ant-design/pro-table'
import useUsers from '../../hooks/use-users'
import { CheckCircleFilled, PauseCircleFilled } from '@ant-design/icons'
import { compareStrings } from '../../utils/text-utils'
import { PageContainer } from '@ant-design/pro-layout'
import useLocations from '../../hooks/use-locations'
import { useHistory } from 'react-router-dom'
import routes from '../../config/routes'

const { Text } = Typography

function returnName(name) {
  let stringResult = ''
  name !== undefined && name !== null
    ? (stringResult = name)
    : (stringResult = 'Not Filled')
  return stringResult
}
function returnRole(role) {
  let receivedRole = Object.keys(role)[0]
  let formattedRole =
    receivedRole.charAt(0).toUpperCase() + receivedRole.slice(1)
  return formattedRole
}

function getUsersTableColumns(history) {
  return [
    {
      key: 'disabled',
      title: '',
      dataIndex: 'disabled',
      width: 25,
      render: text => {
        if (text) {
          return (
            <Tooltip placement="topLeft" title={'Inactive User'}>
              <Text type="danger">
                <PauseCircleFilled />
              </Text>
            </Tooltip>
          )
        }
        return (
          <Tooltip placement="topLeft" title={'Active User'}>
            <Text type="success">
              <CheckCircleFilled />
            </Text>
          </Tooltip>
        )
      },
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: 250,
      valueType: 'text',
      search: false,
      defaultSortOrder: 'descend',
      renderText: name => returnName(name),
      sorter: {
        compare: (a, b) =>
          compareStrings(returnName(a.name), returnName(b.name)),
        multiple: 6,
      },
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) =>
          compareStrings(returnRole(a.role), returnRole(b.role)),
        multiple: 6,
      },
      renderText: role => returnRole(role),
    },
    {
      key: 'email',
      title: (_, type) => (type === 'table' ? 'Email' : 'Emails'),
      dataIndex: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 2,
      },
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, row) => [
        <Button
          type="link"
          onClick={() =>
            history.push({
              pathname: `${routes.dashboard.specimensList}/${row.uid}`,
              state: {
                isLocation: false,
              },
            })
          }
        >
          View Appointments
        </Button>,
      ],
    },
  ]
}
function getLocationsTableColumns(history) {
  return [
    {
      key: 'title',
      title: (_, type) => (type === 'table' ? 'Location' : 'Search Locations'),
      dataIndex: 'title',
      sorter: {
        compare: (a, b) => a.title.localeCompare(b.title),
        multiple: 1,
      },
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, row) => [
        <Button
          type="link"
          onClick={() =>
            history.push({
              pathname: `${routes.dashboard.specimensList}/${row.key}`,
              state: {
                isLocation: true,
              },
            })
          }
        >
          View Appointments
        </Button>,
      ],
    },
  ]
}
export default () => {
  const { specimenLocations, isLocationsLoading } = useLocations()
  const { specimenUsers, isUsersLoading } = useUsers()
  const history = useHistory()

  return (
    <PageContainer title="Specimens">
      <ProTable
        columns={getUsersTableColumns(history)}
        dataSource={specimenUsers}
        loading={
          (isUsersLoading && isLocationsLoading) ||
          (isUsersLoading && !isLocationsLoading)
        }
        rowKey="key"
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        options={false}
        dateFormatter="string"
        headerTitle="Doctor and Organization's Users"
        search={false}
      />
      <br />
      <ProTable
        columns={getLocationsTableColumns(history)}
        dataSource={specimenLocations}
        loading={
          (isLocationsLoading && isUsersLoading) ||
          (!isLocationsLoading && isUsersLoading)
        }
        rowKey="key"
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        options={false}
        dateFormatter="string"
        headerTitle="Sterling Pathology Locations"
        search={false}
      />
    </PageContainer>
  )
}
