import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import routes from '../config/routes'
import useAppointments from '../hooks/use-appointments'
import useQueryParam from '../hooks/use-query-param'
import {
  getAppointmentFromStorage,
  setAppointmentOnStorage,
} from '../utils/appointment-utils'

const AppointmentContext = createContext()
const { Provider } = AppointmentContext

function AppointmentProvider({ children }) {
  const [appointmentId, setAppointmentId] = useState()
  const { appointment, isAppointmentLoading } = useAppointments(appointmentId)
  const history = useHistory()
  const location = useLocation()
  const appointmentIdParameter = useQueryParam('appointmentId')

  useEffect(() => {
    if (!appointmentId) {
      const appointmentOnStorage = getAppointmentFromStorage()

      if (appointmentOnStorage?.id) {
        return setAppointmentId(appointmentOnStorage?.id)
      }

      if (
        location.pathname === routes.doctorReferred &&
        !!appointmentIdParameter
      ) {
        return setAppointmentId(appointmentIdParameter)
      }

      return history.replace(routes.home)
    }

    setAppointmentOnStorage({ id: appointmentId })
  }, [appointmentId, history, location, appointmentIdParameter])

  useEffect(() => {
    if (appointment?.confirmationBookAppointment) {
      history.push(routes.bookingSuccess)
    }
  }, [appointment, history])

  return (
    <Provider
      value={{
        appointmentId,
        setAppointmentId,
        appointment,
        isAppointmentLoading,
      }}
    >
      {children}
    </Provider>
  )
}

function useAppointmentContext() {
  const context = useContext(AppointmentContext)

  if (context === undefined) {
    throw new Error(
      'useAppointmentContext must be used within a AppointmentProvider',
    )
  }

  return context
}

export { useAppointmentContext, AppointmentProvider }
