import { Radio } from 'antd'
import styled from 'styled-components'

const { Group } = Radio

const StyledGroup = styled(Group)`
  .ant-radio > * {
    margin-top: 2px !important;
  }
  .ant-radio-wrapper {
    white-space: ${props => (props.noWrap ? 'pre-wrap' : 'wrap')};
  }
`

export default StyledGroup
