import { WELL_POSITION } from '../constants'
import BatchService from '../services/batch-service'
import AppointmentService from '../services/appointments-service'
import routes from '../config/routes'
import { format, isWithinInterval, parse, startOfYesterday } from 'date-fns'
import { STATUS } from '../constants'

async function updateWellPosition(batchDate, batchNumber, currentWell) {
  const nextWell = +currentWell + 1

  const arrayNextPosition = String(nextWell).split('')

  if (arrayNextPosition.length === 1) {
    arrayNextPosition.unshift('0')
  }

  let letter
  let number

  if (+currentWell < 100) {
    letter = WELL_POSITION[0][arrayNextPosition[1]]
    number = WELL_POSITION[1][arrayNextPosition[0]]
  }

  if (+currentWell >= 100) {
    letter = WELL_POSITION[0][arrayNextPosition[2]]
    number = WELL_POSITION[1][arrayNextPosition[0] + arrayNextPosition[1]]
  }

  if (letter === undefined) {
    if (+currentWell < 100) {
      arrayNextPosition[1] = 0
      arrayNextPosition[0] = +arrayNextPosition[0] + 1
    } else {
      arrayNextPosition[2] = 0
      arrayNextPosition[1] = +arrayNextPosition[1] + 1
    }
  }

  if (letter === undefined && number === '12') {
    try {
      await BatchService.updateCurrentWell(batchDate, batchNumber, {
        currentWell: false,
      })
    } catch (error) {
      console.log(error)
    }
    return false
  }

  if (+currentWell < 100) {
    try {
      await BatchService.updateCurrentWell(batchDate, batchNumber, {
        currentWell: `${arrayNextPosition[0]}${arrayNextPosition[1]}`,
      })
    } catch (error) {
      console.log(error)
    }
  } else {
    try {
      await BatchService.updateCurrentWell(batchDate, batchNumber, {
        currentWell: `${arrayNextPosition[0]}${arrayNextPosition[1]}${arrayNextPosition[2]}`,
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export async function getCurrentWell(currentWell) {
  const arrayCurrentPosition = String(currentWell).split('')

  if (arrayCurrentPosition.length === 1) {
    arrayCurrentPosition.unshift('0')
  }

  let letter
  let number

  if (+currentWell < 100) {
    letter = WELL_POSITION[0][arrayCurrentPosition[1]]
    number = WELL_POSITION[1][arrayCurrentPosition[0]]
  }

  if (+currentWell >= 100) {
    letter = WELL_POSITION[0][arrayCurrentPosition[2]]
    number = WELL_POSITION[1][arrayCurrentPosition[0] + arrayCurrentPosition[1]]
  }

  const wellPosition = `${letter}${number}`

  return wellPosition
}

export async function addWellPosition(
  appointmentId,
  batchNumber,
  batchDate,
  currentWell,
) {
  const currentWellPosition = await getCurrentWell(currentWell)

  try {
    AppointmentService.update(appointmentId, {
      batchDate,
      batchNumber,
      wellPosition: currentWellPosition,
      result: 'Pending',
    })
  } catch (error) {
    console.log(error)
  }

  await updateWellPosition(batchDate, batchNumber, currentWell)
}

export async function createNewBatch(batchDate, history) {
  const date = format(new Date(batchDate), 'dd-MM-yyyy')
  let batchNumber
  await BatchService.get(date, batch => {
    if (!batch) {
      batchNumber = 1
    } else {
      batchNumber = batch.length + 1
    }
  })
  const currentWell = '00'
  await BatchService.create({ date, batchNumber, currentWell })
  history.push({
    pathname: routes.dashboard.batchCreate,
    state: {
      batchDate: date,
      batchNumber,
    },
  })
}

export async function filterBatchAndDate(speciments, batchNumber, batchDate) {
  const filtered = speciments.filter(
    speciment =>
      speciment.batchDate === batchDate &&
      speciment.batchNumber === batchNumber &&
      !speciment.hasOwnProperty('isAutomatedBatch') &&
      speciment.confirmationBookAppointment,
  )
  return filtered
}

export async function checkStatusCompleted(batches) {
  const pendingBatches = batches.filter(
    batch =>
      (batch.result === 'Positive' && batch.confirmationResult === undefined) ||
      batch.result === 'Pending',
  )

  if (pendingBatches.length || batches.length === 0) {
    return STATUS.inProgress
  }
  return STATUS.completed
}

export function FilteredBatchByDate(batches) {
  const result = batches.filter(item => {
    const today = new Date()
    const date = parse(item.date, 'dd-MM-yyyy', new Date())

    if (isWithinInterval(date, { start: startOfYesterday(), end: today })) {
      return item
    }
    return null
  })
  return result
}

export async function checkIfFinalizeBatch(batches) {
  const batchesLength = batches.length
  const finalizedBatchLength = batches.filter(
    batch => batch.finalizedBatch === true,
  ).length

  // checks if all the specimens are finalized
  if (batchesLength === finalizedBatchLength && batchesLength > 0) {
    return true
  }
  return false
}
