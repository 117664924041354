import React from 'react'
import ContentSpace from './content-space'
import { Card } from 'antd'
import LinkButton from './link-button'
import routes from '../config/routes'
import { useTranslation } from 'react-i18next'

export const CardLocation = ({ data, isEditable = true }) => {
  const { t } = useTranslation()
  return (
    <ContentSpace>
      <Card
        title={data.siteTitle}
        extra={
          isEditable && (
            <LinkButton
              type="link"
              to={`${routes.locationOptions}?isEdit=true`}
            >
              {t('buttons.edit')}
            </LinkButton>
          )
        }
      >
        <p>{data.locationLine1}</p>
        <p>{data.locationLine2}</p>
      </Card>
    </ContentSpace>
  )
}
