import React from 'react'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import CenteredCard from '../../components/centered-card'
import { APP_NAME } from '../../constants'

const { Text } = Typography

function EmailExistent() {
  const { t } = useTranslation()
  const email = 'chris@popstand.com'

  return (
    <ContentSpace>
      <PageTitle>{t('existentEmail.title')}</PageTitle>
      <CenteredCard>{email}</CenteredCard>
      <Text>{t('existentEmail.text', { APP_NAME })}</Text>

      <ActionButtons
        hideBackButton={true}
        nextButtonUrl={routes.home}
        nextButtonTitle={t('buttons.home')}
      />
    </ContentSpace>
  )
}

export default EmailExistent
