import React, { useState } from 'react'
import { Typography, Upload, Form, Button } from 'antd'
import { PageContainer } from '@ant-design/pro-layout'
import ProForm from '@ant-design/pro-form'
import ProTable from '@ant-design/pro-table'
import SearchBox from '../../components/search-box'
import IconButton from '../../components/icon-button'
import { UploadOutlined } from '@ant-design/icons'
import { format, parse } from 'date-fns'
import ContainerInline from '../../components/container-inline'
import ConfirmResultModal from '../../components/dashboard/confirm-result-modal'
import useBatch from '../../hooks/use-batch'
import { addWellPosition, filterBatchAndDate } from '../../utils/batch-utils'
import { importExcel } from '../../utils/result-utils'
import AppointmentService from '../../services/appointments-service'
import { STATUS } from '../../constants'
import { compareWellPosition } from '../../utils/text-utils'
import { formatDateOfBirth, parseStringDOB } from '../../utils/date-utils'
import ConfirmPinModal from '../../components/dashboard/confirm-pin-modal'
import {
  successNotification,
  errorNotification,
} from '../../components/dashboard/ui-notification'
import AddNote from '../../components/dashboard/add-note-modal'
import { algoliaSearchBarcode } from '../../utils/algolia-utils'

const { Text } = Typography

const checkDisableAddNotes = row => {
  return (
    (row.result !== 'Negative' && !row.confirmationResult) || row.setFinalized
  )
}

const SheetJSFT = ['xlsx']
  .map(function (x) {
    return '.' + x
  })
  .join(',')

function getTableColumns(
  handleChangeInput,
  setSelectedRow,
  setIsNoteVisible,
  finalizeBatch,
  completed,
) {
  return [
    {
      key: 'barcode',
      title: 'Barcode #',
      dataIndex: 'barcode',
      width: 200,
      hideOn: ['lg', 'sm', 'xs'],
      renderFormItem: (_, { type, defaultRender, ...props }, form) => {
        return (
          <SearchBox
            onChange={handleChangeInput}
            placeholder="Search by barcode"
          />
        )
      },
    },
    {
      key: 'wellPosition',
      title: 'WELL',
      width: 200,
      valueType: 'text',
      search: false,
      dataIndex: 'wellPosition',
      sorter: {
        compare: (a, b) => compareWellPosition(a.wellPosition, b.wellPosition),
        multiple: 3,
      },
    },
    {
      key: 'firstName',
      title: 'First Name',
      valueType: 'text',
      search: false,
      dataIndex: 'firstName',
    },
    {
      key: 'lastName',
      title: 'Last Name',
      valueType: 'text',
      search: false,
      dataIndex: 'lastName',
    },
    {
      key: 'dateOfBirth',
      title: 'DOB',
      valueType: 'text',
      dataIndex: 'dateOfBirth',
      search: false,
      renderText: date => {
        return typeof date === 'string'
          ? parseStringDOB(date)
          : formatDateOfBirth(date)
      },
    },
    {
      key: 'result',
      title: 'Result',
      valueType: 'text',
      dataIndex: 'result',
      search: false,
      render: text => (
        <Text
          type={
            text === 'Positive'
              ? 'danger'
              : text === 'Invalid'
              ? 'warning'
              : 'success'
          }
        >
          {text}
        </Text>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      valueType: 'text',
      align: 'center',
      render: (text, row) => [
        !['Pending'].includes(row.result) && (
          <ConfirmResultModal
            curResult={row.result}
            well={row.wellPosition}
            appointmentId={row.key}
            confirmed={row.confirmationResult}
            sent={row.setFinalized}
          />
        ),
      ],
    },
    {
      valueType: 'addNote',
      align: 'right',
      render: (text, row) => [
        <Button
          disabled={checkDisableAddNotes(row)}
          onClick={() => {
            setIsNoteVisible(true)
            setSelectedRow(row)
          }}
        >
          {row?.notes ? 'View ' : 'Add '}
          Note
        </Button>,
      ],
    },
  ]
}

const BatchCreate = props => {
  const [formInstance] = ProForm.useForm()
  const { batchDate, batchNumber } = props.location.state
  const [isModalVisible, setIsModalVisible] = useState()
  const [isNoteVisible, setIsNoteVisible] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const [filteredSpecimens, setFilteredSpecimens] = useState()
  const [finalizeNegatives, setFinalizeNegatives] = useState(false)
  const {
    batch,
    appointmentsBatch,
    completed,
    finalizeBatch,
    blockAdd,
  } = useBatch(batchDate, batchNumber)
  const [form] = Form.useForm()

  const checkFinalizeDisable = () => {
    if (appointmentsBatch) {
      const hasResultsNegatives = appointmentsBatch.filter(
        appt => appt.result === 'Negative' && !appt.setFinalized,
      )
      if (hasResultsNegatives.length > 0) {
        return false
      } else {
        return true
      }
    }
  }

  const parsedDate = format(
    parse(batchDate, 'dd-MM-yyyy', new Date()),
    'EEEE, d LLLL yyyy',
  )
  const clearBarcodeInput = () => {
    form.setFieldsValue({ barcode: '' })
  }

  const onImportExcel = event => {
    importExcel(event, batchDate, batchNumber)
    checkFinalizeDisable()
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
      checkFinalizeDisable()
    }, 0)
  }

  const handleChangeInput = async e => {
    if (e.target.value.length > 0) {
      const hits = await algoliaSearchBarcode(e.target.value)
      const speciments = await filterBatchAndDate(hits, batchNumber, batchDate)
      setFilteredSpecimens(speciments)
    } else {
      setFilteredSpecimens(false)
    }
  }

  const getErrorMessage = async () => {
    const barcode = form.getFieldValue('barcode')

    if (!barcode) {
      return
    }

    const appointment = await AppointmentService.getAppointmentByBarcode(
      barcode,
    )

    if (!appointment) {
      return Promise.reject('invalid barcode or check in was not confirmed')
    }

    if (appointment) {
      const [appointmentValues] = Object.entries(appointment)

      if (appointmentValues[1]?.wellPosition) {
        return Promise.reject('sample has already been scanned')
      }
    }
  }

  const onFinish = async ({ barcode }) => {
    const { batchNumber, currentWell } = batch
    const appointment = await AppointmentService.getAppointmentByBarcode(
      barcode,
    )
    const [appointmentId] = Object.keys(appointment)
    await addWellPosition(appointmentId, batchNumber, batchDate, currentWell)
    clearBarcodeInput()
  }

  const onClickClose = () => {
    setIsNoteVisible(false)
  }
  const handleFinalizeBatch = () => {
    setIsModalVisible(true)
  }

  const handleFinalizeNegatives = () => {
    setFinalizeNegatives(true)
    setIsModalVisible(true)
  }

  const finalizeNegativesAfterPin = async value => {
    if (!value) {
      return errorNotification({
        message: 'Something went wrong',
      })
    }
    await AppointmentService.finalizeBatch(
      appointmentsBatch.filter(el => el.result === 'Negative'),
      batchDate,
      batchNumber,
      'negatives',
    )

    setFinalizeNegatives(false)
    checkFinalizeDisable()

    return successNotification({
      message: 'Negative specimens finalized',
    })
  }

  const finalizeBatchAfterPin = async value => {
    if (!value) {
      return errorNotification({
        message: 'Something went wrong',
      })
    }

    checkFinalizeDisable()

    if (!finalizeNegatives) {
      await AppointmentService.finalizeBatch(
        appointmentsBatch,
        batchDate,
        batchNumber,
        'all',
      )
      return successNotification({
        message: 'Batch finalized',
      })
    } else {
      finalizeNegativesAfterPin(value)
    }
  }

  const handleCloseButtonClick = () => {
    setIsModalVisible(false)
  }

  return (
    <PageContainer
      content={
        !finalizeBatch ? (
          <ContainerInline>
            <Form name="barcode" form={form} onFinish={onFinish}>
              <Form.Item
                name="barcode"
                rules={[
                  { required: true, message: 'Barcode is required' },
                  { validator: getErrorMessage, validateTrigger: 'submit' },
                ]}
              >
                <SearchBox
                  placeholder="Scan a Barcode"
                  enterButton="Add"
                  onSearch={form.submit}
                  disabled={batch?.currentWell === false || blockAdd}
                  value={form.getFieldValue('barcode')}
                  size="500px"
                />
              </Form.Item>
            </Form>
            <Upload
              accept={SheetJSFT}
              beforeUpload={onImportExcel}
              customRequest={dummyRequest}
            >
              <IconButton icon={<UploadOutlined />}>
                Click to Upload Results
              </IconButton>
            </Upload>
          </ContainerInline>
        ) : null
      }
      title={`${parsedDate}, - Batch #${batchNumber} - Status ${
        completed ? completed : ''
      }`}
    >
      <ProTable
        size="small"
        columns={getTableColumns(
          handleChangeInput,
          setSelectedRow,
          setIsNoteVisible,
          finalizeBatch,
          completed,
        )}
        rowKey="key"
        options={false}
        loading={!appointmentsBatch}
        dataSource={filteredSpecimens || appointmentsBatch}
        toolBarRender={() => {}}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        search={{
          searchText: 'Search',
          labelWidth: 135,
          form: formInstance,
          collapseRender: () => {},
          defaultColsNumber: 1,
          optionRender: () => [
            <Button
              type="primary"
              onClick={handleFinalizeNegatives}
              disabled={checkFinalizeDisable()}
            >
              Finalize Specimens
            </Button>,
            <Button
              type="primary"
              onClick={handleFinalizeBatch}
              disabled={completed === STATUS.inProgress || finalizeBatch}
            >
              Finalize Batch
            </Button>,
          ],
        }}
      />

      <ConfirmPinModal
        isModalVisible={isModalVisible}
        onClickClose={handleCloseButtonClick}
        onReady={finalizeBatchAfterPin}
      />

      <AddNote
        isModalVisible={isNoteVisible}
        data={selectedRow}
        onClickClose={onClickClose}
      />
    </PageContainer>
  )
}

export default BatchCreate
