import { v4 as uuidv4 } from 'uuid'

import { FirebaseService, firebase } from './firebase-service'
import { paths } from './paths'
import {
  generateConfirmationCode,
  removeEmptyValues,
  getFormattedAppointmentValues,
} from '../utils/appointment-utils'
import { parseResultsForBatches } from '../utils/results-utils'
import { endOfToday, format, subDays } from 'date-fns'
import ProfilesService from './profiles-service'
import { INDEX_LINE } from '../constants'

class AppointmentsService extends FirebaseService {
  constructor() {
    super(paths.appointments)
    this.confirmBookingAppointment = firebase
      .functions()
      .httpsCallable('default-confirmBookingAppointment')
    this.updateResultsForBatchesFunction = firebase
      .functions()
      .httpsCallable('default-updateResultsForBatches')
    this.updateResultsForAutomatedBatchesFunction = firebase
      .functions()
      .httpsCallable('default-updateResultsForAutomatedBatches')
    this.cancelAppointmentFunction = firebase
      .functions()
      .httpsCallable('default-cancelAppointment')
    this.getAppointmentToCancelFunction = firebase
      .functions()
      .httpsCallable('default-getAppointmentToCancel')
  }

  get(appointmentId, callback) {
    try {
      this.path.child(appointmentId).once('value', snapshot => {
        if (snapshot.val())
          callback({
            ...snapshot.val(),
            ...getFormattedAppointmentValues(snapshot.val()),
            id: snapshot.key,
          })
      })
    } catch (error) {
      throw error
    }
  }

  async getAppointmentsByGroupId(groupId) {
    try {
      const appointments = await this.path
        .orderByChild('groupId')
        .equalTo(+groupId)
        .once('value')

      return appointments
    } catch (error) {
      throw error
    }
  }

  async getPositivesByDoctor(id, callback) {
    try {
      const doctor = await ProfilesService.get(id)
      this.path
        .orderByChild('result')
        .equalTo('Positive')
        .on('value', snapshot => {
          const appointments = this.formatSnapshotPositiveResults(snapshot)
          callback(
            appointments.filter(
              e => e.doctorReferralCode === doctor.doctorReferralCode,
            ),
          )
        })
    } catch (error) {
      throw error
    }
  }

  observeAppointments(options = {}, callback) {
    const { locationId = undefined } = options

    try {
      if (locationId === undefined) {
        this.path.orderByChild('lastName').on('value', snapshot => {
          const appointments = this.formatSnapshot(snapshot)
          callback(appointments)
        })
      } else {
        this.path
          .orderByChild('locationId')
          .equalTo(locationId)
          .on('value', snapshot => {
            const appointments = this.formatSnapshot(snapshot)
            callback(appointments)
          })
      }
    } catch (error) {
      throw error
    }
  }
  observeAllAppointments(callback) {
    try {
      this.path.on('value', snapshot => {
        const appointments = this.formatSnapshot(snapshot)
        callback(appointments)
      })
    } catch (error) {
      throw error
    }
  }

  observeReportings(callback) {
    try {
      this.path.orderByChild('confirmation').on('value', snapshot => {
        const appointments = this.formatSnapshot(snapshot)
        callback(appointments)
      })
    } catch (error) {
      throw error
    }
  }

  observeDoctorAppointments(doctorReferralCode, callback) {
    try {
      this.path
        .orderByChild('doctorReferralCode')
        .equalTo(doctorReferralCode)
        .on('value', snapshot => {
          const appointments = this.formatDoctorSnapshot(snapshot)
          callback(appointments)
        })
    } catch (error) {
      throw error
    }
  }

  observeDoctorReportings(callback) {
    try {
      this.path.orderByChild('confirmation').on('value', snapshot => {
        const appointments = this.formatDoctorSnapshot(snapshot)
        callback(appointments)
      })
    } catch (error) {
      throw error
    }
  }

  observeBillings(callback) {
    try {
      this.path.orderByChild('lastName').on('value', snapshot => {
        let billings = this.formatSnapshot(snapshot)

        billings = billings.filter(
          billing =>
            billing.hasOwnProperty('firstName') &&
            billing.hasOwnProperty('dateOfBirth'),
        )

        callback(billings)
      })
    } catch (error) {
      throw error
    }
  }

  async getBillings(isProcessed = false, callback) {
    try {
      const snapshot = await this.path.orderByChild('lastName').once('value')

      let billings = this.formatSnapshot(snapshot)
      billings = billings.filter(
        billing =>
          billing.hasOwnProperty('firstName') &&
          billing.hasOwnProperty('dateOfBirth'),
      )
      if (!isProcessed) {
        billings = billings.filter(
          billing => !billing.hasOwnProperty('billingStatus'),
        )
      }

      callback(billings)
    } catch (error) {
      throw error
    }
  }

  observeAppointmentsByUser(uid, callback) {
    try {
      this.path
        .orderByChild('userId')
        .equalTo(uid)
        .on('value', snapshot => {
          const appointments = this.formatSnapshot(snapshot)
          callback(appointments)
        })
    } catch (error) {
      throw error
    }
  }

  observeAppointmentsByPositives(callback) {
    const currentDate = endOfToday()
    const lastThreeDays = subDays(endOfToday(), 3)
    try {
      this.path
        .orderByChild('resultEnteredDate')
        .startAt(lastThreeDays.toISOString())
        .endAt(currentDate.toISOString())
        .on('value', snapshot => {
          const appointments = this.formatSnapshotByPositives(snapshot)
          callback(appointments)
        })
    } catch (error) {
      throw error
    }
  }

  observeAppointmentsByGroupId(groupId, callback) {
    try {
      this.path
        .orderByChild('groupId')
        .equalTo(+groupId)
        .on('value', snapshot => {
          const appointments = this.formatSnapshotToTestGroup(snapshot)
          callback(appointments)
        })
    } catch (error) {
      throw error
    }
  }

  observeReceiving(callback) {
    try {
      this.path.on('value', snapshot => {
        const appointments = this.formatSnapshotToReceiving(snapshot)
        callback(appointments)
      })
    } catch (error) {
      throw error
    }
  }

  observeReceivingAppointments(date, callback) {
    try {
      this.path.on('value', snapshot => {
        const appointments = this.formatSnapshotToReceivingByDate(
          snapshot,
          date,
        )
        callback(appointments)
      })
    } catch (error) {
      throw error
    }
  }

  unsubscribeAppointments() {
    this.path.off()
  }

  unsubscribeReceiving() {
    this.path.off()
  }

  formatSnapshotPositiveResults(snapshot) {
    const appointments = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()

      if (
        values.confirmationBookAppointment &&
        (values.finalizedAutomatedBatch || values.finalizedBatch)
      ) {
        appointments.push({
          ...values,
          key: childSnapshot.key,
        })
      }
    })

    return appointments
  }

  formatSnapshotByPositives(snapshot) {
    const appointments = []
    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()

      if (values.positiveConfirmation === true) {
        appointments.push({
          ...values,
          key: childSnapshot.key,
        })
      }
    })

    return appointments
  }

  formatSnapshot(snapshot) {
    const appointments = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()

      if (values.confirmationBookAppointment) {
        const formattedValues = getFormattedAppointmentValues(values)

        appointments.push({
          ...values,
          ...formattedValues,
          key: childSnapshot.key,
        })
      }
    })

    return appointments
  }

  formatDoctorSnapshot(snapshot) {
    const appointments = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()

      if (values.doctorReferralCode) {
        const formattedValues = getFormattedAppointmentValues(values)

        appointments.push({
          ...values,
          ...formattedValues,
          key: childSnapshot.key,
        })
      }
    })

    return appointments
  }

  formatSnapshotToTestGroup(snapshot) {
    const appointmentsToTestGroup = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()
      if (values.groupId) {
        const formattedValues = getFormattedAppointmentValues(values)

        appointmentsToTestGroup.push({
          ...values,
          ...formattedValues,
          key: childSnapshot.key,
        })
      }
    })
    return appointmentsToTestGroup
  }

  formatSnapshotToReceiving(snapshot) {
    const appointmentsToReceiving = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()
      const formattedValues = getFormattedAppointmentValues(values)
      if (values?.status) {
        appointmentsToReceiving.push({
          ...values,
          ...formattedValues,
          key: childSnapshot.key,
        })
      }
    })
    return appointmentsToReceiving
  }
  formatSnapshotToReceivingByDate(snapshot, date) {
    const appointmentsToReceiving = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()
      const formattedValues = getFormattedAppointmentValues(values)
      const toFormat = values?.status?.receivedOn
      const formattedData = toFormat && format(new Date(toFormat), 'MMddyyyy')
      if (values?.status && !!formattedData && formattedData === date) {
        appointmentsToReceiving.push({
          ...values,
          ...formattedValues,
          key: childSnapshot.key,
        })
      }
    })
    return appointmentsToReceiving
  }
  async compare(appointmentId, callback) {
    return this.path.child(appointmentId).once('value', snapshot => {
      callback(snapshot.val().dateOfBirth)
    })
  }

  async create(appointmentData) {
    try {
      const confirmationCode = await generateConfirmationCode()
      const appointmentId = uuidv4()
      const appointment = {
        ...appointmentData,
        confirmationCode,
        id: appointmentId,
      }
      await this.path.child(appointmentId).set(removeEmptyValues(appointment))

      return appointment
    } catch (error) {
      throw error
    }
  }

  async update(appointmentId, appointment) {
    try {
      await this.path
        .child(appointmentId)
        .update(removeEmptyValues(appointment))
    } catch (error) {
      throw error
    }
  }

  async confirmBooking(values) {
    try {
      await this.confirmBookingAppointment({
        email: values?.personalInfo.email,

        fullName: values?.personalInfo.fullName,

        appointmentId: values.appointmentId,
        locationId: values?.locationId,
        confirmationCode: values.confirmationCode,
        timeSlotDay: values?.timeSlot.dateString,
        timeSlotInterval: values?.timeSlot.timeString,
        timeSlotDateTime: values?.timeSlot.isoString,

        locationTitle: values?.drivethrough.siteTitle,
        locationAddress: values?.drivethrough.locationLine1,
        locationCity: values?.drivethrough.locationLine2,

        dateOfBirth: values?.personalInfo.birthDate,
      })
    } catch (error) {
      throw error
    }
  }

  async delete(appointmentId) {
    await this.path.child(appointmentId).remove()
  }

  async deleteValues(appointmentId, valuesArray) {
    try {
      const valuesObject = valuesArray.reduce((object, item) => {
        object[item] = null
        return object
      }, {})

      await this.path.child(appointmentId).update(valuesObject)
    } catch (error) {
      throw error
    }
  }

  async updateResults({ data, batchDate, batchNumber }) {
    let fluorIndex = data[INDEX_LINE].indexOf('Fluor')
    let sampleIndex = data[INDEX_LINE].indexOf('Sample')
    let cqIndex = data[INDEX_LINE].indexOf('Cq')
    const { positive, negative, invalid } = await parseResultsForBatches(
      data,
      fluorIndex,
      sampleIndex,
      cqIndex,
    )

    return await this.updateResultsForBatchesFunction({
      positive,
      negative,
      invalid,
      batchDate,
      batchNumber,
    })
  }

  async updateAutomatedResults({ data, batchDate, batchNumber }) {
    let fluorIndex = data[INDEX_LINE].indexOf('Fluor')
    let sampleIndex = data[INDEX_LINE].indexOf('Sample')
    let cqIndex = data[INDEX_LINE].indexOf('Cq')
    const { positive, negative, invalid } = await parseResultsForBatches(
      data,
      fluorIndex,
      sampleIndex,
      cqIndex,
    )
    return await this.updateResultsForAutomatedBatchesFunction({
      positive,
      negative,
      invalid,
      batchDate,
      batchNumber,
    })
  }

  async getAppointmentByBarcode(barcode) {
    const appointment = await this.path
      .orderByChild('barcode')
      .equalTo(barcode)
      .once('value')

    return appointment.val()
  }

  async cancelAppointment(appointmentId) {
    try {
      const appointment = await this.getAppointmentToCancelFunction({
        appointmentId,
      })
      return appointment?.data
    } catch (error) {
      throw error
    }
  }

  async finalizeBatch(appointments, batchDate, batchNumber, resultType) {
    const user = await firebase.auth().currentUser
    const batchIndex = batchNumber - 1
    appointments.map(async appointment => {
      if (!appointment.setFinalized && !appointment.finalizeBatch) {
        await this.path.child(appointment.key).update({
          finalizedBatch: true,
          resultEnteredDate: new Date(),
          resultEnteredByName: user.displayName,
          setFinalized: true,
        })

        if (resultType === 'all') {
          await paths.batches.child(`${batchDate}/${batchIndex}`).update({
            status: 'Completed',
          })
        }
      }
    })
  }

  async finalizeAutomatedBatch(
    appointments,
    batchDate,
    batchNumber,
    resultType,
  ) {
    const user = await firebase.auth().currentUser
    const batchIndex = batchNumber - 1
    appointments.map(async appointment => {
      if (!appointment.setFinalized) {
        await this.path.child(appointment.key).update({
          finalizedAutomatedBatch: true,
          resultEnteredDate: new Date(),
          resultEnteredByName: user.displayName,
          setFinalized: true,
        })

        if (resultType === 'all') {
          await paths.automatedBatches
            .child(`${batchDate}/${batchIndex}`)
            .update({
              status: 'Completed',
            })
        }
      }
    })
  }
}

export default new AppointmentsService()
