import { useCallback, useEffect, useState } from 'react'

import { appointmentsIndex } from '../config/config'
import AppointmentsService from '../services/appointments-service'
import {
  algoliaSearch,
  parseSearchResultToAppointments,
} from '../utils/algolia-utils'
import { isBillingStatusPending } from '../utils/appointment-utils'
import { handleError } from '../utils/error-utils'

export default function useBillings(billingId = undefined) {
  const [billings, setBillings] = useState()
  const [isProcessed, setIsProcessed] = useState(false)
  const [isBillingsLoading, setIsBillingsLoading] = useState()
  const [billing, setBilling] = useState()
  const [isBillingLoading, setIsBillingLoading] = useState()
  const [searchQuery, setSearchQuery] = useState()

  const filterProcessedBillings = useCallback(
    appointmentsArray => {
      return appointmentsArray.filter(appointment =>
        isProcessed ? true : isBillingStatusPending(appointment?.billingStatus),
      )
    },
    [isProcessed],
  )

  useEffect(() => {
    if (billingId || searchQuery?.length > 0) {
      return
    }

    try {
      setIsBillingsLoading(true)
      AppointmentsService.observeBillings(billings => {
        setBillings(filterProcessedBillings(billings))
        setIsBillingsLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving billings')
      setIsBillingsLoading(false)
    }

    return () => {
      AppointmentsService.unsubscribeAppointments()
    }
  }, [billingId, searchQuery, filterProcessedBillings])

  useEffect(() => {
    if (billingId || !searchQuery || searchQuery?.length === 0) {
      return
    }

    async function fetchData() {
      try {
        setIsBillingsLoading(true)

        const searchResult = await algoliaSearch(searchQuery, appointmentsIndex)
        const processedBillings = filterProcessedBillings(
          parseSearchResultToAppointments(searchResult),
        )

        setBillings(processedBillings)
        setIsBillingsLoading(false)
      } catch (error) {
        handleError(error, 'Error searching by billings')
        setIsBillingsLoading(false)
      }
    }

    fetchData()
  }, [billingId, searchQuery, filterProcessedBillings])

  useEffect(() => {
    if (!billingId) {
      return
    }

    try {
      setIsBillingLoading(true)
      AppointmentsService.get(billingId, billing => {
        setBilling(billing)
        setIsBillingLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving Appointment')
      setIsBillingLoading(false)
    }
  }, [billingId])

  return {
    billing,
    billings,
    isBillingLoading,
    isBillingsLoading,
    isProcessed,
    setIsProcessed,
    setSearchQuery,
  }
}
