import styled from 'styled-components'

export const LoginButton = styled.div`
  margin-bottom: 1rem;
  border-radius: 3px;
  padding: 4px 8px;
  color: #1890ff;
  font-size: 14px;
  background: white;
  border: 1px solid #1890ff;
  width: 7rem;
  text-align: center;
`
