import React from 'react'
import { conformToMask } from 'react-text-mask'
import { InputMasked } from './styles/masked-input'

export default function SocialSecurityMasked(props) {
  const MASK = [
    /[*,0-9]/,
    /[*,0-9]/,
    /[*,0-9]/,
    '-',
    /[*,0-9]/,
    /[*,0-9]/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ]

  return <InputMasked {...props} mask={MASK} guide showMask />
}
