const theme = {
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '22px',
    xg: '40px',
  },
  colors: {
    white: '#fff',
    black: '#000',
    danger: {
      light: '#ff7875',
    },
    primary: {
      light: '#40a9ff',
      main: '#1890ff',
      pdfBlue: '#218bc4',
    },
    gray: {
      200: '#f5f5f5',
      300: '#f0f0f0',
      400: '#d9d9d9',
      600: '#8c8c8c',
      800: '#282828',
    },
  },
  fontSize: {
    sm: '15px',
  },
}

export default theme
