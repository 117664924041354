import React, { useState, useEffect } from 'react'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import { format, parse } from 'date-fns'
import useReceiving from '../../hooks/use-receiving'
import ProForm from '@ant-design/pro-form'
import ProfilesService from '../../services/profiles-service'
import { compareStrings } from '../../utils/text-utils'

function getTableColumns(profiles) {
  return [
    {
      key: 'barcode',
      title: 'Barcode',
      valueType: 'text',
      dataIndex: 'barcode',
      search: false,
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => compareStrings(a.barcode, b.barcode),
        multiple: 3,
      },
    },
    {
      key: 'firstName',
      title: 'First Name',
      valueType: 'text',
      dataIndex: 'firstName',
    },
    {
      key: 'lastName',
      title: 'Last Name',
      valueType: 'text',
      dataIndex: 'lastName',
    },
    {
      key: 'dateOfBirth',
      title: 'DOB',
      valueType: 'text',
      dataIndex: 'dateOfBirth',
      search: false,
      renderText: date => {
        return format(date, 'MM/dd/yyyy')
      },
    },
    {
      key: 'doctorReferralCode',
      title: (_, type) =>
        type === 'table'
          ? 'Doctor/organization'
          : 'Filter by Doctor/Organization',
      dataIndex: 'doctorReferralCode',
      valueType: 'select',
      hideInTable: true,
      fieldProps: {
        placeholder: 'Search',
        options:
          profiles &&
          profiles.map((e, key) => ({
            key,
            label:
              e.doctorReferralCode !== 'undefined'
                ? `Dr. ${e.fullName}`
                : `Org. ${e.fullName}`,
            value:
              e.doctorReferralCode && e.doctorReferralCode !== 'undefined'
                ? e.doctorReferralCode
                : e.uid,
          })),
      },
    },
  ]
}

const ReceivingAppointments = props => {
  const [formInstance] = ProForm.useForm()
  const [filteredAppointments, setFilteredAppointments] = useState()
  const [profiles, setProfiles] = useState()
  const { date } = props.location.state
  const {
    receivingAppointments,
    isReceivingAppointmentsLoading,
  } = useReceiving(date)
  const parsedDate =
    date && format(parse(date, 'MMddyyyy', new Date()), 'EEEE, LLLL do yyyy')

  useEffect(() => {
    async function get() {
      try {
        var doc = []
        var org = []
        await ProfilesService.observeDoctorByName(profiles => {
          profiles.map(p => {
            if (p.doctorReferralCode === 'undefined') {
              org.push(p)
            } else {
              doc.push(p)
            }
          })
          setProfiles(doc.concat(org))
        })
      } catch (error) {
        console.log(error)
      }
    }
    get()
  }, [])

  const handleSearchSubmit = () => {
    var filtered = receivingAppointments

    if (formInstance.getFieldValue('firstName'))
      filtered = filtered.filter(
        e => e.firstName === formInstance.getFieldValue('firstName'),
      )
    if (formInstance.getFieldValue('lastName'))
      filtered = filtered.filter(
        e => e.lastName === formInstance.getFieldValue('lastName'),
      )
    if (formInstance.getFieldValue('doctorReferralCode'))
      if (formInstance.getFieldValue('doctorReferralCode').length > 7)
        filtered = filtered.filter(
          e =>
            e.organizationId &&
            e.organizationId ==
              formInstance.getFieldValue('doctorReferralCode'),
        )
      else
        filtered = filtered.filter(
          e =>
            e.doctorReferralCode ==
            formInstance.getFieldValue('doctorReferralCode'),
        )

    setFilteredAppointments(filtered)
  }

  const handleResetSearch = () => {
    setFilteredAppointments()
  }

  return (
    <PageContainer title={parsedDate}>
      <ProTable
        size="small"
        columns={getTableColumns(profiles)}
        rowKey="key"
        options={false}
        loading={isReceivingAppointmentsLoading}
        dataSource={filteredAppointments || receivingAppointments}
        search={{
          form: formInstance,
          labelWidth: [100, 200],
          searchText: 'Search',
        }}
        toolBarRender={() => {}}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        onSubmit={handleSearchSubmit}
        onReset={handleResetSearch}
      />
    </PageContainer>
  )
}

export default ReceivingAppointments
