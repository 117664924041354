import React from 'react'
import { Form, Typography, Input } from 'antd'
import AppointmentService from '../../services/appointments-service'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { STATES } from '../../constants'
import { ProFormSelect } from '@ant-design/pro-form'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import LinkButton from '../../components/link-button'
import Spin from '../../components/main-spin'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import useQueryParam from '../../hooks/use-query-param'
import AddressService from '../../services/address-service'

const { Text } = Typography

function PersonalAddress() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()
  const isEditable = useQueryParam('isEdit') === 'true'

  const onFinish = async values => {
    if (appointmentId) {
      try {
        await AppointmentService.update(appointmentId, {
          ...values,
          homeless: false,
        })
        goToNextPage()
      } catch (error) {
        handleError(error, t('errorMessage.addressError'))
      }
    }
  }

  const isNextButtonDisabled = () => {
    return (
      hasFormErrors(form) ||
      !form.getFieldValue('zip') ||
      !form.getFieldValue('street1') ||
      !form.getFieldValue('city') ||
      !form.getFieldValue('state')
    )
  }

  const handleClick = async () => {
    try {
      await AppointmentService.update(appointmentId, {
        homeless: true,
      })
      await AppointmentService.deleteValues(appointmentId, [
        'zip',
        'street1',
        'street2',
        'city',
        'state',
      ])
      goToNextPage()
    } catch (error) {
      handleError(error, t('errorMessage.homelessError'))
    }
  }

  const goToNextPage = () => {
    if (isEditable) {
      history.push(routes.bookingConfirmation)
    } else {
      history.push(routes.fullName)
    }
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }
  const getAddressInfo = async typedZip => {
    let response = await AddressService.getAddresByZipcode(typedZip)
    form.setFieldsValue({ state: response.state, city: response.city })
    if (response.error) {
      return false
    }
    return true
  }

  return (
    <ContentSpace>
      <PageTitle>{t('address.title')}</PageTitle>
      <Text>{t('address.text')}</Text>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={appointment}
      >
        <Form.Item
          label={t('address.zipcode')}
          name="zip"
          rules={[
            {
              required: true,
              message: `${t('rules.zipRequired')}`,
            },
            {
              async validator(_, value) {
                if (
                  value &&
                  value.length === 5 &&
                  (await getAddressInfo(value))
                ) {
                  return Promise.resolve()
                }
                form.setFieldsValue({ state: '', city: '' })
                return Promise.reject('Invalid Zip code')
              },
            },
          ]}
        >
          <Input placeholder="#####" />
        </Form.Item>

        <Input.Group>
          <Form.Item
            name="street1"
            label={t('address.street')}
            style={{ margin: '8px 0' }}
            rules={[
              { required: true, message: `${t('rules.streetRequired')}` },
            ]}
          >
            <Input placeholder={t('address.address1')} />
          </Form.Item>
          <Form.Item name="street2">
            <Input placeholder={t('address.address2')} />
          </Form.Item>
        </Input.Group>

        <Form.Item style={{ display: 'flex' }}>
          <Form.Item
            label={t('address.city')}
            name="city"
            rules={[{ required: true, message: `${t('rules.cityRequired')}` }]}
            style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
          >
            <Input placeholder={t('address.cityPlaceholder')} />
          </Form.Item>
          <Form.Item
            label={t('address.state')}
            name="state"
            rules={[{ required: true, message: `${t('rules.stateRequired')}` }]}
            style={{
              display: 'inline-block',
              width: 'calc(40% - 8px)',
              marginLeft: '16px',
            }}
          >
            <ProFormSelect
              name="state"
              placeholder={t('address.state')}
              valueEnum={STATES}
              width="100%"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            backButtonUrl={
              isEditable ? routes.bookingConfirmation : routes.timeSlots
            }
          />
        </Form.Item>
        <LinkButton
          onClick={handleClick}
          type="link"
          htmlType="button"
          style={{ position: 'relative', padding: 0 }}
        >
          {t('particularity.homeless')}
        </LinkButton>
      </Form>
    </ContentSpace>
  )
}

export default PersonalAddress
