import React from 'react'
import { Typography, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import Radio from '../../components/radio'
import {
  getAppointmentFromStorage,
  setAppointmentOnStorage,
} from '../../utils/appointment-utils'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import StyledGroup from '../../components/styled-group'

const { Text } = Typography

const radioStyle = {
  display: 'flex',
  justifyContent: 'left',
}

function TestReason() {
  const appointment = getAppointmentFromStorage()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()
  const testReasonsList = t('TEST_REASONS_LIST', { returnObjects: true })

  const onFinish = async values => {
    try {
      if (!appointment.contactWithPerson) {
        throw new Error(
          'You need to inform first if you have been in contact with a person infected',
        )
      }

      setAppointmentOnStorage({ ...appointment, ...values })
      history.push(routes.emailAdd)
    } catch (error) {
      handleError(error)
    }
  }

  const isNextButtonDisabled = () => {
    return hasFormErrors(form) || !form.getFieldValue('reasonForTesting')
  }

  return (
    <ContentSpace>
      <PageTitle>{t('testReason.title')}</PageTitle>
      <Text>{t('testReason.text')}</Text>

      <Form form={form} onFinish={onFinish} initialValues={appointment}>
        <Form.Item
          name="reasonForTesting"
          rules={[{ required: true, message: 'Please select a reason!' }]}
        >
          <StyledGroup>
            <ContentSpace>
              {testReasonsList.map(item => {
                return (
                  <Radio key={item.key} value={item.value} style={radioStyle}>
                    {item.title}
                  </Radio>
                )
              })}
            </ContentSpace>
          </StyledGroup>
        </Form.Item>

        <Form.Item>
          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            backButtonUrl={routes.contact}
          />
        </Form.Item>
      </Form>
    </ContentSpace>
  )
}

export default TestReason
