import styled from 'styled-components'
import { Typography } from 'antd'

const { Text } = Typography

const TextSpacing = styled(Text)`
  margin-right: 20px;
  font-weight: ${props => (props.big ? '400' : 'normal')};
  font-size: ${props => (props.big ? '18px' : '')};
`

export default TextSpacing
