import styled from 'styled-components'
import MaskedInput from 'react-text-mask'

export const InputMasked = styled(MaskedInput)`
  font-size: ${props => (props.antdStyle ? '14px' : '18px')};
  letter-spacing: 5px;
  padding-left: 10px;

  width: ${props => (props.antdStyle ? '100%' : 'auto')};
  box-sizing: ${props => (props.antdStyle ? 'border-box' : 'none')};
  padding: ${props => (props.antdStyle ? '4px 8px' : '')};
  border: ${props =>
    props.antdStyle ? '1px solid #d9d9d9' : '1px solid #d9d9d9'};
  color: ${props =>
    props.antdStyle ? 'rgba(0, 0, 0, 0.25)' : 'black'}!important;
  border-radius: ${props => (props.antdStyle ? '2px' : '2px')};
  -webkit-transition: ${props => (props.antdStyle ? 'all 0.3s' : '')};
  transition: ${props => (props.antdStyle ? 'all 0.3s' : '')};

  :focus {
    color: black !important;
    border-color: ${props => (props.antdStyle ? '#40a9ff' : 'none')};
    border-right-width: ${props => (props.antdStyle ? '1px !important' : '')};
    outline: ${props => (props.antdStyle ? '0' : '')};
    -webkit-box-shadow: ${props =>
      props.antdStyle ? '0 0 0 2px rgb(24 144 255 / 20%)' : ''};
    box-shadow: ${props =>
      props.antdStyle ? '0 0 0 2px rgb(24 144 255 / 20%)' : ''};
  }
`
