import { useEffect, useState, useContext } from 'react'

import { locationsIndex } from '../config/config'
import LocationService from '../services/location-service'
import {
  algoliaSearch,
  parseSearchResultToLocations,
} from '../utils/algolia-utils'
import { handleError } from '../utils/error-utils'
import { UserContext } from '../contexts/user-context'
import ProfilesService from '../services/profiles-service'

export default function useLocations(locationId) {
  const [locations, setLocations] = useState()
  const [specimenLocations, setSpecimenLocations] = useState()
  const [isLocationsLoading, setIsLocationsLoading] = useState()
  const [location, setLocation] = useState()
  const [isLocationLoading, setIsLocationLoading] = useState()
  const [searchQuery, setSearchQuery] = useState()
  const { role, user } = useContext(UserContext)

  const filterNonDoctor = locations => {
    return locations.filter((item, index) => {
      let isNotDoctor = !item.doctorReferralCode
      return isNotDoctor
    })
  }

  useEffect(() => {
    if (locationId || searchQuery?.length > 0) {
      return
    }

    if (role === 'doctor') {
      async function fetchData() {
        try {
          setIsLocationsLoading(true)
          const profile = await ProfilesService.get(user.uid)
          LocationService.observeLocationsByDoctor(
            profile.doctorReferralCode,
            locations => {
              setLocations(locations)
              setIsLocationsLoading(false)
            },
          )
        } catch (error) {
          handleError(error, 'Error retrieving locations')
          setIsLocationsLoading(false)
        }
      }
      fetchData()
    }

    if (role !== 'doctor') {
      try {
        setIsLocationsLoading(true)
        LocationService.observeAllLocations(locations => {
          setSpecimenLocations(filterNonDoctor(locations))
          setIsLocationsLoading(false)
        })
        LocationService.observeLocations(locations => {
          setLocations(locations)
          setIsLocationsLoading(false)
        })
      } catch (error) {
        handleError(error, 'Error retrieving locations')
        setIsLocationsLoading(false)
      }
    }

    return () => {
      LocationService.unsubscribeLocations()
    }
  }, [locationId, role, searchQuery, user.uid])

  useEffect(() => {
    if (locationId || !searchQuery || searchQuery?.length === 0) {
      return
    }

    async function fetchData() {
      try {
        setIsLocationsLoading(true)
        const searchResult = await algoliaSearch(searchQuery, locationsIndex)

        if (role === 'doctor') {
          const profile = await ProfilesService.get(user.uid)
          const searchResultFiltered = searchResult.filter(
            e => e.doctorReferralCode === profile.doctorReferralCode && e,
          )
          setLocations(parseSearchResultToLocations(searchResultFiltered))
        } else if (role === 'admin') {
          const searchResultFiltered = searchResult.filter(e => {
            if (!e.doctorReferralCode && !e.isDisabled && e.address) return e
          })
          setLocations(parseSearchResultToLocations(searchResultFiltered))
        }

        setIsLocationsLoading(false)
      } catch (error) {
        handleError(error, 'Error searching by insurances')
        setIsLocationsLoading(false)
      }
    }

    fetchData()
  }, [searchQuery, locationId, role, user.uid])

  useEffect(() => {
    if (!locationId) {
      return
    }

    try {
      setIsLocationLoading(true)
      LocationService.getLocation(locationId, location => {
        setLocation(location)
        setIsLocationLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving location')
      setIsLocationLoading(false)
    }
  }, [locationId])

  return {
    specimenLocations,
    locations,
    isLocationsLoading,
    location,
    isLocationLoading,
    setSearchQuery,
  }
}
