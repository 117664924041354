import { addDays, endOfToday } from 'date-fns'

import { FirebaseService } from './firebase-service'
import { paths } from './paths'

class LocationScheduleService extends FirebaseService {
  constructor() {
    super(paths.locationsSchedule)
  }

  observeLocationSchedule(locationId, callback) {
    try {
      this.path.child(locationId).on('value', snapshot => {
        const schedule = this.formatSnapshot(snapshot)
        callback(schedule)
      })
    } catch (error) {
      throw error
    }
  }

  getLocationSchedule = async (locationId, callback) => {
    try {
      let schedules = await this.path.child(locationId).once('value')
      const schedule = this.formatSnapshot(schedules)
      return schedule
    } catch (error) {
      throw error
    }
  }

  observeLocationScheduleByDate(locationId, callback = () => null) {
    const currentDate = new Date()
    const nextTwoDays = addDays(endOfToday(), 2)

    try {
      this.path
        .child(locationId)
        .orderByChild('startDate')
        .startAt(currentDate.toISOString())
        .endAt(nextTwoDays.toISOString())
        .on('value', snapshot => {
          const schedule = this.formatSnapshot(snapshot)
          callback(schedule)
        })
    } catch (error) {
      throw error
    }
  }

  unsubscribeLocationSchedule() {
    this.path.off()
  }

  formatSnapshot(snapshot) {
    const schedule = []

    snapshot.forEach(childSnapshot => {
      if (childSnapshot.val().slots < 1) {
        return
      }
      schedule.push({
        ...childSnapshot.val(),
        key: childSnapshot.key,
        startDate: childSnapshot.val().startDate,
      })
    })

    return schedule
  }

  async update(locationId, location) {
    try {
      await this.path.child(locationId).set(location)
      await paths.locations
        .child(locationId)
        .update({ hasScheduleAvailable: true })
    } catch (error) {
      throw error
    }
  }

  async delete(locationId) {
    await this.path.child(locationId).remove()
  }
}

export default new LocationScheduleService()
