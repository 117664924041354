import { WELL_POSITION } from '../constants'
import AutomatedBatchService from '../services/automated-batch-service'
import AppointmentService from '../services/appointments-service'
import StorageService from '../services/storage-service'
import { handleError } from '../utils/error-utils'
import { successNotification } from '../components/dashboard/ui-notification'

import routes from '../config/routes'
import { format, isWithinInterval, parse, startOfYesterday } from 'date-fns'
import { STATUS } from '../constants'
import { saveAs } from 'file-saver'

import XLSX from 'xlsx'

async function updateWellPosition(
  batchDate,
  batchNumber,
  currentWell,
  newWells,
) {
  const nextWell = +currentWell + newWells

  const arrayNextPosition = String(nextWell).split('')

  if (arrayNextPosition.length === 1) {
    arrayNextPosition.unshift('0')
  }

  let letter
  let number

  if (+currentWell < 100) {
    letter = WELL_POSITION[0][arrayNextPosition[1]]
    number = WELL_POSITION[1][arrayNextPosition[0]]
  }

  if (+currentWell >= 100) {
    letter = WELL_POSITION[0][arrayNextPosition[2]]
    number = WELL_POSITION[1][arrayNextPosition[0] + arrayNextPosition[1]]
  }

  if (letter === undefined) {
    if (+currentWell < 100) {
      arrayNextPosition[1] = 0
      arrayNextPosition[0] = +arrayNextPosition[0] + 1
    } else {
      arrayNextPosition[2] = 0
      arrayNextPosition[1] = +arrayNextPosition[1] + 1
    }
  }

  if (letter === undefined && number === '12') {
    return false
  }

  if (+currentWell < 100) {
    try {
      await AutomatedBatchService.updateCurrentWell(batchDate, batchNumber, {
        currentWell: `${arrayNextPosition[0]}${arrayNextPosition[1]}`,
      })
    } catch (error) {
      console.log(error)
    }
  } else {
    try {
      await AutomatedBatchService.updateCurrentWell(batchDate, batchNumber, {
        currentWell: `${arrayNextPosition[0]}${arrayNextPosition[1]}${arrayNextPosition[2]}`,
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export async function getCurrentWell(currentWell) {
  const arrayCurrentPosition = String(currentWell).split('')

  if (arrayCurrentPosition.length === 1) {
    arrayCurrentPosition.unshift('0')
  }

  let letter
  let number

  if (+currentWell < 100) {
    letter = WELL_POSITION[0][arrayCurrentPosition[1]]
    number = WELL_POSITION[1][arrayCurrentPosition[0]]
  }

  if (+currentWell >= 100) {
    letter = WELL_POSITION[0][arrayCurrentPosition[2]]
    number = WELL_POSITION[1][arrayCurrentPosition[0] + arrayCurrentPosition[1]]
  }

  const wellPosition = `${letter}${number}`

  return wellPosition
}

export const createNewAutomatedBatch = async (batchDate, history) => {
  const date = format(new Date(batchDate), 'dd-MM-yyyy')
  let response = await AutomatedBatchService.get(date, batch => batch)
  let batchNumber = response ? response.length + 1 : 1

  const currentWell = '02'
  await AutomatedBatchService.create({ date, batchNumber, currentWell })
  await history.push({
    pathname: routes.dashboard.automatedBatchCreate,
    state: {
      batchDate: date,
      batchNumber,
    },
  })
}

export async function filterBatchAndDate(speciments, batchNumber, batchDate) {
  return speciments.filter(
    speciment =>
      speciment.batchDate === batchDate &&
      speciment.batchNumber === batchNumber &&
      speciment.isAutomatedBatch &&
      speciment.confirmationBookAppointment,
  )
}

export async function checkStatusCompleted(batches) {
  const pendingBatches = batches.filter(
    batch =>
      (batch.result === 'Positive' && batch.confirmationResult === undefined) ||
      batch.result === 'Pending' ||
      !batch.result,
  )

  if (pendingBatches.length || batches.length === 0) {
    return STATUS.inProgress
  }
  return STATUS.completed
}

export function FilteredBatchByDate(batches) {
  const result = batches.filter(item => {
    const today = new Date()
    const date = parse(item.date, 'dd-MM-yyyy', new Date())

    if (isWithinInterval(date, { start: startOfYesterday(), end: today })) {
      return item
    }
    return null
  })
  return result
}

export async function checkIfFinalizeBatch(batches) {
  const batchesLength = batches.length
  const finalizedBatchLength = batches.filter(
    batch =>
      batch.finalizedBatch === true || batch.finalizedAutomatedBatch === true,
  ).length

  // checks if all the specimens are finalized
  if (batchesLength === finalizedBatchLength && batchesLength > 0) {
    return true
  }
  return false
}

export async function import700(file, batchDate, batchNumber) {
  try {
    await StorageService.uploadAutomatedBatchesFile(
      file,
      batchDate,
      batchNumber,
    )
    successNotification({
      message: 'Uploaded successfully!',
    })
  } catch (error) {
    handleError(error, 'File upload error!')
  }
}
export async function removeAutomatedBatches(batches, batchDate, batchNumber) {
  try {
    await AutomatedBatchService.removeAutomatedBatch(
      batches,
      batchDate,
      batchNumber,
    )
    successNotification({
      message: 'Removed successfully!',
    })
  } catch (error) {
    handleError(error, 'File remove error!')
  }
}
export const exportCSV = (batches, batchDate, batchNumber) => {
  const ws = XLSX.utils.json_to_sheet(batches)
  const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',' })
  const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' })
  saveAs(
    blob,
    `automated_batch-batchDate_${batchDate}-batchNumber_${batchNumber}.csv`,
  )
}

export const formatDataToCSV = async batches => {
  const batchFormatted = batches.map(async batch => {
    let Row = batch.wellPosition.substr(0, 1)
    let ColumnFormatted = formatWellPosition(batch.wellPosition)
    let Column = ColumnFormatted.split(Row)[1]
    return {
      Row,
      Column,
      '*Target Name': '',
      '*Sample Name': batch.barcode,
      '*Biological Group': '',
    }
  })
  const batchesFormatted = await Promise.all(batchFormatted)
  var collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  })
  const sortedBatches = batchesFormatted.sort(collator.compare)
  return sortedBatches
}

export const formatWellPosition = wellPosition => {
  const letter = wellPosition[0]
  const number = wellPosition.split(letter)[1]
  let correctWellPosition
  if (Number(number) < 10) {
    correctWellPosition = `${letter}${number[1]}`
  } else {
    correctWellPosition = wellPosition
  }
  return correctWellPosition
}
