import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import 'antd/dist/antd.css'
import '@ant-design/pro-layout/dist/layout.min.css'
import '@ant-design/pro-table/dist/table.min.css'
import '@ant-design/pro-form//dist/form.min.css'
import { Spin } from 'antd'
import './styles/index.css'
import './services/firebase-service'
import App from './App'
import * as serviceWorker from './serviceWorker'
import theme from './styles/theme'
import './i18n'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <div>
            <Spin />
          </div>
        }
      >
        <App />
      </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
