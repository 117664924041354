import { FirebaseService, firebase } from './firebase-service'
import { paths } from './paths'
import { format } from 'date-fns'
import { successNotification } from '../components/dashboard/ui-notification'
import { handleError } from '../utils/error-utils'

class ResultService extends FirebaseService {
  constructor() {
    super(paths.resultFiles)
    this.sendTestResultEmailFunction = firebase
      .functions()
      .httpsCallable('default-sendTestResultEmail')
    this.updateEditedResultsFunction = firebase
      .functions()
      .httpsCallable('default-updateEditedResults')
  }

  add = (file, fileData) => {
    const currentDay = new Date()
    const date = format(currentDay, 'MM/dd/yyyy')
    const time = format(currentDay, 'h:mm a')

    this.path.child(file.uid).set({
      name: file.name,
      date,
      time,
      link: fileData.linkToDownload,
      processed: false,
    })
  }

  observeResultFiles = callback => {
    try {
      this.path.on('value', snapshot => {
        const resultFiles = this.formatSnapshot(snapshot)
        callback(resultFiles)
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  formatSnapshot = snapshot => {
    const resultFiles = []

    snapshot.forEach(childSnapshot => {
      const values = childSnapshot.val()
      resultFiles.push({
        ...values,
        key: childSnapshot.key,
      })
    })

    return resultFiles
  }

  sendTestResultEmail = async (
    appointmentId,
    email,
    result,
    confirmation,
    userId,
  ) => {
    try {
      await this.sendTestResultEmailFunction({
        appointmentId,
        email,
        result,
        confirmation,
        userId,
      })
      successNotification({
        message: 'Email successfully sent',
      })
    } catch (error) {
      handleError(error, 'Error sending email')
      throw error
    }
  }

  updateEditedResults = async (appointmentId, editedValues) => {
    try {
      await this.updateEditedResultsFunction({
        appointmentId,
        editedValues,
      })
      successNotification({
        message: 'Data successfully updated',
      })
    } catch (error) {
      handleError(error, 'Error updating data')
      throw error
    }
  }

  unsubscribeResults = () => {
    this.path.off()
  }
}

export default new ResultService()
