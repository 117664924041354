import React from 'react'
import { InputMasked } from './styles/masked-input'

export default function PhoneMasked(props) {
  return (
    <InputMasked
      {...props}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide
      showMask
      autoFocus={props.autoFocus}
    />
  )
}
