import PeopleService from '../services/people-service'
import { handleError } from '../utils/error-utils'

export function addPeople({ organizationId, appointment }) {
  try {
    PeopleService.add({
      organizationId,
      appointment,
    })
  } catch (error) {
    handleError(error, 'Error when creating people')
  }
}
