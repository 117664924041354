import React from 'react'
import { Button } from 'antd'

function IconButton({ children, icon, handleClick, type, disabled }) {
  return (
    <Button
      type={type}
      icon={icon}
      onClick={handleClick}
      disabled={disabled}
      htmlType="submit"
    >
      {children}
    </Button>
  )
}

export default IconButton
