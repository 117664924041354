import React from 'react'
import { Form, Typography, Input } from 'antd'
import AppointmentService from '../../services/appointments-service'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import Spin from '../../components/main-spin'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'

const { Text } = Typography

function FullName() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const url = new URL(window.location.href)
  const isEdit = url.searchParams.get('isEdit')
  const { t } = useTranslation()

  const onFinish = async values => {
    if (appointmentId) {
      try {
        await AppointmentService.update(appointmentId, { ...values })
        if (isEdit) {
          history.push(routes.bookingConfirmation)
        } else {
          history.push(routes.birthDate)
        }
      } catch (error) {
        handleError(error, 'Error saving your name')
      }
    }
  }

  const isNextButtonDisabled = () => {
    return (
      hasFormErrors(form) ||
      !form.getFieldValue('firstName') ||
      !form.getFieldValue('lastName')
    )
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle>{t('fullName.title')}</PageTitle>
      <Text>{t('fullName.text')}</Text>
      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        initialValues={appointment}
      >
        <Form.Item
          label={t('fullName.firstName')}
          name="firstName"
          rules={[
            { required: true, message: `${t('messages.firstNameRequired')}` },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('fullName.middleName')} name="middleName">
          <Input />
        </Form.Item>
        <Form.Item
          label={t('fullName.lastName')}
          name="lastName"
          rules={[
            { required: true, message: `${t('messages.lastNameRequired')}` },
          ]}
        >
          <Input />
        </Form.Item>
        <ActionButtons
          isNextButtonDisabled={isNextButtonDisabled}
          backButtonUrl={
            isEdit ? routes.bookingConfirmation : routes.personalAddress
          }
        />
      </Form>
    </ContentSpace>
  )
}

export default FullName
