import React, { useState } from 'react'
import { Typography, Form } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../components/check-box'
import AppointmentService from '../../services/appointments-service'
import PageTitle from '../../components/page-title'
import routes from '../../config/routes'
import ContentSpace from '../../components/content-space'
import ActionButtons from '../../components/action-buttons'
import { CardDemographic } from '../../components/card-demographic'
import { CardLocation } from '../../components/card-location'
import { CardTimeSlot } from '../../components/card-time-slot'
import { CardPersonalInfo } from '../../components/card-personal-info'
import { CardIdentification } from '../../components/card-identification'
import Spin from '../../components/main-spin'
import {
  parseAppointment,
  isRequiredDataFilled,
} from '../../utils/appointment-utils'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import useLocations from '../../hooks/use-locations'

const { Text } = Typography

const { Group } = Checkbox

export const defaultCheckboxStyle = {
  display: 'flex',
  justifyContent: 'left',
}
export default function BookingConfirmation() {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const { location, isLocationLoading } = useLocations(appointment?.locationId)
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()

  const onFinish = async () => {
    setIsSubmitting(true)

    if (!isRequiredDataFilled(appointment)) {
      form.setFields([
        {
          name: 'confirmation',
          errors: [`${t('rules.dataRequired')}`],
        },
      ])
      setIsSubmitting(false)
      return
    }

    try {
      await AppointmentService.update(appointmentId, {
        confirmationBookAppointment: true,
        locationIdentifier: location.locationIdentifier,
      })
      await AppointmentService.confirmBooking(
        parseAppointment(appointmentId, appointment, location),
      )
      history.push(routes.bookingSuccess)
    } catch (error) {
      setIsSubmitting(false)
      handleError(error, t('errorMessage.bookingError'))
    }
  }

  const isNextButtonDisabled = () => {
    return (
      hasFormErrors(form) ||
      !form.getFieldValue('confirmation') ||
      form.getFieldValue('confirmation')?.length < 2 ||
      !appointment.slotsDateTime ||
      !isRequiredDataFilled(appointment) ||
      isSubmitting
    )
  }

  if (
    isAppointmentLoading ||
    isAppointmentLoading === undefined ||
    isLocationLoading ||
    isLocationLoading === undefined
  ) {
    return <Spin />
  }

  const { timeSlot, personalInfo, demographic, identification, drivethrough } =
    appointment &&
    location &&
    parseAppointment(appointmentId, appointment, location)

  return (
    <ContentSpace>
      <PageTitle>{t('bookingConfirmation.title')}</PageTitle>
      <Text>{t('bookingConfirmation.text')}</Text>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          confirmation: appointment?.confirmationBookAppointment && [1, 2],
          ...appointment,
        }}
      >
        <Form.Item
          name="confirmation"
          rules={[{ required: true, message: t('messages.markCheckbox') }]}
        >
          <Group>
            <ContentSpace>
              <Checkbox style={defaultCheckboxStyle} value={1}>
                {t('confirmation_checks.check1')}
              </Checkbox>
              <Checkbox style={defaultCheckboxStyle} value={2}>
                <Text>
                  <b>{t('confirmation_checks.check2_part1')}</b>
                  {t('confirmation_checks.check2_part2')}
                </Text>
              </Checkbox>
            </ContentSpace>
          </Group>
        </Form.Item>

        <ActionButtons
          backButtonUrl={routes.driverLicense}
          isNextButtonDisabled={isNextButtonDisabled}
          nextButtonTitle={t('bookingConfirmation.confirmButton')}
          loading={isSubmitting}
        />
      </Form>

      {appointment && location && (
        <>
          <CardLocation data={drivethrough} />
          <CardTimeSlot data={timeSlot} />
          <CardPersonalInfo data={personalInfo} />
          <CardDemographic data={demographic} />
          <CardIdentification data={identification} />
        </>
      )}
    </ContentSpace>
  )
}
