import React from 'react'

import { Typography } from 'antd'
import ContentSpace from '../../components/content-space'
import PageTitle from '../../components/page-title'
import useQueryParam from '../../hooks/use-query-param'
import useAppointments from '../../hooks/use-appointments'
import Spin from '../../components/main-spin'
import LinkButton from '../../components/link-button'
import routes from '../../config/routes'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

function CanceledAppointment() {
  const appointmentId = useQueryParam('appointmentId')
  const { isCanceled, appointment } = useAppointments(appointmentId)
  const { t } = useTranslation()

  if (isCanceled === undefined || !appointment) {
    return <Spin />
  }

  const renderTitle = () => {
    if (isCanceled) {
      return t('cancel.title')
    } else if (isCanceled === null) {
      return t('cancel.titleCanceled')
    } else {
      return t('cancel.titleError')
    }
  }

  return (
    <ContentSpace>
      <PageTitle>{renderTitle()}</PageTitle>
      <Text>{t('cancel.confirmation', { String: appointment?.email })}</Text>
      <Text>{t('cancel.newAppointment')}</Text>
      <LinkButton
        title={t('cancel.titleButton')}
        type="primary"
        to={routes.home}
      />
    </ContentSpace>
  )
}

export default CanceledAppointment
