import React from 'react'
import { parse } from 'date-fns'
import { Form, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import PageTitle from '../../components/page-title'
import routes from '../../config/routes'
import ContentSpace from '../../components/content-space'
import ActionButtons from '../../components/action-buttons'
import {
  getAppointmentFromStorage,
  setAppointmentOnStorage,
} from '../../utils/appointment-utils'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import DateMasked from '../../components/date-masked'
import {
  isDateBeforeTwoThousandTwenty,
  isDateAfterToday,
  removeMask,
} from '../../utils/date-utils'

const { Text } = Typography

function SymptomsDate() {
  const appointment = getAppointmentFromStorage()
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()

  const onFinish = async ({ symptomsDate }) => {
    const dateString = removeMask(symptomsDate)
    try {
      if (!appointment.symptoms) {
        throw new Error('You need to inform your symptoms first')
      }

      setAppointmentOnStorage({ ...appointment, symptomsDate: dateString })
      history.push(routes.contact)
    } catch (error) {
      handleError(error)
    }
  }

  const checkSymptomsDate = (_, value) => {
    const dateString = removeMask(value)
    if (dateString.length === 8) {
      try {
        const parsedValue = parse(dateString, 'MMddyyyy', new Date())

        if (isDateAfterToday(parsedValue)) {
          return Promise.reject(`${t('messages.futureDate')}`)
        }

        if (isDateBeforeTwoThousandTwenty(parsedValue)) {
          return Promise.reject(`${t('messages.invalidYear')}`)
        }

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(`${t('messages.invalidDate')}`)
      }
    }
  }

  const isNextButtonDisabled = () => {
    const symptomsDate = form.getFieldValue('symptomsDate')
    const dateString = removeMask(symptomsDate)
    return (
      hasFormErrors(form) ||
      !form.getFieldValue('symptomsDate') ||
      dateString?.length < 8
    )
  }

  return (
    <ContentSpace>
      <PageTitle>{t('symptomsDate.title')}</PageTitle>
      <Text>{t('general.date')} (MM/DD/YYYY)</Text>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ symptomsDate: appointment?.symptomsDate }}
      >
        <Form.Item
          name="symptomsDate"
          rules={[{ validator: checkSymptomsDate }]}
        >
          <DateMasked />
        </Form.Item>
        <Form.Item>
          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            backButtonUrl={routes.symptoms}
          />
        </Form.Item>
      </Form>
    </ContentSpace>
  )
}

export default SymptomsDate
