import React, { useState } from 'react'
import { Form, Typography, Input } from 'antd'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { useTranslation } from 'react-i18next'
import routes from '../../config/routes'
import AppointmentService from '../../services/appointments-service'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import LinkButton from '../../components/link-button'
import { sendEmailLogin } from '../../utils/authentication-utils'
import {
  getAppointmentFromStorage,
  setAppointmentOnStorage,
} from '../../utils/appointment-utils'
import { handleError } from '../../utils/error-utils'
import UserService from '../../services/user-service'

const { Text } = Typography

const EmailAdd = () => {
  const appointment = getAppointmentFromStorage()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [form] = Form.useForm()
  const history = useHistory()
  const { t } = useTranslation()

  const onFinish = async ({ email }) => {
    setDisabled(true)
    setIsSubmitting(true)

    try {
      if (!appointment.contactWithPerson) {
        throw new Error('You need to inform the test reason first')
      }

      const user = await UserService.getUserByEmail(email)
      const userId = user?.uid || uuidv4()

      const savedAppointment = await AppointmentService.create({
        ...appointment,
        email,
        userId,
      })

      setAppointmentOnStorage(savedAppointment)
      await sendEmailLogin()
      history.push(routes.emailSuccess)
    } catch (error) {
      setIsSubmitting(false)
      setDisabled(false)
      handleError(error, 'Error sending the email link')
    }
  }

  const handleChange = value => {
    if (value[0]) {
      if (value[0].errors.length > 0) {
        setDisabled(true)
      } else if (!value[0].errors !== 0 && value[0].value) {
        setDisabled(false)
      }
    }
  }

  return (
    <ContentSpace>
      <PageTitle>{t('addEmail.title')}</PageTitle>
      <Text>{t('addEmail.text')}</Text>

      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        onFieldsChange={handleChange}
      >
        <Form.Item
          label={t('personalData.email')}
          name="email"
          rules={[
            {
              required: true,
              message: `${t('messages.validEmail')}`,
              type: 'email',
            },
          ]}
        >
          <Input placeholder="example@example.com" />
        </Form.Item>
        <Form.Item>
          <LinkButton to={routes.noEmail} type={'dashed'}>
            {t('messages.noEmail')}
          </LinkButton>
        </Form.Item>

        <ActionButtons
          backButtonUrl={routes.testReason}
          isNextButtonDisabled={disabled}
          loading={isSubmitting}
        />
      </Form>
    </ContentSpace>
  )
}

export default EmailAdd
