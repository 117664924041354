import React, { useState } from 'react'
import { Typography, Modal } from 'antd'
import ProTable from '@ant-design/pro-table'
import { PageContainer } from '@ant-design/pro-layout'
import { compareAsc, format, parseISO } from 'date-fns'
import useAppointmentsByUser from '../../hooks/use-appointments-by-user'
import { compareStrings } from '../../utils/text-utils'
import { PDFViewer } from '@react-pdf/renderer'
import PDFResult from './pdf-result'
import PdfActions from '../../components/dashboard/pdf-actions'

const { Text } = Typography

function getTableColumns(setVisible, setAppointment) {
  return [
    {
      key: 'locationName',
      title: 'Location',
      dataIndex: 'locationName',
      sorter: {
        compare: (a, b) => compareStrings(a.locationName, b.locationName),
        multiple: 4,
      },
    },
    {
      key: 'confirmationCode',
      title: 'Code',
      dataIndex: 'confirmationCode',
      sorter: {
        compare: (a, b) => compareStrings(a.confirmationId, b.confirmationId),
        multiple: 3,
      },
    },
    {
      key: 'confirmation',
      title: 'Confirmation',
      dataIndex: 'confirmation',
      renderText: date => {
        return date && format(parseISO(date), 'MM/dd/yyyy')
      },
      sorter: {
        compare: (a, b) =>
          compareAsc(parseISO(a.confirmation), parseISO(b.confirmation)),
        multiple: 2,
      },
    },
    {
      key: 'result',
      title: 'Result',
      dataIndex: 'result',
      render: _ =>
        _ === 'Positive' ? (
          <Text type="danger">{_}</Text>
        ) : _ === 'Invalid' ? (
          <Text>{_}</Text>
        ) : (
          <Text type="success">{_}</Text>
        ),
      sorter: {
        compare: (a, b) => compareStrings(a.result, b.result),
        multiple: 1,
      },
    },
    {
      key: 'option',
      width: 35,
      valueType: 'option',
      render: (_, row) => [
        <PdfActions
          row={row}
          setAppointment={setAppointment}
          setVisible={setVisible}
        />,
      ],
    },
  ]
}

function ResultsList() {
  const {
    appointmentsByUser,
    isAppointmentsByUserLoading,
  } = useAppointmentsByUser()
  const [visible, setVisible] = useState(false)
  const [appointment, setAppointment] = useState()
  return (
    <PageContainer>
      <ProTable
        columns={getTableColumns(setVisible, setAppointment)}
        dataSource={appointmentsByUser}
        loading={isAppointmentsByUserLoading}
        options={false}
        search={false}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        dateFormatter="string"
        rowKey="key"
        headerTitle="Covid-19 results"
      />
      <Modal
        width={770}
        visible={visible}
        closable
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <PDFViewer width={700} height={700}>
          <PDFResult data={appointment} />
        </PDFViewer>
      </Modal>
    </PageContainer>
  )
}

export default ResultsList
