import styled from 'styled-components'
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui'

const {
  TimeScaleLabel: DefaultTimeScaleLabel,
  TimeScaleLayout: DefaultTimeScaleLayout,
} = WeekView

const TimeScaleLabel = styled(DefaultTimeScaleLabel)`
  height: 24px !important;
  line-height: 24px !important;
`

const TimeScaleLayout = styled(DefaultTimeScaleLayout)`
  height: 24px !important;

  & [class*='TickCell-cell-'] {
    height: 24px !important;
  }

  & [class*='Label-emptyLabel']:last-child {
    display: none;
  }
`

export { TimeScaleLabel, TimeScaleLayout }
