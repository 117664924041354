import { v4 as uuidv4 } from 'uuid'

import { USER_ROLES } from '../constants'
import { firebase } from './firebase-service'
import { paths } from './paths'

class UserService {
  constructor() {
    this.auth = firebase.auth
    this.addUserFunction = firebase.functions().httpsCallable('default-addUser')
    this.addGuestUserFunction = firebase
      .functions()
      .httpsCallable('default-addGuestUser')
    this.updateUserFunction = firebase
      .functions()
      .httpsCallable('default-updateUser')
    this.getUserFunction = firebase.functions().httpsCallable('default-getUser')
    this.getUserByEmailFunction = firebase
      .functions()
      .httpsCallable('default-getUserByEmail')
    this.resetUserPassword = firebase
      .functions()
      .httpsCallable('default-resetPassword')
    this.deleteUser = firebase.functions().httpsCallable('default-deleteUser')
    this.toggleUserEnable = firebase
      .functions()
      .httpsCallable('default-toggleUserEnable')
    this.setGuestClaim = firebase
      .functions()
      .httpsCallable('default-setGuestClaim')
    this.getUsersFunction = firebase
      .functions()
      .httpsCallable('default-getUsers')
    this.createUserToken = firebase
      .functions()
      .httpsCallable('default-createUserToken')
    this.updateProfile = firebase
      .functions()
      .httpsCallable('default-updateProfile')
    this.switchToUser = firebase
      .functions()
      .httpsCallable('default-switchToUser')
  }

  async signInWithCustomToken(token) {
    try {
      await firebase.auth().signInWithCustomToken(token)
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    } catch (error) {
      throw error
    }
  }

  async create(user) {
    try {
      if (user) {
        user.pass = uuidv4().slice(0, 7).toString()
        if (user.type === USER_ROLES.admin) {
          user.claim = { admin: true }
        } else if (user.type === USER_ROLES.lab) {
          user.claim = { lab: true }
        } else if (user.type === USER_ROLES.collection) {
          user.claim = { collection: true }
        } else if (user.type === USER_ROLES.billing) {
          user.claim = { billing: true }
        } else if (user.type === USER_ROLES.reporting) {
          user.claim = { reporting: true }
        } else if (user.type === USER_ROLES.organization) {
          user.claim = { organization: true }
        } else if (user.type === USER_ROLES.doctor) {
          user.claim = { doctor: true }
        }
        return this.addUserFunction({ user })
      }
    } catch (error) {
      console.log(error)
    }
  }

  async createGuestUser({ userId, email }) {
    try {
      return this.addGuestUserFunction({ userId, email })
    } catch (error) {
      throw error
    }
  }

  async updateAuthProvider({ email, password, uid }) {
    try {
      const credential = this.auth.EmailAuthProvider.credential(email, password)
      await this.auth().currentUser.linkWithCredential(credential)
      await this.setGuestClaim({ uid })
    } catch (error) {
      throw error
    }
  }

  async resetPassword(email) {
    try {
      await this.resetUserPassword(email)
    } catch (error) {
      console.log(error)
    }
  }

  async toggleUserEnabledStatus(userId) {
    try {
      await this.toggleUserEnable(userId)
    } catch (error) {
      console.log(error)
    }
  }

  async deleteUserRegister(userId) {
    try {
      await this.deleteUser(userId)
    } catch (error) {
      console.log(error)
    }
  }

  async getUsers(filters, callback) {
    try {
      const response = await this.getUsersFunction(filters)
      callback(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  async getUser(uid, callback) {
    try {
      const response = await this.getUserFunction(uid)
      callback(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  async getUserByEmail(email) {
    try {
      const response = await this.getUserByEmailFunction(email)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async updateUser(uid, user) {
    try {
      if (user.type === 'admin') {
        user.claim = { admin: true }
      } else if (user.type === 'lab') {
        user.claim = { lab: true }
      } else if (user.type === 'collection') {
        user.claim = { collection: true }
      } else if (user.type === 'billing') {
        user.claim = { billing: true }
      } else if (user.type === 'reporting') {
        user.claim = { reporting: true }
      } else if (user.type === 'organization') {
        user.claim = { organization: true }
      } else if (user.type === 'doctor') {
        user.claim = { doctor: true }
      }
      user.uid = uid
      await this.updateUserFunction(user)
      await this.updateProfile(user)
    } catch (error) {
      console.log(error)
    }
  }

  async signOut(callback = () => {}) {
    try {
      await this.auth().signOut()
      callback()
    } catch (error) {
      throw error
    }
  }

  async fetchSignInMethodsForEmail(email) {
    try {
      if (!email) {
        return []
      }

      return firebase.auth().fetchSignInMethodsForEmail(email)
    } catch (error) {
      throw error
    }
  }

  async getIdTokenResult() {
    try {
      return firebase.auth().currentUser.getIdTokenResult()
    } catch (error) {
      throw error
    }
  }

  observeUser(callback) {
    try {
      return firebase.auth().onAuthStateChanged(callback)
    } catch (error) {
      throw error
    }
  }

  getUserInfo(uid, callback) {
    try {
      paths.profiles.child(uid).on('value', snapshot => {
        callback(snapshot.val())
      })
    } catch (error) {
      throw error
    }
  }

  getUserDoctorRefCode(uid, callback) {
    try {
      paths.profiles.child(uid).on('value', snapshot => {
        if (snapshot.val()) callback(snapshot.val().doctorReferralCode)
      })
    } catch (error) {
      throw error
    }
  }

  async switchToUserId(uid) {
    try {
      const token = await this.switchToUser({ uid })
      firebase.auth().signInWithCustomToken(token.data)
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}
export default new UserService()
