import React, { useContext } from 'react'
import { Form, Button } from 'antd'
import { PageContainer } from '@ant-design/pro-layout'
import ProTable from '@ant-design/pro-table'
import SearchBox from '../../components/search-box'
import ContainerInline from '../../components/container-inline'
import AppointmentService from '../../services/appointments-service'
import { UserContext } from '../../contexts/user-context'
import { useHistory } from 'react-router-dom'
import useReceiving from '../../hooks/use-receiving'
import { format, parse } from 'date-fns'
import routes from '../../config/routes'
import { successNotification } from '../../components/dashboard/ui-notification'

function getTableColumns(history) {
  return [
    {
      key: 'date',
      title: 'Date',
      width: 300,
      valueType: 'text',
      dataIndex: 'date',
      renderText: date => {
        return (
          date &&
          format(parse(date, 'MMddyyyy', new Date()), 'EEEE, LLLL do yyyy')
        )
      },
      sorter: {
        compare: (a, b) => a.date - b.date,
      },
      defaultSortOrder: 'descend',
    },
    {
      valueType: 'option',
      align: 'right',
      render: (text, { date }) => [
        <Button
          type="link"
          onClick={() => {
            history.push({
              pathname: routes.dashboard.receivingAppointments,
              state: {
                date,
              },
            })
          }}
        >
          View
        </Button>,
      ],
    },
  ]
}

const ReceivingList = props => {
  const [form] = Form.useForm()
  const { user } = useContext(UserContext)
  const { receiving } = useReceiving()
  const history = useHistory()

  const clearBarcodeInput = () => {
    form.setFieldsValue({ barcode: '' })
  }

  const getErrorMessage = async () => {
    const barcode = form.getFieldValue('barcode')
    if (!barcode) {
      return
    }

    const appointment = await AppointmentService.getAppointmentByBarcode(
      barcode,
    )

    if (!appointment) {
      return Promise.reject('invalid barcode or check in was not confirmed')
    }

    if (appointment) {
      const [appointmentValues] = Object.entries(appointment)

      if (appointmentValues[1]?.status?.receivedOn) {
        return Promise.reject('sample has already been scanned')
      }
    }
  }

  const handleSubmit = (value, event) => {
    if (event.nativeEvent.key === 'Enter') {
      return
    }
    form.submit()
  }

  const onFinish = async ({ barcode }) => {
    const appointment = await AppointmentService.getAppointmentByBarcode(
      barcode,
    )
    const [appointmentId] = Object.keys(appointment)
    const { firstName, lastName } = appointment[appointmentId]

    await AppointmentService.update(appointmentId, {
      status: {
        receivedOn: new Date(),
        receivedBy: user?.uid,
      },
    })

    successNotification({
      message: 'Sample Received',
      description: (
        <span>
          <b>
            {barcode}: {firstName} {lastName}
          </b>{' '}
          was received
        </span>
      ),
    })

    clearBarcodeInput()
  }

  return (
    <PageContainer
      content={
        <ContainerInline>
          <Form name="barcode" form={form} onFinish={onFinish}>
            <Form.Item
              name="barcode"
              rules={[
                { required: true, message: 'Barcode is required' },
                { validator: getErrorMessage, validateTrigger: 'submit' },
              ]}
            >
              <SearchBox
                placeholder="Scan a Barcode"
                enterButton="Add"
                onSearch={handleSubmit}
                value={form.getFieldValue('barcode')}
                size="500px"
              />
            </Form.Item>
          </Form>
        </ContainerInline>
      }
    >
      <ProTable
        size="small"
        columns={getTableColumns(history)}
        rowKey="key"
        options={false}
        loading={false}
        dataSource={receiving}
        toolBarRender={() => {}}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        search={false}
      />
    </PageContainer>
  )
}

export default ReceivingList
