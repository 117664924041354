import React from 'react'
import { Modal, Typography, Button, Form, Radio } from 'antd'
import RadioItem from '../../components/radio-item'
import ContentModal from '../../components/content-modal'
import { updateConfirmationResult } from '../../utils/result-utils'

const { Text } = Typography

export default function ConfirmResultModal({
  curResult,
  well,
  appointmentId,
  confirmed,
  sent,
}) {
  const [modal, contextHolder] = Modal.useModal()
  const [form] = Form.useForm()

  const onFinish = async values => {
    updateConfirmationResult(values, appointmentId)
  }

  const chekDisabled = (sent, confirmed, curResult) => {
    let disable = true
    if (curResult === 'Negative' || !curResult) {
      return disable
    } else if (sent || confirmed) {
      return disable
    } else {
      disable = false
    }
    return disable
  }

  const renderForm = () => {
    return (
      <Form name="confirmationResult" form={form} onFinish={onFinish}>
        <Form.Item
          name="confirmationResult"
          rules={[{ required: true, message: 'please choose an option!' }]}
        >
          <Radio.Group>
            <RadioItem value="Positive">
              <Text type="danger">Positive</Text>
            </RadioItem>
            <RadioItem value="Negative">
              <Text type="success">Negative</Text>
            </RadioItem>
            <RadioItem value="Invalid">
              <Text type="warning">Invalid</Text>
            </RadioItem>
          </Radio.Group>
        </Form.Item>
      </Form>
    )
  }

  const config = {
    title: 'Confirm Result',
    content: (
      <ContentModal>
        <Text>
          Would you like to confirm <Text strong>{curResult}</Text> result for{' '}
          <Text strong>WELL# {well}</Text>?
        </Text>
        {renderForm()}
      </ContentModal>
    ),
    width: 500,
    cancelText: 'Return',
    okText: 'Confirm',
    centered: true,
    onOk: () => form.submit(),
  }

  return (
    <>
      <Button
        type={!confirmed && curResult !== 'Negative' ? 'link' : 'text'}
        disabled={chekDisabled(sent, confirmed, curResult)}
        onClick={() => {
          modal.confirm(config)
        }}
      >
        {!confirmed && curResult !== 'Negative' && curResult
          ? 'Confirm Result'
          : ''}
        {sent && 'Result Sent'}
      </Button>
      {contextHolder}
    </>
  )
}
