import { useEffect, useMemo, useState } from 'react'

import UserService from '../services/user-service'
import { handleError } from '../utils/error-utils'
import ProfilesService from '../services/profiles-service'

export default function useUsers(userId) {
  const [users, setUsers] = useState()
  const [specimenUsers, setSpecimenUsers] = useState()
  const [user, setUser] = useState()
  const [isUsersLoading, setIsUsersLoading] = useState(false)
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [searchFilter, setSearchFilters] = useState()
  const filters = useMemo(() => searchFilter, [searchFilter])

  const filterOrgAndDoc = async users => {
    const responses = await Promise.all(
      users.map(async user => await ProfilesService.get(user.uid)),
    )
    const results = await users.filter((user, index) => {
      let isOrgOrDoc = !!user.role.organization || !!user.role.doctor
      let haveProfile = responses[index]
      let isReady = isOrgOrDoc && !!haveProfile
      return isReady
    })
    return results
  }

  useEffect(() => {
    if (userId) return

    setIsUsersLoading(true)

    try {
      UserService.getUsers(filters, async response => {
        setSpecimenUsers(await filterOrgAndDoc(response))
        setUsers(response)
        setIsUsersLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving users')
      setIsUsersLoading(false)
    }
  }, [userId, filters])

  function reloadUsers() {
    setIsUsersLoading(true)
    try {
      UserService.getUsers(filters, async response => {
        setSpecimenUsers(await filterOrgAndDoc(response))
        setUsers(response)
        setIsUsersLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving users')
      setIsUsersLoading(false)
    }
  }

  useEffect(() => {
    if (!userId) {
      return
    }
    setIsUserLoading(true)
    try {
      UserService.getUser(userId, async response => {
        setSpecimenUsers(await filterOrgAndDoc(response))
        setUser(response)
        setIsUserLoading(false)
      })
    } catch (error) {
      handleError(error, 'Error retrieving an user')
      setIsUserLoading(false)
    }
  }, [userId])

  return {
    users,
    specimenUsers,
    isUsersLoading,
    user,
    isUserLoading,
    reloadUsers,
    setSearchFilters,
    setIsUsersLoading,
  }
}
