import { successNotification } from '../components/dashboard/ui-notification'
import LocationScheduleService from '../services/locations-schedule-service'
import { handleError } from './error-utils'

const INITIAL_AMOUNT_OF_SLOTS = 30

export async function createLocationSchedule(
  locationId,
  schedule,
  initialSlots,
) {
  try {
    const formatedSchedule = formatSchedule(schedule, initialSlots)
    await LocationScheduleService.update(locationId, formatedSchedule)
    successNotification({
      message: 'Schedule updated successfully!',
    })
  } catch (error) {
    handleError(error, 'Error creating schedule')
  }
}
export async function checkScheduleOperation(
  locationId,
  schedule,
  initialSlots,
) {
  try {
    let checkLocation = await LocationScheduleService.getLocationSchedule(
      locationId,
    )
    if (!checkLocation) {
      return createLocationSchedule(locationId, schedule, initialSlots)
    }
    let formattedSchedule = await formatEditedSchedule(
      schedule,
      checkLocation,
      initialSlots,
    )

    LocationScheduleService.update(locationId, formattedSchedule)
    successNotification({
      message: 'Schedule updated successfully!',
    })
  } catch (error) {
    handleError(error, 'Error creating schedule')
  }
}

function formatSchedule(schedule, initialSlots) {
  return schedule.map(item => ({
    initialSlots,
    startDate: item.startDate,
    slots: initialSlots,
  }))
}

async function formatEditedSchedule(schedule, scheduleDB, initialSlots) {
  let toModify = []
  await schedule.forEach((item, index) => {
    if (!scheduleDB[index]) {
      toModify.push({
        initialSlots,
        startDate: item.startDate,
        slots: item.slots ? item.slots : initialSlots,
      })
      return
    }
    let slotsAvailable =
      scheduleDB[index]?.slots < INITIAL_AMOUNT_OF_SLOTS
        ? scheduleDB[index]?.slots
        : initialSlots
    toModify.push({
      initialSlots,
      startDate: item.startDate,
      slots: slotsAvailable,
    })
  })
  return toModify
}
