import React from 'react'
import { ViewState } from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  WeekView,
  Appointments,
  DateNavigator,
  TodayButton,
  Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui'

import SchedulerTableCell from './scheduler-table-cell'
import SchedulerBasicTimeSlot from './scheduler-basic-time-slot'
import { useLocationScheduleContext } from '../../contexts/location-schedule-context'
import DayScaleCell from './day-scale-cell'
import { TimeScaleLabel, TimeScaleLayout } from './time-scale'
import { TimeSlotContainer } from './time-slot'
import SchedulerContainer from './scheduler-container'

export default function LocationScheduler() {
  const { schedule } = useLocationScheduleContext()

  return (
    <SchedulerContainer>
      <Scheduler data={schedule}>
        <ViewState defaultCurrentDate={new Date()} />
        <WeekView
          cellDuration={30}
          startDayHour={7}
          endDayHour={20.3}
          timeTableCellComponent={SchedulerTableCell}
          dayScaleCellComponent={DayScaleCell}
          timeScaleLabelComponent={TimeScaleLabel}
          timeScaleLayoutComponent={TimeScaleLayout}
        />
        <Toolbar />
        <DateNavigator />
        <TodayButton />
        <Appointments
          containerComponent={TimeSlotContainer}
          appointmentComponent={SchedulerBasicTimeSlot}
        />
      </Scheduler>
    </SchedulerContainer>
  )
}
