import styled from 'styled-components'
const { device } = require('../constants')

const PageTitle = styled.h1.attrs(props => ({
  fontSize: props?.fontSize || '30px',
}))`
  margin-bottom: 0;
  line-height: 36px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  @media ${device.mobileS}, ${device.mobileM} {
    font-size: ${({ fontSize }) => (fontSize === 22 ? '22px' : '30px')};
  }
  @media ${device.mobileL} {
    font-size: 30px;
  }
`

export default PageTitle
