import { Upload } from 'antd'
import styled from 'styled-components'

export const UploadImage = styled(Upload)`
  text-align: end;
`
export const CenteredUploadImage = styled(Upload)`
  text-align: center;
  .ant-upload-select-picture-card {
    margin: 10px 0;
  }
`
export default UploadImage
