import React, { useEffect, useState } from 'react'
import { Modal, Typography, Button, Form, Input } from 'antd'
import ContentModal from '../../components/content-modal'
import { updateConfirmationResult } from '../../utils/result-utils'
import { CloseOutlined } from '@ant-design/icons'
import {
  successNotification,
  errorNotification,
} from '../../components/dashboard/ui-notification'
import AppointmentsService from '../../services/appointments-service'

const { Text } = Typography
const { TextArea } = Input

export default function AddNote({ data, isModalVisible, onClickClose }) {
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({ notes: data?.notes })
  }, [data, form])
  const onFinish = async values => {
    try {
      let notes = values?.notes
      if (!notes) {
        resetForm()
        return errorNotification({
          message: 'You cannot add an empty note',
        })
      }
      await AppointmentsService.update(data?.key, {
        notes,
      })
      successNotification({ message: 'Note successfully updated' })
      return onClickClose()
    } catch (err) {
      return errorNotification({
        message: 'You cannot add an empty note',
      })
    }
  }

  function resetForm() {
    form.setFieldsValue()
  }

  return (
    <>
      <Modal
        title={`Add a Note to ${data?.barcode}`}
        visible={isModalVisible}
        centered
        closeIcon={<CloseOutlined onClick={onClickClose} />}
        afterClose={resetForm}
        destroyOnClose
        onOk={() => form.submit()}
        footer={[
          <Button key="back" onClick={onClickClose}>
            Return
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            onClick={() => form.submit()}
          >
            Submit
          </Button>,
        ]}
      >
        <Form name="notes" form={form} onFinish={onFinish}>
          <Form.Item
            name="notes"
            rules={[{ required: true, message: 'Please type your note' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
