import React, { useState } from 'react'
import { Form, Typography, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'

import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import LinkButton from '../../components/link-button'

const { Text } = Typography

function PlanDetails2() {
  const [memberIDInput, setMemberIDInput] = useState()
  const [relationshipSelect, setRelationshipSelect] = useState()
  const { t } = useTranslation()
  const insuredRelationshipList = t('INSURED_RELATIONSHIP_LIST', {
    returnObjects: true,
  })
  function memberIDChange(event) {
    setMemberIDInput(event.target.value)
  }

  function relationshipChange(event) {
    setRelationshipSelect(event.value)
  }

  return (
    <ContentSpace>
      <PageTitle>{t('planDetail.title')}</PageTitle>
      <Text>{t('planDetail.text')}</Text>

      <Form layout={'vertical'}>
        <Form.Item
          label={t('planDetail.memberId')}
          name="memberId"
          rules={[{ required: true }]}
        >
          <Input
            placeholder={t('planDetail.memberIdPlaceholder')}
            onChange={memberIDChange}
          />
        </Form.Item>
        <Form.Item label={t('planDetail.groupMember')} name="groupNumber">
          <Input placeholder={t('planDetail.groupPlaceholder')} />
        </Form.Item>
        <Form.Item
          label={t('planDetail.insuredRelationship')}
          name="relationship"
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('planDetail.selection')}
            value={relationshipSelect}
            onChange={relationshipChange}
          >
            {insuredRelationshipList.map(({ key, title }) => (
              <Select.Option key={key} value={key}>
                {title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      <LinkButton type={'dashed'}>{t('planDetail.verifyPlan')}</LinkButton>

      <ActionButtons
        backButtonUrl={routes.testReason}
        nextButtonUrl={routes.notFound}
        isNextButtonDisabled={!memberIDInput || !relationshipSelect}
      />
    </ContentSpace>
  )
}

export default PlanDetails2
