import { useEffect, useState } from 'react'

import BatchService from '../services/batch-service'
import { handleError } from '../utils/error-utils'
import {
  checkStatusCompleted,
  checkIfFinalizeBatch,
} from '../utils/batch-utils'

export default function useBatch(batchDate, batchNumber) {
  const [batch, setBatch] = useState()
  const [completed, setCompleted] = useState()
  const [finalizeBatch, setFinalizeBatch] = useState()
  const [appointmentsBatch, setAppointmentsBatch] = useState()
  const [blockAdd, setBlockAdd] = useState(false)

  const checkWell = ({ currentWell }) => {
    if (Number(currentWell) === 116) {
      setBlockAdd(true)
    }
  }

  useEffect(() => {
    try {
      BatchService.observeBatch(batchDate, batchNumber, response => {
        checkWell(response)
        setBatch(response)
      })
    } catch (error) {
      handleError(error, 'Error retrieving batch')
    }

    return () => {
      BatchService.unsubscribeBatch()
    }
  }, [batchDate, batchNumber])

  useEffect(() => {
    try {
      BatchService.observeAppointmentsBatch(
        batchDate,
        batchNumber,
        async response => {
          checkWell(response)
          setAppointmentsBatch(response)
          const completed = await checkStatusCompleted(response)
          setCompleted(completed)
          const finalizeBatch = await checkIfFinalizeBatch(response)
          setFinalizeBatch(finalizeBatch)
        },
      )
    } catch (error) {
      handleError(error, 'Error retrieving appointments by batch')
    }

    return () => {
      BatchService.unsubscribeBatch()
    }
  }, [batchDate, batchNumber])

  return { batch, appointmentsBatch, completed, finalizeBatch, blockAdd }
}
