import {
  compareAsc,
  format,
  fromUnixTime,
  parse,
  isLeapYear,
  addMinutes,
} from 'date-fns'

export function removeMask(value = '') {
  const dateRegex = /[/() _-]/g
  return value.replace(dateRegex, '')
}

export function validationDay(value) {
  if (value > 31) {
    return false
  }
  return true
}

export function validationMonth(value) {
  if (value > 12) {
    return false
  }
  return true
}

export function isDateBeforeTwoThousandTwenty(date) {
  const twoThousandTwenty = new Date(2020, 1, 1)
  return compareAsc(date, new Date(twoThousandTwenty)) === -1
}

export function isDateAfterToday(date) {
  const today = new Date()
  return compareAsc(date, today) === 1
}

export function parseStringDOB(dateOfBirth) {
  return (
    dateOfBirth.substr(0, 2) +
    '/' +
    dateOfBirth.substr(2, 2) +
    '/' +
    dateOfBirth.substr(4)
  )
}

export function parseDateOfBirth(dateOfBirth) {
  if (!dateOfBirth) {
    return undefined
  }
  if (typeof dateOfBirth === 'string') {
    return parse(dateOfBirth, 'MMddyyyy', new Date())
  }
  return fromUnixTime(dateOfBirth / 1000)
}

export function formatDateOfBirth(dateOfBirth) {
  if (!dateOfBirth) {
    return undefined
  }

  return format(dateOfBirth, 'MM/dd/yyyy')
}

export function formatDateFromString(dateOfBirth) {
  if (!dateOfBirth) {
    return undefined
  }

  const year = dateOfBirth.split('-')[0]
  const month = dateOfBirth.split('-')[1]
  const day = dateOfBirth.split('-')[2]

  return `${month}${day}${year}`
}

export function minimumDay(day) {
  if (day < 1) return true
  return false
}

export function maximumDay(day) {
  if (day > 31) return true
  return false
}

export function minimumMonth(month) {
  if (month.length === 2 && month < 1) return true
  return false
}

export function maximumMonth(month) {
  if (month.length === 2 && month > 12) return true
  return false
}

export function februaryDays(day, month, year) {
  if (year > 0) {
    const isLeap = isLeapYear(new Date(year, month, day))
    if (month === '02' && isLeap) {
      if (day > 29) return 1
    } else if (month === '02' && !isLeap) {
      if (day > 28) return 2
    }
  }
  return false
}

export function months30Days(day, month) {
  if (month === '04' || month === '06' || month === '09' || month === '11') {
    if (day > 30) return true
  }
  return false
}

export function minimumYear(year) {
  if (!year.includes('_')) {
    if (year < 1900) return true
  }
  return false
}

export function maximumDate(day, month, year) {
  if (!year.includes('_')) {
    return isDateAfterToday(
      parse(`${month}${day}${year}`, 'MMddyyyy', new Date()),
    )
  }
}

export function isDateInBoundaries(day, month, year) {
  const isLeap = isLeapYear(new Date(year, month, day))

  if (!(day > 0 && month > 0 && year > 1900)) {
    return false
  }
  if (month === '02' && isLeap) {
    if (day > 29) return false
  } else if (month === '02' && !isLeap) {
    if (day > 28) return false
  } else if (
    month === '04' ||
    month === '06' ||
    month === '09' ||
    month === '11'
  ) {
    if (day > 30) return false
  }
  if (
    isDateAfterToday(parse(`${month}${day}${year}`, 'MMddyyyy', new Date()))
  ) {
    return false
  }
  return true
}

export function algoliaDOBParse(date) {
  if (date)
    return format(addMinutes(date, date.getTimezoneOffset()), 'MM/dd/yyyy')
}
