import { useEffect, useState } from 'react'

import TestGroupService from '../services/test-group-service'
import AppointmentService from '../services/appointments-service'
import { handleError } from '../utils/error-utils'
import { createAppointmentFromTestGroup } from '../utils/appointment-utils'

export default function useTestGroup({
  uid = null,
  groupId = null,
  appointments = null,
  organizationId = null,
  duplicate = null,
  history = null,
  role = null,
}) {
  const [testGroups, setTestGroups] = useState()
  const [testGroup, setTestGroup] = useState()
  const [testGroupAppointment, setTestGroupAppointment] = useState()
  const [isTestGroupLoading, setIsTestGroupLoading] = useState(false)
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false)

  useEffect(() => {
    setIsTestGroupLoading(true)
    try {
      if (uid) {
        TestGroupService.observeTestGroupsById(uid, role, response => {
          setTestGroups(response)
          setIsTestGroupLoading(false)
        })
      } else {
        AppointmentService.observeAppointmentsByGroupId(groupId, response => {
          setTestGroupAppointment(response)
          setIsTestGroupLoading(false)
        })
      }
    } catch (error) {
      handleError(error, 'Error retrieving test groups')
      setIsTestGroupLoading(false)
    }

    return () => {
      TestGroupService.unsubscribeTestGroups()
    }
  }, [groupId, role, uid])

  useEffect(() => {
    async function create() {
      if (appointments && duplicate && history.action === 'PUSH') {
        setIsDuplicateLoading(true)
        const promises = Object.values(appointments).map(async appointment => {
          try {
            await createAppointmentFromTestGroup({
              duplicate,
              organizationId,
              groupId,
              appointment,
            })
          } catch (error) {
            handleError(error, 'Error when creating appointment')
            setIsDuplicateLoading(false)
          }
        })
        await Promise.all(promises)
        setIsDuplicateLoading(false)
      }
    }
    create()
  }, [appointments, duplicate, groupId, history, organizationId, uid])

  useEffect(() => {
    setIsTestGroupLoading(true)
    try {
      if (groupId) {
        TestGroupService.observeTestGroupById(groupId, response => {
          setTestGroup(response)
          setIsTestGroupLoading(false)
        })
      }
    } catch (error) {
      handleError(error, 'Error retrieving test group')
      setIsTestGroupLoading(false)
    }

    return () => {
      TestGroupService.unsubscribeTestGroups()
    }
  }, [groupId, role, uid])

  return {
    testGroup,
    testGroups,
    isTestGroupLoading,
    testGroupAppointment,
    isDuplicateLoading,
  }
}
