import { useEffect, useState } from 'react'

import AutomatedBatchService from '../services/automated-batch-service'
import { handleError } from '../utils/error-utils'
import {
  checkStatusCompleted,
  checkIfFinalizeBatch,
} from '../utils/automated-batch-utils'

export default function useAutomatedBatch(batchDate, batchNumber) {
  const [batch, setBatch] = useState()
  const [completed, setCompleted] = useState()
  const [finalizeBatch, setFinalizeBatch] = useState()
  const [appointmentsBatch, setAppointmentsBatch] = useState()

  useEffect(() => {
    try {
      AutomatedBatchService.observeBatch(batchDate, batchNumber, response => {
        setBatch(response)
      })
    } catch (error) {
      handleError(error, 'Error retrieving batch')
    }

    return () => {
      AutomatedBatchService.unsubscribeBatch()
    }
  }, [batchDate, batchNumber])

  useEffect(() => {
    try {
      AutomatedBatchService.observeAppointmentsBatch(
        batchDate,
        batchNumber,
        async response => {
          setAppointmentsBatch(response)
          const completed = await checkStatusCompleted(response)
          setCompleted(completed)
          const finalizeBatch = await checkIfFinalizeBatch(response)
          setFinalizeBatch(finalizeBatch)
        },
      )
    } catch (error) {
      handleError(error, 'Error retrieving appointments by batch')
    }

    return () => {
      AutomatedBatchService.unsubscribeBatch()
    }
  }, [batchDate, batchNumber])

  return { batch, appointmentsBatch, completed, finalizeBatch }
}
