import React, { useEffect, useState } from 'react'
import { Typography, Card } from 'antd'

import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import { useTranslation } from 'react-i18next'
import { getAppointmentFromStorage } from '../../utils/appointment-utils'
import appointmentsService from '../../services/appointments-service'
import Spin from '../../components/main-spin'
const { Text } = Typography

function HipaaWarn() {
  const { id } = getAppointmentFromStorage()
  const { t } = useTranslation()
  const [email, setEmail] = useState()

  useEffect(() => {
    appointmentsService.get(id, appointment => {
      setEmail(appointment.email)
    })
  })

  if (!email) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle>{t('hipaa.title')}</PageTitle>

      <Text>{t('hipaa.text')}</Text>

      <Card>{t('hipaa.card', { String: email })}</Card>

      <ActionButtons
        backButtonUrl={routes.demographicsPt2}
        nextButtonUrl={routes.hasInsurance}
      />
    </ContentSpace>
  )
}

export default HipaaWarn
