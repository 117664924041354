import React from 'react'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { StyledTooltip } from '../components/styles/styled-tooltip'
import { Typography } from 'antd'
const { Paragraph } = Typography

export const DemographicTooltip = ({ large, text }) => {
  const { t } = useTranslation()
  return (
    <StyledTooltip
      placement="right"
      large={large}
      getPopupContainer={triggerNode => triggerNode}
      title={
        <Paragraph ellipsis={{ rows: 8, expandable: true, symbol: 'more' }}>
          {text ? text : t('demographics.tooltip')}
        </Paragraph>
      }
    >
      <InfoCircleTwoTone />
    </StyledTooltip>
  )
}
