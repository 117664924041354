import React, { useState } from 'react'
import { Typography, Upload, Button } from 'antd'
import { PageContainer } from '@ant-design/pro-layout'
import ProForm from '@ant-design/pro-form'
import ProTable from '@ant-design/pro-table'
import SearchBox from '../../components/search-box'
import { exportCSV, formatDataToCSV } from '../../utils/automated-batch-utils'
import IconButton from '../../components/icon-button'
import { UploadOutlined } from '@ant-design/icons'
import { format, parse } from 'date-fns'
import ContainerInline from '../../components/container-inline'
import ConfirmResultModal from '../../components/dashboard/confirm-result-modal'
import AddNote from '../../components/dashboard/add-note-modal'
import useAutomatedBatch from '../../hooks/use-automated-batch'
import { STATUS } from '../../constants'
import {
  formatWellPosition,
  filterBatchAndDate,
} from '../../utils/automated-batch-utils'
import {
  import700,
  removeAutomatedBatches,
} from '../../utils/automated-batch-utils'
import { importAutomatedExcel } from '../../utils/result-utils'

import { algoliaSearchBarcode } from '../../utils/algolia-utils'
import AppointmentService from '../../services/appointments-service'
import { compareWellPosition } from '../../utils/text-utils'
import { parseStringDOB, formatDateOfBirth } from '../../utils/date-utils'
import ConfirmPinModal from '../../components/dashboard/confirm-pin-modal'
import {
  successNotification,
  errorNotification,
} from '../../components/dashboard/ui-notification'

const { Text } = Typography

const checkDisableAddNotes = row => {
  return (
    (row.result !== 'Negative' && !row.confirmationResult) || row.setFinalized
  )
}

const SheetCSV = ['csv']
  .map(function (x) {
    return '.' + x
  })
  .join(',')
const SheetJSFT = ['xlsx']
  .map(function (x) {
    return '.' + x
  })
  .join(',')

function getTableColumns(
  handleChangeInput,
  setSelectedRow,
  setIsModalVisible,
  finalizeBatch,
  completed,
) {
  return [
    {
      key: 'barcode',
      title: (_, type) => (type === 'table' ? 'Barcode #' : 'Search'),
      dataIndex: 'barcode',
      hideOn: ['lg', 'sm', 'xs'],
      renderFormItem: (_, { type, defaultRender, ...props }, form) => {
        return (
          <SearchBox
            onChange={handleChangeInput}
            placeholder="Search by barcode"
          />
        )
      },
    },
    {
      key: 'wellPosition',
      title: (_, type) => (type === 'table' ? 'Well' : 'Search Well Position'),
      width: 200,
      valueType: 'text',
      hideInSearch: true,
      dataIndex: 'wellPosition',
      render: data => formatWellPosition(data),
      sorter: {
        compare: (a, b) => compareWellPosition(a.wellPosition, b.wellPosition),
        multiple: 3,
      },
    },
    {
      key: 'firstName',
      title: 'First Name',
      valueType: 'text',
      hideInSearch: true,
      dataIndex: 'firstName',
    },
    {
      key: 'lastName',
      title: 'Last Name',
      valueType: 'text',
      hideInSearch: true,
      dataIndex: 'lastName',
    },
    {
      key: 'dateOfBirth',
      title: 'DOB',
      valueType: 'text',
      dataIndex: 'dateOfBirth',
      hideInSearch: true,
      renderText: date => {
        if (typeof date === 'string') {
          return parseStringDOB(date)
        }
        return formatDateOfBirth(date)
      },
    },
    {
      key: 'result',
      title: 'Result',
      valueType: 'text',
      dataIndex: 'result',
      hideInSearch: true,
      render: text => (
        <Text
          type={
            text === 'Positive'
              ? 'danger'
              : text === 'Invalid'
              ? 'warning'
              : 'success'
          }
        >
          {text}
        </Text>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      valueType: 'text',
      align: 'center',
      render: (text, row) => [
        !['Pending'].includes(row.result) && (
          <ConfirmResultModal
            curResult={row.result}
            well={row.wellPosition}
            appointmentId={row.key}
            confirmed={row.confirmationResult}
            sent={row.setFinalized}
          />
        ),
      ],
    },
    {
      valueType: 'addNote',
      align: 'right',
      render: (text, row) => [
        <Button
          disabled={checkDisableAddNotes(row)}
          onClick={() => {
            setIsModalVisible(true)
            setSelectedRow(row)
          }}
        >
          {row?.notes ? 'View ' : 'Add '}
          Note
        </Button>,
      ],
    },
  ]
}

const AutomatedBatchCreate = props => {
  const [formInstance] = ProForm.useForm()
  const { batchDate, batchNumber } = props.location.state
  const [filteredSpecimens, setFilteredSpecimens] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isPinVisible, setIsPinVisible] = useState(false)
  const [finalizeNegatives, setFinalizeNegatives] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const { appointmentsBatch, completed, finalizeBatch } = useAutomatedBatch(
    batchDate,
    batchNumber,
  )

  const checkFinalizeDisable = () => {
    if (appointmentsBatch) {
      const hasResultsNegatives = appointmentsBatch.filter(
        appt => appt.result === 'Negative' && !appt.setFinalized,
      )
      if (hasResultsNegatives.length > 0) {
        return false
      } else {
        return true
      }
    }
  }

  const parsedDate = format(
    parse(batchDate, 'dd-MM-yyyy', new Date()),
    'EEEE, d LLLL yyyy',
  )

  const onImportExcel = async event => {
    setIsLoading(true)
    await import700(event, batchDate, batchNumber)
    setIsLoading(false)
    checkFinalizeDisable()
  }
  const onImportResults = async event => {
    setIsLoading(true)
    await importAutomatedExcel(event, batchDate, batchNumber)
    setIsLoading(false)
    checkFinalizeDisable()
  }
  const onClickClose = () => {
    setIsModalVisible(false)
  }
  const handleExportCSV = async batches => {
    let data = await formatDataToCSV(batches)
    exportCSV(data, batchDate, batchNumber)
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const handleChangeInput = async e => {
    if (e.target.value.length > 0) {
      const hits = await algoliaSearchBarcode(e.target.value)
      const speciments = await filterBatchAndDate(hits, batchNumber, batchDate)
      setFilteredSpecimens(speciments)
    } else {
      setFilteredSpecimens(false)
    }
  }

  const handleFinalizeBatch = () => {
    setIsPinVisible(true)
  }

  const handleFinalizeNegatives = () => {
    setFinalizeNegatives(true)
    setIsPinVisible(true)
  }

  const remove7000 = async batches => {
    setIsLoading(true)
    await removeAutomatedBatches(batches, batchDate, batchNumber)
    setIsLoading(false)
  }

  const finalizeBatchAfterPin = async value => {
    if (!value) {
      return errorNotification({
        message: 'Something went wrong',
      })
    }
    if (!finalizeNegatives) {
      await AppointmentService.finalizeAutomatedBatch(
        appointmentsBatch,
        batchDate,
        batchNumber,
        'all',
      )
      return successNotification({
        message: 'Batch finalized',
      })
    } else {
      finalizeNegativesAfterPin(value)
    }
  }

  const finalizeNegativesAfterPin = async value => {
    if (!value) {
      return errorNotification({
        message: 'Something went wrong',
      })
    }
    await AppointmentService.finalizeAutomatedBatch(
      appointmentsBatch.filter(el => el.result === 'Negative'),
      batchDate,
      batchNumber,
      'negatives',
    )

    setFinalizeNegatives(false)
    return successNotification({
      message: 'Negative specimens finalized',
    })
  }

  return (
    <PageContainer
      content={
        !finalizeBatch ? (
          <ContainerInline>
            <Upload
              accept={SheetCSV}
              beforeUpload={onImportExcel}
              customRequest={dummyRequest}
            >
              <IconButton icon={<UploadOutlined />} type={'primary'}>
                Upload from 7000
              </IconButton>
            </Upload>
            <div style={{ paddingLeft: 10 }}>
              <Upload
                accept={SheetJSFT}
                beforeUpload={onImportResults}
                customRequest={dummyRequest}
              >
                <IconButton
                  disabled={appointmentsBatch?.length === 0}
                  icon={<UploadOutlined />}
                >
                  Click to Upload Results
                </IconButton>
              </Upload>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <Button
                disabled={appointmentsBatch?.length === 0}
                onClick={() => remove7000(appointmentsBatch)}
                type="primary"
              >
                Remove 7000 Upload
              </Button>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <Button
                onClick={() => handleExportCSV(appointmentsBatch)}
                type="primary"
              >
                Export to BioRad
              </Button>
            </div>
          </ContainerInline>
        ) : null
      }
      title={`${parsedDate}, - Batch #${batchNumber} - Status ${
        completed ? completed : ''
      }`}
    >
      <ProTable
        size="small"
        columns={getTableColumns(
          handleChangeInput,
          setSelectedRow,
          setIsModalVisible,
          finalizeBatch,
          completed,
        )}
        rowKey="barcode"
        options={false}
        loading={isLoading}
        dataSource={filteredSpecimens || appointmentsBatch}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        search={{
          searchText: 'Search',
          form: formInstance,
          collapseRender: () => {},
          defaultColsNumber: 1,
          optionRender: () => [
            <Button
              type="primary"
              onClick={handleFinalizeNegatives}
              disabled={checkFinalizeDisable()}
            >
              Finalize Specimens
            </Button>,
            <Button
              type="primary"
              onClick={handleFinalizeBatch}
              disabled={completed === STATUS.inProgress || finalizeBatch}
            >
              Finalize Batch
            </Button>,
          ],
        }}
      />
      <AddNote
        isModalVisible={isModalVisible}
        data={isModalVisible && selectedRow}
        onClickClose={onClickClose}
      />

      <ConfirmPinModal
        isModalVisible={isPinVisible}
        onClickClose={() => setIsPinVisible(false)}
        onReady={finalizeBatchAfterPin}
      />
    </PageContainer>
  )
}

export default AutomatedBatchCreate
