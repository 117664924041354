import React from 'react'
import { Col, Row, Select, Spin } from 'antd'

import { useLocationSelectorContext } from '../../contexts/location-selector-context'
import SelectFullWidth from '../select-full-width'

const { Option } = Select

const AppointmentsListParameters = () => {
  const {
    locations,
    locationId,
    setLocationId,
    isLocationsLoading,
  } = useLocationSelectorContext()

  const onChange = value => {
    setLocationId(value)
  }

  if (isLocationsLoading) {
    return (
      <Row>
        <Col span={8}>
          <SelectFullWidth>
            <Option value={'loading'} key={'loading'}>
              Loading...
            </Option>
          </SelectFullWidth>
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col span={8}>
        <SelectFullWidth onChange={onChange} value={locationId}>
          {locations &&
            locations.map(element => (
              <Option value={element.value} key={element.value}>
                {element.label}
              </Option>
            ))}
        </SelectFullWidth>
      </Col>
    </Row>
  )
}

export default AppointmentsListParameters
