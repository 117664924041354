import React, { useState, useEffect, useContext } from 'react'
import {
  Modal,
  Button,
  Form,
  Input,
  Divider,
  Select,
  Checkbox,
  Radio,
  Typography,
} from 'antd'
import DateMasked from '../../components/date-masked'
import SocialSecurityMasked from '../../components/social-security-masked'
import ContentSpace from '../../components/content-space'
import { RadioGroup } from '../../components/radio-group'
import { removeMask, formatDateFromString } from '../../utils/date-utils'
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/page-title'
import { InstantSearch } from 'react-instantsearch-dom'
import InsuranceProviderSearchBox from '../../components/insurance-provider-search-box'
import { algolia, payersIndex } from '../../config/config'
import { addPeople } from '../../utils/people-utils'
import { createAppointmentFromTestGroup } from '../../utils/appointment-utils'
import { handleError } from '../../utils/error-utils'
import PhoneMasked from '../../components/phone-masked'
import HasNoInsuranceModal from '../no-insurance-modal'
import { COMPANY_NAME } from '../../constants'
import { UserContext } from '../../contexts/user-context'
import ProfilesService from '../../services/profiles-service'
import { STATES } from '../../constants'
import { ProFormSelect } from '@ant-design/pro-form'
import AddressService from '../../services/address-service'

const { Text } = Typography
const { Group } = Checkbox
const { confirm } = HasNoInsuranceModal

export default function TestGroupFormModal({
  data,
  isModalVisible,
  onClickClose,
  organizationId,
  groupId,
}) {
  const [socialSecurityNumber, setSocialSecurityNumber] = useState()
  const [hasInsurance, setHasInsurance] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { user, role } = useContext(UserContext)
  const [userInfo, setUserInfo] = useState()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const RACE_OPTIONS = t('RACE_OPTIONS', { returnObjects: true })
  const ETHNIC_OPTIONS = t('ETHNIC_OPTIONS', { returnObjects: true })
  const symptonsList = t('SYMPTOMS_LIST', { returnObjects: true })

  const insuredRelationshipList = t('INSURED_RELATIONSHIP_LIST', {
    returnObjects: true,
  })

  useEffect(() => {
    form.setFieldsValue({
      ...data,
      dateOfBirth: formatDateFromString(
        data.dateOfBirth?.toISOString().split('T')[0],
      ),
    })
  }, [data, form])
  useEffect(() => {
    async function fetchData() {
      const profile = await ProfilesService.get(user.uid)
      setUserInfo(profile)
    }
    fetchData()
  }, [user.uid, setUserInfo])

  const onFinish = async values => {
    const { dateOfBirth } = values
    const dateFormatted = removeMask(dateOfBirth)
    const isInscribedSMS = checkIfPhoneIsValid(values.phoneNumber)
    delete values.barcodeConfirmation
    let isDoctor = role === 'doctor'
    const profile = await ProfilesService.get(user.uid)
    const providerInfo = {
      ...(isDoctor && {
        providerFacilityName: profile?.providerFacilityName,
        providerFirstName: profile?.providerFirstName,
        providerMiddleName: profile?.providerMiddleName,
        providerLastName: profile?.providerLastName,
        providerAddress: profile?.providerAddress,
        providerCity: profile?.providerCity,
        providerState: profile?.providerState,
        providerZip: profile?.providerZip,
        providerPhone: profile?.providerPhone,
        providerNPI: profile?.providerNPI,
      }),
    }
    try {
      setIsLoading(true)
      await createAppointmentFromTestGroup({
        duplicate: null,
        doctorReferralCode: profile?.doctorReferralCode,
        organizationId,
        groupId,
        providerInfo,
        appointment: {
          ...values,
          socialSecurityNumber,
          dateOfBirth: dateFormatted,
          isInscribedSMS,
          slotsDateTime: new Date().toISOString(),
        },
      })
      await addPeople({
        organizationId,
        appointment: {
          ...values,
          socialSecurityNumber,
          dateOfBirth: dateFormatted,
          isInscribedSMS,
        },
      })
      setIsLoading(false)
      onClickClose()
    } catch (error) {
      handleError(error, 'Error when creating appointment and people')
    }
  }

  const checkIfPhoneIsValid = phone => {
    const noMaskPhone = removeMask(phone)

    if (noMaskPhone.length === 10) {
      return true
    }
    return false
  }

  const showConfirm = () => {
    confirm({
      title: 'Confirm: No Insurance Plan',
      icon: <ExclamationCircleOutlined />,
      content:
        "By clicking on Ok button you confirm: I swear I don't have any health insurance or not able to afford for one",
      onOk() {},
      onCancel() {},
      cancelButtonProps: {
        ghost: true,
        disabled: true,
        style: { display: 'none' },
      },
    })
  }

  const handleSSNChange = event => {
    const value = removeMask(event.target.value)
    const counter = value?.length
    const lastNumber = socialSecurityNumber ? socialSecurityNumber : ''
    const currentNumber = value?.substr(counter - 1, counter)
    if (value?.length > socialSecurityNumber?.length || !socialSecurityNumber) {
      setSocialSecurityNumber(lastNumber + currentNumber)
    }
    if (value?.length < socialSecurityNumber?.length) {
      setSocialSecurityNumber(lastNumber.slice(0, -1))
    }
    if (value?.length < 6) {
      form.setFieldsValue({
        socialSecurityNumber: event.target.value.replace(/[0-9]/g, '*'),
      })
    }
  }

  const handleInsuranceChange = value => {
    form.setFieldsValue({ insuranceProviderName: value })
  }

  const handleHasInsuranceChange = event => {
    setHasInsurance(event.target.value)
  }

  const handleRaceChange = chekedValues => {
    return form.setFieldsValue({ race: chekedValues })
  }
  const getAddressInfo = async typedZip => {
    let response = await AddressService.getAddresByZipcode(typedZip)
    form.setFieldsValue({ state: response.state, city: response.city })
    if (response.error) {
      return false
    }
    return true
  }
  const handleSymptomsChange = chekedValues => {
    const lastListIndex = chekedValues.length - 1

    if (chekedValues[lastListIndex] === 'noneAbove') {
      return form.setFieldsValue({ symptoms: ['noneAbove'] })
    }

    if (chekedValues[0] === 'noneAbove') {
      return form.setFieldsValue({
        symptoms: chekedValues.filter(item => item !== 'noneAbove'),
      })
    }
    return form.setFieldsValue({ symptoms: chekedValues })
  }

  const resetForm = () => {
    form.resetFields()
  }

  const getErrorMessage = async () => {
    const noMaskSSN = removeMask(form.getFieldValue('socialSecurityNumber'))
    if (noMaskSSN.length > 0 && noMaskSSN.length < 9) {
      return Promise.reject(
        'Social security number was not filled in correctly',
      )
    }
  }

  return (
    <Modal
      title="Add person to the test group"
      visible={isModalVisible}
      centered
      width={600}
      closeIcon={<CloseOutlined onClick={onClickClose} />}
      afterClose={resetForm}
      onOk={() => form.submit()}
      destroyOnClose
      footer={[
        <Button key="back" onClick={onClickClose}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form name="testGroup" form={form} onFinish={onFinish} layout="vertical">
        <ContentSpace>
          <PageTitle>Barcode</PageTitle>
          <Form.Item name="barcode">
            <Input
              name="barcode"
              placeholder="Enter barcode"
              width="100%"
              autoFocus
              hasFeedback
            />
          </Form.Item>
          <Form.Item
            name="barcodeConfirmation"
            dependencies={['barcode']}
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                required: getFieldValue('barcode') === '',
                message: 'Please confirm your barcode!',
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('barcode') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('The two barcodes do not match!')
                },
              }),
            ]}
          >
            <Input
              name="barcodeConfirmation"
              placeholder="Re-enter barcode"
              width="100%"
            />
          </Form.Item>
        </ContentSpace>
        <Divider />
        <ContentSpace>
          <PageTitle>Personal</PageTitle>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: `${t('messages.firstNameRequired')}` },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: `${t('messages.lastNameRequired')}` },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dateOfBirth"
            label="Date of Birth"
            rules={[
              { required: true, message: `${t('messages.dobRequired')}` },
            ]}
          >
            <DateMasked />
          </Form.Item>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: `${t('messages.validEmail')}`,
                type: 'email',
              },
            ]}
          >
            <Input placeholder="example@example.com" />
          </Form.Item>
          <Form.Item name="driverLicenseNumber" label="Drivers License Number">
            <Input placeholder="#####" />
          </Form.Item>
          <Form.Item
            name="socialSecurityNumber"
            label="Social Security Number"
            rules={[{ validator: getErrorMessage, validateTrigger: 'submit' }]}
          >
            <SocialSecurityMasked onChange={handleSSNChange} />
          </Form.Item>
          <Form.Item name="sexAtBirth" label={t('demographics.sex')}>
            <RadioGroup
              options={t('SEX_AT_BIRTH_OPTIONS', { returnObjects: true })}
            />
          </Form.Item>
          <Form.Item name="race" label="Race">
            <Group onChange={handleRaceChange}>
              <ContentSpace>
                {RACE_OPTIONS.map(item => (
                  <Checkbox key={item.key} value={item.value}>
                    {item.label}
                  </Checkbox>
                ))}
              </ContentSpace>
            </Group>
          </Form.Item>
          <Form.Item name="ethnicity" label="Ethnicity">
            <RadioGroup options={ETHNIC_OPTIONS} />
          </Form.Item>
          <Form.Item name="symptoms" label="Symptoms">
            <Group onChange={handleSymptomsChange}>
              <ContentSpace>
                {symptonsList.map(item => (
                  <Checkbox key={item.key} value={item.key}>
                    {item.title}
                  </Checkbox>
                ))}
              </ContentSpace>
            </Group>
          </Form.Item>
          <Form.Item label={t('phoneNumber.placeholder')} name="phoneNumber">
            <PhoneMasked autoFocus={false} />
          </Form.Item>
        </ContentSpace>
        <Divider />
        <ContentSpace>
          <PageTitle>Address</PageTitle>
          <Input.Group>
            <Form.Item
              name="street1"
              label="Street Address"
              style={{ margin: '8px 0' }}
              rules={[
                { required: true, message: `${t('rules.streetRequired')}` },
              ]}
            >
              <Input placeholder="Street and number" />
            </Form.Item>
            <Form.Item name="street2">
              <Input placeholder="Apartment, suite, building, floor, etc." />
            </Form.Item>
          </Input.Group>
          <Form.Item
            label={t('address.zipcode')}
            name="zip"
            rules={[
              {
                required: true,
                message: `${t('rules.zipRequired')}`,
              },
              {
                async validator(_, value) {
                  if (
                    value &&
                    value.length === 5 &&
                    (await getAddressInfo(value))
                  ) {
                    return Promise.resolve()
                  }
                  form.setFieldsValue({ state: '', city: '' })
                  return Promise.reject('Invalid Zip code')
                },
              },
            ]}
          >
            <Input placeholder="#####" />
          </Form.Item>
          <Form.Item style={{ display: 'flex' }}>
            <Form.Item
              label="City"
              name="city"
              style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
              rules={[
                { required: true, message: `${t('rules.cityRequired')}` },
              ]}
            >
              <Input placeholder="City Name" />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              style={{
                display: 'inline-block',
                width: 'calc(40% - 8px)',
                marginLeft: '16px',
              }}
              rules={[
                { required: true, message: `${t('rules.stateRequired')}` },
              ]}
            >
              <ProFormSelect
                name="state"
                placeholder={t('address.state')}
                valueEnum={STATES}
                width="100%"
              />
            </Form.Item>
          </Form.Item>
        </ContentSpace>
        <Divider />
        <ContentSpace>
          <PageTitle>Insurance</PageTitle>
          <Text>{t('hasInsurance.title', { String: COMPANY_NAME })}</Text>
          <Form.Item name="hasInsurance">
            <Radio.Group onChange={handleHasInsuranceChange}>
              <ContentSpace>
                <Radio key={1} value="yes">
                  {t('affirmation.positive')}
                </Radio>
                <Radio key={2} value="no" onClick={() => showConfirm()}>
                  {t('affirmation.negative')}
                </Radio>
              </ContentSpace>
            </Radio.Group>
          </Form.Item>
          {hasInsurance === 'yes' && (
            <ContentSpace>
              <Form.Item
                label={t('planDetail.insuranceProvider')}
                name="insuranceProviderName"
              >
                <InstantSearch
                  indexName={payersIndex.indexName}
                  searchClient={algolia}
                >
                  <InsuranceProviderSearchBox
                    getFieldValue={form.getFieldValue}
                    handleChange={handleInsuranceChange}
                  />
                </InstantSearch>
              </Form.Item>
              <Form.Item
                label={t('planDetail.memberId')}
                name="insuranceMemberId"
              >
                <Input placeholder={t('planDetail.memberIdPlaceholder')} />
              </Form.Item>

              <Form.Item label={t('planDetail.groupMember')} name="groupNumber">
                <Input placeholder={t('planDetail.groupPlaceholder')} />
              </Form.Item>

              <Form.Item
                label={t('planDetail.insuredRelationship')}
                name="insuredRelationship"
              >
                <Select placeholder={t('planDetail.selection')}>
                  {insuredRelationshipList.map(({ key, title }) => (
                    <Select.Option key={key} value={key}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </ContentSpace>
          )}
        </ContentSpace>
      </Form>
    </Modal>
  )
}
