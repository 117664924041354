import React from 'react'
import { Typography, Form, Spin, Collapse } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DownOutlined } from '@ant-design/icons'

import routes from '../../config/routes'
import PageTitle from '../../components/page-title'
import ActionButtons from '../../components/action-buttons'
import ContentSpace from '../../components/content-space'
import { LocationSelect } from '../../components/location-select'
import { handleError, hasFormErrors } from '../../utils/error-utils'
import AppointmentsService from '../../services/appointments-service'
import useQueryParam from '../../hooks/use-query-param'
import CollapsableOptionsContentSpace from '../../components/collapsable-options-content-space'
import { useAppointmentContext } from '../../contexts/appointment-context'
import { getDoctorReferralCodeFromStorage } from '../../utils/appointment-utils'

const { Text, Title } = Typography

function LocationOptions(props) {
  const {
    appointmentId,
    appointment,
    isAppointmentLoading,
  } = useAppointmentContext()
  const [form] = Form.useForm()
  const history = useHistory()
  const isEditable = useQueryParam('isEdit') === 'true'
  const { t } = useTranslation()

  const referralCode = getDoctorReferralCodeFromStorage()

  const onFinish = async values => {
    const { key, title } = values.location
    try {
      if (isEditable) {
        await AppointmentsService.update(appointmentId, {
          locationId: key,
          locationName: title,
          slotsDateTime: null,
        })
        return history.push(routes.bookingConfirmation)
      }
      await AppointmentsService.update(appointmentId, {
        locationId: key,
        locationName: title,
      })
      return history.push(routes.timeSlots)
    } catch (error) {
      handleError(error, 'Error saving a drive-through location')
    }
  }

  const isNextButtonDisabled = () => {
    return hasFormErrors(form) || !form.getFieldValue('location')
  }

  const backButtonRoute = () => {
    if (isEditable) {
      return routes.bookingConfirmation
    } else if (referralCode) {
      return routes.doctorReferralCode
    } else {
      return routes.doctorReferred
    }
  }

  if (isAppointmentLoading || isAppointmentLoading === undefined) {
    return <Spin />
  }

  return (
    <ContentSpace>
      <PageTitle>{t('locationOptions.title')}</PageTitle>
      <Text>{t('locationOptions.text')}</Text>
      <Form form={form} onFinish={onFinish} initialValues={appointment}>
        <ContentSpace>
          <Form.Item
            name="location"
            rules={[{ required: true, message: 'Please select a location!' }]}
          >
            <LocationSelect isActive={true} referralCode={referralCode} />
          </Form.Item>
          <Collapse
            accordion
            bordered={false}
            ghost={true}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <DownOutlined
                rotate={isActive ? 0 : -90}
                style={{ fontSize: '16px' }}
              />
            )}
          >
            <CollapsableOptionsContentSpace
              header={
                <Title level={3} type="primary">
                  {t('locationOptions.sites')}
                </Title>
              }
              key="1"
            >
              <p>
                <Text>
                  {t('locationOptions.info')}
                  <Text type="primary">
                    {t('locationOptions.emptyTimeSlots')}
                  </Text>
                  . {t('locationOptions.unavailableLocation')}
                </Text>
              </p>
              <LocationSelect isActive={false} hasScheduleAvailable={false} />
            </CollapsableOptionsContentSpace>
          </Collapse>

          <ActionButtons
            isNextButtonDisabled={isNextButtonDisabled}
            backButtonUrl={backButtonRoute}
          />
        </ContentSpace>
      </Form>
    </ContentSpace>
  )
}

export default LocationOptions
