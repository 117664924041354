import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'

import styled from 'styled-components'

const DatePicker = styled(generatePicker(dateFnsGenerateConfig))`
  padding: 0;

  & input {
    font-size: ${props => (props.smallText ? '14px' : '22px')};
    font-weight: 600;
    width: 350px;
  }

  & span {
    display: none;
  }
`

export default DatePicker
