import { useEffect, useState, useCallback } from 'react'

import SpecimenServices from '../services/specimens-service'
import { handleError } from '../utils/error-utils'

export default function useSpecimens(
  organizationId,
  doctorReferralCode,
  locationId,
  isAdmin,
) {
  const [isReceived, setIsReceived] = useState(false)
  const [appointmentsSpecimen, setAppointmentsSpecimen] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const filterReceivedAppointments = useCallback(
    appointmentsArray => {
      return appointmentsArray.filter(item => {
        return !isReceived ? !item?.status?.receivedOn : true
      })
    },
    [isReceived],
  )

  //For organization
  useEffect(() => {
    if (!organizationId) {
      return
    }
    try {
      setIsLoading(true)
      SpecimenServices.observeAppointmentsByOrganizationID(
        organizationId,
        isAdmin,
        response => {
          setAppointmentsSpecimen(filterReceivedAppointments(response))
          setIsLoading(false)
        },
      )
    } catch (error) {
      handleError(error, 'Error retrieving appointments')
    }

    return () => {
      SpecimenServices.unsubscribe()
    }
  }, [filterReceivedAppointments, isAdmin, organizationId])

  //For doctors
  useEffect(() => {
    if (!doctorReferralCode) {
      return
    }
    try {
      setIsLoading(true)
      SpecimenServices.observeAppointmentsByDoctor(
        doctorReferralCode,
        isAdmin,
        response => {
          setAppointmentsSpecimen(filterReceivedAppointments(response))
          setIsLoading(false)
        },
      )
    } catch (error) {
      handleError(error, 'Error retrieving appointments')
    }

    return () => {
      SpecimenServices.unsubscribe()
    }
  }, [doctorReferralCode, filterReceivedAppointments, isAdmin])

  //For location
  useEffect(() => {
    if (!locationId) {
      return
    }
    try {
      setIsLoading(true)
      SpecimenServices.observeAppointmentsLocationID(
        locationId,
        isAdmin,
        response => {
          setAppointmentsSpecimen(filterReceivedAppointments(response))
          setIsLoading(false)
        },
      )
    } catch (error) {
      handleError(error, 'Error retrieving appointments')
    }

    return () => {
      SpecimenServices.unsubscribe()
    }
  }, [filterReceivedAppointments, isAdmin, locationId])

  return { appointmentsSpecimen, isLoading, isReceived, setIsReceived }
}
