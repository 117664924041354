import styled from 'styled-components'
import { Form } from 'antd'

const { Item } = Form

export const noDisplay = {
  display: 'none',
}

export const FlexDisplay = styled(Item)`
  display: flex;
`

export const AddressFieldContainer = styled(Item)`
  margin: 8px 0;
`

export const CityFieldContainer = styled(Item)`
  display: inline-block;
  width: calc(60% - 8px);
`

export const StateFieldContainer = styled(Item)`
  display: inline-block;
  width: calc(40% - 8px);
  marginleft: 16px;
`
