import { v4 as uuidv4 } from 'uuid'

import { FirebaseService } from './firebase-service'
import { paths } from './paths'

class LocationService extends FirebaseService {
  constructor() {
    super(paths.locations)
  }

  get = async locationId => {
    try {
      return await this.path.child(locationId).once('value')
    } catch (error) {
      throw error
    }
  }

  observeLocations(callback) {
    try {
      this.path.orderByChild('title').on('value', snapshot => {
        const locations = this.formatSnapshot(snapshot)
        callback(locations)
      })
    } catch (error) {
      throw error
    }
  }
  observeAllLocations(callback) {
    try {
      this.path.orderByChild('title').on('value', snapshot => {
        const locations = this.formatSnapshotAllLocations(snapshot)
        callback(locations)
      })
    } catch (error) {
      throw error
    }
  }

  observeLocationsByDoctor(code, callback) {
    try {
      this.path
        .orderByChild('doctorReferralCode')
        .equalTo(code)
        .on('value', snapshot => {
          const locationsByDoctor = this.formatSnapshotByDoctor(snapshot)
          callback(locationsByDoctor)
        })
    } catch (error) {
      throw error
    }
  }

  observeLocationsFilteredByAvailability(hasScheduleAvailable, callback) {
    try {
      this.path
        .orderByChild('hasScheduleAvailable')
        .equalTo(hasScheduleAvailable)
        .on('value', snapshot => {
          const locations = this.formatSnapshot(snapshot)
          callback(locations)
        })
    } catch (error) {
      throw error
    }
  }

  observeLocationsFilteredByReferralCode(referralCode, callback) {
    try {
      this.path
        .orderByChild('doctorReferralCode')
        .equalTo(+referralCode)
        .on('value', snapshot => {
          const locations = this.formatSnapshotByReferralCode(snapshot)
          callback(locations)
        })
    } catch (error) {
      throw error
    }
  }

  unsubscribeLocations() {
    this.path.off()
  }

  formatSnapshot(snapshot) {
    const locations = []

    snapshot.forEach(childSnapshot => {
      const locationData = childSnapshot.val()
      if (!locationData?.isDisabled && !locationData?.doctorReferralCode) {
        locations.push({
          key: childSnapshot.key,
          ...locationData,
        })
      }
    })

    return locations
  }
  formatSnapshotAllLocations(snapshot) {
    const locations = []

    snapshot.forEach(childSnapshot => {
      const locationData = childSnapshot.val()
      if (!locationData?.isDisabled) {
        locations.push({
          key: childSnapshot.key,
          ...locationData,
        })
      }
    })

    return locations
  }

  formatSnapshotByReferralCode(snapshot) {
    const locations = []

    snapshot.forEach(childSnapshot => {
      const locationData = childSnapshot.val()
      if (
        !locationData?.isDisabled &&
        locationData?.hasScheduleAvailable === true
      ) {
        locations.push({
          key: childSnapshot.key,
          ...locationData,
        })
      }
    })

    return locations
  }

  formatSnapshotByDoctor(snapshot) {
    const locations = []

    snapshot.forEach(childSnapshot => {
      const locationData = childSnapshot.val()
      if (!locationData?.isDisabled) {
        locations.push({
          key: childSnapshot.key,
          ...locationData,
        })
      }
    })

    return locations
  }

  getLocation(locationId, callback = null) {
    try {
      this.path.child(locationId).once('value', snapshot => {
        if (callback) {
          callback(snapshot.val())
        } else {
          return snapshot.val()
        }
      })
    } catch (error) {
      throw error
    }
  }
  async getLocationNoCallback(locationId) {
    try {
      const snapshot = await this.path.child(locationId).once('value')
      const location = snapshot.val()
      return location
    } catch (error) {
      throw error
    }
  }

  async create(location, profile) {
    try {
      const newLocationId = uuidv4()
      const locations = await this.path.once('value')
      const lastIdentifier = this.getLastIdentifier(locations.val())
      const doctorReferralCode = profile?.doctorReferralCode

      await this.path.child(newLocationId).set({
        ...location,
        hasScheduleAvailable: false,
        locationIdentifier: lastIdentifier + 1,
        ...(doctorReferralCode && { doctorReferralCode: doctorReferralCode }),
      })
    } catch (error) {
      throw error
    }
  }

  async update(locationId, location) {
    try {
      await this.path.child(locationId).update(location)
    } catch (error) {
      throw error
    }
  }

  async delete(locationId) {
    await this.path.child(locationId).remove()
  }

  getLastIdentifier(locations) {
    if (!locations) {
      return 0
    }

    const locationsArray = Object.values(locations)
    return locationsArray.reduce(
      (max, character) =>
        character.locationIdentifier > max ? character.locationIdentifier : max,
      locationsArray[0].locationIdentifier,
    )
  }
}

export default new LocationService()
