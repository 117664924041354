import styled from 'styled-components'

const ContainerInline = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > span:last-child {
    display: flex;
  }
`

export default ContainerInline
