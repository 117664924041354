import React from 'react'
import ContentSpace from './content-space'
import { Card } from 'antd'
import { useTranslation } from 'react-i18next'

import LinkButton from './link-button'
import routes from '../config/routes'
import { Description } from './description'

export const CardPersonalInfo = ({ data, isEditable = true }) => {
  const { t } = useTranslation()

  return (
    <ContentSpace>
      <Card>
        <Description
          label={t('personalData.name')}
          content={data.fullName}
          extra={
            isEditable && (
              <LinkButton type="link" to={routes.fullName + '?isEdit=true'}>
                {t('buttons.edit')}
              </LinkButton>
            )
          }
        />
        <Description
          label={t('nonUserAuth.birthPlaceholder')}
          content={data.birthDate}
          extra={
            isEditable && (
              <LinkButton type="link" to={routes.birthDate + '?isEdit=true'}>
                {t('buttons.edit')}
              </LinkButton>
            )
          }
        />
        <Description
          label={t('personalData.phone')}
          content={data.phone}
          extra={
            isEditable && (
              <LinkButton type="link" to={routes.phoneNumber + '?isEdit=true'}>
                {t('buttons.edit')}
              </LinkButton>
            )
          }
        />
        <Description
          label={t('personalData.address')}
          content={
            data.homeless ? t('particularity.homeless') : data.personalAddress
          }
          extra={
            isEditable && (
              <LinkButton
                type="link"
                to={routes.personalAddress + '?isEdit=true'}
              >
                {t('buttons.edit')}
              </LinkButton>
            )
          }
        />
      </Card>
    </ContentSpace>
  )
}
