import React from 'react'
import { DatePicker } from 'antd'
import ProTable from '@ant-design/pro-table'
import { format } from 'date-fns'
import routes from '../../config/routes'
import { PageContainer } from '@ant-design/pro-layout'
import { APP_NAME } from '../../constants'
import TableActionsDropdown from '../../components/dashboard/table-actions-dropdown'

const { RangePicker } = DatePicker

const tableListDataSource = []
const titleOpt = [
  `${APP_NAME} Terms of Service and Privacy Policy`,
  'Authorization for Release of Protected Health Info',
  'Electronic Delivery Authorization',
]

for (let i = 0; i < 10; i += 1) {
  tableListDataSource.push({
    key: i,
    title: titleOpt[Math.floor(Math.random() * titleOpt.length)],
    signedAt: Date.now() - Math.floor(Math.random() * 100000),
  })
}

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    width: 120,
    fixed: 'left',
    render: (_, record) => (
      <a href={`${routes.dashboard.documentsList}/${record.key}`}>{_}</a>
    ),
    sorter: {
      compare: (a, b) => a.title.localeCompare(b.title),
      multiple: 2,
    },
  },
  {
    title: 'Signed',
    width: 120,
    dataIndex: 'signedAt',
    align: 'left',
    hideInSearch: true,

    render: (_, { signedAt }) => <p>{format(signedAt, 'EEEE, d LLLL yyyy')}</p>,
    sorter: {
      compare: (a, b) => a.signedAt - b.signedAt,
      multiple: 1,
    },
    renderFormItem: (_, { value, onChange }) => {
      return <RangePicker value={value} onChange={onChange} />
    },
  },
  {
    key: 'option',
    width: 35,
    valueType: 'option',
    render: (_, record) => [
      <TableActionsDropdown
        key="actionGroup"
        onSelect={async key => {
          if (key === 'edit') {
            console.log('edit clicked')
          }

          if (key === 'delete') {
            console.log('delete clicked')
          }
        }}
        menus={[
          {
            key: 'edit',
            name: 'Edit',
          },
          {
            key: 'delete',
            name: 'Delete',
          },
        ]}
      />,
    ],
  },
]

export const DocumentsList = () => {
  return (
    <PageContainer>
      <ProTable
        columns={columns}
        request={() => {
          return Promise.resolve({
            data: tableListDataSource,
            success: true,
          })
        }}
        scroll={{
          x: 1300,
        }}
        options={false}
        search={false}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        dateFormatter="string"
        rowKey="key"
        headerTitle="Available Documents"
      />
    </PageContainer>
  )
}
