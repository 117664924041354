import { Descriptions as DefaultDescription } from 'antd'
import styled from 'styled-components'

const Descriptions = styled(DefaultDescription)`
  & .ant-descriptions-item-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & .ant-typography {
    width: 100%;
  }
`

export default Descriptions
