import { useEffect, useState } from 'react'
import AppointmentService from '../services/appointments-service'
import { handleError } from '../utils/error-utils'
import { dateExists, removeDup } from '../utils/receiving-utils'
import { v4 as uuidv4 } from 'uuid'
import { format, parseISO } from 'date-fns'

export default function useTestGroup(date) {
  const [receiving, setReceiving] = useState()
  const [isReceivingLoading, setIsReceivingLoading] = useState(false)
  const [receivingAppointments, setReceivingAppointments] = useState()
  const [
    isReceivingAppointmentsLoading,
    setIsReceivingAppointmentsLoading,
  ] = useState(false)

  useEffect(() => {
    async function fetchData() {
      if (date) {
        try {
          setIsReceivingAppointmentsLoading(true)
          AppointmentService.observeReceivingAppointments(date, response => {
            setReceivingAppointments(response)
            setIsReceivingAppointmentsLoading(false)
          })
        } catch (error) {
          setIsReceivingAppointmentsLoading(false)
          handleError(error, 'Error retrieving appointments by receivedOn')
        }
      } else {
        try {
          setIsReceivingLoading(true)
          AppointmentService.observeReceiving(async receiving => {
            let dates = []
            let parsedDates = []
            await receiving.reduce(async (_, item) => {
              const date = item.status.receivedOn

              if (dates.length > 0 && (await dateExists(dates, date))) {
                return null
              }
              if (date) {
                const parsedDate = format(
                  parseISO(date, 'MMddyyyy', new Date()),
                  'EEEE, LLLL do yyyy',
                )
                const dateToCompare = format(new Date(date), 'MMddyyyy')
                dates.push({ key: uuidv4(), date: dateToCompare })
                parsedDates.push({ key: uuidv4(), date: parsedDate })
              }

              return parsedDates
            }, {})

            setReceiving(await removeDup(dates))
            setIsReceivingLoading(false)
          })
        } catch (error) {
          setIsReceivingLoading(false)
          handleError(error, 'Error retrieving receiving')
        }
      }

      return () => {
        AppointmentService.unsubscribeReceiving()
      }
    }
    fetchData()
  }, [date])

  return {
    receiving,
    isReceivingLoading,
    isReceivingAppointmentsLoading,
    receivingAppointments,
  }
}
