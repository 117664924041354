import XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { TEST_REASONS_LIST } from '../constants'
import LocationService from '../services/location-service'
import getUUid from 'uuid-by-string'
import { parseDateOfBirth } from '../utils/date-utils'
import { parseISO, format } from 'date-fns'

export const exportCSV = (reportings, startDate, endDate) => {
  const ws = XLSX.utils.json_to_sheet(reportings)
  const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',' })
  const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' })
  saveAs(blob, `report_startDate-${startDate}-endDate-${endDate}.csv`)
}

export const exportCSVByOneDate = (title, TgNumber, reportings, date) => {
  const ws = XLSX.utils.json_to_sheet(reportings)
  const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',' })
  const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' })
  let correctTitle = title ? title.replace(' ', '_') : `TestGroup#${TgNumber}`
  saveAs(blob, `${correctTitle}_${date ?? 'Date'}.csv`)
}
export const exportCSVTemplate = () => {
  var template = [
    {
      Barcode: '',
      'First Name': 'Ezekiel',
      'Last Name': 'James',
      'Date of Birth': '4/22/1970',
      Email: 'random@email.com',
      'Driver License': '',
      SSN: '',
      Sex: 'Male',
      Race: 'Black',
      Ethnicity: 'Other',
      Symptoms: '',
      'Phone Number': '3211234141',
      'Street Address': 'Street 4',
      City: 'Long Beach',
      State: 'CA',
      'Zip Code': '91019',
      'Has Insurance': '',
      'Insurance Provider': '',
      'Insurance member ID': '',
      'Insurance Group Number': '',
      'Relationship to the insured': '',
    },
  ]
  const ws = XLSX.utils.json_to_sheet(template)
  const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',' })
  const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' })
  saveAs(blob, `TestGroup_Template.csv`)
}

const renderResult = result => {
  if (result === 'Negative') {
    return 'Not Detected'
  } else if (result === 'Positive') {
    return 'Detected'
  } else {
    return 'Invalid'
  }
}
const renderSnomedCode = result => {
  if (result === 'Negative') {
    return '260415000'
  } else if (result === 'Positive') {
    return '260373001'
  } else {
    return '419984006'
  }
}

export const formatDataToCSV = async reportings => {
  const reportingFormatted = reportings.map(async reporting => {
    // const location = await LocationService.get(reporting.locationId)
    // const { address, cityState, zipCode } = location.val()

    const receivedOnDate =
      reporting?.status?.receivedOn &&
      format(parseISO(reporting?.status?.receivedOn), 'MM/dd/yyyy')
    const receivedOnHour =
      reporting?.status?.receivedOn &&
      format(parseISO(reporting?.status?.receivedOn), 'hh:mm a')
    const collectedOnDate =
      reporting?.status?.slotsDateTime &&
      format(parseISO(reporting?.status?.slotsDateTime), 'MM/dd/yyyy')
    const collectedOnHour =
      reporting?.status?.slotsDateTime &&
      format(parseISO(reporting?.status?.slotsDateTime), 'hh:mm a')
    const resultEnteredDateDate =
      reporting?.resultEnteredDate &&
      format(parseISO(reporting?.resultEnteredDate), 'MM/dd/yyyy')
    const resultEnteredDateHour =
      reporting?.resultEnteredDate &&
      format(parseISO(reporting?.resultEnteredDate), 'hh:mm a')

    return {
      '#PatientID': getUUid(
        `${reporting.firstName}${reporting.lastName}${
          reporting.email
        }${parseDateOfBirth(reporting.dateOfBirth)}`,
      ),
      'Patient Last Name': reporting.lastName,
      'Patient First Name': reporting.firstName,
      'Patient Middle Name': reporting.middleName,
      'Patient Date of Birth': reporting.dateOfBirth,
      'Patient Sex': reporting.sexAtBirth,
      'Patient Street Address': reporting.street1,
      'Patient City': reporting.city,
      'Patient State': reporting.state,
      'Patient Zip': reporting.zip,
      'Patient Phone': reporting.phoneNumber,
      'Patient Email': reporting.email,
      ProviderFacilityName: reporting.providerFacilityName,
      'Provider Last Name': reporting.providerLastName,
      'Provider First Name': reporting.providerFirstName,
      'Provider Middle Name': reporting.providerMiddleName,
      'Provider Street': reporting.providerAddress,
      'Provider City': reporting.providerCity,
      'Provider State': reporting.providerState,
      'Provider Zip': reporting.providerZip,
      'Provider Phone': reporting.providerPhone,
      'Specimen ID': reporting.barcode,
      'Collection Date': reporting.collectedOnDate,
      'Collection Time': reporting.collectedOnHour,
      'Received Date': receivedOnDate,
      'Received Time': receivedOnHour,
      'Specimen SNOMED Code': renderSnomedCode(reporting.result),
      'Test LOINC Code': '96094-8',
      'Analysis Date': resultEnteredDateDate,
      'Analysis Time': resultEnteredDateHour,
      Result: reporting.result,
      'Reported Date': resultEnteredDateDate,
      'Reported Time': resultEnteredDateHour,
      Race: reporting.race,
      Ethnicity: reporting.ethnicity,
      'Accession ID': reporting.barcode,
      'Provider NPI': reporting.providerNPI,
    }
  })
  const reportingsFormatted = await Promise.all(reportingFormatted)
  return reportingsFormatted
}
